import React, {useState,useEffect} from 'react';
import axios from "../../axios_tteg";
import Cookies from 'universal-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import PaidIcon from '@mui/icons-material/Paid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import classes from "./ExpenseManagementSystem.module.css";
import paymentSuccessful from '../../assets/images/payment Successful.gif';
import paymentUnsuccessful from '../../assets/images/payment unsuccessful.gif';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Header from '../Dashboard/Components/Header/Header';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VerifiedIcon from '@mui/icons-material/Verified';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import AssessmentIcon from '@mui/icons-material/Assessment';
import DownloadIcon from '@mui/icons-material/Download';
import PaymentsIcon from '@mui/icons-material/Payments';
import Settlement from './Settlement';


let cookies = new Cookies();
const Reporting = (props)=>{
    let sumTotal = 0;
    const [projects,setProjects] = useState([]);
    const [loading,setLoading] = useState(false)
    const [transactionLog,setTransactionLog] = useState([]);
    const [selectedProjectID,setSelectedProjectID] = useState(null);
    const [selectedResourceID,setSelectedResourceID] = useState(null);
    const [balance,setBalance] = useState(null);
    const [viewTransactionDetails,setViewTransactionDetails] = useState(false);
    const [selectedTransaction,setSelectedTransaction] = useState(null);
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const [transactionType,setTransactionType] = useState(0);
    const [projectResourceAssociation,setProjectResourceAssociation] = useState([]);
    const [openSettlement,setOpenSettlement] = useState(false);
    const [uniquePayer,setUniquePayer] = useState([]);
    const [viewReceipt,setViewReceipt] = useState(false);
    const [selectedReceipt,setSelectedReceipt] = useState(null);
    
    useEffect(()=>{
        getTransactionLog(cookies.get('userData').resourceID);
        getProject();
    },[])

    useEffect(()=>{
        getTransactionLog(cookies.get('userData').resourceID);
        if(selectedProjectID)
            findUniquePayer()
    },[selectedProjectID])

    useEffect(()=>{
        if(selectedResourceID)
            getTransactionLog(selectedResourceID);
        else
            getTransactionLog(cookies.get('userData').resourceID);
    },[selectedResourceID])

    const getTransactionLog = (resourceID,start,end)=>{
        setLoading(true);
        axios.get('/getTransactionLog',{
            params:{
                resourceID:resourceID,
                projectID:selectedProjectID,
                startDate:start ? new Date(new Date(start).setHours(0,0,0,0)).toISOString().replace("Z", "+00:00"):null,
                endDate:end ? new Date(new Date(end).setHours(23,59,59,999)).toISOString().replace("Z", "+00:00"):null,
                acknowledged:true
            }
        })
        .then(response=>{
            setLoading(false);
            setTransactionLog(response.data.transactionLog);
            setBalance(calculateBalance(response.data.transactionLog));
            sumTotal = 0;
        })
        .catch(e=>console.log(e))
    }
    const getProject = ()=>{
        setLoading(true);
        axios.get('/getProjectDetails',
            {
                params:{
                    resourceID:cookies.get('userData').resourceID
                }
            }
        )
        .then(response=>{
            setLoading(false)
            setProjects(response.data.projectCase);
        })
        .catch(e=>{
            setLoading(false);
            console.log(e);
        })
    }

    const getProjectResourceAssociation = (projectID)=>{
        setLoading(true);
        axios.get('/getProjectResourceAssociation',{
            params:{
                projectID:projectID
            }
        }).then(response=>{
            setLoading(false);
            setProjectResourceAssociation(response.data.projectAssociation);

        })
    }

    const selectDateHandler = ()=>{
        if(new Date(startDate) && new Date(endDate) && (new Date(endDate) >= new Date(startDate))){

            // new Date(new Date(startDate).setHours(23,59,59,999)).toISOString().replace("Z", "+00:00")
            // new Date(new Date(endDate).setHours(23,59,59,999)).toISOString().replace("Z", "+00:00")
            // let tempTransactionLog = [... transactionLog];
            // tempTransactionLog
            getTransactionLog(selectedResourceID ? selectedResourceID:cookies.get('userData').resourceID,new Date(startDate),new Date(endDate));
        }
    }

    const calculateBalance = (transLog)=>{
        let credit = 0;
        let debit = 0;
        let settlementDone = 0;
        let settlementReceived = 0;
        transLog.map(item=>{
            if(item.payerID === (selectedResourceID ? selectedResourceID:cookies.get('userData').resourceID)){
                if(item.isSettlement === 'true'){
                    settlementDone += item.amount
                }
                else
                    debit += item.amount;
            }
            else if(item.payeeID === (selectedResourceID ? selectedResourceID:cookies.get('userData').resourceID)){
                if(item.isSettlement === 'true'){
                    settlementReceived += item.amount
                }
                else
                    credit += item.amount;
            }
        })
        return {
            debit:debit,
            credit:credit,
            settlementDone:settlementDone,
            settlementReceived:settlementReceived,
            balance : (credit+settlementReceived) - (debit+settlementDone)
        }
    }

    const findUniquePayer = ()=>{
        setLoading(true);
        axios.get('/getUniquePayer',{
            params:{
                resourceID:cookies.get('userData').resourceID,
                projectID:selectedProjectID,
                acknowledged:true
            }
        })
        .then(response=>{
            setLoading(false);
            let tempUniquePayer = [];
            response.data.uniquePayer.map(item=>{
                if(item.payerID !== cookies.get('userData').resourceID && item.payerSubscriberTypeID !== 'P-018'){
                    let isUnique = true 
                        tempUniquePayer.map(innerItem=>{
                            if(innerItem.resourceID == item.payerID){
                                isUnique = false
                            }  
                        })
                        if(isUnique)
                            tempUniquePayer.push({
                                resourceID:item.payerID,
                                name:item.payerName,
                                subscriberTypeName:item.payerSubscriberType
                        });
                }
                else if(item.payeeID !== cookies.get('userData').resourceID && item.payeeSubscriberTypeID !== 'P-018'){
                    let isUnique = true 
                    tempUniquePayer.map(innerItem=>{
                        if(innerItem.resourceID == item.payeeID){
                            isUnique = false
                        }  
                    })
                    if(isUnique)
                        tempUniquePayer.push({
                            resourceID:item.payeeID,
                            name:item.payeeName,
                            subscriberTypeName:item.payeeSubscriberType
                        });
                }
            })
            setUniquePayer(tempUniquePayer);
           
        })
        .catch(e=>console.log(e))
        
    }

    let container = null;
    if(loading){
        container = (
            <CircularProgress/>
        )
    }
    else if(openSettlement){
        container = (
            <Settlement
                transaction = {selectedTransaction}
                getData = {()=>{
                    setOpenSettlement(false)
                    getTransactionLog(selectedResourceID?selectedResourceID:cookies.get('userData'.resourceID));
                    // getUnacknowledgedTransactionLog();
                }}
            />
        )
    }
    else {
        container = (
            <div>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <div style={{display:'flex', flexWrap:'wrap',justifyContent:'center', alignItems:'center', width:'100%'}}>
                        <div style = {{background:'red', borderRadius:5, color:'white', padding:20, margin:10, width:320, textAlign:'end'}}>
                            <h5 style={{margin:0}}>Amount Paid: {'\u20B9'}{balance ? balance.debit:0}</h5>
                            <h5 style={{margin:0}}>Settlement Done: {'\u20B9'}{balance ? balance.settlementDone:0}</h5>
                            <h3 style={{margin:0}}>Net Amount Paid : {'\u20B9'}{balance ? balance.debit+balance.settlementDone:0}</h3>
                        </div>
                        <div style = {{background:'green', borderRadius:5, color:'white', padding:20, margin:10, width:320, textAlign:'end'}}>
                            <h5 style={{margin:0}}>Amount Received: {'\u20B9'}{balance ? balance.credit:0}</h5>
                            <h5 style={{margin:0}}>Settlement Received: {'\u20B9'}{balance ? balance.settlementReceived:0}</h5>
                            <h3 style={{margin:0}}>Net Amount Received : {'\u20B9'}{balance ? balance.credit+balance.settlementReceived:0}</h3>
                        </div>
                        <div style = {{background:'teal', borderRadius:5, color:'white', padding:'0px 20px', margin:10, width:320}}>
                            <h3>Remaining : {'\u20B9'}{balance ? balance.balance:0}</h3>
                        </div>
                    </div>
                    <CloseIcon style = {{padding:10, cursor:'pointer'}} onClick = {()=>props.getData(false)}/>
                </div>
                
                <div>
                    <div style = {{ margin:10, padding:10, display:'flex',flexWrap:'wrap',justifyContent:'center', alignItems:'center', background:'aliceblue'}}>
                        <FormControl style={{margin:10,minWidth:200, width:300}} >
                            <InputLabel id="demo-simple-select-label" size='small'>Select Project</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedProjectID}
                                label="Select Project"
                                style={{padding:0}}
                                size = 'small'
                                onChange={(e)=>{
                                    setSelectedProjectID(e.target.value);
                                    getProjectResourceAssociation(e.target.value);
                                }}
                            >
                                <MenuItem value = {null}>All Projects</MenuItem>
                                {projects.map((item,index)=>{
                                    return(
                                        <MenuItem value = {item.mProjectCase.projectID}>{item.mProjectCase.caseMaster[0].title}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        {cookies.get('userData').subscriberTypeID === 'P-018' ? (
                            <>
                                {projectResourceAssociation.length > 0 && projectResourceAssociation[0].vendorID === cookies.get('userData').resourceID ? (
                                    <FormControl style={{margin:10,minWidth:200, width:300}} >
                                        <InputLabel id="demo-simple-select-label" size='small'>Select Individual</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedResourceID}
                                            style={{padding:0}}
                                            size = 'small'
                                            onChange={(e)=>{
                                                setSelectedResourceID(e.target.value);
                                                sumTotal = 0;
                                            }}
                                        >
                                            <MenuItem value = {null}>Please Select</MenuItem>
                                            {projectResourceAssociation[0].vendorMaster.map(item=>{
                                                return(
                                                    <MenuItem value = {item.resourceID}>{item.subscriberTypeMaster[0].subscriberTypeName} - {item.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                ):null}
                            </>
                            
                        ):(
                            <>
                                {uniquePayer.length > 0 && cookies.get('userData').subscriberTypeID === 'F-002' ? (
                                    <FormControl style={{margin:10,minWidth:200, width:300}} >
                                        <InputLabel id="demo-simple-select-label" size='small'>Select Individual</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedResourceID}
                                            style={{padding:0}}
                                            size = 'small'
                                            onChange={(e)=>{
                                                setSelectedResourceID(e.target.value);
                                                sumTotal = 0;
                                            }}
                                        >
                                            <MenuItem value = {null}>Please Select</MenuItem>
                                            {uniquePayer.map(item=>{
                                                return(
                                                    <MenuItem value = {item.resourceID}>{item.subscriberTypeName} - {item.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                ):null}
                                
                            </>
                            
                        )}
                        {/* {projectResourceAssociation.length > 0 && projectResourceAssociation[0].vendorID === cookies.get('userData').resourceID ? (
                            <FormControl style={{margin:10,minWidth:200, width:300}} >
                                <InputLabel id="demo-simple-select-label" size='small'>Select Individual</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedResourceID}
                                    style={{padding:0}}
                                    size = 'small'
                                    onChange={(e)=>{
                                        setSelectedResourceID(e.target.value);
                                        sumTotal = 0;
                                    }}
                                >
                                    <MenuItem value = {null}>Please Select</MenuItem>
                                    {projectResourceAssociation[0].vendorMaster.map(item=>{
                                        return(
                                            <MenuItem value = {item.resourceID}>{item.subscriberTypeMaster[0].subscriberTypeName} - {item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        ):(
                            <FormControl style={{margin:10,minWidth:200, width:300}} >
                                <InputLabel id="demo-simple-select-label" size='small'>Select Individual</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedResourceID}
                                    style={{padding:0}}
                                    size = 'small'
                                    onChange={(e)=>{
                                        setSelectedResourceID(e.target.value);
                                        sumTotal = 0;
                                    }}
                                >
                                    <MenuItem value = {null}>Please Select</MenuItem>
                                    {uniquePayer.map(item=>{
                                        console.log(item)
                                        return(
                                            <MenuItem value = {item.resourceID}>{item.subscriberTypeMaster.subscriberTypeName} - {item.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        )} */}
                        
                        {/* <FormControl style={{margin:10,minWidth:200, width:300}} >
                            <InputLabel id="demo-simple-select-label" size='small'>Select Transaction Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={transactionType}
                                style={{padding:0}}
                                size = 'small'
                                onChange={(e)=>{setTransactionType(e.target.value);sumTotal = 0;}}
                            >
                                <MenuItem value = {0}>All Transactions</MenuItem>
                                <MenuItem value = {1}>Debit</MenuItem>
                                <MenuItem value = {2}>Credit</MenuItem>
                            </Select>
                        </FormControl> */}
                        <div style={{height:60,border:'1px dotted darkgray'}}></div>
                        <FormControl style={{margin:10,minWidth:200, width:300}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} style = {{margin:'0px 5px'}}>
                                <DesktopDatePicker
                                    label="Start Date"
                                    inputFormat="DD/MM/YYYY"
                                    maxDate={new Date()}
                                    value={startDate}
                                    onChange={(date)=>setStartDate(date)}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl style={{margin:10,minWidth:200, width:300}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} style = {{margin:'0px 5px'}} >
                                <DesktopDatePicker
                                    label="End Date"
                                    inputFormat="DD/MM/YYYY"
                                    minDate={startDate}
                                    maxDate={new Date()}
                                    value={endDate}
                                    onChange={(date)=>setEndDate(date)}
                                    renderInput={(params) => <TextField size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <Tooltip title = 'Filter'>
                            <IconButton>
                                <FilterAltIcon style={{color:'rgb(9, 56, 120)', cursor:'pointer', padding:'0px 5px', fontSize:24}}
                                    onClick = {()=>selectDateHandler()}/>        
                            </IconButton>
                        </Tooltip>
                        <Tooltip title = 'Clear'>
                            <IconButton>
                                <CloseIcon style={{color:'rgb(9, 56, 120)', cursor:'pointer', padding:'0px 5px', fontSize:24}}
                                    onClick = {()=>{
                                        setStartDate(null);
                                        setEndDate(null);
                                        getTransactionLog(cookies.get('userData').resourceID);
                                    }}/>        
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title = 'Download'>
                            <IconButton>
                                <DownloadIcon style={{color:'rgb(9, 56, 120)', cursor:'pointer', padding:'0px 5px', fontSize:24}}
                                    />
                            </IconButton>
                        </Tooltip> */}
                        
                    </div>
                </div>
                <>
                    <h3 style={{margin:5}}>Transactions</h3> 
                    <div>
                        {transactionLog.length > 0 ? (
                            <div>
                                <TableContainer component={Paper}style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow style = {{textAlign:'center', fontWeight:'bold'}}>
                                                <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                    Date / Time
                                                </TableCell>
                                                <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                    Transaction ID
                                                </TableCell>
                                                <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                    Project
                                                </TableCell>
                                                <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                    Paid To
                                                </TableCell>
                                                <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                    Paid By
                                                </TableCell>
                                                <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                    Mode
                                                </TableCell>
                                                <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                    Amount
                                                </TableCell>
                                                <TableCell style = {{textAlign:'center', fontWeight:'bold'}}>
                                                    Receipt
                                                </TableCell>
                                                <TableCell style = {{textAlign:'center', fontWeight:'bold'}}>

                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {transactionLog.map((item,index)=>{
                                                if(transactionType === 1){
                                                    if(item.payerID === cookies.get('userData').resourceID ){
                                                        if(item.payerID === cookies.get('userData').resourceID) 
                                                            sumTotal-= parseFloat(item.amount)
                                                        else
                                                            sumTotal+= parseFloat(item.amount)
                                                        return(
                                                            <TableRow>
                                                                <TableCell>
                                                                    {new Date(item.timestamp).toLocaleString('en-IN')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.transactionID}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.mProjectCase.caseMaster.title}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.payeeDetails.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.payerDetails.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.isSettlement === 'true'? 'Settlement':null}
                                                                    {item.mode === 1 ? 'Online' : item.mode === 2 ? 'Cash':null}

                                                                </TableCell>
                                                                
                                                                {selectedResourceID ? (
                                                                    <TableCell style={{color:item.payerID === selectedResourceID ? 'red':'green'}}>
                                                                        {item.payerID === selectedResourceID ? `- ${item.amount}`: item.amount}
                                                                    </TableCell>
                                                                ):(
                                                                    <TableCell style={{color:item.payerID === cookies.get('userData').resourceID ? 'red':'green'}}>
                                                                        {item.payerID === cookies.get('userData').resourceID ? `- ${item.amount}`: item.amount}
                                                                    </TableCell>
                                                                )}
                                                                <TableCell>
                                                                    {item.receipt ? (
                                                                        <Tooltip title = 'Receipt'>
                                                                            <IconButton>
                                                                                <SummarizeIcon 
                                                                                    style={{color:'teal', cursor:'pointer'}}
                                                                                    onClick = {()=>{
                                                                                        setSelectedReceipt(item.receipt);
                                                                                        setViewReceipt(true)
                                                                                        // window.open(item.receipt, '_blank', 'noreferrer')
                                                                                    }}/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ):null}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.isSettlement === 'false'  ? (
                                                                        <Tooltip title = 'Settlement'>
                                                                            <IconButton>
                                                                                <PaymentsIcon 
                                                                                    style={{color:'teal', cursor:'pointer'}}
                                                                                    onClick = {()=>{
                                                                                        setOpenSettlement(true);
                                                                                        setSelectedTransaction(item)
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ):null}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <KeyboardArrowRightIcon 
                                                                        style = {{cursor:'pointer'}}
                                                                        onClick = {()=> {
                                                                            setViewTransactionDetails(true);
                                                                            setSelectedTransaction(item)
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    
                                                }
                                                else if(transactionType === 2){
                                                    if(item.payeeID === cookies.get('userData').resourceID){
                                                        if(item.payerID === cookies.get('userData').resourceID) 
                                                            sumTotal-= parseFloat(item.amount)
                                                        else
                                                            sumTotal+= parseFloat(item.amount)
                                                        return(
                                                            <TableRow>
                                                                <TableCell>
                                                                    {new Date(item.timestamp).toLocaleString('en-IN')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.transactionID}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.mProjectCase.caseMaster.title}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.payeeDetails.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.payerDetails.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.isSettlement === 'true'? 'Settlement':null}
                                                                    {item.mode === 1 ? 'Online' : item.mode === 2 ? 'Cash':null}

                                                                </TableCell>
                                                                {selectedResourceID ? (
                                                                    <TableCell style={{color:item.payerID === selectedResourceID ? 'red':'green'}}>
                                                                        {item.payerID === selectedResourceID ? `- ${item.amount}`: item.amount}
                                                                    </TableCell>
                                                                ):(
                                                                    <TableCell style={{color:item.payerID === cookies.get('userData').resourceID ? 'red':'green'}}>
                                                                        {item.payerID === cookies.get('userData').resourceID ? `- ${item.amount}`: item.amount}
                                                                    </TableCell>
                                                                )}
                                                                <TableCell>
                                                                    {item.receipt ? (
                                                                        <Tooltip title = 'Receipt'>
                                                                            <IconButton>
                                                                                <SummarizeIcon 
                                                                                    style={{color:'teal', cursor:'pointer'}}
                                                                                    onClick = {()=>{
                                                                                        setSelectedReceipt(item.receipt);
                                                                                        setViewReceipt(true)
                                                                                        // window.open(item.receipt, '_blank', 'noreferrer')
                                                                                    }}/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ):null}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.isSettlement === 'false'  ? (
                                                                        <Tooltip title = 'Settlement'>
                                                                            <IconButton>
                                                                                <PaymentsIcon 
                                                                                    style={{color:'teal', cursor:'pointer'}}
                                                                                    onClick = {()=>{
                                                                                        setOpenSettlement(true);
                                                                                        setSelectedTransaction(item)
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ):null}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <KeyboardArrowRightIcon 
                                                                        style = {{cursor:'pointer'}}
                                                                        onClick = {()=> {
                                                                            setViewTransactionDetails(true);
                                                                            setSelectedTransaction(item)
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                    
                                                }
                                                else{
                                                    if(item.payerID === cookies.get('userData').resourceID) 
                                                        sumTotal-= parseFloat(item.amount)
                                                    else
                                                        sumTotal+= parseFloat(item.amount)
                                                    return(
                                                        <TableRow>
                                                            <TableCell>
                                                                {new Date(item.timestamp).toLocaleString('en-IN')}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.transactionID}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.mProjectCase.caseMaster.title}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.payeeDetails.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.payerDetails.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.isSettlement === 'true'? 'Settlement':null}
                                                                {item.mode === 1 ? 'Online' : item.mode === 2 ? 'Cash':null}

                                                            </TableCell>
                                                            {selectedResourceID ? (
                                                                <TableCell style={{color:item.payerID === selectedResourceID ? 'red':'green'}}>
                                                                    {item.payerID === selectedResourceID ? `- ${item.amount}`: item.amount}
                                                                </TableCell>
                                                            ):(
                                                                <TableCell style={{color:item.payerID === cookies.get('userData').resourceID ? 'red':'green'}}>
                                                                    {item.payerID === cookies.get('userData').resourceID ? `- ${item.amount}`: item.amount}
                                                                </TableCell>
                                                            )}
                                                            <TableCell>
                                                                {item.receipt ? (
                                                                    <Tooltip title = 'Receipt'>
                                                                        <IconButton>
                                                                            <SummarizeIcon 
                                                                                style={{color:'teal', cursor:'pointer'}}
                                                                                onClick = {()=>{
                                                                                    setSelectedReceipt(item.receipt);
                                                                                    setViewReceipt(true)
                                                                                    // window.open(item.receipt, '_blank', 'noreferrer')
                                                                                }}
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ):null}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.isSettlement === 'false'  ? (
                                                                    <Tooltip title = 'Settlement'>
                                                                        <IconButton>
                                                                            <PaymentsIcon 
                                                                                style={{color:'teal', cursor:'pointer'}}
                                                                                onClick = {()=>{
                                                                                    setOpenSettlement(true);
                                                                                    setSelectedTransaction(item)
                                                                                }}
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ):null}
                                                            </TableCell>
                                                            <TableCell>
                                                                <KeyboardArrowRightIcon 
                                                                    style = {{cursor:'pointer'}}
                                                                    onClick = {()=> {
                                                                        setViewTransactionDetails(true);
                                                                        setSelectedTransaction(item)
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                                
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        ):(
                            <div>
                                <p>No Transaction Logs</p>
                            </div>
                        )}
                    </div>
                </>
            </div>
        )
    }
    return(
        <div>
            {container}
            <Dialog onClose={()=>{setViewTransactionDetails(false)}} open={viewTransactionDetails}>
                <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <DialogTitle>Transaction Details</DialogTitle>
                    <div>
                        <CloseIcon onClick = {()=>{setViewTransactionDetails(false)}} style = {{padding:10, cursor:'pointer'}}/>
                    </div>
                </Typography>
                <Typography>
                    {loading?(<CircularProgress/>):(
                        <div>
                            {selectedTransaction ? (
                                <div>
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center', margin:10}}>
                                        <CurrencyRupeeIcon style = {{fontSize:42, fontWeight:'bold'}}/>
                                        <p style = {{fontSize:42, fontWeight:'bold', margin:0}}>{selectedTransaction.amount}</p>
                                    </div>
                                    <div style={{textAlign:'center'}}>
                                        <>
                                            <img src = {paymentSuccessful} width={80}/>
                                        </> 
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table" style={{margin:'10px 0px', background:'#f2f2f2', overflow:'wrap'}}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Date - Time
                                                        </TableCell>
                                                        <TableCell>
                                                            {new Date(selectedTransaction.timestamp).toLocaleString('en-IN')}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Transaction ID
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.transactionID}
                                                        </TableCell>
                                                    </TableRow>            
                                                    <TableRow>
                                                        <TableCell>
                                                            Amount
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.amount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>  
                                                        <TableCell>
                                                            Payment Mode
                                                        </TableCell>  
                                                        <TableCell>
                                                            {selectedTransaction.mode === 1 ? 'Online' : 'Cash'}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>  
                                                        <TableCell>
                                                            Description
                                                        </TableCell>  
                                                        <TableCell>
                                                            {selectedTransaction.description}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <p>Project Details</p>
                                            <Table style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Project Title
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.mProjectCase.caseMaster.title}
                                                        </TableCell>
                                                    </TableRow>        
                                                    <TableRow>
                                                        <TableCell>
                                                            Latitude
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.mProjectCase.caseMaster.location.latitude}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Longitude
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.mProjectCase.caseMaster.location.longitude}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Distance (in Km)
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.mProjectCase.caseMaster.location.distance}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Project Description
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.mProjectCase.caseMaster.caseDescription}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <p>Payee Details</p>
                                            <Table style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Name
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payeeDetails.name}
                                                        </TableCell>
                                                    </TableRow>        
                                                    <TableRow>
                                                        <TableCell>
                                                            Email
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payeeDetails.email}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Mobile
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payeeDetails.mobile}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Subscriber Type
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payeeDetails.subscriberTypeMaster.subscriberTypeName}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <p>Payer Details</p>
                                            <Table style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Name
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payerDetails.name}
                                                        </TableCell>
                                                    </TableRow>        
                                                    <TableRow>
                                                        <TableCell>
                                                            Email
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payerDetails.email}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Mobile
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payerDetails.mobile}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Subscriber Type
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payerDetails.subscriberTypeMaster.subscriberTypeName}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            {selectedTransaction.isSettlement == 'true' ? (
                                                <>
                                                    <p>Transactions</p>
                                                    <Table style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    S.No
                                                                </TableCell>
                                                                <TableCell>
                                                                    Amount
                                                                </TableCell>
                                                                <TableCell>
                                                                    Description
                                                                </TableCell>
                                                                <TableCell>
                                                                    Mode
                                                                </TableCell>
                                                                <TableCell>
                                                                    Receipt
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {selectedTransaction.settlementDetails && selectedTransaction.settlementDetails.map((item,index)=>(
                                                                
                                                                <TableRow>
                                                                    <TableCell>
                                                                        {index+1}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.amount}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.description}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.mode === 1 ? 'Online':'Cash'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.receipt ? (
                                                                            <Tooltip title = 'Receipt'>
                                                                                <IconButton>
                                                                                    <SummarizeIcon 
                                                                                        style={{color:'teal', cursor:'pointer'}}
                                                                                        onClick = {()=>{
                                                                                            setSelectedReceipt(item.receipt);
                                                                                            setViewReceipt(true)
                                                                                            // window.open(item.receipt, '_blank', 'noreferrer')
                                                                                        }}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        ):null}
                                                                    </TableCell>
                                                                </TableRow>
                                                                
                                                                    
                                                            ))}
                                                        </TableBody>
                                                            
                                                        
                                                    </Table>
                                                </>
                                            ):null}
                                            
                                        </TableContainer>
                                        <CardActions style={{justifyContent:'space-around'}}>
                                            <Button 
                                                style = {{background: 'teal',color: 'white',width: 120,padding: 10}}
                                                size="small" 
                                                onClick = {()=>{
                                                        setViewTransactionDetails(false);
                                                        setSelectedTransaction(null);
                                                    } 
                                                }>OK</Button>
                                        </CardActions>
                                    </div>
                                </div>
                            ):(
                                null
                            )}
                        </div>
                        
                    )}
                    
                </Typography>
            </Dialog>
            <Dialog onClose={()=>{setViewReceipt(false)}} open={viewReceipt}>
                <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <DialogTitle>Receipt</DialogTitle>
                    <div>
                        <DownloadIcon style = {{padding:10, cursor:'pointer'}}
                            onClick = {()=>{window.open(selectedReceipt, '_blank', 'noreferrer')}}
                        />
                        <CloseIcon onClick = {()=>{setViewReceipt(false)}} style = {{padding:10, cursor:'pointer'}}/>
                    </div>
                </Typography>
                <Typography>
                    <div style = {{margin:20}}>
                        <img src = {selectedReceipt} width='100%'/>
                    </div>
                </Typography>
            </Dialog>
        </div>
    )
}

export default Reporting