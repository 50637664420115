import React from 'react';
import LoginLocationLog from "../../AdminDashboard/MainComponent/LoginLocationLog";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";

const cookies = new Cookies();

const MyLocation = ()=>{
    let navigate = useNavigate();
    return(
        <div>
            <Header/>
            <div style={{background: 'rgb(83, 69, 154)'}}>
                <h3 style={{color:'white', margin:0, padding:10}}>My Last 10 Locations</h3>
            </div>
            <div>
                <LoginLocationLog
                    resourceID = {cookies.get('userData').resourceID}
                    getData = {(data)=>{
                        console.log(data)
                        navigate('/')
                    }}
                />
            </div>
        </div>
        
    )
}

export default MyLocation