import React, {useState, useEffect,useContext} from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from "../../axios_tteg";
import { CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import classes from "../Dashboard/Components/tabPanel1/tabPanel1.module.css";
import './AddResource.css'


function AddResourceToSubProj(props) {
    //stores all the already associated individual with subProject 
    const [alreadyAssociated,setAlreadyAssociated]=useState(null)
    //stores the all available resources initially but this updates 
    const [newAlreadyAssociated,setNewAlreadyAssociated]=useState(null)
    console.log(newAlreadyAssociated)
    //will store all available to associate so that can be appended in newAssociatedIndividual later 
    const [allAvailableAssociatedIndividual,setAllAvailableAssociatedIndividual]=useState([])
    const [vendorID,setVendorID]=useState(null)
    const [subscriberTypes,setSubscriberTypes]=useState([])
    const [subProjects,setSubProjects]=useState([])
    const [subProjectAssociations,setSubProjectAssociations]=useState(null)
    const [loading,setLoading] = useState(false);
    const [selectedSubscriberType,setSelectedSubscriberType]=useState(null)
    const [selectedSubProjNo,setSelectedSubProjNo]=useState(null)
    const [availableSubProjNo,setAvailableSubProjNo]=useState([])
    const [openDialog, setOpenDialog]=useState(false)
    const [startDate,setStartDate] = useState(new Date());
    const [endDate,setEndDate] = useState(new Date());
    const [initialAssiciatedSubProjs,setInitialAssiciatedSubProjs]=useState([]);
    const [tempSelectedIndividual,setTempSelectedIndividual] = useState(null);
    const [noOfSubProjects,setNoOfSubProjects]=useState(0)
    const [startDateLimit,setStartDateLimit]=useState(null)
    console.log(startDateLimit)
    const [endDateLimit,setEndDateLimit]=useState(null)
    console.log(endDateLimit)
    const [grid,setGrid]=useState([]);
    const handleChangeDuration = (key,value)=>{
        if(key === 'startDate'){
            setStartDate(value);
        }
        else if(key === 'endDate'){
            setEndDate(value)
        }
    }

    const availableSubProjNoArray=(n)=>{
        let newArray=[]
        for(let i=1;i<=n;i++){
            newArray.push(i);
        }
        return newArray
    }

    const updateCell = (rowIndex, value) => {
        
        let newGrid = [...grid]; 
        if(newGrid[rowIndex]===undefined){
            for(let i=-0;i<=rowIndex;i++){
                newGrid.push([])
            }
        }
        newGrid[rowIndex].push(value)
        setGrid(newGrid); 
    };

    

    const getAlreadyAssociated=async()=>{
        setLoading(true)
        await axios.get('/getSubProjectResourceAssociation',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setSubProjectAssociations(response.data.data)
            setLoading(false)
        })
        await axios.get('/getProjectResourceAssociation',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setLoading(false);
            const resources=[]
            const vendorMaster=[]
            for(let i=0;i<response.data.projectAssociation[0].resources.length;i++){
                const resource=response.data.projectAssociation[0].resources[i]
                if(resource.used===false){
                    resources.push(resource);
                    response.data.projectAssociation[0].vendorMaster.map(item=>{
                        if(item.resourceID === resource.resourceID){
                            vendorMaster.push(item)
                        }
                    })
                    // vendorMaster.push(response.data.projectAssociation[0].vendorMaster[i])
                }
            }
            
            let associations=response.data.projectAssociation[0]
            associations.resources=resources
            associations.vendorMaster=vendorMaster
            //destructuring so that later unexpated variable does not occur

            setAllAvailableAssociatedIndividual({ ...associations })
            setAlreadyAssociated({ ...associations })
            setNewAlreadyAssociated({ ...associations })
            setVendorID(response.data.projectAssociation[0].vendorID)
        })
        
        
    }

    const getSubscriberType = ()=>{
        setLoading(true);
        axios.get('/getSubscriberTypes')    
        .then(response=>{
            setSubscriberTypes(response.data.data)
            setLoading(false)
        })
    }

    const subProject = ()=>{
        setLoading(true)
        axios.get('/getSubProjects',{
            params:{
                projectID:props.caseDetails.projectID
            }
        })
        .then(response=>{
            // let tempGrid = []
            // response.data.subProject[0].data.map(item=>{
            //     console.log("in map with subProjectNumber:",item)
            //     const newArray=associationOfParticularSubProj(item.subProjectNo)
            //     if(newArray.length>0){
            //         console.log("pushed lengthed")
            //         tempGrid.push(newArray[0].vendorMaster)
            //     }
            //     else{
            //         tempGrid.push([])
            //         console.log("pushed empty")
            //     }
                
            // })
            // setGrid(tempGrid);
            setSubProjects(response.data.subProject)
            setAvailableSubProjNo(availableSubProjNoArray(response.data.subProject[0].data.length))
            setLoading(false)
        })
    }

    useEffect(()=>{
        getAlreadyAssociated()
        getSubscriberType()
        subProject()
    },[])

    const removeElement=(row,col,item,subProjectNo)=>{

        let resources=newAlreadyAssociated.resources
        let vendorMaster=newAlreadyAssociated.vendorMaster

        for(let i=0;i<allAvailableAssociatedIndividual.resources.length;i++){
            if(allAvailableAssociatedIndividual.resources[i].resourceID===grid[row][col].item.resourceID){
                resources.push(allAvailableAssociatedIndividual.resources[i])
            }
        }
        
        for(let i=0;i<allAvailableAssociatedIndividual.vendorMaster.length;i++){
            if(allAvailableAssociatedIndividual.vendorMaster[i].resourceID===grid[row][col].vendorMaster.resourceID){
                vendorMaster.push(allAvailableAssociatedIndividual.vendorMaster[i])
            }
        }
        if(resources.length===newAlreadyAssociated.resources.length && vendorMaster.length === newAlreadyAssociated.vendorMaster.length){
            removeASubProjectAssociation(subProjectNo,item.item.resourceID)
            const newArray=grid
            newArray[row].splice(col,1)
            setGrid(newArray)
        }
        else{

            let associations={... newAlreadyAssociated}
            associations.resources=resources
            associations.vendorMaster=vendorMaster
            setNewAlreadyAssociated(associations)

            const newArray=grid
            newArray[row].splice(col,1)
            setGrid(newArray)
        }
    }

    const initiallyAssociatedSubProjects=async()=>{
        await axios.get('/getSubProjectResourceAssociation',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            if(response.data.data){
                // change
                let tempGrid = []
                response.data.data.map((outerItem,outerIndex)=>{
                    tempGrid.push([])
                })
                for(let i=0;i<response.data.data.length;i++){
                    const association=response.data.data[i]
                    if(association.resources.length>0 && association.vendorMaster.length>0){
                        for(let j=0;j<association.resources.length;j++){
                            let resource=association.resources[j]
                            let item={}
                            item.vendorMaster={}
                            item.vendorMaster.name=association.vendorMaster[j].name
                            item.vendorMaster.domainID=association.vendorMaster[j].domainID
                            item.vendorMaster.subscriberTypeMaster=[]
                            let dict={}
                            dict.subscriberTypeName=association.vendorMaster[j].subscriberTypeMaster[0].subscriberTypeName
                            item.vendorMaster.subscriberTypeMaster.push(dict)
                            item.vendorMaster.resourceTypeID=association.vendorMaster[j].resourceTypeID
                            item.item={}
                            item.item=resource
                            
                            // let gridCopy=[... grid]
                            tempGrid[association.subProjectNo-1].push(item)
                            setGrid(tempGrid)
                        }
                       
                    }
                    
                }
              
            }

        })

        }

    useEffect(()=>{
        initiallyAssociatedSubProjects()
    },[])

    // const associationOfParticularSubProj=(subProjNo)=>{

    //     const newArray=subProjectAssociations?.filter(item=>item.subProjectNo===subProjNo)
    //     console.log("new Array:",newArray)
    //     if(newArray===undefined){
    //         return []
    //     }
    //     return newArray
        
    // }

    const handleSubmit=async ()=>{
        if(props.caseDetails.resourceID){
            let response=null
            for(let i=0;i<subProjects[0]?.data.length;i++){
                let body={}
                body.projectID=props.caseDetails.projectID
                body.vendorID=props.caseDetails.resourceID
                body.subProjectNo=i+1
                let resources=[]
                for(let j=0;j<grid[i]?.length;j++){
                    resources.push(grid[i][j].item)
                }
                body.resources=resources
                
                if(resources.length>0){
                    response=await axios.post('/subProjectResourceAssociation',body);
                }

            }
            if(response.status===200){
                props.getData(false)
            }
            
        }

    }

    const removeASubProjectAssociation=async (subProjNo,resourceIDGiven)=>{
        const response=await axios.post('/removeSubProjectAssociation',
        {
            projectID:props.caseDetails.projectID,
            resourceID:resourceIDGiven,
            subProjectNo:subProjNo
        })
        if(response.status===200){
            getAlreadyAssociated()
        }
   }

    const getDate = (resourceID)=>{
        let date = {
            startDate:null,
            endDate:null
        }
        newAlreadyAssociated.resources.map(item=>{
            if(item.resourceID === resourceID){
                date.startDate = item.startDate;
                date.endDate = item.endDate
            }
        });
        return date
    }

    let container=null
    if(loading ){
        container = (<CircularProgress/>)
    }
    else{
        container=(
            <div >
                <div style = {{width:'100%'}}>
                    <ArrowBackIcon style={{float:'left', cursor:'pointer'}} onClick = {()=>props.getData('back')}/>
                    <h3 style={{textAlign:'center'}}>Assign Resources to Sub-Projects</h3>
                </div>
                <div>
                    <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
                        <DialogTitle>Associate</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Thank you for showing intreast. Our sales team will call you for future references.
                            </DialogContentText>
                            <div className={classes.AssociateResourceStart}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="Start Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={startDate}
                                        minDate={new Date()}
                                        maxDate={tempSelectedIndividual ? getDate(tempSelectedIndividual.item.resourceID).endDate: new Date()}
                                        onChange={(date)=>handleChangeDuration('startDate',date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    <DesktopDatePicker
                                        label="End Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate}
                                        minDate={new Date()}
                                        maxDate={tempSelectedIndividual ? getDate(tempSelectedIndividual.item.resourceID).endDate: new Date()}
                                        onChange={(date)=>handleChangeDuration('endDate',date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>setOpenDialog(false)}>Cancel</Button>
                            {/* <Button onClick={requestForAssociation}>Submit</Button> */}
                            <Button onClick={()=>{
                                console.log("Clicked")
                                let tSelectedIndividual = {...tempSelectedIndividual}
                                tSelectedIndividual.item.startDate = startDate;
                                tSelectedIndividual.item.endDate = endDate;
                                setTempSelectedIndividual(tSelectedIndividual);
                                updateCell(selectedSubProjNo-1,tSelectedIndividual)
                                let tempNewAlreadyAssociated = {... newAlreadyAssociated}
                                let tempResources = []
                                let tempVendorMaster = []
                                tempNewAlreadyAssociated.resources.map(item=>{
                                    if(item.resourceID!==tSelectedIndividual.item.resourceID){
                                        tempResources.push(item)
                                    }
                                })
                                tempNewAlreadyAssociated.vendorMaster.map(item=>{
                                    if(item.resourceID!==tSelectedIndividual.item.resourceID){
                                        tempVendorMaster.push(item)
                                    }
                                })
                                tempNewAlreadyAssociated.resources=tempResources
                                tempNewAlreadyAssociated.vendorMaster=tempVendorMaster
                                setNewAlreadyAssociated(tempNewAlreadyAssociated) 
                                setTempSelectedIndividual(null);
                                setOpenDialog(false)
                            }}>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div style = {{display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-around',alignItems:'start',gap:'10px'}}>
                    <div style = {{width:'50%',display:'flex',flexDirection:'column',borderRight:'1px solid gainsbro',alignItems:'center', margin:10}}>
                        <h3>Available Resources to a project</h3>
                        <div>
                            <TableContainer className="availableResourceTable">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Name</TableCell>
                                            <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Domain</TableCell>
                                            {subscriberTypes.length>0?
                                                <TableCell>
                                                    <FormControl style={{padding:'0px 10px', minWidth:150}}>
                                                        <InputLabel id="demo-simple-select-label" style={{top:-8}}>Subscriber Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            size="small"
                                                            value={selectedSubscriberType}
                                                            onChange={(e)=>{
                                                                if(!e.target.value){
                                                                    setNewAlreadyAssociated(alreadyAssociated)
                                                                    setSelectedSubscriberType(e.target.value)
                                                                }
                                                                else{
                                                                setSelectedSubscriberType(e.target.value)
                                                                const newArray=alreadyAssociated.filter((item)=>item.individualResourceAssociation.subscriberTypeID===selectedSubscriberType)
                                                                setNewAlreadyAssociated(newArray)}
                                                            }}
                                                        >
                                                        
                                                            <MenuItem value = {null}>All Type Subscribers</MenuItem>
                                                                {subscriberTypes.map(item=>{
                                                                    return(
                                                                        <MenuItem value = {item?.subscriberTypeID}>{item?.subscriberTypeName}</MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>:"no Subscriber Type"
                                            }
                                            <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Type</TableCell>
                                            <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>SubProj. No.</TableCell>
                                            <TableCell style={{fontWeight:'bolder'}}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            newAlreadyAssociated?
                                            newAlreadyAssociated?.vendorMaster.map((item,index)=>(
                                            <TableRow key={index}>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.domainID}</TableCell>
                                                <TableCell>{item.subscriberTypeMaster[0].subscriberTypeName}</TableCell>
                                                <TableCell>{item.resourceTypeID === 'I' ? 'Individual':'Company'}</TableCell>
                                                <TableCell>
                                                    <FormControl style={{minWidth:100}}>
                                                        <InputLabel id="demo-simple-select-label">SubProj. No.</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectedSubProjNo}
                                                            size="small"
                                                            onChange={(e)=>{
                                                                setSelectedSubProjNo(e.target.value)
                                                            }}
                                                        >
                                                        
                                                            <MenuItem value = {null}>SubProj. No.</MenuItem>
                                                                {availableSubProjNo?.map(item2=>{
                                                                    return(
                                                                        <MenuItem value = {item2}>{item2}</MenuItem>
                                                                    )
                                                                })}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    <p 
                                                        className="addResourceRemoveBtn addResourceMoveToBtn"
                                                        onClick={()=>{
                                                            let tSelectedIndividual = {... tempSelectedIndividual};
                                                            tSelectedIndividual.item = {
                                                                resourceID:item.resourceID,
                                                                subscriberTypeID:item.subscriberTypeMaster[0].subscriberTypeID,
                                                                subscriberTypeName:item.subscriberTypeMaster[0].subscriberTypeName,
                                                                used:false
                                                            }
                                                            tSelectedIndividual.vendorMaster = item
                                                            setTempSelectedIndividual(tSelectedIndividual);
                                                            setOpenDialog(true);

                                                            setStartDateLimit(new Date())
                                                            setEndDateLimit(new Date(newAlreadyAssociated.resources[index].endDate))
                                                        }}
                                                    >   
                                                    Move To Proj.</p>
                                                </TableCell>

                                            </TableRow>
                                            ))
                                            :<div></div>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    
                    </div>
                
                    
                    <div style={{width:'50%',display:'flex',flexDirection:'column',alignItems:'center',overflowX:'hidden',overflowY:"auto",height:'500px', margin:10}}>
                        <h3>Available SubProjects To Add</h3>
                        <div style={{width:'100%'}}>
                            {
                                subProjects && subProjects.length>0?
                                subProjects[0].data.map((item,index)=>(
                                    <div>
                                        <h3>Sub Project Association {index+1}</h3>
                                        <TableContainer className="addedResourceTable" style={{background:'#FFFADA',boxShadow:'2px 2px 20px #FFFADA'}}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{fontWeight:'bolder'}}>Name</TableCell>
                                                        <TableCell style={{fontWeight:'bolder'}}>Domain</TableCell>
                                                        <TableCell style={{fontWeight:'bolder'}}>Subscriber Type</TableCell>
                                                        <TableCell style={{fontWeight:'bolder'}}>Type</TableCell>
                                                        <TableCell style={{fontWeight:'bolder'}}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        grid[index]?.map((item2,index2)=>(
                                                            item2?(
                                                                <TableRow>
                                                                    <TableCell>{item2.vendorMaster.name}</TableCell>
                                                                    <TableCell>{item2.vendorMaster.domainID}</TableCell>
                                                                    <TableCell>{item2.vendorMaster.subscriberTypeMaster[0].subscriberTypeName}</TableCell>
                                                                    <TableCell>{item2.vendorMaster.resourceTypeID === 'I' ? 'Individual':'Company'}</TableCell>
                                                                    <TableCell>
                                                                        <p 
                                                                            className="addResourceRemoveBtn"
                                                                            onClick={
                                                                            ()=>{
                                                                                    removeElement(index,index2,item2,index+1)
                                                                                    setSelectedSubProjNo(!selectedSubProjNo)
                                                                                    // let tSelectedIndividual = {... tempSelectedIndividual};
                                                                                    // tSelectedIndividual.item = {
                                                                                    //     resourceID:item.resourceID,
                                                                                    //     subscriberTypeID:item.subscriberTypeMaster[0].subscriberTypeID,
                                                                                    //     subscriberTypeName:item.subscriberTypeMaster[0].subscriberTypeName,
                                                                                    //     used:false
                                                                                    // }
                                                                                    // tSelectedIndividual.vendorMaster = item
                                                                                    // setTempSelectedIndividual(tSelectedIndividual);
                                                                            } 
                                                                            }
                                                                        >   
                                                                        Remove</p>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ):(<div></div>)
                                                        ))
                                                    }
                                                
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                )):<div></div>
                            }
                            </div>
                        <div style={{padding:'10px'}}>
                            <Button 
                                onClick={handleSubmit}
                            variant="outlined">Add</Button>

                        </div>
                        </div>
                    </div>

        </div>
        )
    }

  return (
    <div>{container}</div>
  )
}

export default AddResourceToSubProj