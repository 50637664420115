import React, {useState,useEffect} from 'react';
import axios from "../../axios_tteg";
import Cookies from 'universal-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import PaidIcon from '@mui/icons-material/Paid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import classes from "./ExpenseManagementSystem.module.css";
import paymentSuccessful from '../../assets/images/payment Successful.gif';
import paymentUnsuccessful from '../../assets/images/payment unsuccessful.gif';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Header from '../Dashboard/Components/Header/Header';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ClearIcon from '@mui/icons-material/Clear';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VerifiedIcon from '@mui/icons-material/Verified';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";
import AssessmentIcon from '@mui/icons-material/Assessment';
import Reporting from './Reporting';
import PaymentsIcon from '@mui/icons-material/Payments';
import Settlement from './Settlement';
import unacknowledged from '../../../src/assets/images/unacknowledged.png';
import DownloadIcon from '@mui/icons-material/Download';

const cookies = new Cookies();

const ExpenseManagementSystem = (props)=>{
    let navigate = useNavigate();
    const [projects,setProjects] = useState([]);
    const [loading,setLoading] = useState(false)
    const [transactionLog,setTransactionsLog] = useState([]);
    const [selectedProjectID,setSelectedProjectID] = useState(null);
    const [addNewTransaction,setAddNewTransaction] = useState(false);
    const [openSettlement,setOpenSettlement] = useState(false);
    const [selectedIndividualResources,setSelectedIndividualResources] = useState(null);
    const [projectResourceAssociation,setProjectResourceAssociation] = useState([]);
    const [selectedAmount,setSelectedAmount] = useState({
        value:null,
        mode:null
    })
    const [agreeCheckBox,setAgreeCheckBox] = useState(null);
    const [selectedFile,setSelectedFile] = useState(null);
    const [fileToUpload,setFileToUpload] = useState(null);
    const [description,setDescription] = useState(null);
    const [transactionResponse,setTransactionResponse] = useState(null);
    const [balance,setBalance] = useState(0);
    const [uniquePayer,setUniquePayer] = useState([]);
    const [viewTransactionDetails,setViewTransactionDetails] = useState(false);
    const [selectedTransaction,setSelectedTransaction] = useState(null);
    const [pageNumber,setPageNumber] = useState(1);
    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const [totalPages,setTotalPages] = useState(1);
    const [notificationCounter,setNotificationCounter] = useState(null);
    const [openNotification,setOpenNotification] = useState(false);
    const [unacknowledgedTransactionLog, setUnacknowledgedTransactionLog] = useState([]);
    const [unacknowledgedTotalPages,setUnacknowledgedTotalPages] = useState(1);
    const [unacknowledgedbalance,setUnacknowledgedBalance] = useState(0);
    const [openConfirmation,setOpenConfirmation] = useState(false);
    const [selectedTransactionID,setSelectedTransactionID] = useState(null);
    const [openReporting,setOpenReporting] = useState(false);
    const [viewReceipt,setViewReceipt] = useState(false);
    const [selectedReceipt,setSelectedReceipt] = useState(null);

    const pageSize = 5;
    useEffect(()=>{
        getProject();
        getTransactionLog();
        getUnacknowledgedTransactionLog();
    },[])

    useEffect(()=>{
        if(selectedProjectID){
            getProjectResourceAssociation(selectedProjectID);
            findUniquePayer();
        }
        getTransactionLog();
        getUnacknowledgedTransactionLog();
    },[selectedProjectID, pageNumber, openSettlement])

    

    const getProject = ()=>{
        setLoading(true);
        axios.get('/getProjectDetails',
            {
                params:{
                    resourceID:cookies.get('userData').resourceID
                }
            }
        )
        .then(response=>{
            setLoading(false)
            setProjects(response.data.projectCase);

        })
        .catch(e=>{
            setLoading(false);
            console.log(e);
        })
    }

    const getTransactionLog = (start,end)=>{
        setLoading(true);
        axios.get('/getTransactionLog',{
            params:{
                resourceID:cookies.get('userData').resourceID,
                projectID:selectedProjectID,
                startDate:start ? new Date(new Date(start).setHours(0,0,0,0)).toISOString().replace("Z", "+00:00"):null,
                endDate:end ? new Date(new Date(end).setHours(23,59,59,999)).toISOString().replace("Z", "+00:00"):null,
                pageSize:pageSize,
                pageNumber:pageNumber,
                acknowledged:true
            }
        })
        .then(response=>{
            setLoading(false);
            setTransactionsLog(response.data.transactionLog);
            setTotalPages(response.data.totalPages);
            setBalance(calculateBalance(response.data.transactionLog));
        })
        .catch(e=>console.log(e))
    }

    const getUnacknowledgedTransactionLog = (start,end)=>{
        setLoading(true);
        axios.get('/getTransactionLog',{
            params:{
                resourceID:cookies.get('userData').resourceID,
                projectID:selectedProjectID,
                startDate:start ? new Date(new Date(start).setHours(23,59,59,999)).toISOString().replace("Z", "+00:00"):null,
                endDate:end ? new Date(new Date(end).setHours(23,59,59,999)).toISOString().replace("Z", "+00:00"):null,
                pageSize:pageSize,
                pageNumber:pageNumber,
                acknowledged:false
            }
        })
        .then(response=>{
            setLoading(false);
            
            setNotificationCounter(response.data.totalCount);
            setUnacknowledgedTransactionLog(response.data.transactionLog);
            setUnacknowledgedTotalPages(response.data.totalPages);
            setUnacknowledgedBalance(calculateBalance(response.data.transactionLog));
            // findUniquePayer();
        })
        .catch(e=>console.log(e))
    }

    const getProjectResourceAssociation = (projectID)=>{
        setLoading(true);
        axios.get('/getProjectResourceAssociation',{
            params:{
                projectID:projectID
            }
        }).then(response=>{
            setLoading(false);
            setProjectResourceAssociation(response.data.projectAssociation);

        })
    }

    const acknowledgeTransaction = (transactionID, acknowledge)=>{
        setLoading(true);
        axios.post('/acknowledgeTransaction',{
            transactionID:transactionID,
            acknowledge:acknowledge
        }).then(response=>{
            setLoading(false)
            getTransactionLog();
            getUnacknowledgedTransactionLog();
            
        }).catch(e=>console.log(e))
    }

    function handleFile(files) {
        // uploadDocument(files);
        if (files.length > 1){
            alert ("Please process one file at a time");
        }
        else if (files[0].type !== 'video/mp4' && files[0].type !== 'image/png' && files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg'){
            alert('Only .png, .jpeg, .jpg and .mp4 is allowed')
        }
        else if (files[0].size > 5000000){
            alert('Cannot upload file more than 5MB')
        }
        else{
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.addEventListener("load", () => {
                const url = reader.result;
                setSelectedFile(url);
              }, false);
            setFileToUpload(files[0]);
        }
        
    }
    
    // drag drop file component
    const DragDropFile = () => {
        // drag state
        const [dragActive, setDragActive] = React.useState(false);
        // ref
        const inputRef = React.useRef(null);
    
        // handle drag events
        const handleDrag = function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActive(true);
            } else if (e.type === "dragleave") {
                setDragActive(false);
            }
        };
    
        // triggers when file is dropped
        const handleDrop = function(e) {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                handleFile(e.dataTransfer.files);
            }
        };
        
        // triggers when file is selected with click
        const handleChange = function(e) {
            e.preventDefault();
            if (e.target.files && e.target.files[0]) {
                handleFile(e.target.files);
            }
        };
        
        // triggers the input when the button is clicked
        const onButtonClick = () => {
            inputRef.current.click();
        };


        let container = null;
        
        container = (
            <form 
                id="form-file-upload" 
                onDragEnter={handleDrag} 
                onSubmit={(e) => e.preventDefault()}
                style={{maxWidth: '100%',textAlign: 'center',position: 'relative', margin :'10px 0 0 10px'}}
            >
                <input 
                    ref={inputRef} 
                    type="file" 
                    id="input-file-upload" 
                    style = {{display: 'none'}} 
                    onChange={handleChange} 
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                />
                
                <label 
                    id="label-file-upload" 
                    htmlFor="input-file-upload" 
                    className={dragActive ? "drag-active" : "" }
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        borderWidth: 2,
                        borderStyle: 'dashed',
                        borderColor: '#cbd5e1',
                        backgroundColor: '#f8fafc'
                    }}
                >
                    {/* {selectedFile && <img src = {selectedFile} height={120} style={{padding:5}}/>} */}
                    <div>
                        <p>Drag and drop your file here or</p>
                        <button className={classes['upload-button']} onClick={onButtonClick}>Upload a file</button>
                    </div> 
                </label>
                { dragActive && <div 
                    id="drag-file-element" 
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    }}
                    onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>
        )

        
        
        return (
            <div>
                {container}
            </div>
            
        );
    };

    const submitPaymentHandler = ()=>{
        let isValid = false;
        if(selectedProjectID && selectedIndividualResources && selectedAmount.value && selectedAmount.mode)
        isValid = true
        if(selectedAmount.mode === 2)
            isValid = isValid && agreeCheckBox
        else if (selectedAmount.mode === 1){
            if(fileToUpload)
                isValid = true
            else
                isValid = false
        }
            
        if(isValid){
            setLoading(true)
            var formData = new FormData();
            formData.append("projectID", selectedProjectID);
            formData.append("payerID", cookies.get('userData').resourceID);
            formData.append("payeeID", selectedIndividualResources);
            formData.append("amount", parseFloat(selectedAmount.value));
            formData.append("description", description);
            formData.append("isSettlement",false);
            formData.append('acknowledged',false);
            formData.append('settlementDetails',null)
            formData.append("mode", parseInt(selectedAmount.mode));
            if(selectedAmount.mode === 1)
                formData.append("file", fileToUpload);
            else
                formData.append('file',null);
            axios.post('/addNewPayment',formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                  },
            })
            .then(response=>{
                setLoading(false)
                setTransactionResponse(response.data)
                setSelectedAmount({
                    value:null,
                    mode:null
                });
                setSelectedProjectID(null);
                setSelectedFile(null);
                setSelectedIndividualResources(null);
                setDescription(null);
                setFileToUpload(null);
                setAgreeCheckBox(false);
            })
            .catch(e=>{
                setLoading(false); 
                console.log(e)
            })
        }
        else{
            alert("Please fill all data")
        }

    }

    const calculateBalance = (transLog)=>{
        let balance = 0;
        transLog.map(item=>{
            if(item.payerID === cookies.get('userData').resourceID)
                balance -= item.amount;
            else if(item.payeeID === cookies.get('userData').resourceID)
                balance += item.amount;
        })
        return balance
    }

    const findUniquePayer = ()=>{
        
        let tempUniquePayer = [];
        transactionLog.map((item,index)=>{
            if(item.projectID === selectedProjectID && item.payerDetails.resourceID != cookies.get('userData').resourceID){
                if(tempUniquePayer.length === 0){
                    tempUniquePayer.push(item.payerDetails)
                }
                else{
                    let isUnique = true 
                    
                    tempUniquePayer.map(innerItem=>{
                        if(innerItem.resourceID == item.payerID){
                            isUnique = false
                        }  
                    })
                    if(isUnique)
                        tempUniquePayer.push(item.payerDetails);
                }
            }
            
            
        })
        setUniquePayer(tempUniquePayer);
    }

    const selectDateHandler = ()=>{
        if(new Date(startDate) && new Date(endDate) && (new Date(endDate) >= new Date(startDate))){
            getTransactionLog(startDate,endDate);
        }
    }

    
    let container = null;
    if(loading)
        container = (<CircularProgress/>)
    else if(openSettlement){
        container = (
            <Settlement
                transaction = {selectedTransaction}
                getData = {()=>{
                    setOpenSettlement(false)
                    getTransactionLog();
                    getUnacknowledgedTransactionLog();
                }}
            />
        )
    }
    else if(openReporting){
        container = (
            <Reporting getData = {(flag)=>setOpenReporting(flag)}/>
        )
    }
    else{
        container = (
            <div
                style = {{
                    padding: 10,
                    margin: '5px auto',
                    background: 'white'
                }}
            >
                <div style={{borderBottom:'2px solid lightgray', margin:5, display:'flex',justifyContent:'space-between'}}>
                    <h3 style={{margin:5, width:'100%'}}>Expense Management System</h3>
                    <CloseIcon onClick = {()=>{navigate('/')}} style = {{padding:10, cursor:'pointer'}}/>
                </div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <div style = {{background:'teal', borderRadius:5, color:'white'}}>
                        {balance >= 0 ? (
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center', margin:10}}>
                                <p style={{margin:0, fontSize:18}}>Balance </p>
                                <CurrencyRupeeIcon style = {{fontWeight:'bold'}}/>
                                <p style = {{fontWeight:'bold', margin:0, fontSize:28}}>{balance}</p>
                            </div>
                        ):(
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center', margin:10}}>
                                <p style={{margin:0, fontSize:18}}>Balance </p>
                                <p style = {{fontWeight:'bold', marginLeft:10,marginBottom:0, marginTop:0, fontSize:28}}>-</p>
                                <CurrencyRupeeIcon style = {{fontWeight:'bold'}}/>
                                <p style = {{fontWeight:'bold', margin:0, fontSize:28}}>{balance*-1}</p>
                            </div>
                        )}
                        
                    </div>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <div>
                            <FormControl style={{margin:10,minWidth:200, width:'95%'}} >
                                <InputLabel id="demo-simple-select-label">Select Project</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedProjectID}
                                    label="Select Project"
                                    style={{padding:0}}
                                    onChange={(e)=>setSelectedProjectID(e.target.value)}
                                >
                                    <MenuItem value = {null}>All Projects</MenuItem>
                                    {projects.map((item,index)=>{
                                        return(
                                            <MenuItem value = {item.mProjectCase.projectID}>{item.mProjectCase.caseMaster[0].title}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <div style={{display:'flex',color:'teal', justifyContent:'space-evenly'}}>
                                {(cookies.get('userData').subscriberTypeID === 'P-018' || cookies.get('userData').subscriberTypeID === 'F-002') ? (
                                    <div 
                                        style= {{cursor:'pointer', padding:10}} 
                                        onClick={()=>setAddNewTransaction(true)}
                                    >
                                        <AddIcon/>
                                        <p style={{margin:0}}>Make Payment</p>
                                    </div>
                                ):(
                                    null
                                )}
                                {/* {cookies.get('userData').subscriberTypeID !== 'P-018' ? (
                                    <div 
                                        style= {{cursor:'pointer', padding:10}}
                                        onClick={()=>setOpenSettlement(true)}
                                    >
                                        <AddIcon/>
                                        <p style={{margin:0}}>Make Settlement</p>
                                    </div>
                                ):null} */}
                                {/* <div style= {{cursor:'pointer', padding:10}}>
                                    <HistoryIcon/>
                                    <p style={{margin:0}}>View History</p>
                                </div> */}
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{display:'flex',justifyContent: 'flex-end',alignItems: 'center',background:'aliceblue', padding:5}}>  
                        <LocalizationProvider dateAdapter={AdapterDayjs} style = {{margin:'0px 5px'}}>
                            <DesktopDatePicker
                                label="Start Date"
                                inputFormat="DD/MM/YYYY"
                                maxDate={new Date()}
                                value={startDate}
                                onChange={(date)=>setStartDate(date)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs} style = {{margin:'0px 5px'}}>
                            <DesktopDatePicker
                                label="End Date"
                                inputFormat="DD/MM/YYYY"
                                minDate={startDate}
                                maxDate={new Date()}
                                value={endDate}
                                onChange={(date)=>setEndDate(date)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        
                        <FilterAltIcon 
                            style={{color:'rgb(9, 56, 120)', cursor:'pointer', padding:'0px 5px', fontSize:24}}
                            onClick = {()=>selectDateHandler()}
                        />
                        <ClearIcon
                            style={{color:'rgb(9, 56, 120)', cursor:'pointer', padding:'0px 5px', fontSize:24}}
                            onClick = {()=>{setStartDate(null);setEndDate(null);getTransactionLog()}}
                        />
                        <AssessmentIcon
                            style={{color:'rgb(9, 56, 120)', cursor:'pointer', padding:'0px 5px', fontSize:24}}
                            onClick = {()=>{setOpenReporting(true)}}
                        />
                        <div style={{position:'relative'}}>
                            <NotificationsIcon 
                                style={{color:'rgb(9, 56, 120)', cursor:'pointer', padding:'0px 5px', fontSize:24}}
                                onClick = {()=>{setOpenNotification(true)}}
                            />
                            <div className={classes.circle}>
                                <p className={classes.count}>{notificationCounter}</p>
                            </div>
                        </div>
                        
                        <KeyboardArrowLeftIcon 
                            style={{color:'rgb(9, 56, 120)', cursor:'pointer', padding:'0px 5px', fontSize:24}}
                            onClick = {()=>{
                                if(pageNumber > 1)
                                    setPageNumber(pageNumber -1)
                                else{
                                    alert('Minimum Limit Reached')
                                }
                            }}
                        />
                        {transactionLog.length > 0 ? <p>Page {pageNumber} of {totalPages}</p>:<p>Page {pageNumber}</p>}
                        <KeyboardArrowRightIcon
                            style={{color:'rgb(9, 56, 120)', cursor:'pointer', padding:'0px 5px', fontSize:24}}
                            onClick = {()=>{
                                if(pageNumber < totalPages)
                                    setPageNumber(pageNumber +1)
                                else{
                                    alert('Maximum Limit Reached')
                                }
                            }}
                        />
                    </div>

                    {openNotification?(
                        <>
                            <h3 style={{margin:5}}>Unacknowledged Transaction</h3> 
                            <div>
                                {unacknowledgedTransactionLog.length > 0 ? (
                                    <div>
                                        <TableContainer component={Paper}style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow style = {{textAlign:'center', fontWeight:'bold'}}>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Date / Time
                                                        </TableCell>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Transaction ID
                                                        </TableCell>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Project
                                                        </TableCell>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Payer/Payee
                                                        </TableCell>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Mode
                                                        </TableCell>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Amount
                                                        </TableCell>
                                                        <TableCell style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Receipt
                                                        </TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {unacknowledgedTransactionLog.map((item,index)=>{
                                                        return(
                                                            <TableRow>
                                                                <TableCell>
                                                                    {new Date(item.timestamp).toLocaleString('en-IN')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.transactionID}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.mProjectCase.caseMaster.title}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.payerID === cookies.get('userData').resourceID ? item.payeeDetails.name : item.payerDetails.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.isSettlement === 'true'? 'Settlement':null}
                                                                    {item.mode === 1 ? 'Online' : item.mode === 2 ? 'Cash':null}
                                                                </TableCell>
                                                                <TableCell style={{color:item.payerID === cookies.get('userData').resourceID ? 'red':'green'}}>
                                                                    {item.payerID === cookies.get('userData').resourceID ? `- ${item.amount}`: item.amount}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.receipt ? (
                                                                        <Tooltip title = 'Receipt'>
                                                                            <IconButton>
                                                                                <SummarizeIcon 
                                                                                    style={{color:'teal', cursor:'pointer'}}
                                                                                    onClick = {()=>{
                                                                                        setSelectedReceipt(item.receipt);
                                                                                        setViewReceipt(true)
                                                                                        // window.open(item.receipt, '_blank', 'noreferrer')
                                                                                    }}/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        
                                                                    ):null}
                                                                </TableCell>
                                                                
                                                                <TableCell>
                                                                    {item.payeeID === cookies.get('userData').resourceID?(
                                                                        <Tooltip title = 'Acknowledge'>
                                                                            <IconButton>
                                                                                <VerifiedIcon 
                                                                                    style = {{cursor:'pointer', color:'teal'}} 
                                                                                    onClick = {()=>{
                                                                                        setOpenConfirmation(true);
                                                                                        setSelectedTransactionID(item.transactionID)
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        
                                                                    ):null}
                                                                    
                                                                </TableCell>
                                                                <TableCell>
                                                                    <KeyboardArrowRightIcon 
                                                                        style = {{cursor:'pointer'}}
                                                                        onClick = {()=> {
                                                                            setViewTransactionDetails(true);
                                                                            setSelectedTransaction(item)
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        
                                    </div>
                                ):(
                                    <div>
                                        <p>No Transaction Logs</p>
                                    </div>
                                )}
                                <Button 
                                    style = {{background: 'teal',color: 'white',width: 120,padding: 10}}
                                    size="small" 
                                    onClick = {()=>{
                                            setOpenNotification(false)
                                        } 
                                    }
                                >
                                    Ok
                                </Button>
                            </div>
                        </>
                    ):(
                        <>
                            <h3 style={{margin:5}}>Transactions</h3> 
                            <div>
                                {transactionLog.length > 0 ? (
                                    <div>
                                        <TableContainer component={Paper}style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow style = {{textAlign:'center', fontWeight:'bold'}}>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Date / Time
                                                        </TableCell>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Transaction ID
                                                        </TableCell>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Project
                                                        </TableCell>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Payer/Payee
                                                        </TableCell>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Mode
                                                        </TableCell>
                                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Amount
                                                        </TableCell>
                                                        <TableCell style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            Receipt
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {transactionLog.map((item,index)=>{
                                                        console.log(item);
                                                        return(
                                                            <TableRow>
                                                                <TableCell>
                                                                    {new Date(item.timestamp).toLocaleString('en-IN')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.transactionID}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.mProjectCase.caseMaster.title}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.payerID === cookies.get('userData').resourceID ? item.payeeDetails.name : item.payerDetails.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.isSettlement === 'true'? 'Settlement':null}
                                                                    {item.mode === 1 ? 'Online' : item.mode === 2 ? 'Cash':null}

                                                                </TableCell>
                                                                <TableCell style={{color:item.payerID === cookies.get('userData').resourceID ? 'red':'green'}}>
                                                                    {item.payerID === cookies.get('userData').resourceID ? `- ${'\u20B9'}${item.amount}`: `${'\u20B9'}${item.amount}`}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.receipt ? (
                                                                        <Tooltip title = 'Receipt'>
                                                                            <IconButton>
                                                                                <SummarizeIcon 
                                                                                    style={{color:'teal', cursor:'pointer'}}
                                                                                    onClick = {()=>{
                                                                                        setSelectedReceipt(item.receipt);
                                                                                        setViewReceipt(true)
                                                                                        // window.open(item.receipt, '_blank', 'noreferrer')
                                                                                    }}/>
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ):null}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.isSettlement === 'false'  ? (
                                                                        <Tooltip title = 'Settlement'>
                                                                            <IconButton>
                                                                                <PaymentsIcon 
                                                                                    style={{color:'teal', cursor:'pointer'}}
                                                                                    onClick = {()=>{
                                                                                        setOpenSettlement(true);
                                                                                        setSelectedTransaction(item)
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ):null}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <KeyboardArrowRightIcon 
                                                                        style = {{cursor:'pointer'}}
                                                                        onClick = {()=> {
                                                                            setViewTransactionDetails(true);
                                                                            setSelectedTransaction(item)
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                ):(
                                    <div>
                                        <p>No Transaction Logs</p>
                                    </div>
                                )}
                            </div>
                        </>
                        
                    )}
                </div>
            </div>
        )
    }

    return (
        <div style={{textAlign:'center'}}>
            {props.fromProject ? (
                null
            ):<Header/>}
            {container}
            <Dialog onClose={()=>{setAddNewTransaction(false)}} open={addNewTransaction}>
                <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <DialogTitle>Add Money</DialogTitle>
                    <div>
                        <CloseIcon onClick = {()=>{setAddNewTransaction(false)}} style = {{padding:10, cursor:'pointer'}}/>
                    </div>
                </Typography>
                <Typography>
                    {loading?(<CircularProgress/>):(
                        <div>
                            {transactionResponse ? (
                                <div>
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center', margin:10}}>
                                        <CurrencyRupeeIcon style = {{fontSize:42, fontWeight:'bold'}}/>
                                        <p style = {{fontSize:42, fontWeight:'bold', margin:0}}>{transactionResponse.amount}</p>
                                    </div>
                                    <div style={{textAlign:'center'}}>
                                        {
                                            transactionResponse.message === 'Success' ? (
                                                <>
                                                    <img src = {unacknowledged} width={80}/>
                                                    <p style={{fontWeight:'bold', padding:10, margin:5}}>Congratulations !! Payment Successful</p>
                                                    <p style={{fontWeight:'bold', padding:10, margin:5}}>Waiting for acknowledgement</p>
                                                    <p style={{fontWeight:'bold', padding:20, margin:5}}>Transaction ID : {transactionResponse.transactionID}</p>
                                                </> 
                                            ):(
                                                <>
                                                    <img src = {paymentUnsuccessful} width={80}/>
                                                    <p style={{fontWeight:'bold', padding:10, margin:5}}>Sorry !! Payment Declined</p>
                                                    <p style={{fontWeight:'bold', padding:20, margin:5}}>Transaction ID : {transactionResponse.transactionID}</p>
                                                </>
                                                
                                            )
                                        }
                                        <CardActions style={{justifyContent:'space-around'}}>
                                            <Button 
                                                style = {{background: 'teal',color: 'white',width: 120,padding: 10}}
                                                size="small" 
                                                onClick = {()=>{
                                                        setAddNewTransaction(false);
                                                        setTransactionResponse(null);
                                                        getTransactionLog();
                                                        getUnacknowledgedTransactionLog();
                                                    } 
                                                }>OK</Button>
                                        </CardActions>
                                    </div>
                                </div>
                            ):(
                                <div>
                                    <div style = {{ margin:10, padding:10}}>
                                        <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                            <InputLabel id="demo-simple-select-label">Select Project</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedProjectID}
                                                label="Subscriber Type"
                                                style={{padding:0}}
                                                onChange={(e)=>setSelectedProjectID(e.target.value)}
                                            >
                                                {projects.map((item,index)=>{
                                                    return(
                                                        <MenuItem value = {item.mProjectCase.projectID}>{item.mProjectCase.caseMaster[0].title}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                            <InputLabel id="demo-simple-select-label">Select Individual</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedIndividualResources}
                                                label="Individual"
                                                style={{padding:0}}
                                                onChange={(e)=>setSelectedIndividualResources(e.target.value)}
                                            >
                                                {projectResourceAssociation.length > 0 && projectResourceAssociation[0].vendorMaster.map((item,index)=>{
                                                    if(item.resourceID !== cookies.get('userData').resourceID){
                                                        if(cookies.get('userData').subscriberTypeID === 'P-018'){
                                                            if(item.subscriberTypeMaster[0].subscriberTypeID === 'F-002'){
                                                                return(
                                                                    <MenuItem value = {item.resourceID}>
                                                                        {item.subscriberTypeMaster[0].subscriberTypeName}-{item.name}</MenuItem>
                                                                )
                                                            }
                                                        }
                                                        else if(cookies.get('userData').subscriberTypeID === 'F-002'){
                                                            if(item.subscriberTypeMaster[0].subscriberTypeID === 'F-004'){
                                                                return(
                                                                    <MenuItem value = {item.resourceID}>
                                                                        {item.subscriberTypeMaster[0].subscriberTypeName}-{item.name}</MenuItem>
                                                                )
                                                            }
                                                        }
                                                    }
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                            <TextField 
                                                id="outlined-basic" 
                                                label="Amount" 
                                                type='Number'
                                                variant="outlined" 
                                                value = {selectedAmount.value}
                                                onChange={(e)=>{
                                                    let tempSelectedAmount = {... selectedAmount};
                                                    tempSelectedAmount.value = e.target.value;
                                                    setSelectedAmount(tempSelectedAmount)
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                            <TextField 
                                                id="outlined-basic" 
                                                label="Description" 
                                                variant="outlined" 
                                                multiline
                                                rows={4}
                                                value = {description}
                                                onChange={(e)=>{setDescription(e.target.value)}}
                                            />
                                        </FormControl>
                                        <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                            <InputLabel id="demo-simple-select-label">Payment mode</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedAmount.mode}
                                                style={{padding:0}}
                                                onChange={(e)=>{
                                                    let tempSelectedAmount = {... selectedAmount};
                                                    tempSelectedAmount.mode = e.target.value;
                                                    setSelectedAmount(tempSelectedAmount)
                                                }}
                                            >
                                                <MenuItem value = {1}>Online</MenuItem>
                                                <MenuItem value = {2}>Cash</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {selectedAmount.mode === 1 ? (
                                            <DragDropFile/>
                                        ):null}
                                        {selectedAmount.mode === 2 ? (
                                            <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={agreeCheckBox}
                                                            onChange={(e) => setAgreeCheckBox(e.target.checked)}
                                                        />
                                                    }
                                                    label="I confirm that i have paid the party in cash and the information entered is accurate to the best of my knowledge."
                                                />
                                            </FormControl>
                                        ):null}
                                        
                                    </div>
                                        
                                    <CardActions style={{justifyContent:'space-around'}}>
                                        <Button size="small" onClick = {()=>submitPaymentHandler()}>Pay</Button>
                                    </CardActions>
                                    
                                </div>
                            )}
                            
                        </div>
                        
                    )}
                    
                </Typography>
            </Dialog>
            <Dialog onClose={()=>{setViewTransactionDetails(false)}} open={viewTransactionDetails}>
                <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <DialogTitle>Transaction Details</DialogTitle>
                    <div>
                        <CloseIcon onClick = {()=>{setViewTransactionDetails(false)}} style = {{padding:10, cursor:'pointer'}}/>
                    </div>
                </Typography>
                <Typography>
                    {loading?(<CircularProgress/>):(
                        <div>
                            {selectedTransaction ? (
                                <div>
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center', margin:10}}>
                                        <CurrencyRupeeIcon style = {{fontSize:42, fontWeight:'bold'}}/>
                                        <p style = {{fontSize:42, fontWeight:'bold', margin:0}}>{selectedTransaction.amount}</p>
                                    </div>
                                    <div style={{textAlign:'center'}}>
                                        {selectedTransaction.acknowledged === 'true' ? (
                                            <>
                                                <img src = {paymentSuccessful} width={80}/>
                                            </> 
                                        ):(
                                            <>
                                                <img src = {unacknowledged} width={80}/>
                                                <p style={{fontWeight:'bold', padding:10, margin:5}}>Waiting for acknowledgement</p>
                                            </> 
                                        )}
                                        
                                        <TableContainer component={Paper}>
                                            <Table aria-label="simple table" style={{margin:'10px 0px', background:'#f2f2f2', overflow:'wrap'}}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Date - Time
                                                        </TableCell>
                                                        <TableCell>
                                                            {new Date(selectedTransaction.timestamp).toLocaleString('en-IN')}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Transaction ID
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.transactionID}
                                                        </TableCell>
                                                    </TableRow>            
                                                    <TableRow>
                                                        <TableCell>
                                                            Amount
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.amount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>  
                                                        <TableCell>
                                                            Payment Mode
                                                        </TableCell>  
                                                        <TableCell>
                                                            {selectedTransaction.mode === 1 ? 'Online' : 'Cash'}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>  
                                                        <TableCell>
                                                            Description
                                                        </TableCell>  
                                                        <TableCell>
                                                            {selectedTransaction.description}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <p>Project Details</p>
                                            <Table style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Project Title
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.mProjectCase.caseMaster.title}
                                                        </TableCell>
                                                    </TableRow>        
                                                    <TableRow>
                                                        <TableCell>
                                                            Latitude
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.mProjectCase.caseMaster.location.latitude}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Longitude
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.mProjectCase.caseMaster.location.longitude}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Distance (in Km)
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.mProjectCase.caseMaster.location.distance}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Project Description
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.mProjectCase.caseMaster.caseDescription}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <p>Payee Details</p>
                                            <Table style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Name
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payeeDetails.name}
                                                        </TableCell>
                                                    </TableRow>        
                                                    <TableRow>
                                                        <TableCell>
                                                            Email
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payeeDetails.email}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Mobile
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payeeDetails.mobile}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Subscriber Type
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payeeDetails.subscriberTypeMaster.subscriberTypeName}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <p>Payer Details</p>
                                            <Table style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            Name
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payerDetails.name}
                                                        </TableCell>
                                                    </TableRow>        
                                                    <TableRow>
                                                        <TableCell>
                                                            Email
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payerDetails.email}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Mobile
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payerDetails.mobile}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            Subscriber Type
                                                        </TableCell>
                                                        <TableCell>
                                                            {selectedTransaction.payerDetails.subscriberTypeMaster.subscriberTypeName}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            {selectedTransaction.isSettlement == 'true' ? (
                                                <>
                                                    <p>Transactions</p>
                                                    <Table style={{margin:'10px 0px', background:'#f2f2f2'}}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    S.No
                                                                </TableCell>
                                                                <TableCell>
                                                                    Amount
                                                                </TableCell>
                                                                <TableCell>
                                                                    Description
                                                                </TableCell>
                                                                <TableCell>
                                                                    Mode
                                                                </TableCell>
                                                                <TableCell>
                                                                    Receipt
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {selectedTransaction.settlementDetails && selectedTransaction.settlementDetails.map((item,index)=>(
                                                                
                                                                <TableRow>
                                                                    <TableCell>
                                                                        {index+1}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.amount}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.description}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.mode === 1 ? 'Online':'Cash'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.receipt ? (
                                                                            <Tooltip title = 'Receipt'>
                                                                                <IconButton>
                                                                                    <SummarizeIcon 
                                                                                        style={{color:'teal', cursor:'pointer'}}
                                                                                        onClick = {()=>{
                                                                                            setSelectedReceipt(item.receipt);
                                                                                            setViewReceipt(true)
                                                                                            // window.open(item.receipt, '_blank', 'noreferrer')
                                                                                        }}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        ):null}
                                                                    </TableCell>
                                                                </TableRow>
                                                                
                                                                    
                                                            ))}
                                                        </TableBody>
                                                            
                                                        
                                                    </Table>
                                                </>
                                            ):null}
                                            
                                        </TableContainer>
                                        <CardActions style={{justifyContent:'space-around'}}>
                                            <Button 
                                                style = {{background: 'teal',color: 'white',width: 120,padding: 10}}
                                                size="small" 
                                                onClick = {()=>{
                                                        setViewTransactionDetails(false);
                                                        setSelectedTransaction(null);
                                                    } 
                                                }>OK</Button>
                                        </CardActions>
                                    </div>
                                </div>
                            ):(
                                <div>
                                    <div style = {{ margin:10, padding:10}}>
                                        <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                            <InputLabel id="demo-simple-select-label">Select Project</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedProjectID}
                                                label="Subscriber Type"
                                                style={{padding:0}}
                                                onChange={(e)=>setSelectedProjectID(e.target.value)}
                                            >
                                                {projects.map((item,index)=>{
                                                    return(
                                                        <MenuItem value = {item.mProjectCase.projectID}>{item.mProjectCase.caseMaster[0].title}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                            <InputLabel id="demo-simple-select-label">Select Individual</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedIndividualResources}
                                                label="Individual"
                                                style={{padding:0}}
                                                onChange={(e)=>setSelectedIndividualResources(e.target.value)}
                                            >
                                                {uniquePayer.length > 0 && uniquePayer.map((item,index)=>{
                                                    return(
                                                        <MenuItem value = {item.resourceID}>
                                                            {item.subscriberTypeMaster.subscriberTypeName}-{item.name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl> 
                                        <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                            <TextField 
                                                id="outlined-basic" 
                                                label="Amount" 
                                                variant="outlined" 
                                                value = {selectedAmount.value}
                                                onChange={(e)=>{
                                                    let tempSelectedAmount = {... selectedAmount};
                                                    tempSelectedAmount.value = e.target.value;
                                                    setSelectedAmount(tempSelectedAmount)
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                            <TextField 
                                                id="outlined-basic" 
                                                label="Description" 
                                                variant="outlined" 
                                                multiline
                                                rows={4}
                                                value = {description}
                                                onChange={(e)=>{setDescription(e.target.value)}}
                                            />
                                        </FormControl>
                                        <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                            <InputLabel id="demo-simple-select-label">Payment mode</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedAmount.mode}
                                                style={{padding:0}}
                                                onChange={(e)=>{
                                                    let tempSelectedAmount = {... selectedAmount};
                                                    tempSelectedAmount.mode = e.target.value;
                                                    setSelectedAmount(tempSelectedAmount)
                                                }}
                                            >
                                                <MenuItem value = {1}>Online</MenuItem>
                                                <MenuItem value = {2}>Cash</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {selectedAmount.mode === 1 ? (
                                            <DragDropFile/>
                                        ):null}
                                        {selectedAmount.mode === 2 ? (
                                            <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={agreeCheckBox}
                                                            onChange={(e) => setAgreeCheckBox(e.target.checked)}
                                                        />
                                                    }
                                                    label="I confirm that i have paid the party in cash and the information entered is accurate to the best of my knowledge."
                                                />
                                            </FormControl>
                                        ):null}
                                        
                                    </div>
                                        
                                    <CardActions style={{justifyContent:'space-around'}}>
                                        <Button size="small" onClick = {()=>submitPaymentHandler()}>Pay</Button>
                                    </CardActions>
                                    
                                </div>
                            )}
                            
                        </div>
                        
                    )}
                    
                </Typography>
            </Dialog>
            <Dialog onClose={()=>{setOpenConfirmation(false)}} open={openConfirmation}>
                <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <DialogTitle>Confirmation</DialogTitle>
                    <div>
                        <CloseIcon onClick = {()=>{setOpenConfirmation(false)}} style = {{padding:10, cursor:'pointer'}}/>
                    </div>
                </Typography>
                <Typography>
                    <p style={{padding:20}}>
                        Are you sure you want to acknowledge this transaction !!
                    </p>
                    <div style={{display:'flex', justifyContent:'space-evenly', padding:20}}>
                        <Button 
                            style={{background:'red',color:'white'}}
                            onClick = {()=>setOpenConfirmation(false)}
                        >Cancel</Button>
                        <Button
                            style={{background:'teal',color:'white'}}
                            onClick = {()=>{
                                acknowledgeTransaction(selectedTransactionID, true);
                                setOpenConfirmation(false);
                            }}
                        >Yes</Button>
                    </div>
                </Typography>
            </Dialog>
            <Dialog onClose={()=>{setViewReceipt(false)}} open={viewReceipt}>
                <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <DialogTitle>Receipt</DialogTitle>
                    <div>
                        <DownloadIcon style = {{padding:10, cursor:'pointer'}}
                            onClick = {()=>{window.open(selectedReceipt, '_blank', 'noreferrer')}}
                        />
                        <CloseIcon onClick = {()=>{setViewReceipt(false)}} style = {{padding:10, cursor:'pointer'}}/>
                    </div>
                </Typography>
                <Typography>
                    <div style = {{margin:20}}>
                        <img src = {selectedReceipt} width='100%'/>
                    </div>
                </Typography>
            </Dialog>
        </div>
    )
}

export default ExpenseManagementSystem