import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import axios from '../../axios_tteg';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import satellite from "../../assets/images/satellite.png";
import roadMap from "../../assets/images/roadMap.png";
import comment from "../../assets/images/comment.png";
import location from "../../assets/images/location.png";
import "@reach/combobox/styles.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';
import ImgsViewer from "react-images-viewer";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';  
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import mapStyles from "./mapStyles";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit';
import MapExplorer from "./MapExplorer";
import dot from '../../assets/images/dot.png';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItems from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const libraries = ["places"];

const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

const contextMenuStyle = {
    left : "0",
    backgroundColor : "white",
    padding: "10px 0",
    zIndex: "99",
    width: "200px",
    top: "calc(100% + 10px)",
    borderRadius: "4px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)"
  }

  const contextMenuItemStyle = {
    fontSize: "14px",
    padding: "10px 15px",
    cursor: "pointer",
    transition: "0.2s",
    marginBottom: "2px",
    fontWeight: "500",
    display: "flex",
    columnGap : "10px",
    backgroundColor:"#fff",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#cccccc"
    }
  }

const Opentrench = (props)=>{
    console.log(props)

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyC_kRxUGZMpYA361uWGeGohZW5PYqCoj4k",
        libraries,
    });
        
    const [center,setCenter] = useState({
        lat: props.caseDetails.caseMaster[0].location.latitude,
        lng: props.caseDetails.caseMaster[0].location.longitude,
    });
    const [isEntryOrExitPit, setisEntryOrExitPit] = useState(""); // For storing marker type
    const [indexs, setindex] = useState(null); // For storing index
    const [selectedMarker, setSelectedMarker] = useState(null); // For storing selected marker details
    const [trackingSelected, setTrackingSelected] = useState(null); // For storing tracking selection
  
    const [mapView,setMapView] = React.useState("roadmap");
    const [selectedRoute,setSelectedRoute] = useState(0);
    const [selectedLayer,setSelectedLayer] = useState(0);
    const [selectedSubProjectsNo,setSelectedSubProjetsNo] = useState(null);
    const [subProjectData,setSubProjectData] = useState([]);
    const [HDDData,setHddData]=useState([])
    const [selectedSubProject,setSelectedSubProject] = useState(null);
    const [selectedPoint,setSelectedPoint] = useState(null);
    const [subProjects,setSubProjects] = useState(null);
    const [loading,setLoading] = useState(false);
    const [edit,setEdit] = useState(true);
    const [selectedSubProjectID,setSelectedSubProjectID] = useState([]);
    const [manholeHover,setManholeHover] = useState(null);
    const [editMap,setEditMap] = useState(false);
    const [polyLineActive,setPolyLineActive] = useState(false);
    const [manholePopBox, setManholePopBox] = React.useState(false);
    const [latLng,setLatLng] = React.useState({}); // to store the latitude and longitude when user clicks 
    const [selectedManhole,setSelectedManhole] = useState(null);
    const [materialItem,setMaterialItem] = useState(null);
    const [serviceItem,setServiceItem] = useState(null);
    const [assetsType,setAssetsType] = useState(null);
    const [pipeTypes,setPipeTypes] = useState(null);
    const [selectedPipeTypes,setSelectedPipeTypes] = useState({
        PipeType:'',
        Quantity:''
    });
    const [subProjectno,setsub]=useState(1)
    const [showComment,setShowComment] = useState(false);
    const [openZoomImage,setOpenZoomImage] = useState({status:false,uri:null});
    const [selected, setSelected] = React.useState({
        marker: null,
        index: null,
      });
      useEffect(()=>{
      const getProjectandSubProjectDetail = async()=>{
        // setLoading(true)
        await axios.get('/getProjectAndSubProjectDetails',{
            params:{
                projectID:props.caseDetails.projectID,
                subProjectNo:subProjectno,
                date:new Date()
            }
        }).then(response=>{
            // setLoading(false)
            console.log("datat",response)
            // setProjectDetails(response.data.data[0])
        }).catch(e=>console.log(e))
    }
    const getTrackerHDDData = async()=>{
        await axios.get('/getTrackerOTData',{
            params:{
                projectID:props.caseDetails.projectID,
                subProjectNo:subProjectno
            }
        }).then(response=>{
            if(response.data.OTData.length > 0){
                console.log(response.data.OTData[0].trackingData)
                setHddData(response.data.OTData[0].trackingData);
                // setDuctData(response.data.HDDData[0].ductData);
            }
            
        }).catch(e=>console.log(e))
    }
getTrackerHDDData()
    getProjectandSubProjectDetail()
},[])
    useEffect(()=>{
        getSubProjects();
        getMaterialItem();
        // getAssetsType();
    },[])

    const mapContainerStyle = {
        height: "80vh",
        width: "100%",
    };

    const mapRef = React.useRef();

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const distBtwPoints = (coords1, coords2) => {
        const R = 6371e3; // metres
        const φ1 = parseFloat(coords1.lat) * Math.PI / 180; // φ, λ in radians
        const φ2 = parseFloat(coords2.lat) * Math.PI / 180;
        const Δφ = (parseFloat(coords2.lat) - parseFloat(coords1.lat)) * Math.PI / 180;
        const Δλ = (parseFloat(coords2.lng) - parseFloat(coords1.lng)) * Math.PI / 180;
      
        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
           Math.cos(φ1) * Math.cos(φ2) *
           Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      
        return R * c; // in metres
    }

    const findDistance = (mapData,outerIndex,startIndex,endIndex)=>{
        let distance = 0;
        for (let i = startIndex; i< endIndex; i++){
            distance = distance + distBtwPoints(
                {
                    lat:mapData[outerIndex][i].lat,
                    lng:mapData[outerIndex][i].lng
                },{
                    lat:mapData[outerIndex][i+1].lat,
                    lng:mapData[outerIndex][i+1].lng
                }
            )
        }
        return distance
        
    }

    const getDetailedBOQforSubProject = (mapData)=>{
        let countDesc = {};
        let pipeDescArray = [];
        let countDescArray = [];
        let MHContainer = [];
        let PoleContainer = [];
        let BOQ = [];

        const manholeArray = [];
        const poleArray = [];
        // Find no of manholes and store in the array
        mapData.map((item,index)=>{
            let manholeRows = [];
            let poleRows = [];
            item.map((innerItem,innerIndex)=>{
                if(innerItem.type === 'Manhole')
                    manholeRows.push(innerItem)
                else if(innerItem.type === 'Pole')
                    poleRows.push(innerItem)
            })
            manholeArray.push(manholeRows);
            poleArray.push(poleRows);
        })
        mapData.map((item,index)=>{
            let initialIndex = 0;
            let pipeDesc = {};
            item.map((innerItem1,innerIndex1)=>{
                if(innerItem1.type === 'Manhole'){
                    // check if no of manhole = 1
                    if(manholeArray[index].length === 1){
                        if(innerItem1.pipeContainers.A.length > 0){
                            innerItem1.pipeContainers.A.map((innerItem,innerIndex)=>{
                                if(pipeDesc[innerItem.PipeType]){
                                    pipeDesc[innerItem.PipeType].push({
                                        quantity:innerItem.Quantity,
                                        length :findDistance(mapData,index,initialIndex,innerIndex1) 
                                    }) 
                                }
                                else{
                                    pipeDesc[innerItem.PipeType] = [{
                                        quantity:innerItem.Quantity,
                                        length : findDistance(mapData,index,initialIndex,innerIndex1) 
                                    }]
                                }
                            })
                        }
                        if(innerItem1.pipeContainers.C.length > 0){
                            innerItem1.pipeContainers.C.map((innerItem,innerIndex)=>{
                                if(pipeDesc[innerItem.PipeType]){
                                    pipeDesc[innerItem.PipeType].push({
                                        quantity:innerItem.Quantity,
                                        length :findDistance(mapData,index,innerIndex1,item.length-1) 
                                    }) 
                                }
                                else{
                                    pipeDesc[innerItem.PipeType] = [{
                                        quantity:innerItem.Quantity,
                                        length :findDistance(mapData,index,innerIndex1,item.length-1) 
                                    }]
                                }
                                
                            })
                            
                        }
                    }
                    else{
                        // check if manhole is the last manhole
                        if(innerItem1.count === manholeArray[index][manholeArray[index].length -1].count){
                            if(innerItem1.pipeContainers.A.length > 0){
                                innerItem1.pipeContainers.A.map((innerItem,innerIndex)=>{
                                    if(pipeDesc[innerItem.PipeType]){
                                        pipeDesc[innerItem.PipeType].push({
                                            quantity:innerItem.Quantity,
                                            length :findDistance(mapData,index,initialIndex,innerIndex1) 
                                        }) 
                                    }
                                    else{
                                        pipeDesc[innerItem.PipeType] = [{
                                            quantity:innerItem.Quantity,
                                            length : findDistance(mapData,index,initialIndex,innerIndex1) 
                                        }]
                                    }
                                    
                                })
                                // initialIndex = innerIndex1
                            }
                            if(innerItem1.pipeContainers.C.length > 0){
                                innerItem1.pipeContainers.C.map((innerItem,innerIndex)=>{
                                    if(pipeDesc[innerItem.PipeType]){
                                        pipeDesc[innerItem.PipeType].push({
                                            quantity:innerItem.Quantity,
                                            length :findDistance(mapData,index,innerIndex1,item.length-1) 
                                        }) 
                                    }
                                    else{
                                        pipeDesc[innerItem.PipeType] = [{
                                            quantity:innerItem.Quantity,
                                            length :findDistance(mapData,index,innerIndex1,item.length-1) 
                                        }]
                                    }
                                    
                                })
                                initialIndex = innerIndex1
                            }
                        }
                        else{
                            if(innerItem1.pipeContainers.A.length > 0){
                                innerItem1.pipeContainers.A.map((innerItem,innerIndex)=>{
                                    if(pipeDesc[innerItem.PipeType]){
                                        pipeDesc[innerItem.PipeType].push({
                                            quantity:innerItem.Quantity,
                                            length :findDistance(mapData,index,initialIndex,innerIndex1) 
                                        }) 
                                    }
                                    else{
                                        pipeDesc[innerItem.PipeType] = [{
                                            quantity:innerItem.Quantity,
                                            length : findDistance(mapData,index,initialIndex,innerIndex1) 
                                        }]
                                    }
                                    
                                })
                                initialIndex = innerIndex1
                            }
                        }
                    }
                }
            })
            pipeDescArray.push(pipeDesc);
        })
        
        return({
            pipeDesc:pipeDescArray,
            countDesc:{manhole:manholeArray,pole:poleArray}
        })
    }

    const selectWP = (marker, markerIndex, routeIndex)=>{
        if(selectedSubProject && selectedPoint){
            let tempSubProjectData = [... subProjectData];
            if(props.mapData.store[0].data[selectedRoute]){
                let nearestLocation = findNearestLocation(props.mapData.store[0].data[selectedRoute].markers,marker);
                console.log(nearestLocation);
                if(selectedPoint === 1){
                    tempSubProjectData[0][selectedSubProject-1].start = marker;
                    tempSubProjectData[0][selectedSubProject-1].startIndex = markerIndex;
                    tempSubProjectData[0][selectedSubProject-1].routeIndex = routeIndex
                    tempSubProjectData[1][selectedSubProject-1].start = nearestLocation.nearestLocation;
                    tempSubProjectData[1][selectedSubProject-1].startIndex = nearestLocation.nearestLocationIndex;
                    tempSubProjectData[1][selectedSubProject-1].routeIndex = routeIndex
                    // setSelectedPoint(2)
                }
                if (selectedPoint === 2){
                    tempSubProjectData[0][selectedSubProject-1].end = marker;
                    tempSubProjectData[0][selectedSubProject-1].endIndex = markerIndex;
                    tempSubProjectData[0][selectedSubProject-1].routeIndex = routeIndex;
                    tempSubProjectData[1][selectedSubProject-1].end = nearestLocation.nearestLocation;
                    tempSubProjectData[1][selectedSubProject-1].endIndex = nearestLocation.nearestLocationIndex;
                    tempSubProjectData[1][selectedSubProject-1].routeIndex = routeIndex;
                    // setSelectedPoint(1)
                }
            }
            else{
                if(selectedPoint === 1){
                    tempSubProjectData[0][selectedSubProject-1].start = marker
                    tempSubProjectData[0][selectedSubProject-1].startIndex = markerIndex
                    tempSubProjectData[0][selectedSubProject-1].routeIndex = routeIndex;
                    // setSelectedPoint(2)
                }
                if (selectedPoint === 2){
                    tempSubProjectData[0][selectedSubProject-1].end = marker
                    tempSubProjectData[0][selectedSubProject-1].endIndex = markerIndex
                    tempSubProjectData[0][selectedSubProject-1].routeIndex = routeIndex;
                    // setSelectedPoint(1)
                }
            }
            
            setSubProjectData(tempSubProjectData);
        }
    }

    // Function to find the nearest location
    const findNearestLocation = (locations, point) => {
        let nearestLocation = null;
        let nearestDistance = Infinity;
        let nearestLocationIndex = 0;
        
        locations.forEach((location, locationIndex) => {
        const distance = distBtwPoints(
            {
                lat:point.lat,
                lng:point.lng
            },{
                lat:location.lat,
                lng:location.lng
            }
        )
        if (distance < nearestDistance) {
            nearestDistance = distance;
            nearestLocation = location;
            nearestLocationIndex =locationIndex
        }
        });
        
        return { nearestLocation, nearestDistance, nearestLocationIndex};
    };

  const data=async(isdaa)=>{
    console.log("isEntryOrExitPit changed:", isEntryOrExitPit);
    console.log(HDDData[0],"vyuv")
    // {isEntryOrExitPit && (
    //     <MapWithInfoWindow
    //       isEntryOrExitPit={isEntryOrExitPit}
    //     //   trackingSelected={trackingSelected}
    //       HDDData={HDDData[0]}
    //     />
    //   )}
        await HDDData[0].map((item,index)=>{
            console.log(item.type,isdaa)

        if(item.type==isdaa){
            setindex(index)
            console.log(item.type,isdaa,index)

        }
       })
       console.log(HDDData[0][indexs],indexs,"sgs")
      
  }
    const addSubProject = (length)=>{
        let tempSubProjectData = [];
        for (let j = 0; j<2 ; j++){
            tempSubProjectData.push([])
            
            for (let i = 0; i<length;i++){
                tempSubProjectData[j].push({
                    routeIndex:null,
                    start:null,
                    startIndex:null,
                    end:null,
                    endIndex:null
                })
            }
        }
        console.log(tempSubProjectData);
        setSubProjectData(tempSubProjectData);
        setSelectedPoint(null);
        setSelectedSubProject(null);
    }

    const createSubProjectHandler = ()=>{
        let isValid = true;
        subProjectData[0].map(item=>{
            isValid = isValid && item.start !== null;
            isValid = isValid && item.startIndex !== null;
            isValid = isValid && item.end !== null;
            isValid = isValid && item.endIndex !== null;
        })    
        if(isValid){
            setLoading(true);
            let data = [];
            let surveyData =[];
            let BOQData = [];
            subProjectData[0].map((item,index)=>{
                let tempData = [];
                for(let i = item.startIndex;i<=item.endIndex; i++){
                    tempData.push(props.mapData.store[0].data[item.routeIndex].markers[i])
                }
                BOQData.push(tempData);
                data.push({
                    subProjectNo:index+1,
                    routeIndex:item.routeIndex,
                    mapData:tempData,
                    BOQ:{
                        pipeDesc:null,
                        countDesc:{
                            manhole:null,
                            pole:null
                        }
                    },
                    materialofBOQ:[],
                    servicesofBOQ:[]
                })
            })
            subProjectData[1].map((item,index)=>{
                let tempData = []
                for(let i = item.startIndex;i<= item.endIndex; i++){
                    tempData.push(props.mapData.store[0].data[item.routeIndex].markers[i])
                };
                surveyData.push({
                    subProjectNo:index+1,
                    routeIndex:item.routeIndex,
                    mapData:tempData,
                })
            })
            
            let BOQ = getDetailedBOQforSubProject(BOQData);
            
            BOQ.pipeDesc.map((item,index)=>{
                data[index].BOQ.pipeDesc = item
            });
            BOQ.countDesc.manhole.map((item,index)=>{
                data[index].BOQ.countDesc.manhole = item;
            });
            BOQ.countDesc.pole.map((item,index)=>{
                data[index].BOQ.countDesc.pole = item;
            });
            axios.post('/createSubProjects',{
                caseID:props.caseDetails.caseID,
                projectID:props.caseDetails.projectID,
                data:data,
                surveyData:surveyData
            }).then(response=>{
                alert('Sub Projects created successfully');
                setSelectedSubProjetsNo(null);
                setSubProjectData(null);
                getSubProjects();
            }).catch(e=>console.log(e))
        }
        else{
            alert('Please fill all data')
        }
    }

    const getSubProjects = ()=>{
        setLoading(true);
        axios.get('/getSubProjects',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setSubProjects(response.data)
            if(response.data.subProject.length > 0){
                setEdit(true)
                createSelectedSubProjectID(response.data)
            }
        })
    }

    const createSelectedSubProjectID = (subP)=>{
        let tempSelectedSubProjectID = [];
        subP.subProject[0].data.map((item,index)=>{
            tempSelectedSubProjectID.push(index);
        })
        setSelectedSubProjectID(tempSelectedSubProjectID);
    }

    const handleCheckboxChange = (index, event) => {
        // Update the state or perform any other logic when the checkbox is changed
        const isChecked = event.target.checked;
      
        if (isChecked) {
            // If the checkbox is checked, add the index to the selectedSubProjectID array
            setSelectedSubProjectID((prevSelected) => [...prevSelected, index]);
        } else {
            // If the checkbox is unchecked, remove the index from the selectedSubProjectID array
            setSelectedSubProjectID((prevSelected) =>
                prevSelected.filter((item) => item !== index)
            );
        }
    };

    const colors = [
        {"color": "Red", "hex": "#FF0000"},
        {"color": "Green", "hex": "#00FF00"},
        {"color": "Blue", "hex": "#0000FF"},
        {"color": "Yellow", "hex": "#FFFF00"},
        {"color": "Cyan", "hex": "#00FFFF"},
        {"color": "Magenta", "hex": "#FF00FF"},
        {"color": "Orange", "hex": "#FFA500"},
        {"color": "Purple", "hex": "#800080"},
        {"color": "Pink", "hex": "#FFC0CB"},
        {"color": "Brown", "hex": "#A52A2A"},
        {"color": "Gray", "hex": "#808080"},
        {"color": "Teal", "hex": "#008080"},
        {"color": "Lime", "hex": "#00FF00"},
        {"color": "Indigo", "hex": "#4B0082"},
        {"color": "SlateBlue", "hex": "#6A5ACD"}
    ]

    const dataCleanSubProject = ()=>{
        let tempMarkers = []
        if(subProjects && subProjects.subProject.length >0){
            subProjects.subProject[0].data.map((markers,markersIndex)=>{
                if(selectedSubProjectID.includes(markersIndex)){
                    markers.mapData.map(marker=>{
                        tempMarkers.push(marker)
                    })
                }
            })
        }
    
        return(tempMarkers)
        
    }

    const onMapRightClick = React.useCallback((e) => {
        setLatLng({
            lat:e.latLng.lat(),
            lng:e.latLng.lng(),
            time:new Date()
        })
    }, []);

    
    const addMarker = (e) => {
        let  markerType = e.target.attributes.getNamedItem("data-marker-type")
        // if he clicks on context Menu but not the menu item
        if(markerType) markerType = markerType.value; 
        else return;
        if(markerType==="WayPoint"){
            setManholePopBox(true);
            return;
        }
        // Finding the previous Manhole
        let prevManhole = null,i;
        const tempMarkers = subProjects.subProject[0].data[selectedSubProject-1].mapData
            if(markerType==="Manhole"){
                for(let i=tempMarkers.length-1;i>=0;i--){
                    if(tempMarkers[i].type === "Manhole"){
                        prevManhole = tempMarkers[i];
                        break;
                    }
                }       
            }

        // we need to insert correctly into main markers array
        let x = latLng.lat,y = latLng.lng;
        for(i=0;i<tempMarkers.length-1;i++){
            let x1 = tempMarkers[i].lat,y1 = tempMarkers[i].lng,x2 = tempMarkers[i+1].lat,y2 = tempMarkers[i+1].lng
            // line equation
            // y-y1 = (y2-y1)/(x2-x1) * (x-x1)
            // (y-y1)*(x2-x1) = (y2-y1)*(x-x1);
            if(Math.abs(((y-y1)*(x2-x1)).toFixed(12)-((y2-y1)*(x-x1)).toFixed(12))<6e-9){
            }
            else  continue;
            let maxLat = Math.max(x1,x2),minLat = Math.min(x1,x2),maxLng = Math.max(y1,y2),minLng = Math.min(y1,y2);
            if((maxLat-x)>0 && (x-minLat)>0 && (maxLng-y)>0 &&  (y-minLng)>0 ){
              break;
            }
        }

        // Finding the maximum Manhole count
        let manholeCount = 0;
        subProjects.subProject[0].data.map(outerItem=>{
            outerItem.mapData.map(item=>{
                if(item.type === 'Manhole' && item.count > manholeCount)
                    manholeCount = item.count;
            })
        })

        // Inserting the manhole
        tempMarkers.splice(i+1, 0,{
            type : markerType,
            lat: latLng.lat,
            lng: latLng.lng,
            time: new Date(),
            count : manholeCount+1,
            ...(markerType ==="Manhole") && 
            {pipeContainers : {
                "A" : prevManhole ? [...prevManhole.pipeContainers.C] : [],
                "B" : [],
                "C" : [],
                "D" : []
            }}
        });
        let tempSubProjects = [... subProjects.subProject[0].data];
        let tempSubProject = [... tempSubProjects[selectedSubProject-1].mapData]
        tempSubProject = tempMarkers;
        let tempSubProjs = []
        tempSubProjs.push({data:tempSubProjects})
        setSubProjects({
            subProject:tempSubProjs
        });
        setSelectedRoute(0);
        setSelectedSubProject(null);
    }

    const updateSubProjecthandler = (subP)=>{
        setLoading(true);
        let data = [];
        let surveyData =[];
        let BOQData = [];
        subP.subProject[0].data.map((item,index)=>{
            BOQData.push(item.mapData)
            console.log(item)
            data.push({
                subProjectNo:item.subProjectNo,
                routeIndex:item.routeIndex,
                mapData:item.mapData,
                BOQ:{
                    pipeDesc:null,
                    countDesc:{
                        manhole:null,
                        pole:null
                    }
                },
                materialofBOQ:[],
                servicesofBOQ:[]
            })
            
        })
        subP.subProject[0].surveyData.map((item,index)=>{
            let tempData = []
            for(let i = item.startIndex;i<= item.endIndex; i++){
                tempData.push(props.mapData.store[0].data[item.routeIndex].markers[i])
            };
            surveyData.push({
                subProjectNo:item.subProjectNo,
                routeIndex:item.routeIndex,
                mapData:item.mapData,
            })
        })
        let BOQ = getDetailedBOQforSubProject(BOQData);
            
        BOQ.pipeDesc.map((item,index)=>{
            data[index].BOQ.pipeDesc = item
        });
        BOQ.countDesc.manhole.map((item,index)=>{
            data[index].BOQ.countDesc.manhole = item;
        });
        BOQ.countDesc.pole.map((item,index)=>{
            data[index].BOQ.countDesc.pole = item;
        });
        axios.post('/createSubProjects',{
            caseID:props.caseDetails.caseID,
            projectID:props.caseDetails.projectID,
            data:data,
            surveyData:surveyData
        }).then(response=>{
            setLoading(false);
            alert('Sub Projects updated successfully');
            setSelectedSubProjetsNo(null);
            setSubProjectData(null);
            getSubProjects();
        }).catch(e=>console.log(e))
    }

    const getMaterialItem = ()=>{
    axios.get('/getAssetSubComponent',{
      params:{
        subComponentSubCategoryID:props.caseDetails.caseMaster[0].assetSubComponentSubCategory,
        subComponentCategoryID:'Material01'
      }
    })
    .then(response=>{
        console.log(response)
        setMaterialItem(response.data.assetSubComponent);
    })
    .catch(e=>console.log(e))
    }
  
    const getServiceItem = ()=>{
        axios.get('/getAssetSubComponent',{
            params:{
                subComponentSubCategoryID:props.caseDetails.caseMaster[0].assetSubComponentSubCategory,
                subComponentCategoryID:'Services01'
            }
        })
        .then(response=>{
            setServiceItem(response.data.assetSubComponent);
        })
        .catch(e=>console.log(e))
   }

    return (
        <div style={{position:'relative'}}>
            {isLoaded ? (
                <div>
                    <div style={{ position:'absolute', top:10, right:10, background:'white', zIndex:100, zoom:0.8}}>
                        {edit?(
                            <>
                                {subProjects && subProjects.subProject.length > 0 ?<CloseIcon
                                    style={{float:'right', color:'teal', cursor:'pointer'}}
                                    onClick = {()=>setEdit(true)}
                                />:null}
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    {props.mapData.store[0].data.map((item,index)=>{
                                        return(
                                            <div 
                                                style={{display:'flex', justifyContent:'center', alignItems:'center', margin:5, cursor:'pointer'}}
                                                onClick={()=>setSelectedRoute(index)}
                                            >
                                                <div style={{width:20,height:10,background:item.color, margin:5}}></div>
                                                <p style={{ margin: 0, color: selectedRoute === index ? 'teal' : 'black' }}>Route - {index}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div style={{padding:5}}>
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Subproject number" 
                                        variant="outlined" 
                                        size = 'small'
                                        type="number"
                                        value={subProjectno}
                                        onChange={(e)=>{
                                            setsub(e.target.value);
                                            // addSubProject(e.target.value);
                                        }}    
                                    />
                                </div>
                                {selectedSubProjectsNo ? (
                                    <div style={{maxHeight:'40vh', overflow:'auto'}}>
                                        {Array.from({ length: selectedSubProjectsNo }).map((_, index) => (
                                            <>
                                                <div style={{margin:'10px 0px', background:'aliceblue'}}>
                                                    <div style={{display:'flex',justifyContent:'center', alignItems:'center' }}>
                                                        <div 
                                                            style={{width:15, height:15, borderRadius:'50%', background:parseInt(selectedSubProject-1) == index ? 'teal':'black', margin:5, cursor:'pointer'}}
                                                            onClick={()=>{
                                                                if(selectedSubProject === parseInt(index)+1)
                                                                    setSelectedSubProject(null);
                                                                else
                                                                    setSelectedSubProject(parseInt(index)+1);
                                                                setSelectedPoint(null)
                                                            }}
                                                        ></div>
                                                        <p style={{margin:5}}>Sub-Project {index+1}</p>
                                                    </div>
                                                    {parseInt(selectedSubProject-1) === index ? (
                                                        // <p style={{fontSize:'small', width:225, color:'teal', margin:0}}>*Please click on map for starting point and end point</p>
                                                        <div>
                                                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                <Button 
                                                                    onClick={()=>setSelectedPoint(1)}
                                                                    style={
                                                                        {
                                                                            color:selectedPoint === 1 && parseInt(selectedSubProject-1) === index ? 'teal':'black',
                                                                            margin:'0px 10px'
                                                                        }
                                                                    }
                                                                >Start</Button>
                                                                {subProjectData[0].length > 0 && subProjectData[0][index].start ?(
                                                                    <p style={{margin:'0px 10px'}}>{`${subProjectData[0][index].start.type}-${subProjectData[0][index].start.count}`}</p>
                                                                ):null}
                                                                
                                                            </div>
                                                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                <Button 
                                                                    onClick={()=>setSelectedPoint(2)}
                                                                    style={
                                                                        {
                                                                            color:selectedPoint === 2 && parseInt(selectedSubProject-1) === index ? 'teal':'black',
                                                                            margin:'0px 10px'
                                                                        }
                                                                    }
                                                                >End</Button>
                                                                {subProjectData[0].length > 0 && subProjectData[0][index].end ?(
                                                                    <p style={{margin:'0px 10px'}}>{`${subProjectData[0][index].end.type}-${subProjectData[0][index].end.count}`}</p>
                                                                ):null}
                                                            </div>
                                                            {/* <Button onClick={()=>setSelectedPoint(1)}>Start WP</Button>
                                                            <Button onClick={()=>setSelectedPoint(2)}>End WP</Button> */}
                                                        </div>
                                                        
                                                        ):null
                                                    }
                                                </div>
                                                
                                            </>
                                            
                                        ))}
                                        {selectedSubProjectsNo >0 ? (
                                            <div style={{textAlign:'center'}}>
                                                <Button
                                                    style={{background:'teal', color:'white', width:120, margin:10, padding:2}}
                                                    onClick={()=>createSubProjectHandler()}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        ):null}
                                        
                                    </div>
                                ):null}
                            </>
                        ):(
                            <>
                                {subProjects && subProjects.subProject.length > 0 ? (
                                    <div>
                                        <EditIcon 
                                            style={{fontSize:18, color:'teal', cursor:'pointer', float:'right'}} 
                                            onClick = {()=>setEdit(true)}
                                        />
                                        <p style={{textAlign:'center', fontWeight:'bold', padding:5, borderBottom:'1px solid teal'}}>Sub-Projects</p>
                                        <FormGroup style={{paddingLeft:54, background:'aliceblue'}}>
                                            <FormControlLabel control={
                                                <Checkbox 
                                                    checked = {selectedSubProjectID.length === subProjects.subProject[0].data.length}
                                                    onChange={(event) => {
                                                        if(event.target.checked)
                                                            createSelectedSubProjectID(subProjects)
                                                        }}
                                                />} label={ "Select all"}  />
                                        </FormGroup>
                                        {subProjects.subProject[0].data.map((item,index)=>{
                                            return(
                                                <div style = {{display:'flex', justifyContent:'space-evenly', alignItems:'center', margin:'5px 10px'}}>
                                                    <div style={{width:25, height:10, background:colors[index].hex, margin:'5px 10px'}}></div>
                                                    <FormGroup>
                                                        <FormControlLabel control={
                                                            <Checkbox 
                                                                checked = {selectedSubProjectID.includes(index)}
                                                                onChange={(event) => handleCheckboxChange(index, event)}
                                                            />} label={ "Sub-Project "+item.subProjectNo} />
                                                    </FormGroup>
                                                </div>
                                                
                                            )
                                        })}
                                        
                                        <div style={{textAlign:'center'}}>
                                            
                                            {editMap ? (
                                                    <div>
                                                        <p style={{margin:0, padding:'0px 10px', color:'red', textAlign:'left'}}>Note: Right click on the route to add manhole</p>
                                                        <p style={{margin:0, padding:'0px 10px', color:'red', textAlign:'left'}}>Note: Click manhole to add Pipe configuration</p>
                                                        <Button
                                                            onClick={()=>{
                                                                setEditMap(true)
                                                            }}
                                                            style={{color:'red', margin:10}}
                                                            
                                                        >Close Editing
                                                        </Button>
                                                    </div>
                                                ):(
                                                    <div>
                                                        <Button
                                                            onClick={()=>{
                                                                setEditMap(true)
                                                            }}
                                                            style={{color:'teal', margin:10}}
                                                            
                                                        >Edit Route
                                                        </Button>
                                                    </div>
                                                )
                                            }
                                            <div>
                                                <Button
                                                    onClick={()=>{
                                                        updateSubProjecthandler(subProjects)
                                                    }}
                                                    style={{color:'white', margin:10, background:'teal'}}
                                                    
                                                >Submit
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ):null}
                            </>
                        )}
                            
                    </div>
                    <div style={{ position:'absolute', top:10, left:10, background:'white', zIndex:100, maxWidth:254}}>
                        <MapExplorer
                            markers={
                                subProjects && subProjects.subProject.length >0 && !edit? dataCleanSubProject():props.mapData.store[0].data[selectedRoute].markers
                            }
                            isSubProject = {
                                subProjects && subProjects.subProject.length >0 && !edit ? true:false
                            }
                            subProjectData = {subProjects && subProjects.subProject.length >0 ?subProjects.subProject:null}
                            manholeHover={manholeHover}
                            mapData = {props.mapData}
                            getData = {(data)=>setSelectedLayer(data)}
                        />
                    </div>
                    <ContextMenuTrigger id="contextmenu">
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            mapTypeId = {mapView}
                            tilt={0}
                            zoom={18}
                            center={center} 
                            // onClick={edit && lineActive && checkAccess() && props.overallEdit ? addWayPoint : null}
                            options={options}
                            onLoad={onMapLoad} // when map loads it should be in the browserLocation
                            clickableIcons = {false}
                            showsBuildings={false}
                            
                        >
                            <Marker
                                label="Initial Location"
                                position={{
                                    lat: props.caseDetails.caseMaster[0].location.latitude,
                                    lng: props.caseDetails.caseMaster[0].location.longitude,
                                }}
                                pin
                                icon={{
                                    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                                    scaledSize: new window.google.maps.Size(30, 30),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(15, 15),
                                    labelOrigin: new window.google.maps.Point(15, -10)
                                }}
                            />
                            {selectedLayer === 0? (
                                <>
                                    {subProjects && subProjects.subProject.length >0 ? (
                                        <>
                                            {subProjects.subProject[0].data.map((markers,markersIndex)=>{
                                                if(selectedSubProjectID.includes(markersIndex)){
                                                    return(
                                                        <>
                                                            {HDDData[0]?.map((marker,markerIndex)=>{
                                                                console.log("insisde 6")
                                                                return(
                                                                    <Marker
                                                                        data-count = {marker.count}
                                                                        label= {{text:`${marker.type}-${marker.count}`,color:'#00008B'}}
                                                                        position={{ lat: marker.lat, lng: marker.lng }}
                                                                        pin
                                                                        // draggable={editMap ? true:false}
                                                                        icon={{
                                                                            url: (
                                                                                marker.type === "Entry Pit" ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png" : 
                                                                                marker.type === "Exit Pit" ? "http://maps.google.com/mapfiles/ms/icons/orange-dot.png":
                                                                                marker.type === "Tracking Point" ? "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png":
                                                                            null),
                                                                            scaledSize: new window.google.maps.Size(30, 30),
                                                                            origin: new window.google.maps.Point(0, 0),
                                                                            anchor: new window.google.maps.Point(15, 15),
                                                                            labelOrigin: new window.google.maps.Point(15, -10)
                                                                        }}
                                                                       
                                                                      
                                                                        onClick={async()=>{
                                                                            if(marker.type === "Entry Pit" || marker.type=="Exit Pit" || marker.type=="Tracking Point"){
                                                                                setTrackingSelected({
                                                                                    position:{
                                                                                        lat: marker.lat, 
                                                                                        lng: marker.lng 
                                                                                    },
                                                                                    markerIndex : marker.count, 
                                                                                    routeIndex : selectedRoute,
                                                                                    accordianData :  marker.pipeContainers 
                                                                                })
                                                                            }
                                                                            setSelectedMarker(marker)
                                                                                setisEntryOrExitPit(marker.type)
                                                                               await  data(marker.type)

                                                                                console.log("Marker clicked:", marker.type,isEntryOrExitPit); // Debugging
                                                                            }}
      
                                                                    />
                                                                    
                                                                )
                                                            })}
                                                              {selectedMarker && isEntryOrExitPit !== "" && indexs!==null ? (
                                                                
                                                                            <InfoWindow
                                                                              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                                                                              onCloseClick={() => {
                                                                                setSelectedMarker(null); // Close InfoWindow
                                                                                setisEntryOrExitPit(""); // Reset type when InfoWindow is closed
                                                                              }}
                                                                            >
                                                                              <div>
                                                                              <img
                  src={HDDData[0][indexs].img.uri} // Assuming each marker has an imageUrl property
                  alt={`Image for ${HDDData[0][indexs].img.uri}`}
                  style={{
                    maxWidth: '40%', // Ensure image fits within the div
                    height: 'auto',
                    borderRadius: '5px', // Optional: rounded corners
                  }}></img>
                                                                                <h3>Pipe Configuration</h3>
                                                                                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                                                                  {/* Loop through the pipeConfiguration data for the selected marker */}
                                                                                  {HDDData[0]?.find(item => item.type === selectedMarker.type)?.pipeConfiguration &&
                                                                                    Object.keys(HDDData[0].find(item => item.type === selectedMarker.type).pipeConfiguration).map((key, i) => (
                                                                                      <div
                                                                                        key={i}
                                                                                        style={{
                                                                                          padding: '10px',
                                                                                          backgroundColor: 'aliceblue',
                                                                                          marginBottom: '10px',
                                                                                        }}
                                                                                      >
                                                                                        <div>
                                                                                          <span><strong>{key}</strong></span>
                                                                                        </div>
                                                                                        {/* Map over each item in the pipe configuration */}
                                                                                        {HDDData[0].find(item => item.type === selectedMarker.type).pipeConfiguration[key].map((item, itemIndex) => (
                                                                                          <div
                                                                                            key={itemIndex}
                                                                                            style={{
                                                                                              display: 'flex',
                                                                                              justifyContent: 'space-between',
                                                                                              padding: '0 10px',
                                                                                            }}
                                                                                          >
                                                                                            <span>{item.PipeType}</span>
                                                                                            <span>{item.Quantity}</span>
                                                                                          </div>
                                                                                        ))}
                                                                                      </div>
                                                                                    ))}
                                                                                </div>
                                                                              </div>
                                                                            </InfoWindow>
                                                                          ):null}
                                                            <Polyline
                                                                path={markers.mapData}
                                                                strokeWidth = {10000}
                                                                options={{strokeColor : colors[markersIndex].hex}}
                                                                strokeWeight={2}
                                                                onRightClick = {(e)=>{
                                                                    if(editMap){
                                                                        setPolyLineActive(true);
                                                                        onMapRightClick(e);
                                                                        setSelectedSubProject(markers.subProjectNo);
                                                                        setSelectedRoute(markers.routeIndex);                          
                                                                    }
                                                                    
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                
                                                
                                                
                                            })}
                                            
                                        </>
                                        
                                    ):(
                                        <>
                                            {HDDData[0]?.map((marker,markerIndex)=>{
                                                console.log("inside5")
                                                return(
                                                    <Marker
                                                        data-count = {marker.count}
                                                        label= {{text:`${marker.type}-${marker.count}`,color:'#00008B'}}
                                                        position={{ lat: marker.lat, lng: marker.lng }}
                                                        pin
                                                        icon={{
                                                            url: (
                                                                marker.type === "Entry Pit" ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png" : 
                                                                marker.type === "Exit Pit" ? "http://maps.google.com/mapfiles/ms/icons/orange-dot.png":
                                                                marker.type === "Tracking Point" ? "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png":
                                                            null),
                                                            scaledSize: new window.google.maps.Size(30, 30),
                                                            origin: new window.google.maps.Point(0, 0),
                                                            anchor: new window.google.maps.Point(15, 15),
                                                            labelOrigin: new window.google.maps.Point(15, -10)
                                                        }}
                                                        onClick={()=>{
                                                            if(marker.type === 'WayPoint' || marker.type === 'Pole' || marker.type === 'manhole')
                                                                selectWP(marker,markerIndex,selectedRoute)}
                                                        }
                                                        onMouseOver={()=>{
                                                            if(marker.type === "Manhole"){
                                                                setManholeHover({
                                                                position:{
                                                                    lat: marker.lat, 
                                                                    lng: marker.lng 
                                                                },
                                                                markerIndex : marker.count, 
                                                                routeIndex : selectedRoute,
                                                                accordianData :  marker.pipeContainers 
                                                                })
                                                            }
                                                        }}
                                                        
                                                    />
                                                )
                                            })}
                                            <Polyline
                                                path={props.mapData.store[0].data[selectedRoute].markers}
                                                strokeWidth = {10000}
                                                options={{strokeColor : props.mapData.store[0].data[selectedRoute].color}}
                                                strokeWeight={2}
                                            />
                                        </>
                                        
                                    )}
                                </>
                            ):null}

                            {/*Planner Route*/} 
                            {selectedLayer === 0 ? (
                                <>
                                    {subProjects && subProjects.subProject.length >0 && !edit ? (
                                        <>
                                            {subProjects.subProject[0].data.map((markers,markersIndex)=>{
                                                if(selectedSubProjectID.includes(markersIndex)){
                                                    return(
                                                        <>
                                                            {markers.mapData.map((marker,markerIndex)=>{
                                                                return(
                                                                    <Marker
                                                                        data-count = {marker.count}
                                                                        label= {{text:`${marker.type}-${marker.count}`,color:'#00008B'}}
                                                                        position={{ lat: marker.lat, lng: marker.lng }}
                                                                        pin
                                                                        draggable={editMap ? true:false}
                                                                        icon={{
                                                                            url: (
                                                                                marker.type === "Manhole" ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png" : 
                                                                                marker.type === "WayPoint" ? "http://maps.google.com/mapfiles/ms/icons/orange-dot.png":
                                                                                marker.type === "Pole" ? "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png":
                                                                            null),
                                                                            scaledSize: new window.google.maps.Size(30, 30),
                                                                            origin: new window.google.maps.Point(0, 0),
                                                                            anchor: new window.google.maps.Point(15, 15),
                                                                            labelOrigin: new window.google.maps.Point(15, -10)
                                                                        }}
                                                                        onMouseOver={()=>{
                                                                            if(marker.type === "Manhole"){
                                                                                setManholeHover({
                                                                                    position:{
                                                                                        lat: marker.lat, 
                                                                                        lng: marker.lng 
                                                                                    },
                                                                                    markerIndex : marker.count, 
                                                                                    routeIndex : selectedRoute,
                                                                                    accordianData :  marker.pipeContainers 
                                                                                })
                                                                            }
                                                                        }}
                                                                        
                                                                        onClick={()=>{
                                                                            if(editMap && marker.type === "Manhole"){
                                                                                setSelectedManhole({
                                                                                    position:{
                                                                                        lat: marker.lat, 
                                                                                        lng: marker.lng 
                                                                                    },
                                                                                    markerIndex : marker.count, 
                                                                                    routeIndex : selectedRoute,
                                                                                    accordianData :  marker.pipeContainers,
                                                                                    subProjectIndex:markersIndex,
                                                                                    subProjectMapDataIndex:markerIndex
                                                                                })
                                                                            }
                                                                        }}
                                                                        onDragEnd = {(e)=>{
                                                                            let tempSubProjects = {... subProjects};
                                                                            tempSubProjects.subProject[0].data[markersIndex].mapData[markerIndex].lat = e.latLng.lat();
                                                                            tempSubProjects.subProject[0].data[markersIndex].mapData[markerIndex].lng = e.latLng.lng();
                                                                            setSubProjects(tempSubProjects);
                                                                            // onMarkerDragEnd(e,{marker,index:index,routeIndex});
                                                                        }}
                                                                                
                                                                    />
                                                                )
                                                            })}
                                                            <Polyline
                                                                path={markers.mapData}
                                                                strokeWidth = {10000}
                                                                options={{strokeColor : colors[markersIndex].hex}}
                                                                strokeWeight={2}
                                                                onRightClick = {(e)=>{
                                                                    if(editMap){
                                                                        setPolyLineActive(true);
                                                                        onMapRightClick(e);
                                                                        setSelectedSubProject(markers.subProjectNo);
                                                                        setSelectedRoute(markers.routeIndex);                          
                                                                    }
                                                                    
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                
                                                
                                                
                                            })}
                                            
                                        </>
                                        
                                    ):(
                                        <>
                                            {props.mapData.store[selectedLayer].data[selectedRoute].markers.map((marker,markerIndex)=>{
                                                return(
                                                    <Marker
                                                        data-count = {marker.count}
                                                        label= {{text:`${marker.type}-${marker.count}`,color:'#00008B'}}
                                                        position={{ lat: marker.lat, lng: marker.lng }}
                                                        pin
                                                        icon={{
                                                            url: (
                                                                marker.type === "Manhole" ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png" : 
                                                                marker.type === "WayPoint" ? "http://maps.google.com/mapfiles/ms/icons/orange-dot.png":
                                                                marker.type === "Pole" ? "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png":
                                                            null),
                                                            scaledSize: new window.google.maps.Size(30, 30),
                                                            origin: new window.google.maps.Point(0, 0),
                                                            anchor: new window.google.maps.Point(15, 15),
                                                            labelOrigin: new window.google.maps.Point(15, -10)
                                                        }}
                                                        onClick={()=>{
                                                            if(marker.type === 'WayPoint' || marker.type === 'Pole' || marker.type === 'manhole')
                                                                selectWP(marker,markerIndex,selectedRoute)}
                                                        }
                                                        onMouseOver={()=>{
                                                            if(marker.type === "Manhole"){
                                                                setManholeHover({
                                                                position:{
                                                                    lat: marker.lat, 
                                                                    lng: marker.lng 
                                                                },
                                                                markerIndex : marker.count, 
                                                                routeIndex : selectedRoute,
                                                                accordianData :  marker.pipeContainers 
                                                                })
                                                            }
                                                        }}
                                                        
                                                    />
                                                )
                                            })}
                                            <Polyline
                                                path={props.mapData.store[0].data[selectedRoute].markers}
                                                strokeWidth = {10000}
                                                options={{strokeColor : props.mapData.store[0].data[selectedRoute].color}}
                                                strokeWeight={2}
                                            />
                                        </>
                                        
                                    )}
                                </>
                            ):null}
{selectedLayer === 0? (
                                <>
                                    {subProjects && subProjects.subProject.length >0 ? (
                                        <>
                                            {subProjects.subProject[0].data.map((markers,markersIndex)=>{
                                                if(selectedSubProjectID.includes(markersIndex)){
                                                    return(
                                                        <>
                                                            {HDDData[0]?.map((marker,markerIndex)=>{
                                                                console.log("insisde 6")
                                                                return(
                                                                    <Marker
                                                                        data-count = {marker.count}
                                                                        label= {{text:`${marker.type}-${marker.count}`,color:'#00008B'}}
                                                                        position={{ lat: marker.lat, lng: marker.lng }}
                                                                        pin
                                                                        draggable={editMap ? true:false}
                                                                        icon={{
                                                                            url: (
                                                                                marker.type === "Entry Point" ? "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" : 
                                                                                marker.type === "Exit Point" ? "http://maps.google.com/mapfiles/ms/icons/pink-dot.png":
                                                                                marker.type === "Tracking Point" ? "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png":
                                                                            null),
                                                                            scaledSize: new window.google.maps.Size(30, 30),
                                                                            origin: new window.google.maps.Point(0, 0),
                                                                            anchor: new window.google.maps.Point(15, 15),
                                                                            labelOrigin: new window.google.maps.Point(15, -10)
                                                                        }}
                                                                        onMouseOver={()=>{
                                                                            if(marker.type === "Manhole"){
                                                                                setManholeHover({
                                                                                    position:{
                                                                                        lat: marker.lat, 
                                                                                        lng: marker.lng 
                                                                                    },
                                                                                    markerIndex : marker.count, 
                                                                                    routeIndex : selectedRoute,
                                                                                    accordianData :  marker.pipeContainers 
                                                                                })
                                                                            }
                                                                        }}
                                                                        
                                                                        onClick={()=>{
                                                                            if(editMap && marker.type === "Manhole"){
                                                                                setSelectedManhole({
                                                                                    position:{
                                                                                        lat: marker.lat, 
                                                                                        lng: marker.lng 
                                                                                    },
                                                                                    markerIndex : marker.count, 
                                                                                    routeIndex : selectedRoute,
                                                                                    accordianData :  marker.pipeContainers,
                                                                                    subProjectIndex:markersIndex,
                                                                                    subProjectMapDataIndex:markerIndex
                                                                                })
                                                                            }
                                                                        }}
                                                                        onDragEnd = {(e)=>{
                                                                            let tempSubProjects = {... subProjects};
                                                                            tempSubProjects.subProject[0].data[markersIndex].mapData[markerIndex].lat = e.latLng.lat();
                                                                            tempSubProjects.subProject[0].data[markersIndex].mapData[markerIndex].lng = e.latLng.lng();
                                                                            setSubProjects(tempSubProjects);
                                                                            // onMarkerDragEnd(e,{marker,index:index,routeIndex});
                                                                        }}
                                                                                
                                                                    />
                                                                )
                                                            })}
                                                            <Polyline
                                                                path={markers.mapData}
                                                                strokeWidth = {10000}
                                                                options={{strokeColor : colors[markersIndex].hex}}
                                                                strokeWeight={2}
                                                                onRightClick = {(e)=>{
                                                                    if(editMap){
                                                                        setPolyLineActive(true);
                                                                        onMapRightClick(e);
                                                                        setSelectedSubProject(markers.subProjectNo);
                                                                        setSelectedRoute(markers.routeIndex);                          
                                                                    }
                                                                    
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                
                                                
                                                
                                            })}
                                            
                                        </>
                                        
                                    ):(
                                        <>
                                            {HDDData[0]?.map((marker,markerIndex)=>{
                                                console.log("inside5")
                                                return(
                                                    <Marker
                                                        data-count = {marker.count}
                                                        label= {{text:`${marker.type}-${marker.count}`,color:'#00008B'}}
                                                        position={{ lat: marker.lat, lng: marker.lng }}
                                                        pin
                                                        icon={{
                                                            url: (
                                                                marker.type === "Entry Point" ? "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" : 
                                                                marker.type === "Exit Point" ? "http://maps.google.com/mapfiles/ms/icons/pink-dot.png":
                                                                marker.type === "Tracking Point" ? "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png":
                                                            null),
                                                            scaledSize: new window.google.maps.Size(30, 30),
                                                            origin: new window.google.maps.Point(0, 0),
                                                            anchor: new window.google.maps.Point(15, 15),
                                                            labelOrigin: new window.google.maps.Point(15, -10)
                                                        }}
                                                        onClick={()=>{
                                                            if(marker.type === 'WayPoint' || marker.type === 'Pole' || marker.type === 'manhole')
                                                                selectWP(marker,markerIndex,selectedRoute)}
                                                        }
                                                        onMouseOver={()=>{
                                                            if(marker.type === "Manhole"){
                                                                setManholeHover({
                                                                position:{
                                                                    lat: marker.lat, 
                                                                    lng: marker.lng 
                                                                },
                                                                markerIndex : marker.count, 
                                                                routeIndex : selectedRoute,
                                                                accordianData :  marker.pipeContainers 
                                                                })
                                                            }
                                                        }}
                                                        
                                                    />
                                                )
                                            })}
                                            <Polyline
                                                path={props.mapData.store[0].data[selectedRoute].markers}
                                                strokeWidth = {10000}
                                                options={{strokeColor : props.mapData.store[0].data[selectedRoute].color}}
                                                strokeWeight={2}
                                            />
                                        </>
                                        
                                    )}
                                </>
                            ):null}

                            {/*Survey Route*/} 
                            {selectedLayer === 1 ? (
                                <>
                                    {subProjects && subProjects.subProject.length >0 && !edit ?(
                                        <>
                                            {subProjects.subProject[0].surveyData.map((markers,markersIndex)=>{
                                                if(selectedSubProjectID.includes(markersIndex)){
                                                    return(
                                                        <>
                                                            {markers.mapData.map((marker,markerIndex)=>{
                                                                return(
                                                                    <Marker
                                                                        data-count = {marker.count}
                                                                        label= {{text:`${marker.type}-${marker.count}`,color:'#00008B'}}
                                                                        position={{ lat: marker.lat, lng: marker.lng }}
                                                                        pin
                                                                        icon={{
                                                                            url: (
                                                                                marker.type === "comment" ? comment:
                                                                                marker.type === "location"? location:
                                                                            null),
                                                                            scaledSize: new window.google.maps.Size(30, 30),
                                                                            origin: new window.google.maps.Point(0, 0),
                                                                            anchor: new window.google.maps.Point(15, 15),
                                                                            labelOrigin: new window.google.maps.Point(15, -10)
                                                                        }}
                                                                        onClick={()=>{
                                                                            setSelected({
                                                                                marker,
                                                                                index: markerIndex+1,
                                                                            });
                                                                        }}      
                                                                    />
                                                                )
                                                            })}
                                                            <Polyline
                                                                path={markers.mapData}
                                                                strokeWidth = {10000}
                                                                options={{strokeColor : colors[markersIndex].hex}}
                                                                strokeWeight={2}
                                                                onRightClick = {(e)=>{
                                                                    if(editMap){
                                                                        setPolyLineActive(true);
                                                                        onMapRightClick(e);
                                                                        setSelectedSubProject(markers.subProjectNo);
                                                                        setSelectedRoute(markers.routeIndex);                          
                                                                    }
                                                                    
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                
                                                
                                                
                                            })}
                                        </>
                                    ):(
                                        <>
                                            {props.mapData.store[selectedLayer].data[selectedRoute] ? (
                                                <div>
                                                    {props.mapData.store[selectedLayer].data[selectedRoute].markers.map((marker,markerIndex)=>{
                                                        return(
                                                            <Marker
                                                                data-count = {marker.count}
                                                                label= {{text:`${marker.type}-${marker.count}`,color:'#00008B'}}
                                                                position={{ lat: marker.lat, lng: marker.lng }}
                                                                pin
                                                                icon={{
                                                                    url: (
                                                                        marker.type === "comment" ? comment:
                                                                        marker.type === "location"? location:
                                                                    null),
                                                                    scaledSize: new window.google.maps.Size(30, 30),
                                                                    origin: new window.google.maps.Point(0, 0),
                                                                    anchor: new window.google.maps.Point(15, 15),
                                                                    labelOrigin: new window.google.maps.Point(15, -10)
                                                                }}
                                                                onClick={()=>{
                                                                    setSelected({
                                                                        marker,
                                                                        index: markerIndex+1,
                                                                    });
                                                                }}
                                                                
                                                            />
                                                        )
                                                    })}
                                                    <Polyline
                                                        path={props.mapData.store[selectedLayer].data[0].markers}
                                                        strokeWidth = {10000}
                                                        options={{strokeColor : props.mapData.store[selectedLayer].data[0].color}}
                                                        strokeWeight={2}
                                                    />
                                                </div>
                                            ):null}
                                        </>
                                    )}
                                    
                                    
                                    
                                </>
                            ):null}
                                
                            {selectedManhole ? (
                                <InfoWindow
                                    position={{lat:selectedManhole.position.lat,lng:selectedManhole.position.lng}}
                                    onCloseClick={()=>{
                                        setSelectedManhole(null);
                                    }}
                                    style = {{width:350}}
                                    
                                >
                                    <div style={{zoom:0.75, padding:5}}>
                                        <div>
                                            <div style={{padding:5, border:'1px solid', margin:'10px auto', borderRadius:'50%', width:100, textAlign:'center',}}>
                                            <p>B - Branch 1</p>
                                            </div>
                                            <div style={{display:'flex'}}>
                                            <div>
                                                <div style={{padding:5, border:'1px solid', margin:10, borderRadius:'50%', width:100}}>
                                                <p>A - Incoming Fibre</p>
                                                </div>
                                            </div>
                                            
                                            <div style={{background:'teal', padding:10, margin:'10px auto'}}>
                                                <p style={{color:'white'}}>Manhole - {selectedManhole.markerIndex}</p>
                                            </div>
                                            <div style={{padding:5, border:'1px solid', margin:10, borderRadius:'50%', width:100}}>
                                                <p>C - Outgoing Fibre</p>
                                            </div>
                                            </div>
                                            
                                            <div style={{padding:5, border:'1px solid', margin:'10px auto', borderRadius:'50%', width:100,textAlign:'center'}}>
                                            <p>D - Branch 2</p>
                                            </div>
                                        </div>
                                        <div style={{height:200, overflow:'auto', padding:'0px 10px'}}>
                                        {Object.keys(selectedManhole.accordianData).map(key=>{
                                            return(
                                                <Accordion >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel3-content"
                                                        id="panel3-header"
                                                    >
                                                        Side - {key}
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{borderTop:'1px solid teal'}}>
                                                        <div>
                                                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                <div>
                                                                    <Box sx={{ minWidth: 120 }}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="demo-simple-select-label">Pipe Types</InputLabel>
                                                                            <Select
                                                                                labelId="demo-simple-select-label"
                                                                                id="demo-simple-select"
                                                                                size="small"
                                                                                value={selectedPipeTypes.PipeType}
                                                                                label="Types"
                                                                                onChange={(e)=>{
                                                                                    let tempSelectedPipeTypes = {... selectedPipeTypes};
                                                                                    tempSelectedPipeTypes.PipeType = e.target.value;
                                                                                    setSelectedPipeTypes(tempSelectedPipeTypes)
                                                                                }}
                                                                            >
                                                                                {materialItem && materialItem.map(item1=>{
                                                                                    return(
                                                                                        <MenuItem value = {item1.assetSubComponentName + "-" +item1.assetSubComponentVarieties}>{item1.assetSubComponentName + "-" +item1.assetSubComponentVarieties}</MenuItem>
                                                                                    )
                                                                                })}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Box>
                                                                </div>
                                                                <div>
                                                                    <TextField 
                                                                        id="outlined-basic" 
                                                                        label="Quantity" 
                                                                        variant="outlined" 
                                                                        size="small" 
                                                                        type="number"
                                                                        min="0"
                                                                        style = {{margin:10,width:100}} 
                                                                        value = {selectedPipeTypes.Quantity}
                                                                        onChange={(e)=>{
                                                                            let tempSelectedPipeTypes = {... selectedPipeTypes};
                                                                            tempSelectedPipeTypes.Quantity = e.target.value;
                                                                            setSelectedPipeTypes(tempSelectedPipeTypes)
                                                                        }}
                                                                        // onChange = {(e)=>specificationHandler(parseInt(e.target.value),'Quantity')}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <Button 
                                                                        onClick = {()=>{
                                                                            if(selectedPipeTypes.PipeType && selectedPipeTypes.Quantity){
                                                                                let tempSelectedManhole = {... selectedManhole};
                                                                                tempSelectedManhole.accordianData[key].push(selectedPipeTypes)
                                                                                setSelectedManhole(tempSelectedManhole)
                                                                                let tempSelectedPipeTypes = {... selectedPipeTypes};
                                                                                tempSelectedPipeTypes = {
                                                                                    PipeType:'',
                                                                                    Quantity:''
                                                                                };
                                                                                setSelectedPipeTypes(tempSelectedPipeTypes)
                                                                            }
                                                                            
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            <table style={{width:'100%',background:'aliceblue'}}>
                                                                <tr>
                                                                    <th>S.No.</th>
                                                                    <th style={{width:'75%'}}>Type</th>
                                                                    <th>Quantity</th>
                                                                </tr>
                                                                {selectedManhole.accordianData[key].map((item,index)=>{
                                                                    return(
                                                                        <tr>
                                                                            <td style={{textAlign:'center'}}>{index+1}</td>
                                                                            <td style={{textAlign:'center'}}>{item.PipeType}</td>
                                                                            <td style={{textAlign:'center'}}>{item.Quantity}</td>
                                                                            <td>
                                                                                <DeleteIcon
                                                                                    style = {{color:'red', cursor:'pointer'}}
                                                                                    onClick = {()=>{
                                                                                        let tempSelectedManhole = {... selectedManhole}
                                                                                        tempSelectedManhole.accordianData[key].splice(index,1)
                                                                                        setSelectedManhole(tempSelectedManhole)
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </table>
                                                        </div>
                                                    </AccordionDetails>
                                                    {/* <AccordionActions>
                                                        <Button>Cancel</Button>
                                                        <Button>Save</Button>
                                                    </AccordionActions> */}
                                                </Accordion>
                                            )
                                        })}
                                        
                                        </div>
                                        <div 
                                            style={{display:'flex', justifyContent:'space-evenly'}}
                                        >
                                            <Button 
                                                style={{color:'red'}}
                                                // onClick={()=>deleteAssets()}
                                            >
                                                Delete
                                            </Button>
                                            <Button 
                                                style={{background:'teal', color:'white'}}
                                                // onClick={()=>saveAssets()}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                        {/* <div style={{height:200, overflow:'auto', padding:'0px 10px'}}>
                                            {Object.keys(selectedManhole.accordianData).map(key=>{
                                            return(
                                                <div style={{borderBottom:'1px solid teal'}}>
                                                <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
                                                    <p style={{textAlign:'left', fontWeight:'bold', color:'teal'}}>Side {key}</p>
                                                    {selectedManhole.showPipeSide[key]? (<KeyboardArrowUpIcon  style = {{color:'teal'}} onClick = {()=>{
                                                    let tempselectedManhole = {... selectedManhole};
                                                    tempselectedManhole.showPipeSide[key]= false;
                                                    setManholeHover(tempselectedManhole);
                                                    }}/>):(<KeyboardArrowDownIcon style = {{color:'teal'}} onClick = {()=>{
                                                    let tempselectedManhole = {... selectedManhole};
                                                    tempselectedManhole.showPipeSide[key]= true;
                                                    setManholeHover(tempselectedManhole);
                                                    }}/>)}
                                                </div>  
                                                {selectedManhole.showPipeSide[key] ? (
                                                    <table style={{width:'100%',background:'aliceblue'}}>
                                                    <tr>
                                                        <th style={{width:'75%'}}>Type</th>
                                                        <th>Quantity</th>
                                                    </tr>
                                                    {selectedManhole.accordianData[key].map((item,index)=>{
                                                        return(
                                                        <tr>
                                                            <td>{item.PipeType}</td>
                                                            <td>{item.Quantity}</td>
                                                        </tr>
                                                        )
                                                    })
                                                    }
                                                    </table>
                                                ):null}
                                                
                                                
                                                </div>
                                            )
                                            })}
                                        </div> */}
                                    </div>
                                </InfoWindow>
                                ):null}
                            {
                                selected.marker ? (
                                    <InfoWindow
                                        position={{ lat: selected.marker.lat, lng: selected.marker.lng }}
                                        onCloseClick={() => {
                                            setSelected({
                                            marker: null,
                                            index: null
                                            });
                                        }}
                                    >
                                        {selected.marker.comment?(
                                            <div>
                                            <div style={{display:'flex',justifyContent:'space-evenly', alignItems:'center'}}>
                                                <div style={{fontSize:18, fontWeight:'bold',margin:10}}>Comments</div>
                                                {showComment ? (<KeyboardArrowUpIcon onClick = {()=>setShowComment(false)}/>) : (<KeyboardArrowDownIcon onClick = {()=>setShowComment(true)}/>)}
                                            </div>
                                            
                                            {showComment && selected.marker.comment.map(item=>{
                                                return(
                                                <div style = {{margin:5, padding:5, backgroundColor:'aliceblue'}}>
                                                    <div style = {{display:'flex', justifyContent:'space-between'}}>
                                                    <p style = {{color:'#aaa'}}>{item.resourceID}</p>
                                                    <p style = {{color:'#aaa'}}>{new Date(item.timestamp).toLocaleString()}</p>
                                                    </div>
                                                    <div>
                                                    {item.uri ? <img 
                                                        src={item.uri} 
                                                        style = {{width:256,height:256, padding:5}}
                                                        onClick = {()=>{
                                                        setOpenZoomImage({
                                                            status:true,
                                                            uri:item.uri
                                                        })
                                                        }}
                                                    />:null}
                                                    <p style= {{padding:5, textAlign:'left'}}>{item.commentText}</p>
                                                </div>
                                                </div>
                                                )
                                            })}
                                            </div>
                                        ):null} 
                                    </InfoWindow>
                                ):null
                            }
                            {
                                openZoomImage.status?(
                                    <div style = {{
                                    position: "absolute",
                                    top: "0.2rem",
                                    right: "4rem",
                                    background:'none'
                                    }}>
                                    <ImgsViewer
                                        imgs={[
                                        { src: openZoomImage.uri }
                                        ]}
                                        currImg={openZoomImage.uri}
                                        isOpen={true}
                                        onClose={setOpenZoomImage({status:false,uri:null})}
                                    />
                                    </div>
                                ):null
                                }
            
                        </GoogleMap>
                    </ContextMenuTrigger>
                    {editMap  ? 
                        <ContextMenu hideOnLeave={true} id="contextmenu">
                            <div style={contextMenuStyle}>
                                {polyLineActive && props.caseDetails.caseMaster[0].assetSubComponentSubCategory === 'Underground01' && 
                                    <MenuItem onClick={(e)=>addMarker(e)}>
                                        <div style={contextMenuItemStyle} >
                                            <img src={dot} alt="Manhole" style={{ width: "26px" }} />
                                            <span data-marker-type="Manhole">Manhole</span>
                                        </div>
                                    </MenuItem> 
                                }
                        {/* { 
                        !polyLineActive  && <MenuItem onClick={(e)=>addMarker(e)}>
                            <div style={contextMenuItemStyle} >
                            {(props.caseDetails.caseMaster[0].assetSubComponentSubCategory === 'Underground01' && props.overallEdit && checkAccess()) ? (
                                <>
                                <img src={line} alt="Manhole" style={{ width: "26px" }} />
                                <span data-marker-type="WayPoint">Way Point</span>
                                </>
                            ):(
                                (props.caseDetails.caseMaster[0].assetSubComponentSubCategory === 'Aerial01' && props.overallEdit && checkAccess())?(
                                <>
                                <img src={line} alt="pole" style={{ width: "26px" }} />
                                <span data-marker-type="WayPoint">Pole</span>
                                </>
                                ):null
                                
                            )}
                            
                            </div>
                        </MenuItem>
                        } */}
                        </div>
                    </ContextMenu> : null
                    }
                </div>
            ):(<p> Please wait</p>)}
            
            <div style={{display:'flex', position:'absolute', bottom:30, right:60, background:'white', borderRadius:5}}>
                <img 
                    src = {roadMap} 
                    alt = "RoadMap"
                    style = {
                        mapView === 'roadmap' ? 
                        {width:30, height:30,margin:10,cursor:"pointer", border:'2px solid teal'}:{width:30, height:30,margin:10,cursor:"pointer"}
                    } 
                    onClick = {()=>setMapView('roadmap')}/>
                <img 
                    src = {satellite} 
                    alt = "Satellite"
                    style = {
                        mapView === 'satellite' ? 
                        {width:30, height:30,margin:10,cursor:"pointer",border:'2px solid teal'}:{width:30, height:30,margin:10,cursor:"pointer"}
                    }
                    onClick = {()=>setMapView('satellite')}/>
                </div>
        </div>
    )
}

export default Opentrench
