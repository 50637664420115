import React, { useState } from 'react';
import {
    Grid,
    Paper,
    TextField,
    Button,
    Select,
    Typography,
    Link,
    Box,
    MenuItem,
    InputLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    CircularProgress,
    Checkbox
} from "@mui/material";

import classes from "./PreRegistration.module.css";
import Logo from "../../assets/images/logo.png";

const LegalInfoPaid = (props)=>{
    const [agreeCheckBox,setAgreeCheckBox] = useState(false);
    const [error,setError] = useState(null)
    const submitHandler = ()=>{
        if(agreeCheckBox)
            props.getData(4)
        else
            setError('* Please accept the terms and conditions')
    }
    return(
        <div>
            <Box className={classes.LegalInfoContainer}>
                <Paper elevation={24} style={{padding:'2%' }}>
                    <img src = {Logo} style = {{maxWidth:'100%'}}/>
                    <Grid>
                        <Grid style={{textAlign :'center', alignItems:'center'}}>
                            <h2 >Legal Information</h2>
                            <hr style={{ width: "50%" }} />
                            <div style={{background:'aliceblue',padding:'2%',margin:20,borderRadius:5,textAlign:'justify'}}>
                                <p>Paid Subscription - The paid subscription is intended for commercial subscribers, that intend to track the occupancy and location of their assigned machines and resources. The subscription also includes the use of Execution Engine as well as paperless documentation engine. This subscription is useful for execution vendors as well as machine owners interessted in running Business Operations on their own. The subscription is on a per sector per month basis for every HDD or JCB machine. The initial Monthly fee is kept as INR 1000, payable in advance for the year. T-TEG will install a GPS tracker for every machine, capturing the basic location and Engine ON / OFF details with date and time stamps. As a part of platform service, you will be getting various enquiries from the market, both from Private and Government sectors. You also get access to Utility companies on the platform as well as participating OEM. In paid subscription, we capture your basic invoicing related information, as well as the details of main contact person. Please take time out to provide the required details accurately as the same information will be part of Auto invoices from the platform.  </p>
                                <h3 >Terms & Conditions</h3>
                                <p>T-TEG does not intend to sell your data. The data provided is used to integrate the subscriber to the market through suitable aggregation. By clicking below</p>
                                <p>1. You are allowing T-TEG to capture the required data in Electonic form and use it for integration into the market. </p>
                                <p>2. You agree to installation of GPS tracker in the assigned machine by T-TEG and also to take secure custody of the tracker hardware </p>
                                
                            </div>
                            <Grid >
                                <FormGroup style={{padding:'0 2%',textAlign: 'left'}}>
                                <FormControlLabel control={<Checkbox checked = {agreeCheckBox} onChange={(e)=>setAgreeCheckBox(e.target.checked)} />} label="I confirm that I have gone through the information provided regarding paid subscription. I have no objection to platform capturing the data I am providing and using it as a part of platform processes." />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <p style = {{fontSize:12, textAlign: 'center',color:'red', margin:5}}>{error}</p>
                        <Grid style = {{padding: '0 5%',display: 'flex',justifyContent: 'space-between'}}>
                            <div>
                                <Button variant="text" onClick = {()=>props.getData(2)}>Back</Button>
                            </div>
                            <div>
                                <Button variant="contained" onClick = {submitHandler}>Next</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    )
}

export default LegalInfoPaid