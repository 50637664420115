import React,{useEffect, useState} from 'react'
import { CircularProgress } from "@mui/material";
import axios from '../../axios_tteg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function ProjectAssociated(props) {
    const [loading,setLoading]=useState(false)
    const [alreadyAssociated,setAlreadyAssociated] = useState([]);
    const [newAlreadyAssociated,setNewAlreadyAssociated] = useState(null);
    const [selectedSubscriberType,setSelectedSubscriberType]=useState(null)
    const [subscriberTypes,setSubscriberTypes]=useState([])

    const getAssociatedIndividual = async()=>{
        setLoading(true);
        await axios.get('/getProjectResourceAssociation',{
            params:{
                projectID:props.caseDetails.resourceID
            }
        }).then(response=>{
            setAlreadyAssociated(response.data.projectAssociation[0])
            setNewAlreadyAssociated(response.data.projectAssociation[0])
        })

        await axios.get('/getSubscriberTypes')    
            .then(response=>{
            setLoading(false);
           setSubscriberTypes(response.data.data)
            
            })
    }

    useEffect(()=>{
        getAssociatedIndividual()
    },[])
    let container=null
    if(loading ){
        container = (<CircularProgress/>)
    }
    else{
        container=(
            <div style = {{width:'95%',display:'flex',flexDirection:'column',alignItems:'center',overflow:'auto'}}>
                <h3>Available Individuals</h3>
                <div>
                    <TableContainer  className="availableResourceTable">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Name</TableCell>
                                    <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Domain</TableCell>
                                    {subscriberTypes.length>0?
                                    <TableCell><FormControl style={{padding:'0px 10px', minWidth:150, marginTop:8, marginBottom:8}}>
                                    <InputLabel id="demo-simple-select-label">Subscriber Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedSubscriberType}
                                        label="Tehsil"
                                        style={{padding:'0px 10px'}}
                                        onChange={(e)=>{
                                            if(!e.target.value){
                                                setNewAlreadyAssociated(alreadyAssociated)
                                            }
                                            else{
                                            setSelectedSubscriberType(e.target.value)
                                            let resource=[]
                                            let vendor=[]
                                            for(let i=0;i<alreadyAssociated.resources.length;i++){
                                                if(alreadyAssociated.resources[i].subscriberTypeID===e.target.value){
                                                    resource.push(alreadyAssociated.resources[i])
                                                    vendor.push(alreadyAssociated.vendorMaster[i])
                                                }
                                            }
                                            let newObj=newAlreadyAssociated
                                            newObj.resources=resource
                                            newObj.vendorMaster=vendor
                                            setNewAlreadyAssociated(newObj)}
                                        }}
                                    >
                                    
                                    <MenuItem value = {null}>All Type Subscribers</MenuItem>
                                        {subscriberTypes!==undefined?subscriberTypes?.map(item=>{
                                            return(
                                                <MenuItem value = {item?.subscriberTypeID}>{item?.subscriberTypeName}</MenuItem>
                                            )
                                        }):<div></div>}
                                    </Select>
                            </FormControl></TableCell>:"no Subscriber Type"}
                                    <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    newAlreadyAssociated?newAlreadyAssociated.resources.map((item,index)=>(
                                    <TableRow key={index}>
                                        <TableCell>{newAlreadyAssociated.vendorMaster[index].name}</TableCell>
                                        <TableCell>{newAlreadyAssociated.vendorMaster[index].domainID}</TableCell>
                                        <TableCell>{item.subscriberTypeName}</TableCell>
                                        <TableCell>{newAlreadyAssociated.vendorMaster[index].resourceTypeID === 'I' ? 'Individual':'Company'}</TableCell>
                                        
                                    </TableRow>
                                    )):<div></div>
                                   
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        )
    }

  return (
    <div>
        {container}

    </div>
  )
}

export default ProjectAssociated