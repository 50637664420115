import React, { useState, useEffect } from "react";
import { Box, Grid, Paper } from "@mui/material";
import classes from "./machineOnboard.module.css";
import { TextField } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import { FormLabel } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import axios from "../../../../axios_tteg";
import Cookies from "universal-cookie";
import CircularProgress from '@mui/material/CircularProgress';
import AssetsAssociation from '../../../Register/Association/AssetsAssociation';

const cookies = new Cookies();


export default function MachineOnboard(props) {
  const fileTypes = ["PDF"];
  const [goToOnboarding, setGoToOnboarding] = useState(false);
  const [ownerFound, setOwnerFound] = React.useState(null);
  const [value, setValue] = React.useState(new Date());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [goToAssociation,setGoToAssociation] = useState(false);
  const [ownerDetails,setOwnerDetails] = useState([]);
  const [selectedOwner,setSelectedOwner] = useState(null);
  const [miData, setMIData] = useState({
    mType: {
      value: 0,
      valid: false,
    },
    machineTypeName:{
      value: null,
      valid:true
    },
    oem: {
      value: null,
      valid: false,
    },
    machineCapacity: {
      value: null,
      valid: false,
    },
    modelNumber: {
      value: null,
      valid: false,
    },
    machineAvailableSectors: {
      value: null,
      valid: false,
    },
  });
  const [machineType, setMachineType] = useState([]);
  const [machineDescription,setMachineDescription] = useState([]);
  const [machineID,setMachineID] = useState(null);
  const [otherMachineType,setOtherMachineType] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/getMachineType", {})
      .then((response) => {
        setLoading(false);
        setMachineType(response.data.machineType);
        setMachineID(response.data.machineID);
      })
      .catch((e) => console.log(e.response.data.error));
  }, []);

  useEffect(()=>{
    setOwnerDetails([]);
    setSelectedOwner(null);
    if(ownerFound === '2'){
      axios.get('/getMachineOwnwerDetails',{
        params:{
            machineTypeID: miData.mType.value,
            modelNumber: miData.modelNumber.value,
            OEM: miData.oem.value,
            machineCapacityInTonnes: miData.machineCapacity.value,
            resourceOwner: 'true'
        }
      }).then(response=>{
        console.log(response);
        setOwnerDetails(response.data.getOwner);
      }).catch(e=>console.log(e))
    }
  },[ownerFound])

  const handleChange1 = (key, value) => {
    const tempMIData = { ...miData };
    if(key === 'mType'){
      let tempMachineType = [... machineType];
      if(value){
        if(value === 'Other'){
          setOtherMachineType(true);
          tempMIData["machineTypeName"].value = null
          tempMIData["machineTypeName"].valid = false
        }
        else{
          tempMIData["machineTypeName"].value = null
          tempMIData["machineTypeName"].valid = true
          setOtherMachineType(false);
          tempMachineType.map((item,index)=>{
            if(item.machineTypeID === value){
              setMachineDescription(tempMachineType[index].machineDescription)
            }
          })
        }
        
      }
      else{
        setMachineDescription([])
      }
    }
    if(key === 'modelNumber'){
        // const tempMIData = { ...miData };
        machineDescription.map((item,index)=>{
          if(item.modelNo === value){
              tempMIData['oem'].value = machineDescription[index].OEM;
              tempMIData['oem'].valid = true;
              tempMIData['machineCapacity'].value = machineDescription[index].capacity;
              tempMIData['machineCapacity'].valid = true;
          }
        })
        if (value) {
          tempMIData[key].value = value;
          tempMIData[key].valid = true;
        } else {
          tempMIData[key].value = value;
          tempMIData[key].valid = false;
          tempMIData['oem'].value = value;
          tempMIData['oem'].valid = false;
          tempMIData['machineCapacity'].value = value;
          tempMIData['machineCapacity'].valid = false;
        }
        
        setMIData(tempMIData);
    }
    else{
      // const tempMIData = { ...miData };
      if (value) {
        tempMIData[key].value = value;
        tempMIData[key].valid = true;
      } else {
        tempMIData[key].value = value;
        tempMIData[key].valid = false;
      }
      setMIData(tempMIData);
    }
  };

  /*-----------Machine Owner-------- */

  const [oiData, setOIData] = useState({
    name: {
      value: null,
      valid: false,
    },
    mobile: {
      value: null,
      valid: false,
    },
  });
  const handleChange2 = (key, value) => {
    console.log(key, value);
    const tempOIData = { ...oiData };
    if (value) {
      tempOIData[key].value = value;
      tempOIData[key].valid = true;
    } else {
      tempOIData[key].value = value;
      tempOIData[key].valid = false;
    }
    setOIData(tempOIData);
  };

  const [docDetail, setDocDetail] = useState({
    'DT-00013': {
      docTypeID: "DT-00013",
      docTypeName:'Machine Invoice*',
      value: null,
      isUploaded: false,
      path: null,
    },
    "DT-00014": {
      docTypeID: "DT-00014",
      docTypeName:"Any other Document",
      value: null,
      isUploaded: false,
      path: null,
    },
  });

  const uploadDocument = (file, key) => {
    var formData = new FormData();
    formData.append("uploadedFile", file);
    formData.append("resourceID", cookies.get('userData').resourceID);
    formData.append("docTypeID", docDetail[key].docTypeID);
    formData.append('machineID',machineID)

    console.log(formData);

    setLoading(true);
    axios
      .post("/uploadMachine", formData, {})
      .then((response) => {
        console.log(response);
        setLoading(false);
        if (response.status === 200) {
          alert('Files Uploaded Successfully.')
          let tempDocDetail = { ...docDetail };
          tempDocDetail[key].value = file;
          tempDocDetail[key].isUploaded = true;
          tempDocDetail[key].path = response.data.path;
          setDocDetail(tempDocDetail);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(e.response.error);
      });
  };

  const continueHandler = ()=>{
    let tempMI = { ...miData };
    let tempOI = { ...oiData };
    let tempDocDetail = { ...docDetail };
    let isValid = true;
    console.log(tempDocDetail);
    Object.keys(tempMI).map((item) => {
      isValid = isValid && tempMI[item].valid;
    });
    if(ownerFound == 2){
      Object.keys(tempOI).map(item=>{
        isValid = isValid && tempOI[item].valid;
      })
    }
    // Object.keys(docDetail).map(key=>{
    //   isValid = isValid && docDetail[key].isUploaded;
    // })
    if(isValid)
      submitHandler()
      // setGoToAssociation(true);
    else
      setError('* Fill all data')
  }

  // const submitHandler = () => {
  //   let tempMI = { ...miData };
  //   let tempOI = { ...oiData };
  //   let tempDocDetail = { ...docDetail };
  //   setLoading(true);
  //   axios
  //     .post("/machineOnboarding", {
  //       resourceID: cookies.get('userData').resourceID,
  //       machineID:machineID,
  //       machineTypeID: tempMI.mType.value,
  //       modelNumber: tempMI.modelNumber.value,
  //       OEM: tempMI.oem.value,
  //       machineCapacityInTonnes: tempMI.machineCapacity.value,
  //       machineAvailabilitySectors: tempMI.machineAvailableSectors.value,
  //       purchaseDates: value,
  //       resourceOwner: ownerFound == 1 ? true : false,
  //       machineOwnerDetails: {
  //         name: tempOI.idName.value,
  //         email: tempOI.idEmail.value,
  //         bankName: tempOI.bankName.value,
  //         ifscCode: tempOI.ifscCode.value,
  //         Address: tempOI.idAddress.value,
  //         mobile: tempOI.mobNum.value,
  //         accountNumber: tempOI.accNum.value,
  //         branchAddress: tempOI.branchAddress.value,
  //       },
  //       machineInvoicePath: tempDocDetail.file1.path,
  //       machineOperationalManualPath: tempDocDetail.file2.path
  //     })
  //     .then((response) => {
  //       setLoading(false);
  //       console.log(response);
  //       if (response.status == 200) {
  //         alert("Machine Details Entered Successfully");
  //         console.log("SUCESSFULL RESPONSE");
  //         console.log(response.data);
  //         props.getData(false);
  //       } else {
  //         setError(response.data.error);
  //       }
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //       setError(e.response.data.error);
  //     });
  // };

  const submitHandler = () => {
    setLoading(true);
    axios
      .post("/userAssociation", {
        companyID: null,
        companyName: null,
        isAssociated: false,
        managerName: null,
        managerContactNo: null,
        resourceID: cookies.get('userData').resourceID,
        machineID:machineID,
        humanResourceID:null,
        timeStamp:new Date()
      })
      .then((response) => {
        if (response.status === 200) {
            setLoading(true);
            axios
            .post("/machineOnboarding", {
                resourceID: cookies.get('userData').resourceID,
                machineID:machineID,
                machineTypeID: miData.mType.value,
                machineTypeName:miData.machineTypeName.value,
                modelNumber:miData.modelNumber.value,
                OEM: miData.oem.value,
                machineCapacityInTonnes: miData.machineCapacity.value,
                machineAvailabilitySectors: miData.machineAvailableSectors.value,
                purchaseDates: value,
                resourceOwner:  false,
                otherMachineType:otherMachineType,
                machineOwnerDetails: null,
                machineInvoicePath: docDetail['DT-00013'].path,
                machineOperationalManualPath: docDetail['DT-00014'].path
            })
            .then((response) => {
                setLoading(false);
                if (response.status == 200) {
                  alert("Machine Details Entered Successfully");
                  props.getData(false);
                } else {
                  setError(response.data.error);
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        setError(e.response.data.error);
      });
  };

  const selectFile = (file,key) => {
    let tempDocDetail = { ...docDetail };
    tempDocDetail[key].value = file;
    tempDocDetail[key].isUploaded = false;
    setDocDetail(tempDocDetail);
  };
  const getDataFromAssociate = (data)=>{
    if(data === 1){
     props.getData(false)
    }
    else{
      setGoToAssociation(false);
    }
  }
  console.log(ownerDetails);
  let container2 = null;
  if (ownerFound === "2") {
    container2 = (
      <div>
        <Grid>
          <h3 style={{ textAlign: "center" }}>Please Enter Owner Details</h3>
        </Grid>
        {ownerDetails.length > 0 ? (
          <Grid className={classes.details}>
          <Grid className={classes.owner}>
            <Grid>
            <FormControl
              variant="filled"
              // style={{ padding: "1% 1% 1% 1%", width: "40%" }}
              className={classes.inputStyle}
            >
              <InputLabel id="demo-simple-select-filled-label">
                Select Owner*
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={selectedOwner}
                onChange={(e) => {
                  setSelectedOwner(e.target.value);
                  Object.keys(e.target.value).map(key=>{
                    if(key !== '_id')
                      handleChange2(key,e.target.value[key])
                  })
                  
                }}
              >
                
                {ownerDetails.map(item=>{
                  return(
                    <MenuItem value={item.vendorMaster[0]} >{item.vendorMaster[0].name}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          </Grid>
          </Grid>
        ):(
          <Grid className={classes.details}>
          <Grid className={classes.owner}>
            <Grid>
              <TextField
                className={classes.inputStyle}
                id="filled-basic"
                label="Name as in govt ID"
                variant="filled"
                value={oiData.name.value}
                onChange={(e) => handleChange2("name", e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid className={classes.owner}>
            <Grid>
              <TextField
                className={classes.inputStyle}
                id="filled-basic"
                label="Mobile number"
                variant="filled"
                value={oiData.mobile.value}
                onChange={(e) => handleChange2("mobile", e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        )} 
        
        
      </div>
    );
  }

  let container = null;
  if(goToAssociation){
    container = (
      <AssetsAssociation 
        resourceID={cookies.get('userData').resourceID}
        machineID={machineID}
        tempMI={miData}
        tempOI = {oiData}
        tempDocDetail={docDetail}
        value={value}
        ownerFound={ownerFound}
        selectedOwner={selectedOwner}
        otherMachineType = {otherMachineType}
        getData = {(data)=>getDataFromAssociate(data)}
      />
    )
  }
  else{
    container = (
      <div>
        <Box>
          <Paper
            elevation={0}
          >
            <Grid>

              <Grid>
                <Grid
                  className={classes.displaying}
                  style={{ textAlign: "center", width: "100%", padding :'20px' }}
                >
                  <Grid className={classes.style}>
                    <Grid>
                      <FormControl
                        variant="filled"
                        className={classes.inputStyle}
                      >
                        <InputLabel id="demo-simple-select-filled-label">
                          Machine Type*
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          value={miData.mType.value}
                          onChange={(e) => handleChange1("mType", e.target.value)}
                        >
                          {/* <MenuItem value={"MT00001"}>HDD</MenuItem>
                          <MenuItem value={"MT00002"}>Trencher</MenuItem>
                          <MenuItem value={"MT00003"}>Tractor</MenuItem> */}
                          {machineType.map(item=>{
                            return(
                              <MenuItem value={item.machineTypeID} >{item.machineTypeName}</MenuItem>
                            )
                          })}
                          <MenuItem value={'Other'} >{'Other'}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid>
                      {otherMachineType ? (
                        <TextField
                          className={classes.inputStyle}
                          id="filled-basic"
                          label="Machine Type Name*"
                          variant="filled"
                          value={miData.machineTypeName.value}
                          onChange={(e) => handleChange1("machineTypeName", e.target.value)}
                        />
                      ):null}
                        {otherMachineType ? (
                          <TextField
                            className={classes.inputStyle}
                            id="filled-basic"
                            label="OEM*"
                            variant="filled"
                            value={miData.oem.value}
                            onChange={(e) =>
                              handleChange1("oem", e.target.value)
                            }
                          />
                        ):(
                          <FormControl
                          variant="filled"
                          className={classes.inputStyle}
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            OEM*
                          </InputLabel>
                            <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={miData.oem.value}
                            onChange={(e) => handleChange1("oem", e.target.value)}
                            disabled={true}
                          >
                            {/* <MenuItem value={"MT00001"}>HDD</MenuItem>
                            <MenuItem value={"MT00002"}>Trencher</MenuItem>
                            <MenuItem value={"MT00003"}>Tractor</MenuItem> */}
                            {machineDescription && machineDescription.map(item=>{
                              return(
                                <MenuItem value={item.OEM} >{item.OEM}</MenuItem>
                              )
                            })}
                          </Select>
                          </FormControl>
                        )}
                    </Grid>
                    <Grid>
                      {otherMachineType ? (
                        <TextField
                          className={classes.inputStyle}
                          id="filled-basic"
                          label="Machine Capacity(in tonnes)*"
                          variant="filled"
                          value={miData.machineCapacity.value}
                          onChange={(e) =>
                            handleChange1("machineCapacity", e.target.value)
                          }
                        />
                      ):(
                          <FormControl
                          variant="filled"
                          className={classes.inputStyle}
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            Machine Capacity(in tonnes)*
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={miData.machineCapacity.value}
                            onChange={(e) => handleChange1("machineCapacity", e.target.value)}
                            disabled={true}
                          >
                            {machineDescription && machineDescription.map(item=>{
                              return(
                                <MenuItem value={item.capacity} >{item.capacity}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      )}
                      
                      {/* <TextField
                        className={classes.inputStyle}
                        id="filled-basic"
                        label="Machine Capacity(in tonnes)"
                        variant="filled"
                        value={miData.machineCapacity.value}
                        onChange={(e) =>
                          handleChange1("machineCapacity", e.target.value)
                        }
                      /> */}
                    </Grid>
                  </Grid>

                  <Grid className={classes.style} style={{ textAlign: "center" }}>
                    <Grid>
                      {otherMachineType ? (
                        <TextField
                          className={classes.inputStyle}
                          id="filled-basic"
                          label="Model No*"
                          variant="filled"
                          value={miData.modelNumber.value}
                          onChange={(e) =>
                            handleChange1("modelNumber", e.target.value)
                          }
                        />
                      ):(
                          <FormControl
                          variant="filled"
                          className={classes.inputStyle}
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            Model No*
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={miData.modelNumber.value}
                            onChange={(e) => handleChange1("modelNumber", e.target.value)}
                          >
                            {/* <MenuItem value={"MT00001"}>HDD</MenuItem>
                            <MenuItem value={"MT00002"}>Trencher</MenuItem>
                            <MenuItem value={"MT00003"}>Tractor</MenuItem> */}
                            {machineDescription && machineDescription.map(item=>{
                              return(
                                <MenuItem value={item.modelNo} >{item.modelNo}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      )}
                      
                      {/* <TextField
                        className={classes.inputStyle}
                        id="filled-basic"
                        label="Model No"
                        variant="filled"
                        value={miData.modelNumber.value}
                        onChange={(e) =>
                          handleChange1("modelNumber", e.target.value)
                        }
                      /> */}
                    </Grid>
                    <Grid
                      className={classes.dataSelector}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={1}>
                          <DesktopDatePicker
                            className={classes.inputStyle}
                            label="Purchase Date*"
                            value={value}
                            minDate={new Date("2017-01-01")}
                            maxDate={new Date()}
                            onChange={(newValue) => {
                              setValue(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField variant="filled" {...params} />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                    <Grid>
                      <TextField
                        className={classes.inputStyle}
                        id="filled-basic"
                        label="Machine Available Sectors"
                        variant="filled"
                        value={miData.machineAvailableSectors.value}
                        onChange={(e) =>
                          handleChange1("machineAvailableSectors", e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.styling1}>
                {Object.keys(docDetail).map(key=>{
                  return(
                    <Grid className={classes.alignItems}>
                      <Grid style={{ textAlign: "left",display: 'flex',justifyContent: 'space-around',alignItems: 'center' }}>
                        <h3>{docDetail[key].docTypeName}</h3>
                        <Button
                            disabled = {docDetail[key].path ? false:true}
                            onClick={()=>window.open(docDetail[key].path,"_blank")}
                        >
                            
                                <p style={{margin:2}}>{docDetail[key].path ? 'View':'Not Found'}</p>
                            
                        </Button>
                      </Grid>
                        
                      <Grid>
                        <Grid style={{ display: "flex" }}>
                          <Grid style={{ width: "80%" }}>
                            <FileUploader
                              multiple={false}
                              handleChange={(file)=>selectFile(file, key)}
                              name={key}
                              types={fileTypes}
                            />
                          </Grid>
                          {/* <Checkbox checked={docDetail.file1.isUploaded} /> */}
                        </Grid>
                        <Grid style={{ display: "flex" }}>
                          <p style={{ textAlign: "left", width: "60%" }}>
                            {docDetail[key].value
                              ? `File name: ${docDetail[key].value.name}`
                              : "No files uploaded"}
                          </p>
                          <Grid style={{ padding: "1% 20% 1% 0%", width: "40%" }}>
                            <Button
                              style={{
                                borderRadius: "18px",
                                // justifyContent: "right",
                                marginLeft: "0%",
                              }}
                              type="submit"
                              color="primary"
                              variant="contained"
                              // disabled={docDetail[key].value ? false : true}
                              onClick={() => {
                                uploadDocument(docDetail[key].value, key);
                              }}
                            >
                              Upload
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
                

                {/* <Grid className={classes.alignItems}>
                  <Grid style={{ textAlign: "left" }}>
                    <h3>Machine Operational Manual*</h3>
                  </Grid>
                  <Grid>
                    <Grid style={{ display: "flex" }}>
                      <Grid style={{ width: "80%" }}>
                        <FileUploader
                          multiple={false}
                          handleChange={submitHandle2}
                          name="file2"
                          types={fileTypes}
                        />
                      </Grid>
                    </Grid>
                    <Grid style={{ display: "flex" }}>
                      <p style={{ textAlign: "left", width: "60%" }}>
                        {docDetail.file2.isUploaded
                          ? `File name: ${docDetail.file2.value.name}`
                          : "No files uploaded"}
                      </p>
                      <Grid style={{ padding: "1% 20% 1% 0%", width: "40%" }}>
                        <Button
                          style={{
                            borderRadius: "18px",
                            // justifyContent: "right",
                            marginLeft: "0%",
                          }}
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={docDetail.file2.value ? false : true}
                          onClick={() => {
                            uploadDocument(docDetail.file2.value, "file2");
                          }}
                        >
                          Upload
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
            {/* <Grid style={{ textAlign: "left", padding: "0% 0% 0% 7%" }}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  <h3>Are You the Owner of this Machine ?*</h3>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={(event) => setOwnerFound(event.target.value)}
                >
                  <FormControlLabel value="1" control={<Radio checked = {ownerFound == 1 ? true:false}/>} label="Yes" />
                  <FormControlLabel value="2" control={<Radio checked = {ownerFound == 2 ? true:false}/>} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            {container2} */}
            <Grid style={{ textAlign: "center", padding: 10,margin:10 ,display: 'flex',justifyContent: 'space-around'}}>
              <Button variant="outlined" onClick={()=>props.getData(false)}>
                Cancel
              </Button>
              <Button variant="contained" onClick={continueHandler}>
                Submit
              </Button>
            </Grid>
          </Paper>
        </Box>
      </div>
    );
  }
  
  return (
    <div>
      {loading ? (
        <div>
          <CircularProgress/>
        </div>
      ):(
        <div>
          {container}
          <p>{error}</p>
        </div>
      )}
    </div>
  );
}
