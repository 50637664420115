import React,{useState} from "react";
import CaseContainer from "../../Maps/CaseContainer";
import classes from "./DashboardContainer.module.css";
import Header from "./Header/Header";
import OnboardManager from "./OnboardManager/OnboardManager";
import Task from "./Task/Task";
import MapContainer from '../../Maps/MapContainer';
import CircularProgress from '@mui/material/CircularProgress';

const DashboardContainer = (props)=>{
    let [screen,setScreen] = useState(0);
    const [goToMap,setGoToMap] = useState(false);
    const [goToCaseID,setGoToCaseID] = useState(null);
    const [loading,setLoading] = useState(null);
    const getScreensFromHeader = (screens)=>{
        if(screens === 'logout')
            setLoading(true);
        else if(screen === 'completed')
            setLoading(false);
        else
            setScreen(screens)
    }

    const goToCase = (caseDetails)=>{
        let tempGoToCaseID = {... goToCaseID}
        tempGoToCaseID = caseDetails;
        setGoToCaseID(tempGoToCaseID);
        setGoToMap(true);
    }

    const getDataFromTask = (resource)=>{
        goToCase(resource);
    }

    // if(goToMap && goToCaseID){
    //     container = (
    //         <MapContainer caseDetails = {goToCaseID}/>
    //     )
    // }

    let screens = null;
    if(screen === 0){
        screens =(
            <div className={classes.Container}>
                <div className={classes.OnboardManager}>
                    {(goToMap && goToCaseID)?(<MapContainer caseDetails = {goToCaseID} getData = {(data)=>{setGoToMap(false);setGoToCaseID(null)}}/>):(<OnboardManager resourceID={props.resourceID}/>)}
                </div>
                <div className={classes.TaskManager}>
                    <div>
                        <div>
                            <div style={{backgroundColor: 'white',textAlign: 'center',color: 'teal',borderTopWidth: 0,borderBottom: '2px solid teal',padding:10}}>

                                <p style={{margin :0}}>Ongoing Task</p>
                                
                            </div>
                            
                            <div style={{height:'35vh',background:'white',marginBottom:5, overflow:'auto'}}>
                                <Task resourceID={props.resourceID} Completed = {0} getData = {(resource)=>getDataFromTask(resource)}/>
                            </div>
                        </div>
                        <div>
                            <div style={{backgroundColor: 'white',textAlign: 'center',color: 'teal',borderTopWidth: 0,borderBottom: '2px solid teal',padding:10}}>

                                <p style={{margin :0}}>Completed Task</p>

                            </div>
                            
                            <div style={{height:'35vh',background:'white',marginBottom:5, overflow:'auto'}}>
                                <Task resourceID={props.resourceID} Completed = {1} getData = {(resource)=>getDataFromTask(resource)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) 
    }
    else if(screen === 1){
        screens = (
            <div style={{height:'80vh'}}>
                <CaseContainer resourceID={props.resourceID}/>
            </div>)
    }
    let container = null;
    if(loading){
        container = (<CircularProgress/>)
    }
    else{
        container = (
            <div style={{background:'#F2F2F2'}}>
                <div>
                    {/*Header Part*/}
                    <Header getData = {(screens)=>{getScreensFromHeader(screens)}}/>
                </div>
                {screens}
                <div>
                    {/* Footer*/}
                </div>
            </div>
        )
    }
    
    return(
        <div>
            {container}
        </div>
    )
}

export default DashboardContainer