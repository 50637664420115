import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from "../../../../axios_tteg";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import classes from "../../../Maps/Map/MapCheck.module.css";
import mapStyles from "../../../Maps/Map/mapStyles";
import satellite from "../../../../assets/images/satellite.png";
import roadMap from "../../../../assets/images/roadMap.png";
import CloseIcon from '@mui/icons-material/Close';

const libraries = ["places"];
const mapContainerStyle = {
  height: "80vh",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const mapViewStyle = {
  width: 50,
  margin: 10,
  boxShadow: '0 2px 3px #ccc',
  border: '1px solid #eee',
  cursor: "pointer"
};

const EnquiryLocation = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC_kRxUGZMpYA361uWGeGohZW5PYqCoj4k",
    libraries,
  });
  const [locations, setLocations] = useState([]);
  const [mapView, setMapView] = useState("roadmap");
  const [center, setCenter] = useState(null);
  const [loginDetailed, setLoginDetails] = useState("");
  const [selected, setSelected] = useState(null);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState(null);
  
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  useEffect(() => {
    if (props.associatedAssets && props.associatedAssets.length > 0) {
        // Set center based on the first asset's location
        const firstAsset = props.associatedAssets[0];
        
        setCenter({
            lat: parseFloat(firstAsset.location.latitude),
            lng: parseFloat(firstAsset.location.longitude),
        });

        // Set locations to the entire associatedAssets array
        setLocations(props.associatedAssets);

        // Optionally log each asset if needed
        props.associatedAssets.forEach((item) => {
            console.log(item);
        });
    }
}, [props.associatedAssets]);

  useEffect(() => {
    // Call loginLog when selectedVendorDetails changes
    if (selectedVendorDetails) {
      loginLog(selectedVendorDetails.resourceID);
    }
  }, [selectedVendorDetails]);

  const loginLog = async (resourceID) => {
    try {
      const loginDetails = await axios.post("/getLoginLog", { resourceID });
      setLoginDetails(loginDetails.data.latestLoginDetails.timestamp);
    } catch (error) {
      console.error("Error fetching the login log:", error);
    }
  };

  const getResource = (resourceID) => {
    const foundItem = props.associatedAssets.find(item => item.caseID === resourceID);
    if (foundItem) setSelectedVendorDetails(foundItem);
  };

  const getDomainName = (domainID) => {
    const domain = props.inputPIUtility.domain.find(item => item.domainID === domainID);
    return domain ? domain.domainDescription.domainName : null;
  };

  const getSubscriberType = (subscriberTypeID) => {
    const subscriberType = props.inputPIUtility.subscriberType.find(item => item.subscriberTypeID === subscriberTypeID);
    return subscriberType ? subscriberType.subscriberTypeName : null;
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps...";

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        mapTypeId={mapView}
        onLoad={onMapLoad}
        zoom={18}
        options={options}
        clickableIcons={false}
        center={center}
      >
        <CloseIcon onClick={() => props.getData('close')} style={{ cursor: 'pointer', position: 'absolute', top: '0rem', right: '0rem', color: 'white' }} />
        {locations.map(item => (
          <Marker
            draggable={false}
            label={item.caseID}
            key={item.caseID}
            position={{ lat: parseFloat(item.location.latitude), lng: parseFloat(item.location.longitude) }}
            onClick={() => {
              setSelected({
                resourceID: item.caseID,
                position: { lat: parseFloat(item.location.latitude), lng: parseFloat(item.location.longitude) }
              });
              getResource(item.caseID);
            }}
          />
        ))}
        {selected && (
          <InfoWindow
            position={selected.position}
            onCloseClick={() => {
              setSelected(null);
              setSelectedVendorDetails(null);
            }}
          >
            <div>
              {selectedVendorDetails && (
                <>
                  <div style={{ textAlign: 'left' }}>
                    <p>Description - {selectedVendorDetails.caseDescription}</p>
                    <p>DomainID - {selectedVendorDetails.domainID}</p>
                    <p>tite - {selectedVendorDetails.title}</p>
                  </div>
                </>
              )}
            </div>
          </InfoWindow>
        )}
        <div style={{ position: "absolute", bottom: '2rem', right: '4rem', background: 'white', borderRadius: 5 }}>
          <div style={{ display: 'flex' }}>
            <img 
              src={roadMap} 
              alt="RoadMap" 
              style={mapView === 'roadmap' ? mapViewStyle : { width: 50, margin: 10, cursor: "pointer" }} 
              onClick={() => setMapView('roadmap')} 
            />
            <img 
              src={satellite} 
              alt="Satellite" 
              style={mapView === 'satellite' ? mapViewStyle : { width: 50, margin: 10, cursor: "pointer" }} 
              onClick={() => setMapView('satellite')} 
            />
          </div>
        </div>
      </GoogleMap>
    </div>
  );
};

export default EnquiryLocation;
