import React, {useState, useEffect,useContext} from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from "../../axios_tteg";
import Cookies from "universal-cookie";
import { CircularProgress } from "@mui/material";
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ResourceLocation from "../Dashboard/Components/tabPanel1/ResourceLocation";
import classes from "../Dashboard/Components/tabPanel1/tabPanel1.module.css";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { AddResourceShowCtx } from './Project'; // Adjust the path according to your file structure
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import './AddResource.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const cookies = new Cookies()

const AddResource = (props)=>{
   
    const [loading,setLoading] = useState(false);

    const [associatedIndividual,setAssociatedIndividual] = useState([]);
    //will store all available to associate so that can be appended in newAssociatedIndividual later 
    const [allAvailableAssociatedIndividual,setAllAvailableAssociatedIndividual]=useState([])
    //stores the all available resources initially but this updates 
    const [newAssociatedIndividual,setNewAssociatedIndividual] = useState([]);
    //selected individual store the already associated individuals + while associating new individuals 
    //so now we just need to upsert at the backend instead of compairiing now
    const [selectedIndividual,setSelectedIndividual]=useState([])
    const [selectedSubscriberType,setSelectedSubscriberType]=useState(null)
    const [subscriberTypes,setSubscriberTypes]=useState([])
    const [openDialog,setOpenDialog]=useState(false)  
    const [openRemoveDialog,setOpenRemoveDialog] = useState(false);
    const [startDate,setStartDate] = useState(new Date());
    const [endDate,setEndDate] = useState(new Date());
    const [tempSelectedIndividual, setTempSelectedIndividual] = useState(null);


    const getAssociatedIndividual = async()=>{
        setLoading(true);
        await axios.get('/getAssociatedIndividual',{
            params:{
                companyID:props.caseDetails.resourceID
            }
        }).then(response=>{
            setLoading(false);  
            setAssociatedIndividual(response.data.association)
            setNewAssociatedIndividual(response.data.association)
            setAllAvailableAssociatedIndividual(response.data.association)
        })

        // await axios.get('/getSubscriberTypes')    
        //     .then(response=>{
        //     setLoading(false);
            
        //    setSubscriberTypes(response.data.data)
            
        //     })
        // setLoading(false);            

    }

    const getSubscriberTypes = async()=>{
        setLoading(true)
        await axios.get('/getSubscriberTypes')    
        .then(response=>{
            setLoading(false);
            
           setSubscriberTypes(response.data.data)
            
        })
        
    }

    const InitialSelectedResources=async()=>{
        await axios.get('/getProjectResourceAssociation',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            let initialSelected=[]
            if(response.data.projectAssociation.length>0){
                for(let i=0;i<response.data.projectAssociation[0].resources.length;i++){
                    const association=response.data.projectAssociation[0]
                    let item={}
                    let individualResourceAssociation={}
                    individualResourceAssociation.name=association.vendorMaster[i].name
                    individualResourceAssociation.domainID=association.vendorMaster[i].domainID
                    individualResourceAssociation.subscriberTypeMaster={}
                    individualResourceAssociation.subscriberTypeMaster.subscriberTypeName=association.vendorMaster[i].subscriberTypeMaster[0].subscriberTypeName
                    individualResourceAssociation.subscriberTypeMaster.subscriberTypeID=association.vendorMaster[i].subscriberTypeID
                    individualResourceAssociation.resourceTypeID=association.vendorMaster[i].resourceTypeID
                    item.individualResourceAssociation=individualResourceAssociation
                    item.startDate=association.resources[i].startDate
                    item.endDate=association.resources[i].endDate
                    item.individualID=association.resources[i].resourceID
                    item.individualResourceAssociation.resourceID=association.resources[i].resourceID
                    initialSelected.push(item)
                }

            }
            setSelectedIndividual(initialSelected)
        })
    }

    useEffect(()=>{
        getAssociatedIndividual();
        getSubscriberTypes();
        InitialSelectedResources();
    },[])

    const handleChangeDuration = (key,value)=>{
        if(key === 'startDate'){
            setStartDate(value);
        }
        else if(key === 'endDate'){
            setEndDate(value)
        }
    }
    
    const handleSubmit=async ()=>{
        if(selectedIndividual?.length>0){
            let obj={
                projectID:props.caseDetails.projectID,
                vendorID:props.caseDetails.resourceID,
            }
            let newResources=[]
            for(let i=0;i<selectedIndividual.length;i++){
                let hObj={}
                
                hObj.subscriberTypeID=selectedIndividual[i].individualResourceAssociation.subscriberTypeMaster.subscriberTypeID
                hObj.subscriberTypeName=selectedIndividual[i].individualResourceAssociation.subscriberTypeMaster.subscriberTypeName
                hObj.resourceID=selectedIndividual[i].individualResourceAssociation.resourceID
                hObj.startDate=selectedIndividual[i].startDate
                hObj.endDate=selectedIndividual[i].endDate
                hObj.used=false

                newResources.push(hObj)
            }

            obj.resources=newResources
            
            const response=await axios.post('/projectResourceAssociation',obj)
            if(response.status===200){
                props.getData(false);
                // getAssociatedIndividual();
            }
        }

    }


    const removeAProjectAssociation=async(item)=>{
        setLoading(true)
        axios.post('/removeAProjectAssociation',{
            projectID:props.caseDetails.projectID,
            resourceID:item.individualID,
            vendorID:props.caseDetails.resourceID
        }).then(response=>{
            setLoading(false)
            setSelectedIndividual(selectedIndividual=>selectedIndividual.filter(elem=>item.individualID!==elem.individualID));
            getAssociatedIndividual()
            InitialSelectedResources()
        })
    }

    console.log(newAssociatedIndividual)


    let container = null;
    if(loading ){
        container = (<CircularProgress/>)
    }
    else{
            container = (
                <div  className={classes.AssociatedAssestsContainer}>
                    <div>
                        <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
                            <DialogTitle>Associate</DialogTitle>
                            <DialogContent>
                            <DialogContentText>
                                Thank you for showing intreast. Our sales team will call you for future references.
                            </DialogContentText>
                            <div className={classes.AssociateResourceStart}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DesktopDatePicker
                                        label="Start Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={startDate}
                                        minDate={new Date()}
                                        maxDate={tempSelectedIndividual ? tempSelectedIndividual.endDate : new Date()}
                                        onChange={(date)=>handleChangeDuration('startDate',date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    <DesktopDatePicker
                                        label="End Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate}
                                        minDate={startDate ? startDate :new Date()}
                                        maxDate={tempSelectedIndividual ? tempSelectedIndividual.endDate : new Date()}
                                        onChange={(date)=>handleChangeDuration('endDate',date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={()=>setOpenDialog(false)}>Cancel</Button>
                            {/* <Button onClick={requestForAssociation}>Submit</Button> */}
                            <Button onClick={()=>{
                                if(startDate && endDate){
                                    let tempIndividualSelected = [... selectedIndividual];
                                    // setSelectedIndividual(selectedIndividual=>[...selectedIndividual,tempSelectedIndividual]);
                                    let alreadyAssociations = [... newAssociatedIndividual]
                                    alreadyAssociations = alreadyAssociations.filter(elem=>elem.individualID!==tempSelectedIndividual.individualID)
                                    setNewAssociatedIndividual(alreadyAssociations);
                                    // let newArray=selectedIndividual    
                                    // let obj=selectedIndividual[selectedIndividual.length-1]
                                    tempSelectedIndividual.startDate=startDate
                                    tempSelectedIndividual.endDate=endDate
                                    tempIndividualSelected.push(tempSelectedIndividual);
                                    setSelectedIndividual(tempIndividualSelected);
                                }
                                setOpenDialog(false)
                                setStartDate(new Date());
                                setEndDate(null);
                                setTempSelectedIndividual(null);
                            }}>Submit</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog open={openRemoveDialog} onClose={()=>setOpenRemoveDialog(false)}>
                            <DialogTitle>Confirmation</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete? Deleting the resource, it will automatically delete in sub-project if associated.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={()=>setOpenRemoveDialog(false)}>Cancel</Button>
                            {/* <Button onClick={requestForAssociation}>Submit</Button> */}
                            <Button onClick={()=>{
                                const individualIDToCheck = tempSelectedIndividual.individualID;
                                const isPresent = associatedIndividual.some(item => item.individualID === individualIDToCheck);
                                if(isPresent){
                                    setSelectedIndividual(selectedIndividual=>selectedIndividual.filter(elem=>tempSelectedIndividual.individualID!==elem.individualID));
                                    let tempNewAssociatedIndividual = [... newAssociatedIndividual]
                                    tempNewAssociatedIndividual.push(tempSelectedIndividual);
                                    setNewAssociatedIndividual(tempNewAssociatedIndividual);
                                    setTempSelectedIndividual(null);
                                    setOpenRemoveDialog(false)
                                }
                                else{
                                    removeAProjectAssociation(tempSelectedIndividual)
                                    setOpenRemoveDialog(false)
                                    setTempSelectedIndividual(null);
                                }
                            }}>Delete</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <div style={{width:'100%'}}>
                        <ArrowBackIcon style={{float:'left', cursor:'pointer'}} onClick = {()=>props.getData('back')}/>
                        <h3 style={{textAlign:'center', margin:5}}>Assign Resources to Project</h3>
                    </div>
                    <div style = {{width:'100%',display:'flex',flexDirection:'row'}}>
                        <div style = {{width:'50%',display:'flex',flexDirection:'column',borderRight:'1px solid gainsbro',alignItems:'center', margin:10}} >
                            <h3>Available Resources</h3>
                            <div style={{width:'100%'}}>
                                <TableContainer className="availableResourceTable">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="availableResourceTableHead">Name</TableCell>
                                                <TableCell className="availableResourceTableHead">Domain</TableCell>
                                                {subscriberTypes.length>0?
                                                    <TableCell style = {{padding:5}}>
                                                        <FormControl style={{padding:5, minWidth:150}}>
                                                            <InputLabel id="demo-simple-select-label" style={{top:-5}}>Subscriber Type</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={selectedSubscriberType}
                                                                size="small"
                                                                onChange={(e)=>{
                                                                    if(!e.target.value){
                                                                        setNewAssociatedIndividual(associatedIndividual)
                                                                        setSelectedSubscriberType(null)
                                                                    }
                                                                    else{
                                                                    setSelectedSubscriberType(e.target.value)
                                                                    const newArray=associatedIndividual.filter((item)=>item.individualResourceAssociation.subscriberTypeID===e.target.value)
                                                                    setNewAssociatedIndividual(newArray)}
                                                                }}
                                                            >
                                            
                                                                <MenuItem value = {null}>All Type Subscribers</MenuItem>
                                                                    {subscriberTypes.map(item=>{
                                                                        return(
                                                                            <MenuItem value = {item?.subscriberTypeID}>{item?.subscriberTypeName}</MenuItem>
                                                                        )
                                                                    })}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>:"no Subscriber Type"
                                                }
                                                <TableCell className="availableResourceTableHead">Type</TableCell>
                                                <TableCell className="availableResourceTableHead">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                            newAssociatedIndividual.map((item,index)=>(
                                                <TableRow key={index}>
                                                    <TableCell>{item.individualResourceAssociation?.name}</TableCell>
                                                    <TableCell>{item.individualResourceAssociation?.domainID}</TableCell>
                                                    <TableCell>{item.individualResourceAssociation?.subscriberTypeMaster.subscriberTypeName}</TableCell>
                                                    <TableCell>{item.individualResourceAssociation?.resourceTypeID === 'I' ? 'Individual':'Company'}</TableCell>
                                                    <TableCell >
                                                    {/*this move to project button add particular individual to selectedIndividual(new Association) and removes it from,
                                                    newAlreadyAssociated (available for association) so its no more in the available*/}
                                                        <p 
                                                            className="addResourceRemoveBtn addResourceMoveToBtn"
                                                            onClick={()=>{ 
                                                                // setSelectedIndividual(selectedIndividual=>[...selectedIndividual,item]);
                                                                // let alreadyAssociations=newAssociatedIndividual
                                                                // alreadyAssociations=alreadyAssociations.filter(elem=>elem.individualID!==item.individualID)
                                                                // setNewAssociatedIndividual(alreadyAssociations);
                                                                setTempSelectedIndividual(item);                                                        
                                                                setOpenDialog(true);
                                                            }}
                                                            >Move To Project
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            )) 
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        <div>
                                    
                        </div>
                        
                        </div>
                        <div style = {{width:'50%',display:'flex',flexDirection:'column',alignItems:'center', margin:10}} >
                            <h3>Added Resources</h3>
                            <div style={{width:'100%'}}>
                                {selectedIndividual.length > 0 ? (
                                    <TableContainer className=" addedResourceTable">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className="availableResourceTableHead">Name</TableCell>
                                                    <TableCell className="availableResourceTableHead">Domain</TableCell>
                                                    <TableCell>Subscriber Type</TableCell>
                                                    <TableCell className="availableResourceTableHead">Type</TableCell>
                                                    <TableCell className="availableResourceTableHead">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    selectedIndividual.map((item,index)=>(
                                                        <TableRow key={index}>
                                                            <TableCell>{item?.individualResourceAssociation?.name}</TableCell>
                                                            <TableCell>{item?.individualResourceAssociation?.domainID}</TableCell>
                                                            <TableCell>{item?.individualResourceAssociation?.subscriberTypeMaster.subscriberTypeName}</TableCell>
                                                            <TableCell>{item?.individualResourceAssociation?.resourceTypeID === 'I' ? 'Individual':'Company'}</TableCell>
                                                            <TableCell>
                                                                <p 
                                                                    className="addResourceRemoveBtn"
                                                                    onClick={()=>{ 
                                                                        setTempSelectedIndividual(item)
                                                                        setOpenRemoveDialog(true)
                                                                    }}
                                                                >   
                                                                Remove</p>
                                                            </TableCell>
                                                        </TableRow>
                                                    )) 
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ):(
                                    <p style={{textAlign:'center'}}>No Resources moved yet !!</p>
                                )}
                                
                            </div>
                            <div style={{padding:'10px'}}>
                                <Button variant="outlined"
                                    onClick={handleSubmit}
                                >Add Resources</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        
    }

    

    return(
        <div>
            
                <div>
                    {/* <div >
                        <LocationOnIcon 
                            style = {{float:'right', color:'teal',fontSize:24, cursor:'pointer' }}
                            
                        />
                    </div> */}
                    {container}
                    
                </div>
        </div>
    )
}

export default AddResource