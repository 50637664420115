import React from 'react';
import Map from './Map';

const SubProject = (props)=>{

    let container = null;
    container = (
        <div>
            <Map caseDetails = {props.caseDetails} mapData = {props.mapData}/>
        </div>
    )
    return (
        <div>
            {container}
        </div>
    )
}

export default SubProject