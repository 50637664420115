import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from "@mui/icons-material/Edit";
import PageviewIcon from '@mui/icons-material/Pageview';
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Logo from "../../../../assets/images/logo.png";
import classes from "../MapCheck.module.css";
import classes1 from "./Explorer.module.css";
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import dot from "../../../../assets/images/dot.png";
import line from "../../../../assets/images/line.png"
import comment from "../../../../assets/images/comment.png";
import location from "../../../../assets/images/location.png";
import initialLocation from "../../../../assets/images/initialLocation.png"
import usePlacesAutocomplete from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption
} from "@reach/combobox";
import "@reach/combobox/styles.css";

function Head(props) {
  const [editPopUp,setEditPopUp]=useState(true)
  const [switchLine, setSwitchLine] = React.useState(false);
  const [inputLat,setInputLat] = React.useState(null);
  const [inputLng,setInputLng] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [overallEdit,setOverallEdit] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addLine = React.useCallback((data) => {
    let d1 = data && (props.editableLayer === props.selectedLayerIndex)
    setSwitchLine(d1);
      props.getData({type:1,value:d1})
  }, []);

  const saveLine = React.useCallback(()=>{
    props.getData({type:4});setOverallEdit(false)
    props.getData({type:2})
  })

  useEffect(()=>{
    let switch1 = {... switchLine};
    switch1 = switch1 && props.editableLayer === props.selectedLayerIndex;
    setSwitchLine(switch1);
      props.getData({type:1,value:switch1})
  },[props])

  return (
    <div>
      <Dialog
        open={editPopUp}
        onClose={()=>{setEditPopUp(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center' }}>
          Map Edit Access
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Planning and Survey can only be done in satelite Mode.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={()=>{setEditPopUp(false)}} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Search/> */}
      <div
        style={{
          backgroundColor: "#F2F2F2",
          display: "flex",
          border: " gray 1px solid",
          alignItems: 'center'
        }}
      >
        <div className={classes1.HeadLogo}>
          <img src = {Logo} style= {{height:50}}/>
        </div>
        <div
          style={{
            padding:'0 20px',
            display: "flex",
            alignItems:'center'
          }}
        >
          {/* <FolderOpenIcon style={{padding:'0 5px',fontSize:'21px'}}/> */}
          <InfoIcon style={{ padding:'0 5px', cursor:'pointer' }} onClick = {()=>setOpen(true)}/>
          {!overallEdit ? (<EditIcon style={{padding:'0 5px', cursor:'pointer'}} onClick={()=>{
            if(props.mapView==='satellite'){
              props.getData({type:4});setOverallEdit(true)
              }
              else{
                setEditPopUp(true)
              }
              }}/>):(<PageviewIcon style={{padding:'0 5px', cursor:'pointer'}} onClick={()=>{props.getData({type:5});setOverallEdit(false)}}/>)}
          
          <SaveOutlinedIcon style={{ padding:'0 5px',fontSize:'21px', cursor:'pointer' }} onClick = {saveLine} />
          <CloseIcon onClick = {()=>props.getData({type:3})} style = {{cursor:'pointer'}}/>
          {/* <EditIcon style={{ padding:'0 5px',fontSize:'21px',color:(switchLine?'royalblue':'black') }} onClick={()=>addLine(true)} /> */}
          {/* <PanToolOutlinedIcon style={{ padding:'0 5px',fontSize:'21px',color:(!switchLine?'royalblue':'black') }} onClick={()=>addLine(false)}/> */}
          {/* <Search
           panTo={panTo} 
           /> */}
        </div>
        <div
          className={classes1.HeadMenuContainer}
        >
          <TextField onChange ={(e)=>setInputLat(e.target.value)} value={inputLat}id="outlined-basic" label="Latitude" variant="outlined" size="small" />
          <TextField onChange ={(e)=>setInputLng(e.target.value)} value={inputLng} id="outlined-basic" label="Longitude" variant="outlined" size="small" />
          <Button onClick = {()=>props.getData({type:'pan',value:{lat : parseFloat(inputLat),lng : parseFloat(inputLng)}})}>Go</Button>
        </div>
        {!overallEdit ? (<p style={{paddingLeft:10, fontWeight:'bold', color:'blue'}}>Edit mode is off</p>):(<p style={{paddingLeft:10, fontWeight:'bold', color:'blue'}}>Edit mode is on</p>)}
        
      </div>
      
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>
                <div style= {{display:'flex', justifyContent:'flex-start', padding:5}}>
                    <p style = {{width:'30%', margin:0}}>Case ID:</p>
                    <p style={{margin:0}}>{props.caseDetails.caseMaster[0].caseID}</p>
                </div>
                <div style= {{display:'flex', justifyContent:'flex-start', padding:5}}>
                    <p style = {{width:'30%', margin:0}}>Description:</p>
                    <p style = {{flexShrink: 1,margin:0}}>{props.caseDetails.caseMaster[0].caseDescription}</p>
                </div>
                <div style= {{display:'flex', justifyContent:'flex-start', padding:5}}>
                    <p  style = {{width:'30%', margin:0}}>Distance:</p>
                    <p style={{margin:0}}>{`${props.caseDetails.caseMaster[0].location.distance} KM`}</p>
                </div>
                <div style= {{display:'flex', justifyContent:'flex-start', padding:5}}>
                    <p  style = {{width:'30%', margin:0}}>Latitude:</p>
                    <p style={{margin:0}}>{`${props.caseDetails.caseMaster[0].location.latitude}`}</p>
                </div>
                <div style= {{display:'flex', justifyContent:'flex-start', padding:5}}>
                    <p  style = {{width:'30%', margin:0}}>Longitude:</p>
                    <p style={{margin:0}}>{`${props.caseDetails.caseMaster[0].location.longitude}`}</p>
                </div>
                <div>
                    <p style= {{padding:10, fontWeight:'bold',fontSize:18, textAlign:'center',margin:0 }}>Legends</p>
                    <div  style= {{display:'flex', justifyContent:'flex-start', padding:5, alignContent:'center'}}>
                        <img src={dot} width={10} style= {{width:20, height:20, marginRight:80}}/>
                        <p style={{margin:0}}>Manhole</p>
                    </div>
                    <div  style= {{display:'flex', justifyContent:'flex-start', padding:5, alignContent:'center'}}>
                        <img src={comment} width={10} style= {{width:20, height:20, marginRight:80}}/>
                        <p style={{margin:0}}>Comment</p>
                    </div>
                    <div  style= {{display:'flex', justifyContent:'flex-start', padding:5, alignContent:'center'}}>
                        <img src={location} width={10} style= {{width:20, height:20, marginRight:80}}/>
                        <p style={{margin:0}}>Location</p>
                    </div>
                    <div  style= {{display:'flex', justifyContent:'flex-start', padding:5, alignContent:'center'}}>
                        <img src={line} width={10} style= {{width:20, height:20, marginRight:80}}/>
                        <p style={{margin:0}}>Way Point</p>
                    </div>
                    <div  style= {{display:'flex', justifyContent:'flex-start', padding:5, alignContent:'center'}}>
                        <img src={initialLocation} width={10} style= {{width:20, height:20, marginRight:80}}/>
                        <p style={{margin:0}}>Initial Location</p>
                    </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              close
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    </div>
  );
}

export default Head;


//initally uneditable
//hand is active
//afte..

//ABCD
//no of pipes on each faces to be taken input


//wp1 goes on filter , its states are saved
//similarly wp2..

//total distance to be diaplyed
//checkbox to find distance 

function Search() {
  const {
    ready, //similarly like loadScript for googlemapAPI, if its ready
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 28.70406, lng: () => 77.102493 }, //prefer location close to user location
      radius: 100 * 1000,
    },
  });

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  // const handleSelect = async (address) => {
  //   console.log(address)
  //   setValue(address, false);
  //   clearSuggestions();

  //   try {
  //     const results = await getGeocode({ address });
  //     const { lat, lng } = await getLatLng(results[0]);
  //     panTo({ lat, lng });
  //   } catch (error) {
  //     console.log("😱 Error: ", error);
  //   }
  // };

  return (
    <div 
    className={classes.search}
    >
      <Combobox onSelect={(address)=>console.log(address)}>
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Search your location"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}


// function Search() {
//   const {
//     ready,
//     value,
//     suggestions: { status, data },
//     setValue
//   } = usePlacesAutocomplete();

//   const handleInput = (e) => {
//     setValue(e.target.value);
//   };

//   const handleSelect = (val) => {
//     setValue(val, false);
//   };

//   const renderSuggestions = () => {
//     const suggestions = data.map(({ place_id, description }) => (
//       <ComboboxOption key={place_id} value={description} />
//     ));

//     return (
//       <>
//         {suggestions}
//         <li className="logo">
//           <img
//             src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
//             alt="Powered by Google"
//           />
//         </li>
//       </>
//     );
//   };

//   return (
//     <div className="App">
//       <h1 className="title">USE-PLACES-AUTOCOMPLETE</h1>
//       {/* <p className="subtitle">
//         React hook for Google Maps Places Autocomplete.
//       </p> */}
//       <Combobox onSelect={handleSelect} aria-labelledby="demo">
//         <ComboboxInput
//           style={{ width: 300, maxWidth: "90%" }}
//           value={value}
//           onChange={handleInput}
//           disabled={!ready}
//         />
//         <ComboboxPopover>
//           <ComboboxList>{status === "OK" && renderSuggestions()}</ComboboxList>
//         </ComboboxPopover>
//       </Combobox>
//     </div>
//   );
// }
