import React, { useState } from "react";
import SidePanel from "../Tabs/SidePanel";
import Screen from "./Screen";
import classes from "./Registration.module.css"


export default function Registration(props) {
  console.log(props);
  const [menu, setMenu] = useState(1);
  const [completed,setCompleted] = useState([])

  const changeTab = (menu) => {
    setMenu(menu);
  };

  const getDataFromScreen = (data)=>{
    let tempCompleted = [... completed]
    tempCompleted.push(menu)
    setCompleted(tempCompleted);
    setMenu(data.moveToScreen)
  }

  return (
    <>
      <div className={classes.Container}>
        <div className={classes.SidePanelContainer}>
          <SidePanel active={menu} getData={(menu) => changeTab(menu)} completed = {completed} resourceTypeID = {props.resourceTypeID} subscriberTypeID = {props.subscriberTypeID}/>
        </div>
        <div className={classes.MainPanelContainer}>
          <Screen menu={menu} resourceTypeID = {props.resourceTypeID} resourceID= {props.resourceID} getData = {(data)=>getDataFromScreen(data)} completed={completed} subscriberTypeID = {props.subscriberTypeID}/>
        </div>
      </div>
    </>
  );
}
