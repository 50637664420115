import React, { useState, useEffect } from "react";
import { Box, Grid, Paper } from "@mui/material";
import classes from "./machineOnboard.module.css";
import { TextField } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import { FormLabel } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DashboardContainer from "../DashboardContainer";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import axios from "../../../../axios_tteg";
import { useAlert } from "react-alert";
import Cookies from "universal-cookie";
import { parseWithOptions } from "date-fns/fp";
import CircularProgress from '@mui/material/CircularProgress';


const cookies = new Cookies();


export default function ParticularMachineOnboard(props) {
  const fileTypes = ["PDF"];

  const [goToOnboarding, setGoToOnboarding] = useState(false);

  const [ownerFound, setOwnerFound] = React.useState(props.particularMachineDetails.machineDetails.resourceOwner === 'true' ? 1:2);

  const [value, setValue] = React.useState(new Date());

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [ownerDetails,setOwnerDetails] = useState([]);
  const [selectedOwner,setSelectedOwner] = useState(null);
  const [miData, setMIData] = useState({
    mType: {
      value: props.particularMachineDetails.machineDetails.machineTypeID.toString(),
      valid: true,
    },
    machineTypeName:{
      value: props.particularMachineDetails.machineTypeName.toString(),
      valid:true
    },
    oem: {
      value: props.particularMachineDetails.machineDetails.OEM.toString(),
      valid: true,
    },
    machineCapacity: {
      value: props.particularMachineDetails.machineDetails.machineCapacityInTonnes.toString(),
      valid: true,
    },
    modelNumber: {
      value: props.particularMachineDetails.machineDetails.modelNumber.toString(),
      valid: true,
    },
    machineAvailableSectors: {
      value: props.particularMachineDetails.machineDetails.machineAvailabilitySectors,
      valid: true,
    },
  });

  const [machineType, setMachineType] = useState([]);
  const [machineID,setMachineID] = useState( props.particularMachineDetails.machineDetails.machineID);
  const [machineDescription,setMachineDescription] = useState([]);
  const [otherMachineType,setOtherMachineType] = useState(false);

  useEffect(() => {
    console.log(props);
    setLoading(true);
    axios
      .get("/getMachineType", {})
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        setMachineType(response.data.machineType);
        let {found,index} = checkMachineType(response.data.machineType);
        if(!found){
          let tempMIData = {... miData};
          tempMIData.mType.value = "Other"
          setMIData(tempMIData);
          setOtherMachineType(true)
        }
        else{
          setMachineDescription(response.data.machineType[index].machineDescription)
        }
        // setMachineID(response.data.machineID);
        // setDomain(response.data.domain);
        // setDomainBase(response.data.domainBase);
        // setTotalSubscriberType(response.data.subscriberType);
        // handleSubscriberType(response.data.subscriberType);
      })
      .catch((e) => console.log(e.response.data.error));
  }, []);

  const checkMachineType = (optionMachineType)=>{
    let found = false;
    let index = null;
    optionMachineType.map((item,i)=>{
      if(item.machineTypeID === props.particularMachineDetails.machineDetails.machineTypeID){
        found = true;
        index = i;
      }
        
    })
    return {found,index}
  }

  // const handleChange1 = (key, value) => {
  //   const tempMIData = { ...miData };
  //   if (value) {
  //     tempMIData[key].value = value;
  //     tempMIData[key].valid = true;
  //   } else {
  //     tempMIData[key].value = value;
  //     tempMIData[key].valid = false;
  //   }
  //   setMIData(tempMIData);
  // };

  const handleChange1 = (key, value) => {
    const tempMIData = { ...miData };
    if(key === 'mType'){
      let tempMachineType = [... machineType];
      if(value){
        if(value === 'Other'){
          setOtherMachineType(true);
        }
        else{
          tempMIData["machineTypeName"].valid = true
          setOtherMachineType(false);
          tempMachineType.map((item,index)=>{
            if(item.machineTypeID === value){
              setMachineDescription(tempMachineType[index].machineDescription)
            }
          })
        }
        
      }
      else{
        setMachineDescription([])
      }
    }
    if(key === 'modelNumber'){
        // const tempMIData = { ...miData };
        machineDescription.map((item,index)=>{
          if(item.modelNo === value){
              tempMIData['oem'].value = machineDescription[index].OEM;
              tempMIData['oem'].valid = true;
              tempMIData['machineCapacity'].value = machineDescription[index].capacity;
              tempMIData['machineCapacity'].valid = true;
          }
        })
        if (value) {
          tempMIData[key].value = value;
          tempMIData[key].valid = true;
        } else {
          tempMIData[key].value = value;
          tempMIData[key].valid = false;
          tempMIData['oem'].value = value;
          tempMIData['oem'].valid = false;
          tempMIData['machineCapacity'].value = value;
          tempMIData['machineCapacity'].valid = false;
        }
        
        setMIData(tempMIData);
    }
    else{
      // const tempMIData = { ...miData };
      if (value) {
        tempMIData[key].value = value;
        tempMIData[key].valid = true;
      } else {
        tempMIData[key].value = value;
        tempMIData[key].valid = false;
      }
      setMIData(tempMIData);
    }
  };

  /*-----------Machine Owner-------- */

  // const [oiData, setOIData] = useState({
  //   name: {
  //     value: props.particularMachineDetails.machineDetails.machineOwnerDetails.name,
  //     valid: true,
  //   },
  //   mobile: {
  //     value: props.particularMachineDetails.machineDetails.machineOwnerDetails.mobile,
  //     valid: true,
  //   },
    // idName: {
    //   value: props.particularMachineDetails.machineDetails.name,
    //   valid: true,
    // },
    // idEmail: {
    //   value: props.particularMachineDetails.machineDetails.email,
    //   valid: true,
    // },
    // bankName: {
    //   value: props.particularMachineDetails.machineDetails.bankName,
    //   valid: true,
    // },
    // ifscCode: {
    //   value: props.particularMachineDetails.machineDetails.ifscCode,
    //   valid: true,
    // },
    // idAddress: {
    //   value: props.particularMachineDetails.machineDetails.Address,
    //   valid: true,
    // },
    // mobNum: {
    //   value: props.particularMachineDetails.machineDetails.mobile,
    //   valid: true,
    // },
    // accNum: {
    //   value: props.particularMachineDetails.machineDetails.accountNumber,
    //   valid: true,
    // },
    // branchAddress: {
    //   value: props.particularMachineDetails.machineDetails.branchAddress,
    //   valid: true,
    // },
  // });
  // const handleChange2 = (key, value) => {
  //   const tempOIData = { ...oiData };
  //   if (value) {
  //     tempOIData[key].value = value;
  //     tempOIData[key].valid = true;
  //   } else {
  //     tempOIData[key].value = value;
  //     tempOIData[key].valid = false;
  //   }
  //   setOIData(tempOIData);
  // };

  // const [docDetail, setDocDetail] = useState({
  //   file1: {
  //     docTypeID: "DT-00013",
  //     value: null,
  //     isUploaded: false,
  //     path: props.particularMachineDetails.machineDetails.machineInvoicePath,
  //   },
  //   file2: {
  //     docTypeID: "DT-00014",
  //     value: null,
  //     isUploaded: false,
  //     path: props.particularMachineDetails.machineDetails.machineOperationalManualPath,
  //   },
  // });

  const [docDetail, setDocDetail] = useState({
    'DT-00013': {
      docTypeID: "DT-00013",
      docTypeName:'Machine Invoice*',
      value: null,
      isUploaded: false,
      path: props.particularMachineDetails.machineDetails.machineInvoicePath,
    },
    "DT-00014": {
      docTypeID: "DT-00014",
      docTypeName:"Any other Document",
      value: null,
      isUploaded: false,
      path: props.particularMachineDetails.machineDetails.machineOperationalManualPath,
    },
  });

  const uploadDocument = (file, key) => {
    var formData = new FormData();

    formData.append("uploadedFile", file);
    formData.append("resourceID", cookies.get('userData').resourceID);
    formData.append("docTypeID", docDetail[key].docTypeID);
    formData.append('machineID',machineID);
    setLoading(true);
    axios
      .post("/uploadMachine", formData, {})
      .then((response) => {
        console.log(response);
        setLoading(false);
        if (response.status === 200) {
          console.log(response);
          let tempDocDetail = { ...docDetail };
          console.log(tempDocDetail[key].isUploaded);
          tempDocDetail[key].value = null;
          tempDocDetail[key].isUploaded = true;
          tempDocDetail[key].path = response.data.path;
          setDocDetail(tempDocDetail);
        }
      })
      .catch((e) => {
        setLoading(false);
        setError(e.response.error);
      });
  };

  // const submitHandler = () => {
  //   let tempMI = { ...miData };
  //   let tempOI = { ...oiData };
  //   let tempDocDetail = { ...docDetail };

  //   console.log(tempMI);
  //   console.log(tempOI);
  //   console.log(ownerFound);

  //   // let isValid = true;
  //   // Object.keys(tempHR).map((item) => {
  //   //   isValid = isValid && tempHR[item].valid;
  //   // });

  //   // if (isValid) {

  //   setLoading(true);
  //   axios
  //     .post("/machineOnboarding", {
  //       resourceID: cookies.get('userData').resourceID,
  //       machineTypeID: tempMI.mType.value === 'Other' ? props.particularMachineDetails.machineDetails.machineTypeID:tempMI.mType.value,
  //       machineTypeName:tempMI.machineTypeName.value,
  //       modelNumber: tempMI.modelNumber.value,
  //       OEM: tempMI.oem.value,
  //       machineCapacityInTonnes: tempMI.machineCapacity.value,
  //       machineAvailabilitySectors: tempMI.machineAvailableSectors.value,
  //       purchaseDates: value,
  //       resourceOwner: ownerFound == 1 ? true : false,
  //       machineID:machineID,
  //       machineOwnerDetails: {
  //         name: tempOI.name.value,
  //         mobile: tempOI.mobile.value,
  //       },
  //       machineInvoicePath: tempDocDetail['DT-00013'].path,
  //       machineOperationalManualPath: tempDocDetail['DT-00014'].path
  //     })
  //     .then((response) => {
  //       setLoading(false);
  //       console.log(response);
  //       if (response.status == 200) {
  //         alert("Machine Details Entered Successfully");
  //         props.getData(false);
  //       } else {
  //         setError(response.data.error);
  //       }
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //       setError(e.response.data.error);
  //     });
  //   // } else {
  //   //   setError("*Please fill appropraite data");
  //   // }
  // };

  // const submitHandle1 = (file1) => {
  //   let tempDocDetail = { ...docDetail };
  //   tempDocDetail.file1.value = file1;
  //   tempDocDetail.file1.isUploaded = false;
  //   setDocDetail(tempDocDetail);
  // };
  // const submitHandle2 = (file2) => {
  //   let tempDocDetail = { ...docDetail };
  //   tempDocDetail.file2.value = file2;
  //   tempDocDetail.file2.isUploaded = false;
  //   setDocDetail(tempDocDetail);
  // };

  const selectFile = (file,key) => {
    let tempDocDetail = { ...docDetail };
    tempDocDetail[key].value = file;
    tempDocDetail[key].isUploaded = false;
    setDocDetail(tempDocDetail);
  };

  // let container2 = null;
  // if (ownerFound =="2") {
  //   console.log(ownerFound);
  //   container2 = (
  //     <div>
  //       <Grid>
  //         <h3 style={{ textAlign: "center" }}>Please Enter Owner Details</h3>
  //       </Grid>
  //       {ownerDetails.length > 0 ? (
  //         <Grid className={classes.details}>
  //         <Grid className={classes.owner}>
  //           <Grid>
  //           <FormControl
  //             variant="filled"
  //             // style={{ padding: "1% 1% 1% 1%", width: "40%" }}
  //             className={classes.inputStyle}
  //           >
  //             <InputLabel id="demo-simple-select-filled-label">
  //               Select Owner*
  //             </InputLabel>
  //             <Select
  //               labelId="demo-simple-select-filled-label"
  //               id="demo-simple-select-filled"
  //               value={selectedOwner}
  //               onChange={(e) => {
  //                 setSelectedOwner(e.target.value);
  //                 Object.keys(e.target.value).map(key=>{
  //                   if(key !== '_id')
  //                     handleChange2(key,e.target.value[key])
  //                 })
                  
  //               }}
  //             >
                
  //               {ownerDetails.map(item=>{
  //                 return(
  //                   <MenuItem value={item.vendorMaster[0]} >{item.vendorMaster[0].name}</MenuItem>
  //                 )
  //               })}
  //             </Select>
  //           </FormControl>
  //         </Grid>
  //         </Grid>
  //         </Grid>
  //       ):(
  //         <Grid className={classes.details}>
  //         <Grid className={classes.owner}>
  //           <Grid>
  //             <TextField
  //               className={classes.inputStyle}
  //               id="filled-basic"
  //               label="Name as in govt ID"
  //               variant="filled"
  //               value={oiData.name.value}
  //               onChange={(e) => handleChange2("name", e.target.value)}
  //             />
  //           </Grid>
  //         </Grid>
  //         <Grid className={classes.owner}>
  //           <Grid>
  //             <TextField
  //               className={classes.inputStyle}
  //               id="filled-basic"
  //               label="Mobile number"
  //               variant="filled"
  //               value={oiData.mobile.value}
  //               onChange={(e) => handleChange2("mobile", e.target.value)}
  //             />
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //     )}
  //     </div>
  //   );
  // }

  let container = null;
  container = (
    <div>
      <Box>
        <Paper
          elevation={0}
        >
          <Grid>

            <Grid>
              <Grid
                className={classes.displaying}
                style={{ textAlign: "center", width: "100%", padding :'20px' }}
              >
                <Grid className={classes.style}>
                  <Grid>
                    <FormControl
                      variant="filled"
                      className={classes.inputStyle}
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Machine Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disabled = {true}
                        value={miData.mType.value}
                        onChange={(e) => handleChange1("mType", e.target.value)}
                      >
                        {/* <MenuItem value={"MT00001"}>HDD</MenuItem>
                        <MenuItem value={"MT00002"}>Trencher</MenuItem>
                        <MenuItem value={"MT00003"}>Tractor</MenuItem> */}
                        {machineType.map(item=>{
                          return(
                            <MenuItem value={item.machineTypeID} >{item.machineTypeName}</MenuItem>
                          )
                        })}
                        <MenuItem value={'Other'} >{'Other'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid>
                    {otherMachineType ? (
                        <TextField
                        className={classes.inputStyle}
                        id="filled-basic"
                        label="Machine Type Name"
                        variant="filled"
                        disabled = {true}
                        value={miData.machineTypeName.value}
                        onChange={(e) => handleChange1("machineTypeName", e.target.value)}
                      />
                    ):null}
                    
                  </Grid>
                  <Grid>
                  {otherMachineType ? (
                    <TextField
                      className={classes.inputStyle}
                      id="filled-basic"
                      label="OEM*"
                      variant="filled"
                      disabled = {true}
                      value={miData.oem.value}
                      onChange={(e) =>
                        handleChange1("oem", e.target.value)
                      }
                    />
                  ):(
                    <FormControl
                    variant="filled"
                    className={classes.inputStyle}
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      OEM*
                    </InputLabel>
                      <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      value={miData.oem.value}
                      disabled = {true}
                      onChange={(e) => handleChange1("oem", e.target.value)}
                    >
                      {/* <MenuItem value={"MT00001"}>HDD</MenuItem>
                      <MenuItem value={"MT00002"}>Trencher</MenuItem>
                      <MenuItem value={"MT00003"}>Tractor</MenuItem> */}
                      {machineDescription && machineDescription.map(item=>{
                        return(
                          <MenuItem value={item.OEM} >{item.OEM}</MenuItem>
                        )
                      })}
                    </Select>
                    </FormControl>
                  )}
                  </Grid>
                  <Grid>
                  {otherMachineType ? (
                        <TextField
                          className={classes.inputStyle}
                          id="filled-basic"
                          label="Machine Capacity(in tonnes)*"
                          variant="filled"
                          disabled = {true}
                          value={miData.machineCapacity.value}
                          onChange={(e) =>
                            handleChange1("machineCapacity", e.target.value)
                          }
                        />
                      ):(
                          <FormControl
                          variant="filled"
                          className={classes.inputStyle}
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            Machine Capacity(in tonnes)*
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={miData.machineCapacity.value}
                            onChange={(e) => handleChange1("machineCapacity", e.target.value)}
                            disabled={true}
                          >
                            {/* <MenuItem value={"MT00001"}>HDD</MenuItem>
                            <MenuItem value={"MT00002"}>Trencher</MenuItem>
                            <MenuItem value={"MT00003"}>Tractor</MenuItem> */}
                            {machineDescription && machineDescription.map(item=>{
                              return(
                                <MenuItem value={item.capacity} >{item.capacity}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      )}
                  </Grid>
                </Grid>

                <Grid className={classes.style} style={{ textAlign: "center" }}>
                  <Grid>
                  {otherMachineType ? (
                        <TextField
                          className={classes.inputStyle}
                          id="filled-basic"
                          label="Model No*"
                          variant="filled"
                          value={miData.modelNumber.value}
                          disabled = {true}
                          onChange={(e) =>
                            handleChange1("modelNumber", e.target.value)
                          }
                        />
                      ):(
                          <FormControl
                          variant="filled"
                          className={classes.inputStyle}
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            Model No*
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={miData.modelNumber.value}
                            disabled = {true}
                            onChange={(e) => handleChange1("modelNumber", e.target.value)}
                          >
                            {/* <MenuItem value={"MT00001"}>HDD</MenuItem>
                            <MenuItem value={"MT00002"}>Trencher</MenuItem>
                            <MenuItem value={"MT00003"}>Tractor</MenuItem> */}
                            {machineDescription && machineDescription.map(item=>{
                              return(
                                <MenuItem value={item.modelNo} >{item.modelNo}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      )}
                  </Grid>
                  <Grid
                    className={classes.dataSelector}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={1}>
                        <DesktopDatePicker
                          label="Purchase Date*"
                          value={value}
                          minDate={new Date("2017-01-01")}
                          maxDate={new Date()}
                          disabled = {true}
                          onChange={(newValue) => {
                            setValue(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField variant="filled" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid>
                    <TextField
                      className={classes.inputStyle}
                      id="filled-basic"
                      label="Machine Available Sectors"
                      variant="filled"
                      disabled = {true}
                      value={miData.machineAvailableSectors.value}
                      onChange={(e) =>
                        handleChange1("machineAvailableSectors", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.styling1}>
            {Object.keys(docDetail).map(key=>{
                  return(
                    <Grid className={classes.alignItems}>
                      <Grid style={{ textAlign: "left",display: 'flex',justifyContent: 'space-around',alignItems: 'center' }}>
                        <h3>{docDetail[key].docTypeName}</h3>
                        <Button
                            disabled = {docDetail[key].path ? false:true}
                            onClick={()=>window.open(docDetail[key].path,"_blank")}
                        >
                            
                                <p style={{margin:2}}>{docDetail[key].path ? 'View':'Not Found'}</p>
                            
                        </Button>
                      </Grid>
                        
                      {/* <Grid>
                        <Grid style={{ display: "flex" }}>
                          <Grid style={{ width: "80%" }}>
                            <FileUploader
                              multiple={false}
                              handleChange={(file)=>selectFile(file, key)}
                              name={key}
                              types={fileTypes}
                            />
                          </Grid>
                        </Grid>
                        <Grid style={{ display: "flex" }}>
                          <p style={{ textAlign: "left", width: "60%" }}>
                            {docDetail[key].value
                              ? `File name: ${docDetail[key].value.name}`
                              : "No files uploaded"}
                          </p>
                          <Grid style={{ padding: "1% 20% 1% 0%", width: "40%" }}>
                            <Button
                              style={{
                                borderRadius: "18px",
                                // justifyContent: "right",
                                marginLeft: "0%",
                              }}
                              type="submit"
                              color="primary"
                              variant="contained"
                              // disabled={docDetail[key].value ? false : true}
                              onClick={() => {
                                uploadDocument(docDetail[key].value, key);
                              }}
                            >
                              Upload
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid> */}
                    </Grid>
                  )
                })}
            </Grid>
          </Grid>
          {/* <Grid style={{ textAlign: "left", padding: "0% 0% 0% 7%" }}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                <h3>Are You the Owner of this Machine ?*</h3>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={ownerFound}
                value = {ownerFound}
                name="radio-buttons-group"
                onChange={(event) => setOwnerFound(event.target.value)}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="2" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {container2}*/}
          <Grid style={{ textAlign: "center", padding: "1% 0% 1% 0%" }}>
            <Button variant="contained" onClick={()=>props.getData()}>
              Close
            </Button>
          </Grid> 
        </Paper>
      </Box>
    </div>
  );
  return (
    <div>
      {loading ?(
        <div>
          <CircularProgress/>
        </div>
      ):(
      <div>
        {container}
        <p>{error}</p>
      </div>
      )}
    </div>
    
  );
}
