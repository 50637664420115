import React,{useState} from "react";
import Explorer from './Map/Explorer/Explorer';
import Head from './Map/Explorer/Head';
import MapCheck from "./Map/MapCheck";
import MenuIcon from '@mui/icons-material/Menu';

const MapContainer = (props) => {
  // const [wayPoints,setWayPoints] = React.useState([]);
  // const [manholes,setManholes] = React.useState([]);
  const [mapView,setMapView] = React.useState("roadmap")
  const [markers,setMarkers] = React.useState([]);
  const [layers,setLayers] = React.useState(null);
  const [edit,setEdit] = React.useState(false);
  const [panTo,setPanTo] = React.useState({lat:null,lng:null});
  const [selectedRouteIndex,setSelectedRouteIndex] = React.useState(0);
  const [selectedLayerIndex,setSelectedLayerIndex] = React.useState(0);
  const [editableLayer,setEditableLayer] = React.useState(0);
  const [delRouteIndex,setDelRouteIndex] = React.useState(null)
  const [add,setAdd] = React.useState(null)
  const [saveMap,setSaveMap] = React.useState(false);
  const handleMarkers = (data) => {
    setAdd(null);
    setMarkers(data)
  }
  // For checkbox selection of routes
  const [selIndex,setSelIndex] = useState([{
    layer:0,
    route:[0]
  }])

  const [editLayerIndex,setEditLayerIndex] =  useState(0);
  const [editRouteIndex,setEditRouteIndex] =  useState(0);
  const [viewLayerIndex,setViewLayerIndex] = useState(0);
  const [viewRouteIndex,setViewRouteIndex] = useState(0);
  const [layerResourceID,setLayerResourceID] = useState([]);
  const [openExplorer,setOpenExplorer] = useState(false);
  const [overallEdit,setOverallEdit] = useState(false);
  const [manholeHover,setManholeHover] = useState(null);

  const innerWidth = window.innerWidth;
  

  const changeMapParam = (data)=>{
    if(data.type === 1){
      setEdit(data.value);
    }
    else if(data.type=== 'pan'){
      setPanTo(data.value);
    }
    else if(data.type === 2){
        setSaveMap(true);
    }
    else if(data.type === 3){
      props.getData('close');
    }
    else if(data.type === 4){
      setOverallEdit(true);
    }
    else if(data.type === 5){
      setOverallEdit(false);
    }
  }

  const handleLayers = (data,editLayerIndex,layerResourceID)=>{
    setLayers(data,editLayerIndex);
    setLayerResourceID(layerResourceID);
    // setEditableLayer(editableLayer);
    setEditLayerIndex(editLayerIndex);
    data.map((outerItem,outerIndex)=>{
      if(outerItem.selected){
        setSelectedLayerIndex(outerIndex);
        outerItem.routes.map((item,index)=>{
          if(item.selected){
            setSelectedRouteIndex(index);
            return
          }
        })
      }
    })
  }

  const getDataFromExplorer = (data)=>{
    if(data.type === 'layer'){
      changeSelectedEditLayer(data.type,data.layerIndex,data.routeIndex);
      // setSelectedLayerIndex(data.index);
      // setSelectedRouteIndex(0)
    }
      
    else if(data.type === 'route'){
      changeSelectedEditLayer(data.type,data.layerIndex,data.routeIndex);
    }
      
    else if(data.type === 'addlayers' || data.type === 'addroutes'){
      setAdd(data);
    }
    else if(data.type == 'view'){
      setViewLayerIndex(data.layerIndex);
      setViewRouteIndex(data.routeIndex);
    }
    else if (data.type == 'edit'){
      if(data.layerIndex === editLayerIndex){
        setEditRouteIndex(data.routeIndex);
      } 
    }
    else if (data.type == 'delete'){
      if(data.layerIndex === editLayerIndex){
        setDelRouteIndex(data.routeIndex);
      }  
    }
    else if (data.type == 'openExplorer'){
      setOpenExplorer(data.openExplorer);
    }
  }

  // const createTwoButtonAlert = (key,index) =>
  //   alert(
  //           "Are you sure you want to delete ?",
  //       [
  //           {
  //           text: "Cancel",
  //           onPress: () => console.log("Cancel Pressed"),
  //           style: "cancel"
  //           },
  //           { text: "OK", onPress: () => deleteTo(key,index) }
  //     ]
  //   );

  //   const deleteTo = (key,index)=>{
  //       if(key === 'delroutes'){
  //         console.log("hi")
  //           // setDelRouteIndex(index);
  //       }
        
  //   }

  const changeSelectedEditLayer = (key,layerIndex,routeIndex)=>{
    let tempSelIndex = [... selIndex];
    if (key == 'layer'){
        if(getSelected('layerIndex',layerIndex,null).found){
            tempSelIndex.splice(getSelected('layerIndex',layerIndex).foundIndex.layerIndex,1)
        }
        else{
            tempSelIndex.push({
                layer:layerIndex,
                route:[0]
            })
        }   
        
        setSelIndex(tempSelIndex)
    }
    else if(key == 'route'){
        if(getSelected('routeIndex',layerIndex,routeIndex).found){
            tempSelIndex[getSelected('layerIndex',layerIndex).foundIndex.layerIndex].route.splice(getSelected('routeIndex',layerIndex,routeIndex).foundIndex.routeIndex,1);
            if(!tempSelIndex[getSelected('layerIndex',layerIndex).foundIndex.layerIndex].route.length)
              tempSelIndex.splice(getSelected('layerIndex',layerIndex).foundIndex.layerIndex,1)
        }
        else{
          if(getSelected('layerIndex',layerIndex).found){
            tempSelIndex[getSelected('layerIndex',layerIndex).foundIndex.layerIndex].route.push(routeIndex)
          }
          else
            tempSelIndex.push({
              layer:layerIndex,
              route:[routeIndex]
            })
        } 
        setSelIndex(tempSelIndex)  
    }
  }

  const getSelected = (key,layerIndex,routeIndex)=>{

    let found = false;
    let foundIndex = null
    // let promise = new Promise((resolve, reject) => {
        if(key == 'layerIndex'){
            selIndex.map((item,index)=>{
                if(item.layer === layerIndex){
                    found = true;
                    foundIndex={layerIndex:index,routeIndex:null};
                }
            })
            // resolve({found:found,foundIndex:foundIndex})
        }
        else if(key == 'routeIndex'){
            selIndex.map((item,index)=>{
                let tempRouteIndex = [];
                if(item.layer === layerIndex){
                    item.route.map((innerItem,innerIndex)=>{
                        if(innerItem === routeIndex){
                            tempRouteIndex.push(innerIndex);
                            found = true;
                            foundIndex = {layerIndex:index,routeIndex:tempRouteIndex};
                        }
                    })
                    
                }       
            })
            // resolve({found:found,foundIndex:foundIndex})
        }
    // })
    // let result = await promise;
    // return await result;
    return ({found:found,foundIndex:foundIndex});
  }


  return (
    <div>
      <div style={{width:'100%'}}>
          <Head 
            getData = {(data)=>{changeMapParam(data)}} 
            selectedLayerIndex={selectedLayerIndex} 
            editableLayer={editableLayer}
            caseDetails = {props.caseDetails}  
            setMapView={setMapView}
            mapView={mapView}
          /> 
        <div style={{display:'flex'}}>
          <div style={innerWidth <= 768 ? openExplorer ?{width:205}:{width:45, background:'white'}:{width:205}}>
            {!openExplorer && innerWidth <= 768 ? (
              <MenuIcon style={{
                fontSize: 33,
                padding: 10,
                color: '#1976d2',
                cursor:'pointer',
                float:'right'
              }}
                onClick = {()=>{
                  setOpenExplorer(!openExplorer )
                }}
              />
            ):null}
            
            <Explorer 
              markers= {markers} 
              layers = {layers} 
              getData = {(data)=>getDataFromExplorer(data)} 
              editableLayer={editableLayer} 
              editLayerIndex = {editLayerIndex}
              viewLayerIndex = {viewLayerIndex}
              viewRouteIndex = {viewRouteIndex}
              layerResourceID = {layerResourceID}
              openExplorer = {openExplorer}
              manholeHover = {manholeHover}
            />  
          </div>
          <div style={innerWidth <= 768 ? openExplorer ?{width:'85%'}:{width:'99%'}:{width:'85%'}}>
              <MapCheck 
                getMarkers = {(data)=>handleMarkers(data)} 
                overallEdit = {overallEdit}
                edit={edit} 
                panTo={panTo} 
                getLayers = {(layers,editLayerIndex,layerResourceID)=>handleLayers(layers,editLayerIndex,layerResourceID)} 
                selectedLayerIndex={selectedLayerIndex}
                selectedRouteIndex= {selectedRouteIndex}
                selIndex = {selIndex}
                viewLayerIndex = {viewLayerIndex}
                viewRouteIndex = {viewRouteIndex}
                editLayerIndex = {editLayerIndex}
                editRouteIndex = {editRouteIndex}
                delRouteIndex = {delRouteIndex}
                add={add}
                caseDetails = {props.caseDetails}
                saveMap = {saveMap}
                returnSave = {(data)=>setSaveMap(data)}
                returnDelete = {()=>setDelRouteIndex(null)}
                getManholeHover = {(manholeHover)=>setManholeHover(manholeHover)}
                setMapView={setMapView}
                mapView={mapView}
              />
          </div>  
         </div>
         
      </div>
    </div>
  );
}

export default MapContainer;

