import React from "react";
import AssetsTable from "./AssetsTable";

const AssetsRight = ()=>{
    return(
        <div style={{height:'73vh'}}>
            <AssetsTable/>
        </div>
    )
}

export default AssetsRight