import React,{useState,useEffect} from 'react';
import axios from "../../../../axios_tteg";
import Cookies from 'universal-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const cookies = new Cookies();

const AssociatedMachine = ()=>{

    const [assoMach,setAssoMach] = useState([]);
    const [loading,setLoading] = useState(false);
    const [inputOptionMachine,setInputOptionMachine] = useState([]);
    useEffect(()=>{
        machineType();
        getAssociatedMachine()
    },[])
    const getAssociatedMachine = ()=>{
        setLoading(true);
        axios.get('/getAssociatedMachine',{
          params:{
            companyID:cookies.get('userData').resourceID
          }
        }).then(response=>{
            setLoading(false);
            setAssoMach(response.data.assetAssociation)
        })
        .catch(e=>{console.log(e)})
    }

    const machineType = ()=>{
        setLoading(true);
        axios.get('/getMachineType')
        .then(response=>{
            setLoading(false);
            inputFormatMachine(response.data.machineType);
        })
        .catch(e=>console.log(e))
    }

    const getMachineType = (machineTypeID)=>{
        let tempMachineType = null;
        console.log(inputOptionMachine)
        inputOptionMachine.map(item=>{
            if(item.value === machineTypeID)
                tempMachineType = item.label
        })
        return tempMachineType
    }

    const inputFormatMachine = (machineType)=>{
        let format = []
        machineType.map(item=>{
            format.push({
                label:item.machineTypeName,
                value:item.machineTypeID
            })
        })
        setInputOptionMachine(format)
    } 

    let container = null;
    if(loading){
        container = (
            <CircularProgress/>
        )
    }
    else if(assoMach.length > 0){
        container = (
            <TableContainer component={Paper}style={{margin:'10px 0px'}}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style = {{textAlign:'center', fontWeight:'bold'}}>
                            <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                Machine Type
                            </TableCell>
                            <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                Machine Model
                            </TableCell>
                            <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                OEM
                            </TableCell>
                            <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                Machine Owner Name
                            </TableCell>
                            <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                Machine Owner Mob No.
                            </TableCell>
                            <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                Purchase Date
                            </TableCell>
                            <TableCell style = {{textAlign:'center', fontWeight:'bold'}}>
                                Till Date
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assoMach.map(item=>{
                            console.log(item.machineDescriptionMaster[0]);
                            return(
                                <TableRow>
                                    <TableCell>
                                        {getMachineType(item.machineDescriptionMaster[0].machineTypeID)}
                                    </TableCell>
                                    <TableCell>
                                        {item.machineDescriptionMaster[0].modelNumber}
                                    </TableCell>
                                    <TableCell>
                                        {item.machineDescriptionMaster[0].OEM}
                                    </TableCell>
                                    <TableCell>
                                        {item.machineDescriptionMaster[0].mResourceMachine[0].MachineOwner[0].name}
                                    </TableCell>
                                    <TableCell>
                                        {item.machineDescriptionMaster[0].mResourceMachine[0].MachineOwner[0].mobile}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(new Date(item.machineDescriptionMaster[0].purchaseDates).setHours(0,0,0,0)).toLocaleString()}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(new Date(item.endDate).setHours(23,59,59,999)).toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    return(
        <div>
            {container}
        </div>
    )
}

export default AssociatedMachine