import React, { useEffect, useState } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';

function MapWithInfoWindow({ isEntryOrExitPit, trackingSelected, HDDDATA, map }) {
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [open, setOpen] = useState(false);

  // Find the index of the selected pipe configuration based on isEntryOrExitPit
  const indexs = HDDDATA.findIndex((item) => item.type === isEntryOrExitPit);

  // Update the InfoWindow position and visibility when isEntryOrExitPit or trackingSelected changes
  useEffect(() => {
    if (isEntryOrExitPit && trackingSelected && indexs !== -1) {
      const { lat, lng } = trackingSelected.position; // Assuming lat/lng in trackingSelected
      setInfoWindowPosition({ lat, lng });
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isEntryOrExitPit, trackingSelected, indexs]);

  // Handle closing the InfoWindow
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Render Marker at the selected position */}
      {infoWindowPosition && <Marker position={infoWindowPosition} />}

      {/* Render InfoWindow if open is true and we have a valid infoWindowPosition */}
      {open && infoWindowPosition && (
        <InfoWindow position={infoWindowPosition} onCloseClick={handleClose}>
          <div>
            <h3>Pipe Configuration</h3>
            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
              {/* Loop through the pipeConfiguration data for the selected index */}
              {HDDDATA[indexs] &&
                Object.keys(HDDDATA[indexs].pipeConfiguration).map((key, i) => (
                  <div
                    key={i}
                    style={{
                      padding: '10px',
                      backgroundColor: 'aliceblue',
                      marginBottom: '10px',
                    }}
                  >
                    <div>
                      <span><strong>{key}</strong></span>
                    </div>
                    {/* Map over each item in the pipe configuration */}
                    {HDDDATA[indexs].pipeConfiguration[key].map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0 10px',
                        }}
                      >
                        <span>{item.PipeType}</span>
                        <span>{item.Quantity}</span>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
}

export default MapWithInfoWindow;
