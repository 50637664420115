import "./App.css";
import React, { useState, useEffect } from "react";
import { Grid, Paper, Box, CircularProgress } from "@mui/material";
import LoginContainer from "./Components/Login/LoginContainer";
import Cookies from "universal-cookie";
import DashboardContainer from "./Components/Dashboard/Components/DashboardContainer";
import AdminDashboardContainer from "./Components/Dashboard/AdminDashboard/AdminDashboardContainer";
import Register from './Components/Register/Registration';
import PreRegCompleted from "./Components/PreRegistration/PreRegCompleted";

const cookies = new Cookies();

function App() {
  const [goToDashboard, setGoToDashboard] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (cookies.get("userData")) {
      setLoading(false);
      setGoToDashboard(true);
    } else {
      setLoading(false);
      setGoToDashboard(false);
    }
  });


  let Landing = null;
  if (loading) {
    Landing = (
      <Paper>
        <Box>
          <Grid style={{ textAlign: "center" }}>
            <CircularProgress />
          </Grid>
        </Box>
      </Paper>
    );
  } else {
    Landing = (
      <div>
        {goToDashboard ? cookies.get('userData').resourceTypeID === 'T' ? (<AdminDashboardContainer/>) : (<DashboardContainer resourceID={cookies.get("userData").resourceID}/>) : <LoginContainer />}
      </div>
    );
  }

  return (
    <div className="App">
      {Landing}
      {/* <Register  resourceID = 'D07F00510002' resourceTypeID= 'I' subscriberTypeID = "F-010"/> */}
      {/* <PreRegCompleted
        message='Completed'
        resourceID='D07F00510002'
        resourceTypeID='C'
        registrationComplete={false}
        screen={1}
      /> */}
    </div>
  );
}

export default App;
