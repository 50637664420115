import React,{useState,useEffect} from 'react';
import axios from '../../axios_tteg';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ProjectAssociated from './ProjectAssociated';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Planning = (props)=>{
    const [detailedBOQ,setDetailedBOQ] = useState(null);
    const [subProjects,setSubProjects] = useState(null);
    const [alreadyAssociated,setAlreadyAssociated] = useState([]);
    const [newAlreadyAssociated,setNewAlreadyAssociated] = useState(null);
    const [selectedSubscriberType,setSelectedSubscriberType]=useState(null)
    const [subscriberTypes,setSubscriberTypes]=useState([])
    
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        // setDetailedBOQ(getDetailedBOQ());
        setDetailedBOQ(getDetailedBOQNew(props.mapData.store[0].data))
        getSubProjects();
        getAssociatedIndividual();
    },[])
    const distBtwPoints = (coords1, coords2) => {
        const R = 6371e3; // metres
        const φ1 = parseFloat(coords1.lat) * Math.PI / 180; // φ, λ in radians
        const φ2 = parseFloat(coords2.lat) * Math.PI / 180;
        const Δφ = (parseFloat(coords2.lat) - parseFloat(coords1.lat)) * Math.PI / 180;
        const Δλ = (parseFloat(coords2.lng) - parseFloat(coords1.lng)) * Math.PI / 180;
      
        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
           Math.cos(φ1) * Math.cos(φ2) *
           Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      
        return R * c; // in metres
    }
    console.log(newAlreadyAssociated)

    
    const getDetailedBOQ = ()=>{
        let pipeDesc = {};
        let countDesc = {};
        let MHContainer = [];
        let PoleContainer = [];
        // layers[0].map((layer,layerIndex)=>{
        props.mapData.store[0].data.map((route,routeIndex)=>{
            route.markers.map((marker,markerIndex)=>{
                if(marker.type === 'Manhole'){
                    if(countDesc[marker.type]){
                        countDesc[marker.type] = countDesc[marker.type]+1;
                    }
                    else{
                        countDesc[marker.type] = 1;
                    }
                    MHContainer.push(marker);
                }
                else if(marker.type === 'Pole'){
                    if(countDesc[marker.type]){
                        countDesc[marker.type] = countDesc[marker.type]+1;
                    }
                    else{
                        countDesc[marker.type] = 1;
                    }
                    PoleContainer.push(marker);
                }
            })
        })
        if(MHContainer.length > 0){
            for(let i = 0;i < MHContainer.length; i = i+1){
                if(i+1 < MHContainer.length){
                    MHContainer[i].pipeContainers.C.map((innerItem,innerIndex)=>{
                        if(pipeDesc[innerItem.PipeType]){
                            pipeDesc[innerItem.PipeType].push({
                                quantity:innerItem.Quantity,
                                length : distBtwPoints(
                                {
                                    lat:MHContainer[i].lat,
                                    lng:MHContainer[i].lng
                                },{
                                    lat:MHContainer[i+1].lat,
                                    lng:MHContainer[i+1].lng
                                })
                            }) 
                        }
                        else{
                            pipeDesc[innerItem.PipeType] = [{
                                quantity:innerItem.Quantity,
                                length : distBtwPoints(
                                {
                                    lat:MHContainer[i].lat,
                                    lng:MHContainer[i].lng
                                },{
                                    lat:MHContainer[i+1].lat,
                                    lng:MHContainer[i+1].lng
                                }
                                )
                            }]
                        }
                        
                    })
                }
            }
        }
          
        return({
            pipeDesc:pipeDesc,
            countDesc:countDesc
        })
    }

    const getDetailedBOQNew = ()=>{
        let pipeDesc = {};
        let countDesc = {};
        let MHContainer = [];
        let PoleContainer = [];
        let initialIndex = 0;
        // layers[0].map((layer,layerIndex)=>{
        props.mapData.store[0].data.map((route,routeIndex)=>{
            route.markers.map((marker,markerIndex)=>{
                if(marker.type === 'Manhole'){
                    if(countDesc[marker.type]){
                        countDesc[marker.type] = countDesc[marker.type]+1;
                    }
                    else{
                        countDesc[marker.type] = 1;
                    }
                    MHContainer.push(marker);
                }
                else if(marker.type === 'Pole'){
                    if(countDesc[marker.type]){
                        countDesc[marker.type] = countDesc[marker.type]+1;
                    }
                    else{
                        countDesc[marker.type] = 1;
                    }
                    PoleContainer.push(marker);
                }
            })
        })

        props.mapData.store[0].data.map((route,routeIndex)=>{
            route.markers.map((marker,markerIndex)=>{
                console.log(marker);
                if (marker.type === 'Manhole'){
                    if(marker.pipeContainers.A.length > 0 ){
                        marker.pipeContainers.A.map(innerItem=>{
                            if(pipeDesc[innerItem.PipeType]){
                                pipeDesc[innerItem.PipeType].push({
                                    quantity:innerItem.Quantity,
                                    length : findDistance(route.markers,initialIndex,markerIndex)
                                })
                            }
                            else{
                                pipeDesc[innerItem.PipeType] = [{
                                    quantity:innerItem.Quantity,
                                    length : findDistance(route.markers,initialIndex,markerIndex)
                                }]
                            }
                        })
                        
                    }
                    initialIndex = markerIndex;
                }
                
            })
        })
        
        // if(MHContainer.length > 0){
        //     for(let i = 0;i < MHContainer.length; i = i+1){
        //         if(i+1 < MHContainer.length){
        //             MHContainer[i].pipeContainers.C.map((innerItem,innerIndex)=>{
        //                 if(pipeDesc[innerItem.PipeType]){
        //                     pipeDesc[innerItem.PipeType].push({
        //                         quantity:innerItem.Quantity,
        //                         length : distBtwPoints(
        //                         {
        //                             lat:MHContainer[i].lat,
        //                             lng:MHContainer[i].lng
        //                         },{
        //                             lat:MHContainer[i+1].lat,
        //                             lng:MHContainer[i+1].lng
        //                         })
        //                     }) 
        //                 }
        //                 else{
        //                     pipeDesc[innerItem.PipeType] = [{
        //                         quantity:innerItem.Quantity,
        //                         length : distBtwPoints(
        //                         {
        //                             lat:MHContainer[i].lat,
        //                             lng:MHContainer[i].lng
        //                         },{
        //                             lat:MHContainer[i+1].lat,
        //                             lng:MHContainer[i+1].lng
        //                         }
        //                         )
        //                     }]
        //                 }
                        
        //             })
        //         }
        //     }
        // }
          
        return({
            pipeDesc:pipeDesc,
            countDesc:countDesc
        })
    }

    const findDistance = (mapData,startIndex,endIndex)=>{
        console.log(mapData);
        let distance = 0;
        for (let i = startIndex; i< endIndex; i++){
            distance = distance + distBtwPoints(
                {
                    lat:mapData[i].lat,
                    lng:mapData[i].lng
                },{
                    lat:mapData[i+1].lat,
                    lng:mapData[i+1].lng
                }
            )
        }
        return distance
        
    }


    const getSubProjects = ()=>{
        setLoading(true);
        axios.get('/getSubProjects',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setSubProjects(response.data)
        })
    }

    const accumulateBOQData = (data,selectedKey,label)=>{
        console.log(data,selectedKey,label)
        let sum = 0;
        data.map((item)=>{
            sum = sum+ item[label]
        })
        return sum
    }

    const getAssociatedIndividual = async()=>{
        setLoading(true);
        await axios.get('/getProjectResourceAssociation',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setAlreadyAssociated(response.data.projectAssociation[0])
            setNewAlreadyAssociated(response.data.projectAssociation[0])
        })

        await axios.get('/getSubscriberTypes')    
            .then(response=>{
            setLoading(false);
           setSubscriberTypes(response.data.data)
            
            })
    }

    let container = null;
    
    if(detailedBOQ){
        let counter = 0
        container = (
            <div style={{display:'flex',width:'100%',justifyContent:'space-around',alignItems:'start'}}>
                <div style={{background:'white', width:'40%',margin:5}}>
                    <h3 style={{textAlign:'center'}}>Bill of Quantity for Project</h3>
                    <div>
                        <TableContainer component={Paper}style={{margin:'10px 0px'}}>
                            <Table aria-label="simple table" style={{width:'100%'}}>
                                <TableHead>
                                    <TableRow style = {{textAlign:'center', fontWeight:'bold'}}>
                                    
                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                            S.No
                                        </TableCell>
                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                            Description
                                        </TableCell>
                                        <TableCell style = {{textAlign:'center', fontWeight:'bold'}}>
                                            Units
                                        </TableCell>
                                        <TableCell style = {{textAlign:'center', fontWeight:'bold'}}>
                                            Quantity
                                        </TableCell>
                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                            Length
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(detailedBOQ.countDesc).map((key,keyIndex)=>{
                                        counter++;
                                        return(
                                            <TableRow>
                                                <TableCell>
                                                    {counter}
                                                </TableCell>
                                                <TableCell>
                                                    {key}
                                                </TableCell>
                                                <TableCell>
                                                    Nos.
                                                </TableCell>
                                                <TableCell style={{textAlign:'end'}}>
                                                    {detailedBOQ.countDesc[key]}
                                                </TableCell>
                                                <TableCell  style={{textAlign:'center'}}>
                                                    -NA-
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    {Object.keys(detailedBOQ.pipeDesc).map((key,keyIndex)=>{
                                        counter++;
                                        return(
                                            <TableRow>
                                                <TableCell>
                                                    {counter}
                                                </TableCell>
                                                <TableCell>
                                                    {key}
                                                </TableCell>
                                                <TableCell>
                                                    meters
                                                </TableCell>
                                                <TableCell style={{textAlign:'end'}}>
                                                    {accumulateBOQData(detailedBOQ.pipeDesc[key],key,'quantity')}
                                                </TableCell>
                                                <TableCell style={{textAlign:'end'}}>
                                                    {accumulateBOQData(detailedBOQ.pipeDesc[key],key,'length').toFixed(2)}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>    
                    </div>
                </div>
                <div style={{background:'white',margin:5, width:'40%'}}>
                    <h3 style={{textAlign:'center'}}>Available Resources for Project</h3>
                    {newAlreadyAssociated && newAlreadyAssociated.resources.length > 0 ?(
                            <div>
                            <TableContainer  component={Paper}style={{margin:'10px 0px'}}>
                                <Table>
                                    <TableHead>
                                        <TableRow style = {{textAlign:'center', fontWeight:'bold'}}>
                                            <TableCell style = {{textAlign:'center', fontWeight:'bold'}} >Name</TableCell>
                                            <TableCell style = {{textAlign:'center', fontWeight:'bold'}} >Domain</TableCell>
                                            <TableCell style = {{textAlign:'center', fontWeight:'bold'}} >SubscriberType</TableCell>
                                            {/* {subscriberTypes.length>0?
                                            <TableCell><FormControl style={{padding:'0px 10px', minWidth:150, marginTop:8, marginBottom:8}}>
                                            <InputLabel id="demo-simple-select-label">Subscriber Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedSubscriberType}
                                                label="Tehsil"
                                                // style={{padding:'0px 10px'}}
                                                size='small'
                                                onChange={(e)=>{
                                                    if(!e.target.value){
                                                        setNewAlreadyAssociated(alreadyAssociated)
                                                    }
                                                    else{
                                                    setSelectedSubscriberType(e.target.value)
                                                    let resource=[]
                                                    let vendor=[]
                                                    for(let i=0;i<alreadyAssociated.resources.length;i++){
                                                        if(alreadyAssociated.resources[i].subscriberTypeID===e.target.value){
                                                            resource.push(alreadyAssociated.resources[i])
                                                            vendor.push(alreadyAssociated.vendorMaster[i])
                                                        }
                                                    }
                                                    let newObj=newAlreadyAssociated
                                                    newObj.resources=resource
                                                    newObj.vendorMaster=vendor
                                                    setNewAlreadyAssociated(newObj)}
                                                }}
                                            >
                                            
                                            <MenuItem value = {null}>All Type Subscribers</MenuItem>
                                                {subscriberTypes!==undefined?subscriberTypes?.map(item=>{
                                                    return(
                                                        <MenuItem value = {item?.subscriberTypeID}>{item?.subscriberTypeName}</MenuItem>
                                                    )
                                                }):<div></div>}
                                            </Select>
                                            </FormControl></TableCell>:"no Subscriber Type"} */}
                                            <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
  {newAlreadyAssociated ? (
    newAlreadyAssociated?.resources?.map((item, index) => (
      <TableRow key={index}>
        <TableCell>{newAlreadyAssociated.vendorMaster[index].name||""}</TableCell>
        <TableCell>{newAlreadyAssociated.vendorMaster[index].domainID||''}</TableCell>
        <TableCell>{item.subscriberTypeName||""}</TableCell>
        <TableCell>
          {newAlreadyAssociated.vendorMaster[index].resourceTypeID === 'I' ? 'Individual' : 'Company'}||""
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={4}>No Resource associated</TableCell>
    </TableRow>
  )}
</TableBody>

                                </Table>
                            </TableContainer>
                        </div>
                    ):(
                        <div style={{textAlign:'center'}}>
                            <p> No resource associated with the project</p>
                            <Button 
                                style={{background:'teal', color:'white'}}
                                onClick={()=>props.getData(2)}
                            >Associate Resources</Button>
                        </div>
                    )}
                    
                </div>
                {/* <ProjectAssociated/> */}
                <div style={{background:'white',margin:5, width:'30%'}}>
                    <h3 style={{textAlign:'center'}}>Sub-Projects</h3>
                        {subProjects && subProjects.subProject.length > 0 ?(
                            <div>
                                {subProjects.subProject[0].data.map(item=>{
                                    console.log(item)
                                    counter = 0
                                    return(
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <p style={{color:'teal', margin:0}}>Sub-Project {item.subProjectNo}</p>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p>Bill of Quantity</p>
                                                <TableContainer component={Paper}style={{margin:'10px 0px'}}>
                                                    <Table aria-label="simple table" style={{width:'100%'}}>
                                                        <TableHead>
                                                            <TableRow style = {{textAlign:'center', fontWeight:'bold'}}>
                                                            
                                                                <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                                    S.No
                                                                </TableCell>
                                                                <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                                    Description
                                                                </TableCell>
                                                                <TableCell style = {{textAlign:'center', fontWeight:'bold'}}>
                                                                    Units
                                                                </TableCell>
                                                                <TableCell style = {{textAlign:'center', fontWeight:'bold'}}>
                                                                    Quantity
                                                                </TableCell>
                                                                <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                                                    Length
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {Object.keys(item.BOQ.countDesc).map((key,keyIndex)=>{
                                                                if(item.BOQ.countDesc[key].length > 0){
                                                                    counter++;
                                                                    return(
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                {counter}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {key}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Nos.
                                                                            </TableCell>
                                                                            <TableCell style={{textAlign:'end'}}>
                                                                                {item.BOQ.countDesc[key].length}
                                                                            </TableCell>
                                                                            <TableCell  style={{textAlign:'center'}}>
                                                                                -NA-
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                }
                                                                
                                                            })}
                                                            {Object.keys(item.BOQ.pipeDesc).map((key,keyIndex)=>{
                                                                counter++;
                                                                return(
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            {counter}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {key}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            meters
                                                                        </TableCell>
                                                                        <TableCell style={{textAlign:'end'}}>
                                                                            {accumulateBOQData(item.BOQ.pipeDesc[key],key,'quantity')}
                                                                            {/* {item.BOQ.pipeDesc[key][0].quantity} */}
                                                                        </TableCell>
                                                                        <TableCell style={{textAlign:'end'}}>
                                                                            {accumulateBOQData(item.BOQ.pipeDesc[key],key,'length').toFixed(2)}
                                                                            {/* {item.BOQ.pipeDesc[key][0].length.toFixed(2)} */}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                            </div>
                        ):(
                            <div style={{textAlign:'center'}}>
                                <p> No sub-project created</p>
                                <Button 
                                    style={{background:'teal', color:'white'}}
                                    onClick={()=>props.getData(1)}
                                >Create Sub-Project</Button>
                            </div>
                        )}   
                </div>
                    
            </div>
        )
    }

    return (
        <div>
            {container}
        </div>
    )
}

export default Planning