import React,{useEffect,useState} from 'react';
import axios from "../../../../axios_tteg";
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
    Polyline,
  } from "@react-google-maps/api";
    import classes from "../../../Maps/Map/MapCheck.module.css";
    import mapStyles from "../../../Maps/Map/mapStyles";
    import satellite from "../../../../assets/images/satellite.png";
    import roadMap from "../../../../assets/images/roadMap.png";
    import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
    import CloseIcon from '@mui/icons-material/Close';

  const libraries = ["places"];

  const mapContainerStyle = {
    height: "90vh",
    width: "100%",
  };

const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };

  const mapViewStyle = {
    width:50,
    margin:10,
    boxShadow: '0 2px 3px #ccc',
    border: '1px solid #eee',
    cursor:"pointer"
  }
const LoginLocationLog = (props)=>{
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyC_kRxUGZMpYA361uWGeGohZW5PYqCoj4k",
        libraries,
    });
    const [locations,setLocations] = useState([]);
    const [mapView,setMapView] = React.useState("roadmap");
    const [center,setCenter] = useState(null);
    const [selected,setSelected] = useState(null);
    const [selectedVendorDetails,setSelectedVendorDetails] = useState(null);
    
    useEffect(()=>{
        axios.get('/getLast10LoginLogs',{
            params:{
                resourceID:props.resourceID
            }
        })
        .then(response=>{
            console.log(response);
            if (response.data.length > 0)
                setCenter({ lat: parseFloat(response.data[0].latitude), lng: parseFloat(response.data[0].longitude) })
            setLocations(response.data);
        })
        .catch(e=>{
            console.log(e)
        })
    },[])

    // const getLocationLog = (resourceID)=>{
    //     axios.get('/getLast10LoginLogs',{
    //         params:{
    //             resourceID:resourceID
    //         }
    //     })
    //     .then(response=>{
    //         console.log(response);
    //         setLocations(response.data);
    //     })
    //     .catch(e=>{

    //     })
    // }

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    const getResource  = (resourceID)=>{
        props.associatedAssets.map(item=>{
            if(item.individualID === resourceID){
                setSelectedVendorDetails(item.VendorDetails[0])
            }
        })
    }

    const getDomainName = (domainID)=>{
        let tempDomainName = null;
        props.inputPIUtility.domain.map(item=>{

            if(item.domainID === domainID){
                tempDomainName = item.domainDescription.domainName
                // return (item.domainDescription.domainName);
            }
        });
        return tempDomainName
    }

    const getSubscriberType = (subscriberTypeID)=>{
        let tempSubscriberName = null;
        props.inputPIUtility.subscriberType.map(item=>{
            if(item.subscriberTypeID === subscriberTypeID){
                tempSubscriberName = item.subscriberTypeName
                // return (item.domainDescription.domainName);
            }
        });
        return tempSubscriberName
    }


    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";


    return(
        <div>
            <ContextMenuTrigger id="contextmenu">
                
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    mapTypeId = {mapView}
                    onLoad={onMapLoad} // when map loads it should be in the browserLocation
                    zoom={18}
                    options={options}
                    clickableIcons = {false}
                    center={center}
                >,
                    <CloseIcon onClick = {()=>props.getData('close')} style = {{cursor:'pointer', position:'absolute', top:'0rem', right:'0rem', color:'white'}}/>
                    {
                        locations.map((item,index)=>{
                            return(
                                <Marker
                                    draggable = {false}
                                    label = {item.resourceID}
                                    key={index}
                                    position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
                                    onClick = {()=>{
                                        setSelected({
                                            resourceID:item.resourceID,
                                            position:{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }
                                        })
                                        // getResource(item.resourceID);
                                    }}
                                />
                            )
                            
                        })
                    }
                    {/* {
                        selected ?(
                            <InfoWindow
                                position = {selected.position}
                                onCloseClick={() => {
                                    setSelected(null);
                                  }}
                            >
                                <div>
                                </div>
                            </InfoWindow>
                        ):null
                    } */}
                    <div 
                        style={
                        {position:"absolute",
                        bottom:'2rem',right:'4rem',
                        background:'white',
                        borderRadius:5
                        }}>
                        <div style={{display:'flex'}}>
                        <img 
                            src = {roadMap} 
                            alt = "RoadMap"
                            style = {
                            mapView === 'roadmap' ? 
                            mapViewStyle: {width:50,margin:10,cursor:"pointer"}
                            } 
                            onClick = {()=>setMapView('roadmap')}/>
                        <img 
                            src = {satellite} 
                            alt = "Satellite"
                            style = {
                            mapView === 'satellite' ?
                            mapViewStyle: {width:50,margin:10,cursor:"pointer"}
                            } 
                            onClick = {()=>setMapView('satellite')}/>
                        </div>
                    </div>
                </GoogleMap>
            </ContextMenuTrigger>
            
        </div>
    )
}

export default LoginLocationLog