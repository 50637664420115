import React,{useEffect, useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SimpleDenseTable from './SimpleDenseTable';
import TextField from '@mui/material/TextField';
import AutoSelectBox from "./AutoSelectBox";
import Button from '@mui/material/Button';
import axios from '../../../../axios_tteg';
// const unitOptions=[
//     {
//         value:'HDPE - 32mm',
//         display:'HDPE - 32mm'
//     },
//     {
//         value:'HDPE - 40mm',
//         display:'HDPE - 40mm'
//     },
//     {
//         value:'GI - 100mm',
//         display:'GI - 100mm'
//     }
// ]
const Accordian = (props)=>{
    const [specifications,setSpecifications] = React.useState({
        Quantity:null,
        PipeType :null
    })
    const [tempPipeContainer,setTempPipeContainer] = React.useState(props.accordianData)
    const [materialItem,setMaterialItem] = useState([]);
    useEffect(()=>{
        getMaterialItem();
    },[])
    const getMaterialItem = ()=>{
        axios.get('/getAssetSubComponent',{
          params:{
            subComponentSubCategoryID:props.assetSubComponentSubCategory,
            subComponentCategoryID:'Material01'
          }
        })
        .then(response=>{
          setMaterialItem(response.data.assetSubComponent);
        })
        .catch(e=>console.log(e))
       }
    const specificationHandler =  ((data,type)=>{
        let tempSpecification = {...specifications}
        tempSpecification[type] = data
        setSpecifications(tempSpecification)
    })
    const saveSpecification = ((e)=>{
        const key = e.target.value
        if(specifications.Quantity>0 && specifications.PipeType){
            const container = {...tempPipeContainer}
            container[key].push(specifications)
            setTempPipeContainer(container)
        }       
    })
    const deleteSpecification = (e) => {
        const key = e.target.attributes.getNamedItem("data-key").value
        const index = e.target.attributes.getNamedItem("data-index").value
        const tempContainer = {...tempPipeContainer}
        tempContainer[key].splice(index,1)
        setTempPipeContainer(tempContainer)
    }
    return(
        <div>
        {
        Object.entries(props.accordianData).map(([key,value])=>{
            console.log(key)
            return(
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
            <Typography>{key}</Typography>
           </AccordionSummary>
                <AccordionDetails>
                    <div style = {{display:'flex'}}>
                        <AutoSelectBox
                            options = {
                                materialItem && materialItem.map(item1=>{
                                    return(
                                        {
                                            value:`${item1.assetSubComponentName} - ${item1.assetSubComponentVarieties}`,
                                            display:`${item1.assetSubComponentName} - ${item1.assetSubComponentVarieties}`
                                        }
                                    )
                                })
                            }
                            value = {specifications.PipeType}
                            label = 'Pipe Type'
                            getData = {(data)=>specificationHandler(data,'PipeType')}
                        />
                        <TextField 
                            id="outlined-basic" 
                            label="Quantity" 
                            variant="outlined" 
                            size="small" 
                            type="number"
                            min="0"
                            style = {{margin:10,width:100}} 
                            value = {specifications.Quantity}
                            onChange = {(e)=>specificationHandler(parseInt(e.target.value),'Quantity')}
                        />
                        <Button 
                            value = {key}
                            variant="contained"     
                            style = {{margin: 10 }} 
                            onClick={(e)=>{
                                if(props.edit && (key === 'A' || key === 'C')){
                                    saveSpecification(e)
                                }
                                else if(props.edit && (key === 'B' || key === 'D')){
                                    alert('Please create a new route to insert asset');
                                }
                                else{
                                    alert('You don`t have the access to add asset')
                                }
                            }}    
                        >+</Button>
                    </div>
                    <Typography>
                        {/*item.description*/}
                        <SimpleDenseTable
                            col = {['PipeType','Quantity']}
                            rows = {tempPipeContainer[key]}
                            deleteSpecification = {deleteSpecification}
                            holeType = {key}
                            edit = {props.edit}
                        />
                    </Typography>
                </AccordionDetails>
        </Accordion>
            )
        })
    }
    <Button onClick ={()=>{
        if(props.edit){
            props.handleAccordianData(tempPipeContainer,props.routeIndex)
        }
        else{
            alert('You don`t have the access to submit');
        }
        }}>Submit</Button>
    <Button onClick ={()=>{
        if(props.edit){
            props.deleteMarker(props.routeIndex,props.markerIndex)
        }
        else{
            alert('You don`t have the access to delete');
        }
        }}>Delete</Button>
    </div>
    )
}

export default Accordian