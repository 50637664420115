import React,{useCallback, useEffect, useState} from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MultipleSelectChip from "../../../UI/MultipleSelect/MultipleSelect";
import axios from "../../../../axios_tteg";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Pagination from '@mui/material/Pagination';
import Dialog from '@mui/material/Dialog';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from "universal-cookie";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MapContainer from "../../../Maps/MapContainer";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import classes from "./tabPanel1.module.css";
import EnquiryLocation from "./enquiryLocation";
import {  Grid, Typography } from '@mui/material';
import { DatePicker } from '@mui/lab';
const cookies = new Cookies();

const Assignment = (props)=>{
    const [cases,setCases] = useState(null);
    const [inputPIUtility,setInputPIUtility] = useState(null);
    const [openResourceLocation,setOpenResourceLocation] = useState(false);
    const [associatedAssets,setAssociatedAssets] = useState(null);
    const [goToMap,setGoToMap] = useState(false);
    const [goToCaseID,setGoToCaseID] = useState(null);
    const [goToSurveyour,setGoToSurveyour] = useState(false);
    const [selelctedCaseDetail,setSelectedCaseDetail] = useState(null);
    const [surveyDetails,setSurveyDetails] = useState(null);
    const [error,setError] = useState(null);
    const [allResource,setAllResource] = useState([]);
    const [filteredResource,setFilteredResource] = useState([]);
    const [loading,setLoading] = useState(false);
    const [startDate,setStartDate] = useState(new Date());
    const [endDate,setEndDate] = useState(new Date());
    const [minStartDate,setMinStartDate] = useState(null);
    const [maxEndDate,setMaxEndDate] = useState(null);

    const innerWidth =  window.innerWidth;

    useEffect(()=>{
        getCases();
        getAssociatedSurveyor();
    },[])

    const getCases = ()=>{
        setLoading(true);
        axios.get('/getCases')
        .then(response=>{
            setLoading(false)
            setCases(response.data.cases)
            console.log(cases)
        })
        .catch(e=>{
            setLoading(false);
        })
    }

    const goToCase = (caseDetails)=>{
        // if(innerWidth <= 768){
        //     alert("Please use large screen device for exploring the assignment.")
        // }
        // else{
            setGoToCaseID(caseDetails);
            setGoToMap(true);
        // }
            
    }

    const addSurveyour = async (caseDetails) => {
        setFilteredResource([]);
        let filteredResources = [];
    
        for (const item of allResource) {
            const res = await checkValidSurveyor(item);
            if (res) {
                filteredResources.push(item);
            }
        }
        setFilteredResource(filteredResources);
    
        if (caseDetails.caseResourceAssociation.length > 0) {
            let tempDataToSend = { ...dataToSend };
            caseDetails.caseResourceAssociation.forEach(innerItem => {
                const role = innerItem.description.role;
                tempDataToSend[role] = {
                    caseID: caseDetails.caseID,
                    resourceID: innerItem.resourceID,
                    description: innerItem.description,
                    valid: true
                };
            });
            setDataToSend(tempDataToSend);
        }
    
        setSelectedCaseDetail(caseDetails);
        setGoToSurveyour(true);
    };
    const handleStartDateChange = (newDate) => {
        setStartDate(newDate);
    
        // Clear end date if it's before the new start date
        if (endDate && newDate && newDate > endDate) {
          setEndDate(null);
        }
      };
    
      const handleEndDateChange = (newDate) => {
        setEndDate(newDate);
    
        // Clear start date if it's after the new end date
        if (startDate && newDate && newDate < startDate) {
          setStartDate(null);
        }
      };
    
    
    

    const getAssociatedSurveyor = ()=>{
        setLoading(true);
        axios.get('/getAssociatedSurveyor',{
            params:{
                companyID:cookies.get('userData').resourceID,
                subscriberTypeID:'F-005'
            }
        })
        .then(response=>{
            let tempAllResource = []
            response.data.association.map( async item=>{
                tempAllResource.push(item);
            })
            setAllResource(tempAllResource);
            setLoading(false);
        })
        .catch(e=>console.log(e))
    }

    const handleSelectChange = ((caseID,subscriberTypeID,key,value,roleName)=>{
        let tempDataToSend = {... dataToSend};
        if(key === 'resource'){
            tempDataToSend[subscriberTypeID].resourceID = value;
            tempDataToSend[subscriberTypeID].caseID = caseID;
            filteredResource.map(item=>{
                if(item.individualID === value){
                    setMinStartDate((new Date() > new Date(item.startDate)) ? (new Date()) : (new Date(item.startDate)));
                    setMaxEndDate(new Date(item.endDate));
                }
            })
        }
        else if(key === 'access'){
            tempDataToSend[subscriberTypeID].description = {
                role:subscriberTypeID,
                roleName:roleName,
                access:value
            }
        }
        if(tempDataToSend[subscriberTypeID].resourceID && tempDataToSend[subscriberTypeID].description.access){
            tempDataToSend[subscriberTypeID].valid = true
        }
        else{
            tempDataToSend[subscriberTypeID].valid = false
        }
        setDataToSend(tempDataToSend);
    })

    const assignResources = (subscriberTypeID)=>{
        setLoading(true);
        if(dataToSend[subscriberTypeID].valid){
            axios.post('/caseResourceAssociation',{
                association:dataToSend[subscriberTypeID],
                startDate:startDate,
                endDate:endDate,
                log:{
                    caseID:selelctedCaseDetail.caseID,
                    description:'Surveyor is added',
                    status:{
                        planning:subscriberTypeID === 'F-003' ? 1:0,
                        surveyour:subscriberTypeID === 'F-005' ? 1:0
                    },

                }
            })
            .then(response=>{
                if(response.status === 200){
                    setLoading(false);
                    setGoToSurveyour(false);
                    getCases();
                    clearValue();
                }
            })
            .catch(e=>{
                setLoading(false);
                setError(e.response.data.message);
            })
        }
        else{
            clearValue();
            setLoading(false);
            setError('All data are compulsary');
        }
        
    }

    const clearValue = ()=>{
        let tempDataToSend = {... dataToSend};
        
        Object.keys(tempDataToSend).map(key=>{
            tempDataToSend[key].caseID = null;
            tempDataToSend[key].resourceID = null;
            tempDataToSend[key].description.role = null;
            tempDataToSend[key].description.access = null;
            tempDataToSend[key].valid = false
        })
        setDataToSend(tempDataToSend);
    }

    const [dataToSend,setDataToSend] = useState({
        'F-003':{
            caseID:null,
            resourceID:null,
            companyID:cookies.get('userData').resourceID,
            description:{
                role:null,
                roleName:'Planner',
                access:null,
            },
            valid:false,
        },
        'F-005':{
            caseID:null,
            resourceID:null,
            companyID:cookies.get('userData').resourceID,
            description:{
                role:null,
                roleName:'Surveyor',
                access:null,
            },
            valid:false,
        }
    });
    
    const handleChangeDuration = (key,value)=>{
        if(key === 'startDate'){
            setStartDate(value);
        }
        else if(key === 'endDate'){
            setEndDate(value)
        }
    }

    const checkValidSurveyor = async (associatee)=>{
        let startDate = new Date(new Date(associatee.startDate).setHours(0,0,0,0));
        let endDate = new Date(new Date(associatee.endDate).setHours(23,59,59,999));
        return new Promise((resolve,reject)=>{
            if(new Date() >= startDate && new Date() <= endDate){
                resolve(1);
                // axios.get('/checkmResourceCase',{
                //     params:{
                //         resourceID:associatee.individualID
                //     }
                // })
                // .then(response=>{

                //     if(response.data.length > 0)
                //         resolve(0)
                    
                //     else
                //         resolve (1)
                // })
                // .catch(e=>console.log(e))
            }
            else
                resolve (0)
        })
           
    }


    let container = null;
    if(goToMap && goToCaseID){
        container = (<MapContainer caseDetails = {goToCaseID} getData = {()=>{setGoToMap(false);setGoToCaseID(null)}}/>)
    }
    
    else if(cases){
        container = (
            
            cases.map(item=>{
                return(
                    <div style = {{margin:10}}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className={classes.AssignmentHeader}>
                                    <div>
                                        Title - {item.title}
                                    </div>
                                    <div>
                                        Case ID - {item.caseID}
                                    </div>
                                    <div>
                                        Company - {item.companyDetails[0].name}
                                    </div>
                                    <div>
                                        Domain - {item.domainDetails[0].domainDescription.domainName}
                                    </div>
                                </Typography>
                                <Typography className={classes.AssignmentDisplay}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style = {{textAlign:'left',padding: 10}}>
                                        <div>
                                            Latitude (degrees) - {parseFloat(item.location.latitude).toFixed(2)}
                                        </div>
                                        <div>
                                            Longitude (degrees) - {parseFloat(item.location.longitude).toFixed(2)}
                                        </div>
                                        <div>
                                            Distance (Km) - {parseFloat(item.location.distance).toFixed(2)}
                                        </div>
                                    </Typography>
                                    {item.caseResourceAssociation.length > 0 && item.caseResourceAssociation.map(innerItem=>{
                                        if(innerItem.companyID){
                                            if(innerItem.companyID === cookies.get('userData').resourceID ){
                                                return(
                                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style = {{textAlign:'left',padding: 10}}>
                                                        <div>
                                                            Role - {innerItem.description.roleName}
                                                        </div>
                                                        <div>
                                                            ResourceID - {innerItem.resourceID}
                                                        </div>
                                                        <div>
                                                            Name - {innerItem.vendorMaster[0].name}
                                                        </div>
                                                    </Typography>
                                                )
                                            }
                                        }
                                        else{
                                            return(
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style = {{textAlign:'left',padding: 10}}>
                                                    <div>
                                                        Role - {innerItem.description.roleName}
                                                    </div>
                                                    <div>
                                                        ResourceID - {innerItem.resourceID}
                                                    </div>
                                                    <div>
                                                        Name - {innerItem.vendorMaster[0].name}
                                                    </div>
                                                </Typography>
                                            )
                                        }
                                        
                                    })}
                                </Typography>
                            </CardContent>
                            <CardActions style={{justifyContent:'space-around'}}>
                                <Button size="small" onClick={()=>goToCase(item)}>Explore</Button>
                                <Button size="small" onClick={()=>addSurveyour(item)}>Add Surveyor</Button>
                            </CardActions>
                        </Card>
                    </div>        
                )
            })
        )
    }
    return(
        <div>
    {openResourceLocation ? (
        <EnquiryLocation 
            associatedAssets={cases} 
            getData={() => setOpenResourceLocation(false)}
        />
    ) : (
        <>
       
    
         <div >
                        <LocationOnIcon 
                            style = {{float:'right', color:'teal',fontSize:24, cursor:'pointer' }}
                            onClick = {()=>setOpenResourceLocation(true)}
                        />
                    </div>
            {container}
            {goToSurveyour && (
                <Dialog onClose={() => setGoToSurveyour(false)} open={goToSurveyour}>
                    <div>
                        <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <DialogTitle>{selelctedCaseDetail.title}</DialogTitle>
                            <CloseIcon onClick={() => setGoToSurveyour(false)} style={{ padding: 10, cursor: 'pointer' }} />
                        </Typography>
                        <Typography>
                            <table style={{ width: '100%', padding: 10, fontSize: 12, background: 'aliceblue' }}>
                                <tr>
                                    <td>CaseID</td>
                                    <td>{selelctedCaseDetail.caseID}</td>
                                </tr>
                                <tr>
                                    <td>Title</td>
                                    <td>{selelctedCaseDetail.title}</td>
                                </tr>
                                <tr>
                                    <td>Domain</td>
                                    <td>{selelctedCaseDetail.domainDetails[0].domainDescription.domainName}</td>
                                </tr>
                                <tr>
                                    <td>Company</td>
                                    <td>{selelctedCaseDetail.companyDetails[0].name}</td>
                                </tr>
                            </table>
                        </Typography>
                        <h3 style={{ padding: 10, margin: 0, fontSize: 14 }}>Resources</h3>
                        <Typography>
                            <FormControl style={{ maxWidth: 520 }}>
                                <Typography style={{ background: 'aliceblue' }}>
                                    <div className={classes.AddSurveyorContainer}>
                                        <TextField
                                            id="outlined-multiline-static"
                                            value="Surveyor"
                                            style={innerWidth > 768 ? { margin: 5, width: 150 } : { margin: 5, width: 225 }}
                                            disabled
                                            size="small"
                                        />
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={dataToSend['F-005'].resourceID}
                                            label="Resource"
                                            onChange={(e) => handleSelectChange(selelctedCaseDetail.caseID, 'F-005', 'resource', e.target.value)}
                                            style={innerWidth > 768 ? { margin: 5, width: 150 } : { margin: 5, width: 225 }}
                                            size="small"
                                        >
                                            {filteredResource.length > 0 && filteredResource.map(item => (
                                                <MenuItem key={item.individualResourceAssociation.resourceID} value={item.individualResourceAssociation.resourceID}>
                                                    {item.individualResourceAssociation.name} - {item.individualResourceAssociation.resourceID}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={dataToSend['F-005'].description.access}
                                            label="Access"
                                            onChange={(e) => handleSelectChange(selelctedCaseDetail.caseID, 'F-005', 'access', e.target.value, 'Surveyor')}
                                            style={innerWidth > 768 ? { margin: 5, width: 150 } : { margin: 5, width: 225 }}
                                            size="small"
                                        >
                                            <MenuItem value="editor">Editor</MenuItem>
                                            <MenuItem value="viewer">Viewer</MenuItem>
                                        </Select>
                                    </div>
                                    <div className={classes.AddSurveyorContainer}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                label="Start Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={startDate}
                                                minDate={minStartDate}
                                                maxDate={maxEndDate}
                                                onChange={(date) => handleChangeDuration('startDate', date)}
                                                renderInput={(params) => <TextField {...params} />}
                                                size="small"
                                            />
                                            <DesktopDatePicker
                                                label="End Date"
                                                inputFormat="DD/MM/YYYY"
                                                value={endDate}
                                                minDate={minStartDate}
                                                maxDate={maxEndDate}
                                                onChange={(date) => handleChangeDuration('endDate', date)}
                                                renderInput={(params) => <TextField {...params} />}
                                                size="small"
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                        <Button onClick={() => assignResources('F-005')}>
                                            {selelctedCaseDetail.caseResourceAssociation.length > 0 ? "Update" : "Add"}
                                        </Button>
                                    </div>
                                </Typography>
                            </FormControl>
                            <p style={{ textAlign: 'center', color: 'red', fontSize: 'small' }}>{error}</p>
                        </Typography>
                    </div>
                </Dialog>
            )}
        </>
    )}
</div>

)} 


export default Assignment