import React,{useEffect,useState} from 'react';
import axios from "../../../../axios_tteg";
import Cookies from "universal-cookie";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import Header from "../Header/Header";
import Box from '@mui/material/Box';
import {Button,CircularProgress} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DetailedProfileAdmin from './DetailedProfileAdmin';
import classes from "../../Components/Profile/Profile.module.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DetailedUserProfileAdmin from './DetailedUserProfileAdmin';

const cookies = new Cookies();

const UserProfileAdmin = (props)=>{
    const [profileData,setProfileData] = useState([]);
    const [open,setOpen] = useState(false);
    const [openDetailedProfile,setOpenDetailedProfile] = useState(false);
    const [caseDetails,setCaseDetails] = useState(null);
    const [loading,setLoading] = useState(false);
    const [goToDetailedProfile,setGoToDetailedProfile] = useState(false);

    useEffect(()=>{
        setLoading(true);
        axios.get('/getUserProfile',{
            params:{
                resourceID:props.resourceID
            }
        }).then((response)=>{
            setLoading(false)
            setProfileData(response.data.vendors)
        }).catch(e=>{
            console.log(e);
        })
    },[])

    const formatDate = (date)=>{
        return(
            `${new Date(date).getDate()}/${new Date(date).getMonth()+1}/${new Date(date).getFullYear()}`
        )
    }

    let container = null;
    if(loading){
        container = (
            <div style = {{position:'absolute', top:'50vh', left:'50%'}}>
                <CircularProgress />
            </div>
            )
    }
    else if(goToDetailedProfile){
        container = (
            <div>
                <DetailedUserProfileAdmin
                    resourceID = {props.resourceID}
                    resourceTypeID = {props.resourceTypeID}
                    getData = {(data)=>{setGoToDetailedProfile(false)}}
                />
            </div>
        )
    }
    else if(profileData.length > 0){
        container = (
            <div >
                <div >
                    {/* <div
                        className={classes.LeftPanel}
                    >
                        <div>
                            <AccountCircleIcon style = {{fontSize:200,color:'white', width:'100%'}}/>
                        </div>
                        <div style={{
                            margin: 20
                        }}>
                            <table
                                style={{width:'100%'}}
                            >
                                <tr>
                                    <td>
                                        <p style={{margin:2}}>Name</p>
                                    </td>
                                    <td>
                                        <p style={{margin:2}}>{profileData[0].name}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p style={{margin:2}}>ResourceID</p>
                                    </td>
                                    <td>
                                        <p style={{margin:2}}>{profileData[0].resourceID}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p style={{margin:2}}>Email</p>
                                    </td>
                                    <td>
                                        <p style={{margin:2}}>{profileData[0].email}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p style={{margin:2}}>Mobile No.</p>
                                    </td>
                                    <td>
                                        <p style={{margin:2}}>{profileData[0].mobile}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p style={{margin:2}}>Domain</p>
                                    </td>
                                    <td>
                                        <p style={{margin:2}}>{profileData[0].domainMaster[0].domainDescription.domainName}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p style={{margin:2}}>Resource Type</p>
                                    </td>
                                    <td>
                                        <p style={{margin:2}}>{profileData[0].resourceTypeMaster[0].resourceTypeName}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p style={{margin:2}}>Subscriber Type</p>
                                    </td>
                                    <td>
                                        <p style={{margin:2}}>{profileData[0].subscriberTypeMaster[0].subscriberTypeName}</p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <p 
                        style={{fontSize:'small', textAlign:'right', padding: 20,color: '#1976d2', cursor:'pointer'}}
                        onClick ={()=>
                            setOpenDetailedProfile(true)
                        }
                        >MORE DETAILS</p>
                    </div> */}
                    
                    <div>
                        <div style={{
                            display:'flex',
                            justifyContent:'space-between',
                        
                        }}>
                            <ArrowBackIcon onClick = {()=>props.getData('back')} style = {{cursor:'pointer', color: '#53459a'}}/>
                            <Button 
                                style = {{cursor:'pointer', color: 'white',background:'teal',padding:'5px 20px'}}
                                onClick = {()=>setGoToDetailedProfile(true)}
                            >Edit KYC</Button>
                        </div>
                        {props.resourceTypeID === 'C' ?(
                                <div
                                className={classes.LeftPanel1}
                            >
                                <p style={{textAlign:'center', fontWeight:'bold'}}>Associated Resources</p>    
                                <TableContainer component={Paper} style = {{maxHeight:500, overflow:'auto'}}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                        <TableRow >
                                            <TableCell style={{fontWeight:'bold'}}>Resource ID</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Name</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Contact No.</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Domain</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Type</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Start Date</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>End Date</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        { profileData[0].mIndividualAssociationLog.map((item) => (
                                            <TableRow
                                                key={item.individual[0].resourceID}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                className = {classes.User}
                                            >
                                                <TableCell >{item.individual[0].resourceID}</TableCell>
                                                <TableCell >{item.individual[0].name}</TableCell>
                                                <TableCell >{item.individual[0].mobile}</TableCell>
                                                <TableCell >{item.individual[0].domain[0].domainDescription.domainName}</TableCell>
                                                <TableCell >{item.individual[0].subscriberType[0].subscriberTypeName}</TableCell>
                                                <TableCell >{formatDate(item.startDate)}</TableCell>
                                                <TableCell >{formatDate(item.endDate)}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>   
                            </div>
                        ):(
                            <div
                                className={classes.LeftPanel1}
                            >
                                <p style={{textAlign:'center', fontWeight:'bold'}}>My Association</p>    
                                <table>
                                    <tr>
                                        <th>
                                            <p style={{margin:10, textAlign:'left'}}>Name</p>
                                        </th>
                                        <th>
                                            <p style={{margin:10, textAlign:'left'}}>Company ID</p>
                                        </th>
                                        <th>
                                            <p style={{margin:10, textAlign:'left'}}>Subscriber Type</p>
                                        </th>
                                        <th>
                                            <p style={{margin:10, textAlign:'left'}}>Domain</p>
                                        </th>
                                        <th>
                                            <p style={{margin:10, textAlign:'left'}}>Email</p>
                                        </th>
                                        <th>
                                            <p style={{margin:10, textAlign:'left'}}>Mobile</p>
                                        </th>
                                        <th>
                                            <p style={{margin:10, textAlign:'left'}}>Start Date</p>
                                        </th>
                                        <th>
                                            <p style={{margin:10, textAlign:'left'}}>End Date</p>
                                        </th>
                                    </tr>
                                    {
                                        profileData[0].myAssociationLog.map(item =>{
                                            return(
                                                <tr>
                                                    <td>
                                                        <p style={{margin:10}}>{item.company[0].name}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{margin:10}}>{item.company[0].resourceID}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{margin:10}}>{item.company[0].subscriberType[0].subscriberTypeName}</p>
                                                    </td>
                                                    <td>
                                                        <p style={{margin:10}}>{item.company[0].domain[0].domainDescription.domainName}</p>
                                                    </td>
                                                    <td>
                                                        {item.company[0].email ? (<p style={{margin:10}}>{item.company[0].email}</p>):(<p style={{margin:10}}>-</p>)}
                                                    </td>
                                                    <td>
                                                        <p style={{margin:10}}>{item.company[0].mobile}</p>
                                                    </td>
                                                    <td>
                                                        {item.startDate ? (<p style={{margin:10}}>{formatDate(item.startDate)}</p>):(<p style={{margin:10}}>-</p>)}
                                                    </td>
                                                    <td>
                                                        {item.endDate ? (<p style={{margin:10}}>{formatDate(item.endDate)}</p>):(<p style={{margin:10}}>-</p>)}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td></td>
                                    </tr>
                                </table>   
                                
                            </div>
                        )}
                        
                        <div
                            style = {{
                                margin: '1%',
                                boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
                                borderRadius:5,
                                height:'36vh',
                                overflow:'auto', 
                                padding:10,
                            }}
                        >
                            <p style={{fontWeight:'bold', textAlign:'center'}}>Case Associated</p>
                            <div
                                style = {{overflowX: 'auto',whiteSpace: 'nowrap'}}
                            >
                                {profileData[0].mResourceCase.map(item=>{
                                    return(
                                        <Card sx={{ width:275, display:'inline-block', margin:1, color:'#f2bdcd'}}>
                                            <CardContent style = {{padding:10}}>
                                                <Typography  color="text.primary" gutterBottom style = {{color: 'rgb(83, 69, 154)'}}>
                                                    {item.caseDetails[0].title}
                                                </Typography>
                                                <Typography color="text.secondary" gutterBottom style={{overflow:'auto'}}>
                                                    {item.caseDetails[0].caseDescription}
                                                </Typography>
                                                <Typography color="text.secondary" gutterBottom style={{overflow:'auto'}}>
                                                    {`Latitude - ${item.caseDetails[0].location.latitude.toFixed(2)}`}
                                                </Typography>
                                                <Typography color="text.secondary" gutterBottom style={{overflow:'auto'}}>
                                                    {`Longitude - ${item.caseDetails[0].location.longitude.toFixed(2)}`}
                                                </Typography>
                                                <Typography color="text.secondary" gutterBottom style={{overflow:'auto'}}>
                                                    {`Distance - ${item.caseDetails[0].location.distance} Km`}
                                                </Typography>
                                            </CardContent>
                                            {props.resourceTypeID === 'C' ? (
                                                <CardActions style = {{justifyContent:'space-between'}}>
                                                    {/* <Button size="small" onClick={()=>{
                                                        setOpen(true);
                                                        setCaseDetails({item})
                                                    }}>Explore</Button> */}
                                                    <Button size="small" onClick={()=>{
                                                        setOpen(true);
                                                        setCaseDetails(item)
                                                    }}>More Details</Button>
                                                </CardActions>
                                            ):null}
                                            
                                        </Card>
                                    )
                                })}
                            </div>
                            
                            
                        </div>
                    </div>
                        
                </div>
                
            </div>
            
        )
    }
    return (
        <div>
            
            
            {caseDetails ? (
                <div>
                    
                    <Dialog onClose={()=>setOpen(false)} open={open} maxWidth='fit-content'>
                        <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                            <DialogTitle></DialogTitle>
                            <CloseIcon onClick = {()=>setOpen(false)} style = {{padding:10, cursor:'pointer'}}/>
                        </Typography>
                        <Typography  style = {{ padding:10, background:'aliceblue'}} >
                            <Typography  color="text.primary" gutterBottom>
                                {caseDetails.caseDetails[0].title}
                            </Typography>
                            <Typography color="text.secondary" gutterBottom style={{overflow:'auto'}}>
                                {caseDetails.caseDetails[0].caseDescription}
                            </Typography>
                            <Typography color="text.secondary" gutterBottom style={{overflow:'auto'}}>
                                {`Latitude - ${caseDetails.caseDetails[0].location.latitude.toFixed(2)}`}
                            </Typography>
                            <Typography color="text.secondary" gutterBottom style={{overflow:'auto'}}>
                                {`Longitude - ${caseDetails.caseDetails[0].location.longitude.toFixed(2)}`}
                            </Typography>
                            <Typography color="text.secondary" gutterBottom style={{overflow:'auto'}}>
                                {`Distance - ${caseDetails.caseDetails[0].location.distance} Km`}
                            </Typography>
                        </Typography>
                        <Typography  style = {{ padding:10}}>
                            <table>
                                <tr>
                                    <th style = {{ padding:10}}>
                                        Subscriber Type
                                    </th>
                                    <th style = {{ padding:10}}>
                                        Name
                                    </th>
                                    <th style = {{ padding:10}}>
                                        Contact
                                    </th>
                                    <th style = {{ padding:10}}>
                                        Email
                                    </th>
                                    <th style = {{ padding:10}}>
                                        Resource ID
                                    </th>
                                </tr>
                                <tr>
                                    <td style = {{ padding:10}}>
                                        Planner
                                    </td>
                                    <td style = {{ padding:10}}>
                                        {caseDetails.planner[0].plannerDetails[0].name}
                                    </td>
                                    <td style = {{ padding:10}}>
                                        {caseDetails.planner[0].plannerDetails[0].mobile}
                                    </td>
                                    <td style = {{ padding:10}}>
                                        {caseDetails.planner[0].plannerDetails[0].email ?  caseDetails.planner[0].plannerDetails[0].email : '-'}
                                    </td>
                                    <td style = {{ padding:10}}>
                                        {caseDetails.planner[0].plannerDetails[0].resourceID}
                                    </td>
                                </tr>
                                <tr>
                                    <td style = {{ padding:10}}>
                                        Surveyor
                                    </td>
                                    <td style = {{ padding:10}}>
                                        {caseDetails.Individual_Details[0].name}
                                    </td>
                                    <td style = {{ padding:10}}>
                                        {caseDetails.Individual_Details[0].mobile}
                                    </td>
                                    <td style = {{ padding:10}}>
                                        {caseDetails.Individual_Details[0].email ?  caseDetails.Individual_Details[0].email : '-'}
                                    </td>
                                    <td style = {{ padding:10}}>
                                        {caseDetails.Individual_Details[0].resourceID}
                                    </td>
                                </tr>
                            </table>
                        </Typography>
                    </Dialog>
                </div>
                
            ):null}
            {container}
            {/* {openDetailedProfile ?  (<DetailedUserProfileAdmin resourceID = {props.resourceID} resourceTypeID = {props.resourceTypeID} />):(container)} */}
        </div>
    )
}

export default UserProfileAdmin