import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import classes from "./Personal.module.css";
import axios from "../../../axios_tteg";
import axiosExt from "axios"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";

export default function Personal(props) {
  console.log(props);
  const [entity, setEntity] = React.useState("");
  const [loading,setLoading] = useState(false);
  const [piData,setPIData] = useState({
    entityTypeID:{
      value:0,
      valid:false
    },
    address:{
      value:null,
      valid:false
    },
    pinCode:{
      value:null,
      valid:false
    },
    country:{
      value:null,
      valid:false
    },
    state:{
      value:null,
      valid:false
    },
    district:{
      value:null,
      valid:false
    },
    city:{
      value:null,
      valid:false
    },
    aadharCardNo:{
      value:null,
      valid:props.resourceTypeID === 'C'?true:false
    },
    vendorName:{
      value:null,
      valid:props.resourceTypeID === 'C'?false:true
    },
    educationDetails:{
        value:null,
        valid:props.resourceTypeID === 'I'?false:true
    },
    experience:{
        value:null,
        valid:props.resourceTypeID === 'I'?false:true
    },
    skillArea:{
        value:null,
        valid:props.resourceTypeID === 'I'?false:true
    },
    summary:{
        value:null,
        valid:true
    }
  })

  const [disabled,setDisabled] = useState(props.disabled);
  const [error,setError] = useState(null);

  useEffect(()=>{
    setLoading(true);
    axios.get('/registerPIUtility',{
      params:{
        resourceTypeID:props.resourceTypeID
      }
    })
    .then(response=>{
      setLoading(false);
      setEntity(response.data.entityType);
    })
    .catch(e=>console.log(e))
  },[])

  useEffect(()=>{
    axios.get('/getDetailedUserProfile', {
        params:{
            resourceID:props.resourceID
        }
    }).then((response)=>{
        console.log(response);
        let tempPIData = {... piData};
        if(response.data.vendors.length > 0){
         
            if(response.data.vendors[0].mUserPersonalInfo.length > 0){
                Object.keys(response.data.vendors[0].mUserPersonalInfo[0]).map(key=>{
                    if(tempPIData.hasOwnProperty(key)){
                        if(key === 'address'){
                            Object.keys(response.data.vendors[0].mUserPersonalInfo[0][key]).map(innerKey =>{
                                tempPIData[innerKey].value = response.data.vendors[0].mUserPersonalInfo[0][key][innerKey];
                                tempPIData[innerKey].valid = true;
                            })
                        }
                        else{
                            tempPIData[key].value = response.data.vendors[0].mUserPersonalInfo[0][key];
                            tempPIData[key].valid = true;
                        }
                        
                    }
                    
                })
            }
        }
        setPIData(tempPIData);
    }).catch(e=>console.log(e))
},[])


  const handleChange = (key,value)=>{
    const tempPIData = { ... piData};
    if(value){
      tempPIData[key].value = value;
      tempPIData[key].valid = true;
    }
    else{
      tempPIData[key].value = value;
      tempPIData[key].valid = false;
    }
    if(key === 'pinCode'){
      if(value.length === 6)
        getDetailsFromPinCode(value)
      else{
        tempPIData['state'].value = '';
        tempPIData['state'].valid = false;
        tempPIData['district'].value = '';
        tempPIData['district'].valid = false;
        tempPIData['city'].value = '';
        tempPIData['city'].valid = false;
        tempPIData['country'].value = '';
        tempPIData['country'].valid = false;
      }
    }
    setPIData(tempPIData);
  }

  const getDetailsFromPinCode = (pinCode) =>{
    setLoading(true);
    axiosExt.get(`https://api.postalpincode.in/pincode/${parseInt(pinCode)}`)
    .then(response=>{
      setLoading(false);
      if(response.data[0].PostOffice){
        const tempPIData = { ... piData};
        tempPIData['state'].value = response.data[0].PostOffice[0].State;
        tempPIData['state'].valid = true;
        tempPIData['district'].value = response.data[0].PostOffice[0].District;
        tempPIData['district'].valid = true;
        tempPIData['city'].value = response.data[0].PostOffice[0].Division;
        tempPIData['city'].valid = true;
        tempPIData['country'].value = response.data[0].PostOffice[0].Country;
        tempPIData['country'].valid = true;
        setPIData(tempPIData)
      }
      else{
        const tempPIData = { ... piData};
        tempPIData['state'].value = '';
        tempPIData['state'].valid = false;
        tempPIData['district'].value = '';
        tempPIData['district'].valid = false;
        tempPIData['city'].value = '';
        tempPIData['city'].valid = false;
        tempPIData['country'].value = '';
        tempPIData['country'].valid = false;
        setPIData(tempPIData)
      }

    }).catch(e=>{console.log(e)})
  }

  
  const submitHandler = ()=>{
    let isValid = true;
    Object.keys(piData).map(item=>{
      isValid = isValid && piData[item].valid
    });
    if(isValid){
      setLoading(true)
      axios.post('/registerPISubmit',{
        entityTypeID:piData.entityTypeID.value,
        address:{
          address:piData.address.value,
          pinCode:piData.pinCode.value,
          city:piData.city.value,
          district:piData.district.value,
          state:piData.state.value,
          country:piData.country.value,
        },
        aadharCardNo:piData.aadharCardNo.value,
        vendorName:piData.vendorName.value,
        resourceID:props.resourceID,
        educationDetails:piData.educationDetails.value,
        experience:piData.experience.value,
        skillArea:piData.skillArea.value,
        summary:piData.summary.value
      })
      .then(response=>{
        setLoading(false);
        if(response.status === 201){
          // setDisabled(true);
          props.getData({
            status:1,
            moveToScreen:2
          })
        }
        else{
          setError(response.data.error);
        }
      })
      .catch(e=>{
        setLoading(false);
        setError(e.response.data.error)
      })
    }
    else{
      setError('*Please fill appropraite data')
    }
  }

  console.log(piData);
  
  let container = null;
  if(loading || !entity){
    container = (
      <Box>
        <Paper elevation={24} style={{ minHeight:620, overflow:'auto'}}>
          <Grid className={classes.style}>
            <Grid>
              <p
                style={{
                  textAlign: "center",
                  padding: "20px 10px",
                  justifyContent: "center",
                  margin:'0px auto',
                  fontSize:24,
                  fontWeight:'bold'
                }}
              >
                Personal Information
                <hr style={{ width: "50%" }} />
              </p>
            </Grid>
            <Grid>
              <CircularProgress/>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    )
  }
  else{
    container = (
      <Box>
        <Paper elevation={24} style={{ minHeight:620,margin:10, overflow:'auto'}}>
          <Grid>
            <Grid >
              <Grid>
                <p
                  style={{
                    textAlign: "center",
                  padding: "20px 10px",
                  justifyContent: "center",
                  margin:'0px auto',
                  fontSize:24,
                  fontWeight:'bold'
                  }}
                >
                  Personal Information
                  <hr style={{ width: "50%" }} />
                </p>
              </Grid>
              <p style={{padding: '0 7.5%',color: 'red',fontSize: 'small'}}>Fields marked with * are compulsary</p>
              <Grid
                className={classes.InputStyle}
              >
                <Grid className={classes.InputBox}>
                  <Grid>
                    <TextField
                      style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                      id="filled-basic"
                      label="Pincode*"
                      variant="filled"
                      // disabled={disabled}
                      value={piData.pinCode.value}
                      autoComplete="off"
                      onChange={(e)=>handleChange('pinCode',e.target.value)}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                      id="filled-basic"
                      label="District*"
                      variant="filled"
                      disabled={true}
                      value={piData.district.value}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                      id="filled-basic"
                      label="Country*"
                      variant="filled"
                      disabled={true}
                      value={piData.country.value}
                    />
                  </Grid>
                  {props.resourceTypeID === 'C'?(
                    <Grid>
                      <TextField
                        style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                        id="filled-basic"
                        label="Single Point of Contact Name (as in Govt ID)*"
                        variant="filled"
                        // disabled={disabled}
                        value={piData.vendorName.value}
                        autoComplete="off"
                        onChange={(e)=>handleChange('vendorName',e.target.value)}
                      />
                    </Grid>
                  ):(
                    <Grid>
                      <TextField
                        style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                        id="filled-basic"
                        label="Aadhar Number*"
                        variant="filled"
                        // disabled={disabled}
                        value={piData.aadharCardNo.value}
                        autoComplete="off"
                        onChange={(e)=>handleChange('aadharCardNo',e.target.value)}
                      />
                    </Grid>
                  )}
                  {props.resourceTypeID === 'I'?(
                    <Grid>
                      <FormControl
                          variant="filled"
                          style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                      >
                          <InputLabel id="demo-simple-select-filled-label">
                              Educational Details*
                          </InputLabel>
                          <Select
                              labelId="demo-simple-select-filled-label"
                              id="demo-simple-select-filled"
                              value={piData.educationDetails.value}
                              onChange={(e)=>handleChange('educationDetails',e.target.value)}
                              autoComplete="off"
                          >
                              <MenuItem value='10th'>10th</MenuItem>
                              <MenuItem value='12th'>12th</MenuItem>
                              <MenuItem value='Graduate & above'>Graduate & above</MenuItem>
                          </Select>
                      </FormControl>
                    </Grid>
                  ):null}
                  {props.resourceTypeID === 'I' ? (
                    <Grid>
                      <TextField
                        style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                        id="filled-basic"
                        label="Skill Areas*"
                        variant="filled"
                        value={piData.skillArea.value}
                        onChange={(e)=>handleChange('skillArea',e.target.value)}
                        autoComplete="off"
                      />
                  </Grid>
                  ):null}
                </Grid>

                <Grid className={classes.InputBox}>
                  <Grid>
                    <TextField
                      style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                      id="filled-basic"
                      label="Village/Town/City*"
                      variant="filled"
                      disabled={true}
                      value={piData.city.value}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                      id="filled-basic"
                      label="State*"
                      variant="filled"
                      disabled={true}
                      value={piData.state.value}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                      id="filled-basic"
                      label="Address*"
                      variant="filled"
                      // disabled={disabled}
                      value={piData.address.value}
                      onChange={(e)=>handleChange('address',e.target.value)}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid>
                    <FormControl
                      variant="filled"
                      style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Entity Type*
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        // disabled={disabled}
                        value={piData.entityTypeID.value}
                        onChange={(e)=>handleChange('entityTypeID',e.target.value)}
                      >
                        {entity.map(item=>(
                            <MenuItem value={item.entityTypeID}>{item.entityTypeName}</MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  {props.resourceTypeID === 'I'?(
                    <Grid>
                      <TextField
                          style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                          id="filled-basic"
                          label="Experience in months*"
                          variant="filled"
                          value={piData.experience.value}
                          onChange={(e)=>handleChange('experience',e.target.value)}
                          autoComplete="off"
                      />
                    </Grid>
                  ):null}
                  {props.resourceTypeID === 'I'?(
                    <Grid>
                      <TextField
                          style={{ padding: "1% 1% 1% 1%", width: "70%" }}
                          id="filled-basic"
                          label="Summary"
                          variant="filled"
                          multiline
                          rows={4}
                          value={piData.summary.value}
                          onChange={(e)=>handleChange('summary',e.target.value)}
                          autoComplete="off"
                      />
                    </Grid>
                  ):null}
                </Grid>
              </Grid>
              <p style = {{fontSize:12, textAlign: 'center',color:'red', margin:5}}>{error}</p>
              <Grid style={{ textAlign: "center", padding: "2% 0% 2% 0%" }}>
                {/* <SvgButton>Continue</SvgButton> */}
                <Button
                  style={{ justifyContent: "center",padding:10 }}
                  type="submit"
                  color="primary"
                  variant="contained"
                  // disabled={disabled}
                  onClick={()=>submitHandler()}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
            {/* <p style={{color:'red', textAlign:'center'}}>Note : This can be skipped by toggling the left panel</p> */}
          </Grid>
        </Paper>
      </Box>
    )
    
  }

  return (
    <div>
      {container}
    </div>
  );
}