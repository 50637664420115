import React, { useState } from 'react';
import {
    Grid,
    Paper,
    TextField,
    Button,
    Select,
    Typography,
    Link,
    Box,
    MenuItem,
    InputLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    CircularProgress,
  
  } from "@mui/material";
import Logo from "../../assets/images/logo.png";
import classes from "./PreRegistration.module.css";

const PaidConfirmation = (props)=>{
    return(
        <div>
            <img src = {Logo} style = {{maxWidth:'100%', margin:'2%'}}/>
            <Box className={classes.PaidConformationContainer}>
                <Paper elevation={24} style={{margin:'auto' }}>
                    
                    <Grid style = {{ margin:'auto', padding:'3%'}}>
                        <Grid style={{textAlign :'center', alignItems:'center'}}>
                            <h2>Paid Confirmation</h2>
                        </Grid>
                        <Grid style={{padding:10,background:'aliceblue'}}>
                            <p>Thanks for subscribing to Basic plan. Our sales team will contact you for future process.</p>
                            <p style={{fontWeight:'bold'}}>We will ask for some basic data now. Pl contact our Sales team on 0124 – 4269903 in case of any support required.</p>
                            <p style={{textAlign:'justify'}}>Paid Subscription - The paid subscription is intended for commercial subscribers, that intend to track the occupancy and location of their assigned machines and resources. The subscription also includes the use of Execution Engine as well as paperless documentation engine. This subscription is useful for execution vendors as well as machine owners interessted in running Business Operations on their own. The subscription is on a per sector per month basis for every HDD or JCB machine. The initial Monthly fee is kept as INR 1000, payable in advance for the year. T-TEG will install a GPS tracker for every machine, capturing the basic location and Engine ON / OFF details with date and time stamps. As a part of platform service, you will be getting various enquiries from the market, both from Private and Government sectors. You also get access to Utility companies on the platform as well as participating OEM. In paid subscription, we capture your basic invoicing related information, as well as the details of main contact person. Please take time out to provide the required details accurately as the same information will be part of Auto invoices from the platform.</p>
                        </Grid>
                        <Grid style = {{padding: 20,display: 'flex',justifyContent: 'space-between'}}>
                            <div>
                                <Button variant="text" onClick = {()=>props.getData(2)}>Cancel</Button>
                            </div>
                            <div>
                                <Button variant="contained" onClick = {()=>props.getData(6)}>Submit</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    )
}

export default PaidConfirmation