import React, { useState } from 'react';
import {
    Grid,
    Paper,
    TextField,
    Button,
    Select,
    Typography,
    Link,
    Box,
    MenuItem,
    InputLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import classes from "./PreRegistration.module.css";

const OverView = (props)=>{
    let navigate = useNavigate();
    const [moveToSubscription,setMoveToSubscription] = useState(false);
    return(
        <div>
            <Box className={classes.OverviewContainer}>
                <Paper elevation={24} style={{padding:15 }}>
                    <img src = {Logo} style = {{maxWidth:'100%'}}/>
                    <Grid>
                        <Grid style={{textAlign :'center', alignItems:'center'}}>
                            <h3 >Thanks for your interest in T-TEG platform</h3>
                            <hr style={{ width: "50%" }} />
                            <div style={{background:'aliceblue',padding:10,margin:10,borderRadius:5,textAlign:'justify'}}>
                                <p>"T-TEG platform creates a large market for resources involved in deployment and operations of telecom, electricity and city gas field assets. Enrolling makes you a part of a growing “Market aligned virtual team” consisting of various stakeholders including participants from utility companies, original equipment manufacturers, project managers, Field execution vendors, Supervisors and other supporting staff. The platform shares information on opportunities, technology,</p> 
                                <p>The platform supports the subscribers through a secure structured information sharing, arranging for technical support, digital enablement for skilled and unskilled resources."</p>
                                <p>"We look forward to hosting you on the platform for mutual benefit. </p>
                                <h3 style={{width:'100%', textAlign:'center', background:'#947FBA', color:'white', padding:5}}>Subscription type</h3>
                                <table style={{border:'2px solid #aaa', margin:5, fontSize:14, width:'100%'}}>
                                    <tr style={{border:'2px solid #aaa', margin:5}}>
                                        <th style={{border:'2px solid #aaa', padding:5}}>Free - For individuals, machine owners </th>
                                        <th style={{border:'2px solid #aaa', padding:5}}>Paid - For entities seeking commercial business on platform </th>
                                    </tr>
                                    <tr style={{border:'2px solid #aaa', margin:5}}>
                                        <td style={{border:'2px solid #aaa', padding:5}}>
                                            1. Your window to the world, present your bio to our TEG market support ecosystem
                                        </td>
                                        <td style={{border:'2px solid #aaa', padding:5}}>
                                            1.Your window to the world, present your profile to our TEG utility ecosystem 
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid #aaa', margin:5}}>
                                        <td style={{border:'2px solid #aaa', padding:5}}>
                                            2. Get access to work opportunities in the market as a freelancer. 
                                        </td>
                                        <td style={{border:'2px solid #aaa', padding:5}}>
                                            2. Access to All India enquiries coming from T-TEG sales teams and utility Supply Chain Teams
                                        </td>
                                    </tr>
                                    <tr style={{border:'2px solid #aaa', padding:5}}>
                                        <td style={{border:'2px solid #aaa', padding:5}}>
                                            3. Context sensitive access to knowledge in your day to day technical work areas 
                                        </td>
                                        <td style={{border:'2px solid #aaa', padding:5}}>
                                            3. Access to your sub vendors – near location with timely availability
                                        </td>
                                    </tr>
                                </table>
                                <p style = {{fontWeight:'bold'}}>Click next to explore</p>
                            </div>
                        </Grid>
                        <Grid style = {{padding: '0 5%',display: 'flex',justifyContent: 'space-between'}}>
                            <div>
                                <Button variant="text" onClick={()=>navigate('/')}>Back</Button>
                            </div>
                            <div>
                                <Button variant="contained" onClick = {()=>props.getData(2)}>Next</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    )
}

export default OverView