import React,{useState} from 'react'
// import TextField from "@mui/material/TextField";
import classes from "./Explorer.module.css"
import { Checkbox, containerClasses } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import SimpleDenseTable from '../UI/SimpleDenseTable';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Cookies from "universal-cookie";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
// import Button from '@mui/material/Button';
const cookies = new Cookies();
const innerWidth = window.innerWidth;

const sortFunction = (a,b)=>{
  if (a.PipeType < b.PipeType)
    return -1;
  if (a.PipeType > b.PipeType)
 return 1;
  if(a.Quantity<b.Quantity) return -1
  return 1;
}
const equals = (a, b)=>{
  return JSON.stringify(a) === JSON.stringify(b) 
}

const distBtwPoints = (coords1, coords2) => {
  const R = 6371e3; // metres
  const φ1 = coords1.lat * Math.PI / 180; // φ, λ in radians
  const φ2 = coords2.lat * Math.PI / 180;
  const Δφ = (coords2.lat - coords1.lat) * Math.PI / 180;
  const Δλ = (coords2.lng - coords1.lng) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
     Math.cos(φ1) * Math.cos(φ2) *
     Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // in metres
}
const  Explorer=({markers,layers,getData,editableLayer, editLayerIndex, layerResourceID, openExplorer, manholeHover})=> {
  // const [masterSeg,setMasterSeg]= React.useState([]);
  const [segArr,setSegArr] = React.useState([]); 
  const [segProp,setSegProp] = React.useState({});
  const [selectIndex,setSelectIndex] = React.useState(-1);
  const [selectPointObj,setSelectPoint] = React.useState({});
  const [pointsDistance,setPointDist] = React.useState(null);
  const [showPipeSide,setShowPipeSide] = React.useState({
    A:{
      show:true,
    },
    B:{
      show:true
    },
    C:{
      show:true
    },
    D:{
      show:true
    }
  })

  const innerWidth = window.innerWidth;
  

  // const [editLayerIndex,setEditLayerIndex] =  useState(0);
  // const [editRouteIndex,setEditRouteIndex] =  useState(0);
  // const [viewLayerIndex,setViewLayerIndex] = useState(0);
  // const [viewRouteIndex,setViewRouteIndex] = useState(0);
  React.useEffect(() => {
    //  let tempMasterSeg = [],flag=1; 
    //  let WayPoint1 = markers ? markers[0] : null
    //  if(!WayPoint1) {
    //    flag=0;
    //    console.log("the plot should be started with way point");
    //  }
    //  else tempMasterSeg.push(WayPoint1);
    //  let markerIndex = 1,manholeIndex = 0;
    //  while(flag && markerIndex<markers.length && manholeIndex<manholes.length){
    //       let currWayPoint = markers[markerIndex],prevWayPoint = markers[markerIndex-1];
    //       let maxLat = Math.max(currWayPoint.lat,prevWayPoint.lat);
    //       let minLat = Math.min(currWayPoint.lat,prevWayPoint.lat);
    //       let maxLng = Math.max(currWayPoint.lng,prevWayPoint.lng);
    //       let minLng = Math.min(currWayPoint.lng,prevWayPoint.lng);
    //       if(minLat<=manholes[manholeIndex].lat<=maxLat && 
    //           minLng<=manholes[manholeIndex].lng<=maxLng 
    //         ){
    //           tempMasterSeg.push(manholes[manholeIndex]);
    //           manholeIndex+=1;
    //       }
    //       tempMasterSeg.push(currWayPoint);
    //       markerIndex+=1;
    //  }
    //  if(markerIndex<markers.length) {
    //    tempMasterSeg = tempMasterSeg.concat(markers.slice(markerIndex))
    //    console.log(markerIndex);
    //  }
    //  if(manholeIndex<manholes.length){
    //       tempMasterSeg = tempMasterSeg.concat(manholes.slice(manholeIndex))
    //       console.log(markerIndex);
    //  }
    //  console.log(tempMasterSeg);
    //  setMasterSeg(masterSeg);
    if(markers.length > 0){
      const tempSegArr = addSegments(markers);
      setSegArr(tempSegArr);
      setSelectIndex(-2)
      allSegInfo();
      return;
    }
    
  },[markers, layers])

  // useEffect(()=>{
  //   setOpenExplorer(openExplorer)
  // },[openExplorer])
  
  const addSegments =  (markers) => {
      let tempSeg = [];
      let tempSegArr = [];
      for(let i=0;i<markers.length;i++){
        tempSeg.push(markers[i]);
        if(markers[i].type==="Manhole"){
            let A =[...markers[i].pipeContainers.A],C = [...markers[i].pipeContainers.C]   
            A.sort(sortFunction);
            C.sort(sortFunction);
            if(!equals(A,C)){
                tempSegArr.push(tempSeg);
                tempSeg = []
                tempSeg.push(markers[i])
            }
        }
      }
      if(tempSeg.length) 
        tempSegArr.push(tempSeg);
      return tempSegArr;
  }

  const segInfo = (e) => {
    if(selectIndex!==-1){
      setSelectIndex(-1)
      setSegProp({})
      setSelectPoint({})
      setPointDist(null)
      return;
    }
    setSelectPoint({})
    const segIndex = parseInt(e.target.value)
    const segment = segArr[segIndex]
    let distance=0,countWp=0,countMh=0;
    for(let i=0;i<segment.length;i++){
        if(segment[i].type==="Manhole") countMh+=1;
        if(segment[i].type==="WayPoint") countWp+=1;
        distance+=distBtwPoints(segment[i],segment[i-1] ? segment[i-1] : segment[i]);
    }
    //  MH1 WP1 WP2 MH2
    setSegProp({
      Name : `Segment ${segIndex+1}`,
      TotalSegLen : segment.length,
      distance : `${distance.toFixed(2)} m`,
      WayPoint : countWp,
      Manhole : countMh
    })
    setPointDist(null)
    setSelectIndex(segIndex)
  }
  const allSegInfo = () => {
    let distance=0,countWp=0,countMh=0;
    for(let i=0;i<markers.length;i++){
        if(markers[i].type==="Manhole") countMh+=1;
        if(markers[i].type==="WayPoint") countWp+=1;
        distance+=distBtwPoints(markers[i],markers[i-1] ? markers[i-1] : markers[i]);
    }
    setSegProp({
      Name : `All Segments`,
      TotalSegsLen : markers.length,
      distance : `${distance.toFixed(2)} m`,
      WayPoint : countWp,
      Manhole : countMh
   })
  }
  const pointsInfo = (e) => {
      const [segIndex,pointIndex] = e.target.value.split("-");
      const tempPointObj = {... selectPointObj}
      if(tempPointObj[[segIndex,pointIndex]])
          tempPointObj[[segIndex,pointIndex]] = 0;
      else 
        tempPointObj[[segIndex,pointIndex]] = 1;
      let distance = 0,prev=null;
      Object.entries(tempPointObj).map(([key,value]) => {
            let [index,pointIndex] = key.split(',')

            if(value){
              if(prev!=null){
                distance+=distBtwPoints(segArr[parseInt(index)][parseInt(pointIndex)],prev);
              }
              prev =segArr[parseInt(index)][parseInt(pointIndex)];
            }
      })
      setSelectPoint(tempPointObj);
      setPointDist({...pointsDistance,[segIndex] : distance});
  }

  const changeLayersHandler = (data)=>{
    getData(data);
  }
  
  let explorerContainer = null;
  let layersContainer = null;
  if(layers && layerResourceID.length > 0){
    layersContainer = (
      <div>
        <div style={{backgroundColor:'#F2F2F2', height: '40vh', padding:'10px 5%', textAlign:'left', overflowY:'auto', overflowX:'hidden'}}>
          {/* <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',background:'aliceblue'}}>
            <p>Add Layers</p>
            <AddCircleOutlineIcon style={{color:'teal', cursor:'pointer'}} onClick={()=>changeLayersHandler({type:'addlayers'})}/>
          </div> */}
          {/*  */}
          {layerResourceID.length > 0 && layers.map((item,index)=>{
            // choice to display the checkbox
            if(layerResourceID.length > 0 && layerResourceID[index].role === 'F-003'){
              return(
                <div>
                  <div style={{display:'flex', alignItems:'center',justifyContent:'space-around'}}>
                    <FormGroup>
                      <FormControlLabel 
                        control={
                          <Checkbox checked = {item.selected} onClick = {()=>changeLayersHandler({type:'layer',layerIndex:index, routeIndex:null})}/>
                        } 
                        label = {
                          'Planner('+layerResourceID[index].resourceID+')'
                        }
                        // label = {'planner'}
                        // label={item.name+ (item.index+1)}
                      ></FormControlLabel>  
                    </FormGroup>
                      {item.edit ? (
                        <div>
                          {/* <EditIcon style={{padding:5}}/> */}
                          <AddCircleOutlineIcon style={{color:'teal', cursor:'pointer',padding:5}} onClick={()=>changeLayersHandler({type:'addroutes'})}/>
                        </div>
                      ):null}
                      
                    
                      {/* <AddCircleOutlineIcon style={{color:'teal', cursor:'pointer'}} onClick={()=>changeLayersHandler({type:'addlayers'})}/>                 */}
                  </div>
                  {item.routes.map((innerItem,innerIndex)=>{
                    return(
                      <div style={{paddingLeft:'10%', display:'flex',alignItems:'center'}}>
                        <FormGroup>
                          <FormControlLabel checked = {innerItem.selected} control={<Checkbox onClick = {()=>changeLayersHandler({type:'route',layerIndex:item.index,routeIndex:innerItem.index})} />} label={innerItem.name+ (innerItem.index+1)} />
                        </FormGroup>
                        
                        <div style={{width:25,height:15,backgroundColor:innerItem.color,display:'inline-flex'}}></div>
                        <VisibilityIcon style = {{color:innerItem.view?'teal':'#aaa', padding:'0 5px', cursor:'pointer'}} onClick = {()=>changeLayersHandler({type:'view',layerIndex:item.index,routeIndex:innerItem.index})}/>
                        <EditIcon style = {{color:innerItem.edit?'teal':'#aaa',padding:'0 5px', cursor:'pointer'}} onClick = {()=>changeLayersHandler({type:'edit',layerIndex:item.index,routeIndex:innerItem.index})}/>
                        <DeleteIcon style = {{color:'red',padding:'0 5px', cursor:'pointer'}} onClick = {()=>{
                          
                          if(layerResourceID[item.index].resourceID === cookies.get('userData').resourceID){
                            window.confirm('Are you sure you wish to delete this item?') ? changeLayersHandler({type:'delete',layerIndex:item.index,routeIndex:innerItem.index}): console.log("cancel")
                          }
                          else{
                            alert('You don\'t have a right to delete');
                          }
                          
                        }}/>
                      </div>
                    )
                  })}
                </div>
              )
            }
            else{
              if(layerResourceID.length > 0 && 
                ((cookies.get('userData').resourceTypeID === 'C' && layerResourceID[index].companyID === cookies.get('userData').resourceID)||
                (layerResourceID[index].resourceID === cookies.get('userData').resourceID)||
                (cookies.get('userData').resourceTypeID === 'T')||
                (cookies.get('userData').subscriberTypeID === "F-003")
                )
              ){
                return(
                  <div>
                    <div style={{display:'flex', alignItems:'center',justifyContent:'space-around'}}>
                      <FormGroup>
                        <FormControlLabel 
                          control={
                            <Checkbox checked = {item.selected} onClick = {()=>changeLayersHandler({type:'layer',layerIndex:item.index, routeIndex:null})}/>
                          } 
                          label = {
                            layerResourceID[index].role === 'F-005' ? 
                                'Surveyor('+layerResourceID[index].resourceID+')':item.name+ (item.index+1)}
                          // label = {'planner'}
                          // label={item.name+ (item.index+1)}
                        ></FormControlLabel>  
                      </FormGroup>
                        {item.edit ? (
                          <div>
                            {/* <EditIcon style={{padding:5}}/> */}
                            <AddCircleOutlineIcon style={{color:'teal', cursor:'pointer',padding:5}} onClick={()=>changeLayersHandler({type:'addroutes'})}/>
                          </div>
                        ):null}
                        
                      
                        {/* <AddCircleOutlineIcon style={{color:'teal', cursor:'pointer'}} onClick={()=>changeLayersHandler({type:'addlayers'})}/>                 */}
                    </div>
                    {item.routes.map((innerItem,innerIndex)=>{
                      return(
                        <div style={{paddingLeft:'10%', display:'flex',alignItems:'center'}}>
                          <FormGroup>
                            <FormControlLabel checked = {innerItem.selected} control={<Checkbox onClick = {()=>changeLayersHandler({type:'route',layerIndex:item.index,routeIndex:innerItem.index})} />} label={innerItem.name+ (innerItem.index+1)} />
                          </FormGroup>
                          
                          <div style={{width:25,height:15,backgroundColor:innerItem.color,display:'inline-flex'}}></div>
                          <VisibilityIcon style = {{color:innerItem.view?'teal':'#aaa', padding:'0 5px', cursor:'pointer'}} onClick = {()=>changeLayersHandler({type:'view',layerIndex:item.index,routeIndex:innerItem.index})}/>
                          <EditIcon style = {{color:innerItem.edit?'teal':'#aaa',padding:'0 5px', cursor:'pointer'}} onClick = {()=>changeLayersHandler({type:'edit',layerIndex:item.index,routeIndex:innerItem.index})}/>
                          <DeleteIcon style = {{color:'red',padding:'0 5px', cursor:'pointer'}} onClick = {()=>{
                            
                            if(layerResourceID[item.index].resourceID === cookies.get('userData').resourceID){
                              window.confirm('Are you sure you wish to delete this item?') ? changeLayersHandler({type:'delete',layerIndex:item.index,routeIndex:innerItem.index}): console.log("cancel")
                            }
                            else{
                              alert('You don\'t have a right to delete');
                            }
                            
                          }}/>
                        </div>
                      )
                    })}
                  </div>
                )
              }
            }
                // return(
                //   <div>
                //     <div style={{display:'flex', alignItems:'center',justifyContent:'space-around'}}>
                      
                //       <FormGroup>
                //         <FormControlLabel 
                //           control={
                //             layerResourceID[item.index].role === 'F-005' ?
                //             ((cookies.get('userData').resourceTypeID === 'C' && layerResourceID[item.index].companyID === cookies.get('userData').resourceID)||(layerResourceID[item.index].resourceID ===cookies.get('userData').resourceID)||cookies.get('userData').resourceTypeID === 'T')?
                //             <Checkbox checked = {item.selected} onClick = {()=>changeLayersHandler({type:'layer',layerIndex:item.index, routeIndex:null})}/>
                //             :null
                //             :<Checkbox checked = {item.selected} onClick = {()=>changeLayersHandler({type:'layer',layerIndex:item.index, routeIndex:null})}/>
                //           } 
                //           label = {
                //             layerResourceID[item.index].role === 'F-003' ? 
                //               'Planner('+layerResourceID[item.index].resourceID+')':
                //               layerResourceID[item.index].role === 'F-005' ? 
                //                 'Surveyor('+layerResourceID[item.index].resourceID+')':item.name+ (item.index+1)}
                //           // label = {'planner'}
                //           // label={item.name+ (item.index+1)}
                //         ></FormControlLabel>  
                //       </FormGroup>
                //         {item.edit ? (
                //           <div>
                //             {/* <EditIcon style={{padding:5}}/> */}
                //             <AddCircleOutlineIcon style={{color:'teal', cursor:'pointer',padding:5}} onClick={()=>changeLayersHandler({type:'addroutes'})}/>
                //           </div>
                //         ):null}
                        
                      
                //         {/* <AddCircleOutlineIcon style={{color:'teal', cursor:'pointer'}} onClick={()=>changeLayersHandler({type:'addlayers'})}/>                 */}
                //     </div>
                //     {item.routes.map((innerItem,innerIndex)=>{
                //       return(
                //         <div style={{paddingLeft:'10%', display:'flex',alignItems:'center'}}>
                //           <FormGroup>
                //             <FormControlLabel checked = {innerItem.selected} control={<Checkbox onClick = {()=>changeLayersHandler({type:'route',layerIndex:item.index,routeIndex:innerItem.index})} />} label={innerItem.name+ (innerItem.index+1)} />
                //           </FormGroup>
                          
                //           <div style={{width:25,height:15,backgroundColor:innerItem.color,display:'inline-flex'}}></div>
                //           <VisibilityIcon style = {{color:innerItem.view?'teal':'#aaa', padding:'0 5px', cursor:'pointer'}} onClick = {()=>changeLayersHandler({type:'view',layerIndex:item.index,routeIndex:innerItem.index})}/>
                //           <EditIcon style = {{color:innerItem.edit?'teal':'#aaa',padding:'0 5px', cursor:'pointer'}} onClick = {()=>changeLayersHandler({type:'edit',layerIndex:item.index,routeIndex:innerItem.index})}/>
                //           <DeleteIcon style = {{color:'red',padding:'0 5px', cursor:'pointer'}} onClick = {()=>{
                            
                //             if(layerResourceID[item.index].resourceID === cookies.get('userData').resourceID){
                //               window.confirm('Are you sure you wish to delete this item?') ? changeLayersHandler({type:'delete',layerIndex:item.index,routeIndex:innerItem.index}): console.log("cancel")
                //             }
                //             else{
                //               alert('You don\'t have a right to delete');
                //             }
                            
                //           }}/>
                //         </div>
                //       )
                //     })}
                //   </div>
                // )
          })}
        </div>
        {manholeHover ? (
          <div style={{ height:'50vh', backgroundColor:'white',marginRight:5}}>
            <div style={{zoom:0.75, padding:5}}>
              <div>
                <div style={{padding:5, border:'1px solid', margin:'10px auto', borderRadius:'50%', width:100, textAlign:'center',background:manholeHover.accordianData.B.length>0?"aliceblue":""}}>
                  <p>B - Branch 1</p>
                </div>
                <div style={{display:'flex'}}>
                  <div>
                    <div style={{padding:5, border:'1px solid', margin:10, borderRadius:'50%', width:100,background:manholeHover.accordianData.A.length>0?"aliceblue":""}}>
                      <p>A - Incoming Fibre</p>
                    </div>
                  </div>
                  
                  <div style={{background:'teal', padding:10, margin:'10px auto'}}>
                    <p style={{color:'white'}}>Manhole - {manholeHover.markerIndex}</p>
                  </div>
                  <div style={{padding:5, border:'1px solid', margin:10, borderRadius:'50%', width:100,background:manholeHover.accordianData.C.length>0?"aliceblue":""}}>
                    <p>C - Outgoing Fibre</p>
                  </div>
                </div>
                
                <div style={{padding:5, border:'1px solid', margin:'10px auto', borderRadius:'50%', width:100,textAlign:'center',background:manholeHover.accordianData.D.length>0?"aliceblue":""}}>
                  <p>D - Branch 2</p>
                </div>
              </div>
              <div style={{height:200, overflow:'auto', padding:'0px 10px'}}>
                {Object.keys(manholeHover?.accordianData).map(key=>{
                  return(
                    <div style={{borderBottom:'1px solid teal'}}>
                      <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
                        <p style={{textAlign:'left', fontWeight:'bold', color:'teal'}}>Side {key}</p>
                        {showPipeSide[key].show ? (<KeyboardArrowUpIcon  style = {{color:'teal'}} onClick = {()=>{
                          let tempShowPipeSide = {... showPipeSide};
                          tempShowPipeSide[key].show = false;
                          setShowPipeSide(tempShowPipeSide);
                        }}/>):(<KeyboardArrowDownIcon style = {{color:'teal'}} onClick = {()=>{
                          let tempShowPipeSide = {... showPipeSide};
                          tempShowPipeSide[key].show = true;
                          setShowPipeSide(tempShowPipeSide);
                        }}/>)}
                      </div>  
                      {showPipeSide[key].show ? (
                        <table style={{width:'100%',background:'aliceblue'}}>
                          <tr>
                            <th style={{width:'75%'}}>Type</th>
                            <th>Quantity</th>
                          </tr>
                          {manholeHover.accordianData[key].map((item,index)=>{
                            return(
                              <tr>
                                <td>{item.PipeType}</td>
                                <td>{item.Quantity}</td>
                              </tr>
                            )
                          })
                          }
                        </table>
                      ):null}
                      
                      
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        ):null}  
        
      </div>
      
    )
  }
  
  explorerContainer = (
      <div className={classes.gray}>
      <Checkbox 
        onClick={()=>{
          if(selectIndex === -2){
            setSelectIndex(-1)
            setSegProp({})
            setSelectPoint({})
            setPointDist(null)
            return;
          }
          setSelectIndex(-2);
          allSegInfo()
        }
      }
      disabled = {selectIndex!==-2 && selectIndex!==-1}
      checked = {selectIndex === -2} 
      />
      Explorer
      <div   className={classes.search}>
          {/* <TextField
          id="outlined-basic"
          varient="outlined"
          fulLWidth
          style={{width:'98%' ,marginTop:'2%',backgroundColor:'white'}}
          label="Filter"
          /> */}
          <div className={classes.filter}>
            {segArr.length > 0 ?(
              segArr.map((item,index)=>{
                return(
                  <Accordion style = {{zoom:0.8}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                      
                        <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
                          <Checkbox 
                          checked = {selectIndex===-2 || (selectIndex!==-1 && index===selectIndex)} 
                          disabled={selectIndex===-2 || (selectIndex!==-1 && index!==selectIndex)} 
                          value={index} 
                          onClick ={(e)=>segInfo(e)}/>
                          <li style={{listStyle:'none'}}>Segment {index+1}</li>
                        </div>
                    </Typography>
                    
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {item.map((innerItem,tempIndex)=>{
                        return(
                          <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
                            <Checkbox 
                              value={`${index}-${tempIndex}`}
                              checked = {selectIndex===index || selectIndex === -2 || selectPointObj[[index,tempIndex]]===1} 
                              disabled={(selectIndex===index && selectPointObj) || selectIndex!==-1} 
                              onClick = {pointsInfo}
                            />
                            <li style={{listStyle:'none'}}>{innerItem.type} - {innerItem.count}  ({innerItem.lat.toFixed(2)},{innerItem.lng.toFixed(2)})</li>
                          </div>
                          
                        )
                      })}
                      <hr></hr>
                      { pointsDistance && pointsDistance[index] && <div style={{display:'flex',fontWeight:'bold', padding:'0 10px'}}>Distance :{pointsDistance[index].toFixed(2)}m</div>}
                      
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                )
              })
            ):null}
          </div>
          
          <label>Properties</label>
          <div className={classes.properties}>
          <TableContainer component={Paper}>
          <Table aria-label="simple table">
              <TableBody>
                {Object.entries(segProp).map(([key,value]) => (
                  <TableRow
                    key={key}
                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    <TableCell align="right">{value}</TableCell>
                  </TableRow>
                ))}
              
              </TableBody>

          </Table>
        </TableContainer>
       </div>
   </div>
</div>
  )

  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  return (
    <div style={innerWidth <= 768 ? {zoom:0.5}:{zoom:0.8}}>
      {innerWidth <= 768 && openExplorer ? (
        
        <CloseIcon style={{
            fontSize: 24,
            padding: 10,
            color: '#1976d2',
            cursor:'pointer',
            float:'right'
          }}
          onClick = {()=>{
            // setOpenExplorer(!openExplorer);
            getData({
              type:'openExplorer',
              openExplorer:false
            })
          }}
        />
      ):null}
      {innerWidth <= 768 ? openExplorer ? (
        <>
          <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example" >
            <Tab label="layers" {...a11yProps(0)} />
            <Tab label="Route" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {layersContainer}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {explorerContainer}
          </TabPanel>
        </>
      ):null:(
        <>
          <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example" >
            <Tab label="layers" {...a11yProps(0)} />
            <Tab label="Route" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={tabValue} index={0}>
            {layersContainer}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {explorerContainer}
          </TabPanel>
        </>)
      }
        
    </div>
  )
}

export default Explorer