import React,{useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import axios from "../../../../axios_tteg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import classes from "./UserManagement.module.css";
import UserProfileAdmin from "./UserProfileAdmin";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {CircularProgress} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LoginLocationLog from './LoginLocationLog';

const UserManagement = ()=>{
    const [myOptions, setMyOptions] = useState([]);
    const [type,setType] = useState('personalInfo');
    const [filteredData,setFilteredData] = useState([]);
    const [goToProfile,setGoToProfile] = useState(false);
    const [goToResourceID,setGoToResourceID] = useState(null);
    const [goToResourceTypeID,setGoToResourceTypeID] = useState(null);
    const [selectedSubscription,setSelectedSubscription] = useState(null);
    const [openEdit,setOpenEdit] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [totalSubscriberCategory,setTotalSubscriberCategory] = useState(null);
    const [newSubscription,setNewSubscription] = useState(null);
    const [startDate,setStartDate] = useState(new Date());
    const [endDate,setEndDate] = useState(new Date());
    const [error,setError] = useState(null);
    const [loading,setLoading] = useState(false);
    const [recentSubscription,setRecentSubscription] = useState([]);

    useEffect(()=>{
        getTotalSubscriberCategory();
    },[])

    const handleClickOpen = () => {
        setOpenEdit(true);
    };

    const handleClose = () => {
        setOpenEdit(false);
    };
 
    const getDataFromAPI = (filter) => {
        axios.get('/getFilteredUser',{
            params:{
                filter:filter, 
                type:type
            }
        }).then(response=>{
            setFilteredData(response.data.vendor);
        }).catch(e=>{
            console.log(e);
        })
    }

    const getTotalSubscriberCategory= () => {
        axios.get('/getSubscriberCategory')
        .then(response=>{
            console.log(response);
            setTotalSubscriberCategory(response.data.subscriberCategory);

        }).catch(e=>{
            console.log(e);
        })
    }

    const goToDetailedProfile = ((resourceID, resourceTypeID)=>{
        setGoToResourceID(resourceID);
        setGoToResourceTypeID(resourceTypeID);
        setGoToProfile(true);
    })

    const submitSubscriptionHandler = (resourceID)=>{
        if(startDate && endDate && endDate >= startDate && newSubscription && newSubscription != selectedSubscription.subscriberCategoryID){
            setLoading(true);
            axios.post('/changeSubscription',{
                subscriberCategoryID:newSubscription,
                startDate:startDate,
                endDate:endDate,
                resourceID:resourceID
            })
            .then(response=>{
                setLoading(false);
                setOpenEdit(false);
                setStartDate(new Date());
                setEndDate(new Date());
                setNewSubscription(null);
                setFilteredData([]);
                alert("Subscription changed successfully")
            })
            .catch(e=>{setLoading(false);console.log(e)});
        }
        else
            setError("* Please fill appropriate data")
    }

    const getSubscriptionLog = (resourceID)=>{
        setLoading(true);
        axios.get('/getSubscriberCategoryLog',{
            params:{
                resourceID:resourceID
            }
        })
        .then(response=>{
            setLoading(false);
            console.log(response);
            setRecentSubscription(response.data.subsCategoryLog)
        })
        .catch(e=>{console.log(e); setLoading(false)})
    }

    const getLocationLog = (resourceID)=>{
        axios.get('/getLast10LoginLogs',{
            params:{
                resourceID:resourceID
            }
        })
        .then(response=>{
            console.log(response);
        })
        .catch(e=>{

        })
    }



    let container = null;
    if(loading){
        container = (
            <div style = {{position:'absolute', top:'50vh', left:'50%'}}>
                <CircularProgress />
            </div>
            )
    }
    else if(openLocation){
        container = (
            <LoginLocationLog
                resourceID = {selectedSubscription.resourceID}
                getData = {()=>setOpenLocation(false)}
            />
        )
    }
    else if(goToProfile){
        container = (
            <div>
                <UserProfileAdmin
                    resourceID = {goToResourceID}
                    resourceTypeID = {goToResourceTypeID}
                    getData = {(data)=>{
                        setGoToProfile(false);
                        setGoToResourceID(null);
                        setGoToResourceID(null);
                    }}
                />
            </div>
        )
    }
    else if(openEdit){
        container = (
            <div>
                <Dialog
                    open={openEdit}
                    onClose={()=>setOpenEdit(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Resource ID: {selectedSubscription.resourceID}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div >
                                <table >
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>Current Subscription</td>
                                        <td style={{padding:'5px 10px'}}>{selectedSubscription.subscriberCategoryName}</td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>Select Plan*</td>
                                        <td style={{padding:'5px 10px'}}>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={newSubscription}
                                                label="Subscription"
                                                onChange={(e)=>setNewSubscription(e.target.value)}
                                                style = {{width:232}}
                                            >
                                                {totalSubscriberCategory.map(item=>(
                                                    <MenuItem value = {item.subscriberCategoryID}>{item.subscriberCategoryName}</MenuItem>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:'5px 10px'}}> Start Date*</td>
                                        <td style={{padding:'5px 10px'}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DesktopDatePicker
                                                    label="Start Date"
                                                    inputFormat="DD/MM/YYYY"
                                                    minDate={new Date()}
                                                    value={startDate}
                                                    onChange={(date)=>setStartDate(date)}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>End Date*</td>
                                        <td style={{padding:'5px 10px'}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DesktopDatePicker
                                                    label="End Date"
                                                    inputFormat="DD/MM/YYYY"
                                                    minDate={new Date(startDate)}
                                                    value={endDate}
                                                    onChange={(date)=>setEndDate(new Date(date))}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </td>
                                    </tr>
                                </table>
                                <p style={{color:'red', fontSize:'small'}}>{error}</p>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={()=>submitSubscriptionHandler(selectedSubscription.resourceID)} autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                    <div>
                        <div>
                            <h3 style={{textAlign:'center'}}>Recent Subscription</h3>
                        </div>
                        <div>
                            <TableContainer component={Paper} style = {{maxHeight:256, overflow:'auto'}}>
                                <Table sx={{ minWidth:500 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow >
                                            <TableCell style={{fontWeight:'bold'}}>Resource ID</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Date</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Suscriber Category</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>Start Date.</TableCell>
                                            <TableCell style={{fontWeight:'bold'}}>End Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {recentSubscription.map((item) => (
                                            <TableRow
                                                key={item.timeStamp}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                className = {classes.User}
                                                
                                            >
                                                <TableCell >{item.resourceID}</TableCell>
                                                <TableCell >{new Date(item.timeStamp).toLocaleString()}</TableCell>
                                                <TableCell >{item.subscriberCategoryMaster.subscriberCategoryName}</TableCell>
                                                <TableCell >{new Date(item.startDate).toLocaleString()}</TableCell>
                                                <TableCell >{item.endDate ? new Date(item.endDate).toLocaleString():'-'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
    else {
        container = (
            <div>
                <div 
                    style={{
                        background: 'aliceblue',
                        margin: 10,
                        padding: 10
                    }}  
                >
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Search By</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            defaultValue="personalInfo"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel 
                                value="personalInfo" 
                                control={<Radio />} 
                                label="Personal Info"
                                onClick={(e)=>setType(e.target.value)} 
                            />
                            <FormControlLabel 
                                value="subscriberType" 
                                control={<Radio />} 
                                label="Subscriber Type" 
                                onClick={(e)=>setType(e.target.value)} 
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div>
                    <Autocomplete
                        style={{ maxWidth: 500,margin:'10px auto'}}
                        freeSolo
                        autoComplete
                        autoHighlight
                        options={myOptions}
                        renderInput={(params) => (
                            <TextField {...params}
                                onChange={(e)=>getDataFromAPI(e.target.value)}
                                variant="outlined"
                                label="Search Box"
                            />
                        )}
                    />
                </div>
                <div>
                    <TableContainer component={Paper} style = {{maxHeight:500, overflow:'auto'}}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow >
                                <TableCell style={{fontWeight:'bold'}}>Resource ID</TableCell>
                                <TableCell style={{fontWeight:'bold'}}>Name</TableCell>
                                <TableCell style={{fontWeight:'bold'}}>Contact No.</TableCell>
                                <TableCell style={{fontWeight:'bold'}}>Domain</TableCell>
                                <TableCell style={{fontWeight:'bold'}}>Type</TableCell>
                                <TableCell style={{fontWeight:'bold'}}>Subscription</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {filteredData.map((item) => (
                                <TableRow
                                    key={item.vendorMaster.resourceID}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    className = {classes.User}
                                    
                                >
                                <TableCell onClick = {()=>goToDetailedProfile(item.vendorMaster.resourceID, item.vendorMaster.resourceTypeID)}>{item.vendorMaster.resourceID}</TableCell>
                                <TableCell onClick = {()=>goToDetailedProfile(item.vendorMaster.resourceID, item.vendorMaster.resourceTypeID)}>{item.vendorMaster.name}</TableCell>
                                <TableCell onClick = {()=>goToDetailedProfile(item.vendorMaster.resourceID, item.vendorMaster.resourceTypeID)}>{item.vendorMaster.mobile}</TableCell>
                                <TableCell onClick = {()=>goToDetailedProfile(item.vendorMaster.resourceID, item.vendorMaster.resourceTypeID)}>{item.domainMaster.domainDescription.domainName}</TableCell>
                                <TableCell onClick = {()=>goToDetailedProfile(item.vendorMaster.resourceID, item.vendorMaster.resourceTypeID)}>{item.subscriberTypeMaster.subscriberTypeName}</TableCell>
                                <TableCell >{item.subscriberCategoryMaster.subscriberCategoryName}</TableCell>
                                <TableCell>
                                    {/* <Button> */}
                                        <EditIcon style = {{color:'teal'}} onClick = {()=>{
                                            setOpenEdit(true);
                                            getSubscriptionLog(item.vendorMaster.resourceID);
                                            let tempSelectedSubscription = {... selectedSubscription}
                                            tempSelectedSubscription = {
                                                subscriberCategoryID:item.subscriberCategoryMaster.subscriberCategoryID,
                                                subscriberCategoryName:item.subscriberCategoryMaster.subscriberCategoryName,
                                                resourceID:item.vendorMaster.resourceID
                                            }
                                            setSelectedSubscription(tempSelectedSubscription)
                                        }}/>
                                    {/* </Button> */}
                                </TableCell>
                                <TableCell>
                                    {/* <Button> */}
                                        <LocationOnIcon style = {{color:'teal'}} onClick = {()=>{
                                            let tempSelectedSubscription = {... selectedSubscription}
                                            tempSelectedSubscription = {
                                                subscriberCategoryID:item.subscriberCategoryMaster.subscriberCategoryID,
                                                subscriberCategoryName:item.subscriberCategoryMaster.subscriberCategoryName,
                                                resourceID:item.vendorMaster.resourceID
                                            }
                                            setSelectedSubscription(tempSelectedSubscription);
                                            setOpenLocation(true);
                                            // setOpenEdit(true);
                                            // getSubscriptionLog(item.vendorMaster.resourceID);
                                            // let tempSelectedSubscription = {... selectedSubscription}
                                            // tempSelectedSubscription = {
                                            //     subscriberCategoryID:item.subscriberCategoryMaster.subscriberCategoryID,
                                            //     subscriberCategoryName:item.subscriberCategoryMaster.subscriberCategoryName,
                                            //     resourceID:item.vendorMaster.resourceID
                                            // }
                                            // setSelectedSubscription(tempSelectedSubscription)
                                        }}/>
                                    {/* </Button> */}
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        )
    }
    return (
        <div>
            {container}
        </div>
    );
}

export default UserManagement