// Props
//     1. Options:{
//         label:"",
//         value:""
//     }
//     getData = function

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        },
    },
};




function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
    };
}

export default function MultipleSelectChip(props) {
    const theme = useTheme();
    const [output, setOutput] = React.useState([]);
    const [label, setLabel] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        let tempLabel = typeof value === 'string' ? value.split(',') : value;
        setLabel(
            // On autofill we get a stringified value.
            tempLabel)
        if(tempLabel.length > 0){
            let tempOutput = [];
            props.Options.map((item) => {
                tempLabel.map(innerItem=>{
                    if(innerItem === item.label){
                        tempOutput.push(item.value);
                    }
                })
            });
            setOutput(tempOutput);
            props.getData(tempOutput)
        }
        else{
            props.getData([]);
        }
        // let tempOutput = [... output];
        
    };

    // React.useEffect(()=>{
    //     if(label.length > 0){
    //         let tempOutput = [];
    //         props.Options.map((item) => {
    //             label.map(innerItem=>{
    //                 if(innerItem === item.label){
    //                     tempOutput.push(item.value);
    //                 }
    //             })
    //         });
    //         setOutput(tempOutput);
    //         props.getData(tempOutput)
    //     }
    //     else{
    //         props.getData([]);
    //     }
        
    // },[label])


    console.log(output);

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-chip-label">{props.mainLabel}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={label}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                                return(
                                <Chip key={value} label={value} />
                            )})}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {props.Options.map((item) => (
                        <MenuItem
                            name={item.label}
                            value={item.label}
                            style={getStyles(item.label, label, theme)}
                        >
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}