import React, {useEffect,useState} from 'react'
import axios from "../../axios_tteg";
import Cookies from 'universal-cookie';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import classes from "./ExpenseManagementSystem.module.css";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SummarizeIcon from '@mui/icons-material/Summarize';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';


const cookies = new Cookies();

const Settlement = (props)=>{
    const [settlementDetails,setSettlementDetails] = useState({
        amount:null,
        description:null,
        mode:null,
        receipt:null
    })
    const [finalSettlementDetails,setFinalSettlementDetails] = useState([]);
    const [openFileUploader,setOpenFileUploader] = useState(0);
    const [uploadComplete,setUploadComplete] = useState(false);
    const [initialSettlement,setInitialSettlement] = useState(0);
    const [sumTotalSettlement,setSumTotalSettlement] = useState(0);
    const [selectedFile,setSelectedFile] = useState(null);
    const [uploadingFile,setUploadingFile] = useState(false);
    const [loading,setLoading] = useState(false)
    const [fileToUpload,setFileToUpload] = useState(null);
    const [selectedTab,setSelectedTab] = useState(1);
    const [showPaymentDetails,setShowPaymentDetails] = useState(false);
    const [selectedPaymentDetails,setSelectedPaymentDetails] = useState(null);
    const [viewReceipt,setViewReceipt] = useState(false);
    const [selectedReceipt,setSelectedReceipt] = useState(null);

    console.log(props.transaction);

    useEffect(()=>{
        if(props.transaction.mTransactionSettlement && props.transaction.mTransactionSettlement.length > 0){
            let sumTotal = 0;
            props.transaction.mTransactionSettlement.map(item=>{
                sumTotal += parseFloat(item.transactionDetails.amount)
            });
            setInitialSettlement(sumTotal);
        }
    },[])

    useEffect(()=>{
        if(finalSettlementDetails.length > 0){
            let sumTotal = 0;
            finalSettlementDetails.map(item=>{
                sumTotal +=  parseFloat(item.amount)
            });
            setSumTotalSettlement(sumTotal);
        }
        
    },[finalSettlementDetails])

    const settlementHandler = ()=>{
        if(settlementDetails.amount && settlementDetails.description && settlementDetails.mode && (uploadingFile ? settlementDetails.receipt:true)){
            let tempfinalSettlementDetails = [... finalSettlementDetails];
            tempfinalSettlementDetails.push({
                amount:settlementDetails.amount,
                description:settlementDetails.description,
                mode:settlementDetails.mode,
                receipt:settlementDetails.receipt
            })
            setFinalSettlementDetails(tempfinalSettlementDetails);
            setSettlementDetails({
                amount:'',
                description:'',
                mode:null,
                receipt:null
            });
        }
        else{
            alert('Please fill all data')
        }
        
    }

    const deleteSettlementHandler = (index)=>{
        const fileToDelete = finalSettlementDetails[index].receipt;
        if(fileToDelete){
            axios.post('/deleteFile',{
                path:fileToDelete
            }).then(response=>{
                setSelectedFile(null);
            }).catch(e=>console.log(e))
        }
        let tempfinalSettlementDetails = [... finalSettlementDetails];
        tempfinalSettlementDetails.splice(index,1);
        setFinalSettlementDetails(tempfinalSettlementDetails)
        
    }
    
    const finalSettlementHandler = ()=>{
        axios.post('/addNewSettlement',{
            settlementAgainsttransactionID:props.transaction.transactionID,
            projectID:props.transaction.projectID,
            payerID:cookies.get('userData').resourceID,
            payeeID:props.transaction.payerDetails.resourceID,
            amount:sumTotalSettlement,
            mode:null,
            description:null,
            acknowledged:false,
            isSettlement:true,
            settlementDetails:finalSettlementDetails
        }).then(response=>{
            alert('settlement done !! Waiting for acknowledgement.');
            props.getData('close')
            setSettlementDetails({
                amount:null,
                description:null,
                mode:null,
                receipt:null
            });
            setFinalSettlementDetails([])
        }).catch(e=>console.log(e))
    }

    function handleFile(files) {
        // uploadDocument(files);
        if (files.length > 1){
            alert ("Please process one file at a time");
        }
        else if (files[0].type !== 'video/mp4' && files[0].type !== 'image/png' && files[0].type !== 'image/jpeg' && files[0].type !== 'image/jpg'){
            alert('Only .png, .jpeg, .jpg and .mp4 is allowed')
        }
        else if (files[0].size > 5000000){
            alert('Cannot upload file more than 5MB')
        }
        else{
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.addEventListener("load", () => {
                const url = reader.result;
                setSelectedFile(url);
              }, false);
            setFileToUpload(files[0]);
        }
        
    }

    // drag drop file component
    const DragDropFile = () => {
        // drag state
        const [dragActive, setDragActive] = React.useState(false);
        // ref
        const inputRef = React.useRef(null);
    
        // handle drag events
        const handleDrag = function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.type === "dragenter" || e.type === "dragover") {
                setDragActive(true);
            } else if (e.type === "dragleave") {
                setDragActive(false);
            }
        };
    
        // triggers when file is dropped
        const handleDrop = function(e) {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                handleFile(e.dataTransfer.files);
            }
        };
        
        // triggers when file is selected with click
        const handleChange = function(e) {
            e.preventDefault();
            if (e.target.files && e.target.files[0]) {
                handleFile(e.target.files);
            }
        };
        
        // triggers the input when the button is clicked
        const onButtonClick = () => {
            inputRef.current.click();
        };


        let container = null;
        
        container = (
            <form 
                id="form-file-upload" 
                onDragEnter={handleDrag} 
                onSubmit={(e) => e.preventDefault()}
                style={{maxWidth: '100%',textAlign: 'center',position: 'relative', margin :'10px 0 0 10px'}}
            >
                <input 
                    ref={inputRef} 
                    type="file" 
                    id="input-file-upload" 
                    style = {{display: 'none'}} 
                    onChange={handleChange} 
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                />
                
                <label 
                    id="label-file-upload" 
                    htmlFor="input-file-upload" 
                    className={dragActive ? "drag-active" : "" }
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        borderWidth: 2,
                        borderStyle: 'dashed',
                        borderColor: '#cbd5e1',
                        backgroundColor: '#f8fafc'
                    }}
                >
                    {/* {selectedFile && <img src = {selectedFile} height={120} style={{padding:5}}/>} */}
                    <div>
                        <p>Drag and drop your file here or</p>
                        <button className={classes['upload-button']} onClick={onButtonClick}>Upload a file</button>
                    </div> 
                </label>
                { dragActive && <div 
                    id="drag-file-element" 
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    }}
                    onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </form>
        )

        
        
        return (
            <div>
                {container}
            </div>
            
        );
    };

    const uploadFileHandler = ()=>{
        setLoading(true)
        if(fileToUpload){
            var formData = new FormData();
            formData.append('path',`tteg/project/`);
            formData.append('fileName', Date.now())
            formData.append('file',fileToUpload);
            axios.post('/uploadDocument',formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    },
            })
            .then(response=>{
                setLoading(false)
                let tempSettlementDetails = {... settlementDetails};
                tempSettlementDetails.receipt = response.data.path;
                setSettlementDetails(tempSettlementDetails);
                setUploadComplete(true);
                setFileToUpload(null);
            })
            .catch(e=>{
                setLoading(false); 
                console.log(e)
            })
        }
        else{
            alert('Please select the file')
        }
        
    }

    let container = null;
    if(loading){
        container = (<CircularProgress/>)
    }
    else{
        container = (
            <div
                style = {{
                    padding: 10,
                    margin: '5px auto',
                    background: 'white'
                }}
            >
                <div style={{borderBottom:'2px solid lightgray', margin:5, display:'flex',justifyContent:'space-between'}}>
                    <h3 style={{margin:5, width:'100%'}}>Expense Management System - Settlement</h3>
                    <CloseIcon  
                        style = {{padding:10, cursor:'pointer'}}
                        onClick = {()=>props.getData('close')}
                    />
                </div>
                <div>
                    <div style = {{ margin:10, padding:10, display:'flex', alignItems:'center'}}>
                        <TableContainer >
                            <TableBody style={{background:'azure'}}>
                                <TableRow>
                                    <TableCell>
                                        Project
                                    </TableCell>
                                    <TableCell>
                                        {props.transaction.mProjectCase.caseMaster.title}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Payee
                                    </TableCell>
                                    <TableCell>
                                        {props.transaction.payerDetails.name}-{props.transaction.payerDetails.resourceID}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Total Amount
                                    </TableCell>
                                    <TableCell>
                                        {'\u20B9'}{props.transaction.amount}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </TableContainer>
                        <TableContainer>
                            <TableBody  style={{background:'azure'}}>
                                <TableRow>
                                    <TableCell style={{fontSize:18, fontWeight:'bold', color:'green'}}>
                                        Total Amount Settled
                                    </TableCell>
                                    <TableCell style={{fontSize:18, fontWeight:'bold', color:'green'}}>
                                        {'\u20B9'}{parseFloat(sumTotalSettlement)+parseFloat(initialSettlement)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{fontSize:18, fontWeight:'bold', color:'red'}}>
                                        Remaining Amount
                                    </TableCell>
                                    <TableCell style={{fontSize:18, fontWeight:'bold', color:'red'}}>
                                        {'\u20B9'}{parseFloat(props.transaction.amount)-parseFloat(initialSettlement)-parseFloat(sumTotalSettlement)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </TableContainer>
                        {/* <FormControl style={{margin:10,minWidth:200, width:'100%', justifyContent:'center'}} >
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <h3 style={{margin:0}}>Sum Total Settlement - </h3>
                                <CurrencyRupeeIcon style = {{fontWeight:'bold'}}/>
                                <h3 style={{fontSize:28, margin:0}}>{sumTotalSettlement}</h3>
                            </div>
                        </FormControl> */}
                        
                    </div>
                    <div style={{borderBottom:'2px solid lightgray', margin:5}}>
                        <Button
                            style = {selectedTab === 1 ?{background:'teal', padding:'5px 10px', color:'white', margin:10}:null}
                            onClick={()=>setSelectedTab(1)}
                        >
                            Settlement History
                        </Button>
                        <Button
                            style = {selectedTab === 2 ?{background:'teal', padding:'5px 10px', color:'white', margin:10}:null}
                            onClick={()=>setSelectedTab(2)}
                        >
                            Add New Settlement
                        </Button>
                    </div>
                    {selectedTab === 1 ? (
                        <div>
                            {props.transaction.mTransactionSettlement && props.transaction.mTransactionSettlement.length > 0 ? (
                                <TableContainer style={{width:'100%'}}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Date / Time</TableCell>
                                            <TableCell>Transaction ID</TableCell>
                                            <TableCell>Payer</TableCell>
                                            <TableCell>Payee</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Acknowledged</TableCell>
                                            <TableCell>Details</TableCell>
                                        </TableHead>
                                        {props.transaction.mTransactionSettlement.map((item,index)=>(
                                            <TableBody>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell>{new Date(item.transactionDetails.timestamp).toLocaleString('en-IN')}</TableCell>
                                                <TableCell>{item.transactionDetails.transactionID}</TableCell>
                                                <TableCell>{item.transactionDetails.payerDetails.name}</TableCell>
                                                <TableCell>{item.transactionDetails.payeeDetails.name}</TableCell>
                                                <TableCell>{item.transactionDetails.amount}</TableCell>
                                                <TableCell>{item.transactionDetails.acknowledged}</TableCell>
                                                <TableCell>
                                                    <Tooltip title = 'Details'>
                                                        <IconButton>
                                                            <KeyboardArrowRightIcon 
                                                                style={{color:'teal', cursor:'pointer'}}
                                                                onClick = {()=>{
                                                                    setShowPaymentDetails(true);
                                                                    setSelectedPaymentDetails(item);
                                                                }}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableBody>
                                        ))}
                                    </Table>
                                    
                                </TableContainer>
                            ):(
                                <div>
                                    <p>No Transaction yet !!</p>
                                </div>
                            )}
                            
                        </div>
                    ):(
                        <div>   
                            {(cookies.get('userData').subscriberTypeID === 'F-002' && props.transaction.payerDetails.subscriberTypeMaster.subscriberTypeID === 'P-018') ||
                                (cookies.get('userData').subscriberTypeID === 'F-004' && props.transaction.payerDetails.subscriberTypeMaster.subscriberTypeID === 'F-002')?(
                                    <div style={{margin: '10px 0px'}}>
                                        {parseFloat(props.transaction.amount)-parseFloat(initialSettlement)-parseFloat(sumTotalSettlement) > 0 ?(
                                            <TableContainer>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                                                    <TextField 
                                                                        id="outlined-basic" 
                                                                        label="Amount" 
                                                                        type="number"
                                                                        variant="outlined" 
                                                                        value = {settlementDetails.amount}
                                                                        InputProps={{
                                                                            inputProps: {
                                                                            max: parseFloat(props.transaction.amount)-parseFloat(initialSettlement)-parseFloat(sumTotalSettlement), // Set the maximum value here
                                                                            },
                                                                        }}
                                                                        onChange={(e)=>{
                                                                            let tempSettlementDetails = {... settlementDetails};
                                                                            if(e.target.value <= parseFloat(props.transaction.amount)-parseFloat(initialSettlement)-parseFloat(sumTotalSettlement))
                                                                                tempSettlementDetails.amount = e.target.value;
                                                                            else
                                                                                tempSettlementDetails.amount = parseFloat(props.transaction.amount)-parseFloat(initialSettlement)-parseFloat(sumTotalSettlement)
                                                                            setSettlementDetails(tempSettlementDetails)
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell>
                                                                <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                                                    <TextField 
                                                                        id="outlined-basic" 
                                                                        label="Description" 
                                                                        variant="outlined" 
                                                                        value = {settlementDetails.description}
                                                                        onChange={(e)=>{
                                                                            let tempSettlementDetails = {... settlementDetails};
                                                                            tempSettlementDetails.description = e.target.value;
                                                                            setSettlementDetails(tempSettlementDetails)
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell>
                                                                <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                                                    <InputLabel id="demo-simple-select-label">Payment mode</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={settlementDetails.mode}
                                                                        style={{padding:0}}
                                                                        onChange={(e)=>{
                                                                            let tempSettlementDetails = {... settlementDetails};
                                                                            tempSettlementDetails.mode = e.target.value;
                                                                            setSettlementDetails(tempSettlementDetails)
                                                                        }}
                                                                    >
                                                                        <MenuItem value = {1}>Online</MenuItem>
                                                                        <MenuItem value = {2}>Cash</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell>
                                                                <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                                                    <InputLabel id="demo-simple-select-label">Uploading Receipt ?</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={uploadingFile}
                                                                        style={{padding:0}}
                                                                        onChange={(e)=>{
                                                                            setUploadingFile(e.target.value)
                                                                        }}
                                                                    >
                                                                        <MenuItem value = {true}>Yes</MenuItem>
                                                                        <MenuItem value = {false}>No</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell style={{textAlign:'center'}}>
                                                                {uploadingFile ? (
                                                                    <Button 
                                                                        style = {{background:'teal', padding:'5px 10px', color:'white'}}
                                                                        onClick = {()=>setOpenFileUploader(true)}
                                                                    >
                                                                        Upload Receit
                                                                    </Button>
                                                                ):null}
                                                            </TableCell>
                                                            <TableCell style={{textAlign:'center'}}>
                                                                <Button 
                                                                    style = {{background:'teal', padding:'5px 10px', color:'white'}}
                                                                    onClick = {()=>settlementHandler()}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ):null}
                                        
                                    </div> 
                                ):(
                                    <div>
                                        <p>You are not allowed to do the transaction</p>
                                    </div>
                                )
                            }
                             
                            <div style={{margin: '10px 0px',background: 'rgb(242, 242, 242)', padding:10}}>
                                {finalSettlementDetails.length > 0 ? (
                                        <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {finalSettlementDetails.map((item,index)=>{
                                                    return(
                                                        <TableRow>
                                                            <TableCell>
                                                                {index+1}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.amount}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.description}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.mode === 1 ? 'Online':'Cash'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.receipt  ? (
                                                                    <Tooltip title = 'Receipt'>
                                                                        <IconButton>
                                                                            <SummarizeIcon 
                                                                                style={{color:'teal', cursor:'pointer'}}
                                                                                onClick = {()=>{
                                                                                    setSelectedReceipt(item.receipt);
                                                                                    setViewReceipt(true)
                                                                                    // window.open(item.receipt, '_blank', 'noreferrer')
                                                                                }}/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ):null}
                                                            </TableCell>
                                                            <TableCell>
                                                            <Tooltip title = 'Delete'>
                                                                <IconButton>
                                                                    <DeleteIcon
                                                                        style = {{color:'red', cursor:'pointer'}}
                                                                        onClick = {()=>deleteSettlementHandler(index)}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                                
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ):(
                                    <div>
                                        <p>No transaction to show !!</p>
                                    </div>
                                )}
                                
                            </div>  
                            <div style={{display:'flex', justifyContent:'space-around'}}>
                                
                                {finalSettlementDetails.length > 0 ? (
                                    <Button
                                        style = {{background: 'teal',color: 'white',width: 120,padding: 10}}
                                        size="small" 
                                        onClick = {()=>{
                                                finalSettlementHandler()
                                            } 
                                        }
                                    >Submit</Button>
                                ):null}
                                
                            </div>
                        </div>
                    )}
                    
                    
                </div>
                
            </div>    
        )
    }

    console.log(selectedPaymentDetails);
    
    return (
        <div>
            {container}
            <Dialog onClose={()=>{setOpenFileUploader(false)}} open={openFileUploader} style = {{width:'100%'}}>
                {loading ? (
                    <CircularProgress/>
                ):(
                    <>
                        <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                            <DialogTitle>Upload File</DialogTitle>
                            <div>
                                <CloseIcon onClick = {()=>{setOpenFileUploader(false)}} style = {{padding:10, cursor:'pointer'}}/>
                            </div>
                        </Typography>
                        {uploadComplete?(
                            <>
                                <Typography style={{padding:10, margin:10, minWidth:480}}>
                                    <p style={{textAlign:'center'}}>Uploaded Successfully !!</p>
                                </Typography>
                                <Typography style = {{textAlign:'center', padding:10}}>
                                    <Button 
                                        style = {{background:'teal', padding:'5px 10px', color:'white'}}
                                        onClick = {()=>{
                                            setOpenFileUploader(false);
                                            setUploadComplete(false)
                                        }}
                                    >
                                        OK
                                    </Button>
                                </Typography>
                            </>
                            
                        ):(
                            <>
                                <Typography style={{padding:10, margin:10, minWidth:480}}>
                                    {loading?(
                                        <CircularProgress style = {{margin:20, padding:10}}/>
                                    ):(
                                        <div>
                                            <DragDropFile/>
                                        </div>
                                    )}
                                    
                                </Typography>
                                <Typography style = {{width:'100%', textAlign:'center', padding:10}}>
                                    <Button 
                                        style = {{background:'teal', padding:'5px 10px', color:'white'}}
                                        onClick = {()=>{
                                            uploadFileHandler();
                                        }}
                                    >
                                        Upload Receit
                                    </Button>
                                </Typography>
                            </>
                        )}
                        
                    </>
                    
                )}
                
            </Dialog>
            <Dialog onClose={()=>{setShowPaymentDetails(false)}} open={showPaymentDetails} style = {{width:'100%'}}>
                {loading ? (
                    <CircularProgress/>
                ):(
                    <>
                        <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                            <DialogTitle>Transaction Details</DialogTitle>
                            <div>
                                <CloseIcon onClick = {()=>{setShowPaymentDetails(false)}} style = {{padding:10, cursor:'pointer'}}/>
                            </div>
                        </Typography>
                        <div>
                            {selectedPaymentDetails && selectedPaymentDetails.transactionDetails.settlementDetails.length > 0 ? (
                                <TableContainer style={{width:'100%'}}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Payment Mode</TableCell>
                                            <TableCell>Receipt</TableCell>
                                        </TableHead>
                                        {selectedPaymentDetails.transactionDetails.settlementDetails.map((item,index)=>(
                                            <TableBody>
                                                <TableCell>{index+1}</TableCell>
                                                <TableCell>{item.amount}</TableCell>
                                                <TableCell>{item.description}</TableCell>
                                                <TableCell>{item.mode === 1 ? 'Online' : 'Cash'}</TableCell>
                                                <TableCell>
                                                    {item.receipt ? (
                                                        <Tooltip title = 'Receit'>
                                                            <IconButton>
                                                                <SummarizeIcon 
                                                                    style={{color:'teal', cursor:'pointer'}}
                                                                    onClick = {()=>{
                                                                        setSelectedReceipt(item.receipt);
                                                                        setViewReceipt(true)
                                                                        // window.open(item.receipt, '_blank', 'noreferrer')
                                                                    }}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        
                                                    ):null}
                                                </TableCell>
                                            </TableBody>
                                        ))}
                                    </Table>
                                    
                                </TableContainer>
                            ):(
                                <div>
                                    <p>No Transaction yet !!</p>
                                </div>
                            )}
                            
                        </div>
                        
                    </>
                    
                )}
                
            </Dialog>

            <Dialog onClose={()=>{setViewReceipt(false)}} open={viewReceipt}>
                <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <DialogTitle>Receipt</DialogTitle>
                    <div>
                        <DownloadIcon style = {{padding:10, cursor:'pointer'}}
                            onClick = {()=>{window.open(selectedReceipt, '_blank', 'noreferrer')}}
                        />
                        <CloseIcon onClick = {()=>{setViewReceipt(false)}} style = {{padding:10, cursor:'pointer'}}/>
                    </div>
                </Typography>
                <Typography>
                    <div style = {{margin:20}}>
                        <img src = {selectedReceipt} width='100%'/>
                    </div>
                </Typography>
            </Dialog>
        </div>
    )
}

export default Settlement