import React,{useEffect, useState} from 'react'
import { CircularProgress } from "@mui/material";
import axios from '../../axios_tteg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import AddResource from './AddResource';
import AddResourceToSubProj from './AddResourceToSubProj';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ProjectAssociation(props) {
    const [projSubProjSwitch, setProjSubProjSwitch] = useState(false);
    const [loading,setLoading]=useState(false)
    const [subProjectAssociationAddBtn,setSubProjectAssociationAddBtn]=useState(false)
    const [projectAssociationAddBtn,setProjectAssociationAddBtn]=useState(false)
    const [associatedIndividual,setAssociatedIndividual] = useState([]);
    const [newAssociatedIndividual,setNewAssociatedIndividual] = useState([]);
    const [projectAssociation,setProjectAssociation]=useState(null)
    const [newProjectAssociation,setNewProjectAssociation]=useState(null)
    const [selectedSubscriberType,setSelectedSubscriberType]=useState(null)
    const [subscriberTypes,setSubscriberTypes]=useState([]);
    const [subProjects,setSubProjects] = useState(null);
    const [subProjectAssociations,setSubProjectAssociations]=useState(null)
    const [projectAssociation4Sub,setProjectAssociation4Sub]=useState(null)
    const [newProjectAssociation4Sub,setNewprojectAssociation4Sub]=useState(null)
    const [tempSelectedIndividual, setTempSelectedIndividual] = useState(null);
    const [openRemoveDialog,setOpenRemoveDialog] = useState(false);

    const handleProjSubProjSwitch=()=>{
        setProjSubProjSwitch((prev)=>!prev)
    }

    const vendorAssociated=async()=>{
        setLoading(true);
        await axios.get('/getAssociatedIndividual',{
            params:{
                companyID:props.caseDetails.resourceID
            }
        }).then(response=>{
            setAssociatedIndividual(response.data.association)
            setNewAssociatedIndividual(response.data.association)
        })

        await axios.get('/getProjectResourceAssociation',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            console.log(response.data.projectAssociation[0])
            setProjectAssociation(response.data.projectAssociation[0])
            setNewProjectAssociation(response.data.projectAssociation[0])
        })

        await axios.get('/getProjectResourceAssociation4SubProj',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setProjectAssociation4Sub(response.data.projectAssociation[0])
            setNewprojectAssociation4Sub(response.data.projectAssociation[0])
            
        })

        await axios.get('/getSubscriberTypes')    
            .then(response=>{
            setLoading(false);
            setSubscriberTypes(response.data.data)
            setLoading(false)
        })
        await axios.get('/getSubProjects',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setSubProjects(response.data)
        })
        await axios.get('/getSubProjectResourceAssociation',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setSubProjectAssociations(response.data.data)
            setLoading(false)
        })

    }

    const getAssociatedIndividual = async() =>{
        setLoading(true);
        await axios.get('/getAssociatedIndividual',{
            params:{
                companyID:props.caseDetails.resourceID
            }
        }).then(response=>{
            setLoading(false);
            setAssociatedIndividual(response.data.association)
            setNewAssociatedIndividual(response.data.association)
        })
    }

    const getProjectResourceAssociation = async ()=>{
        setLoading(true);
        await axios.get('/getProjectResourceAssociation',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setLoading(false);
            setProjectAssociation(response.data.projectAssociation[0])
            setNewProjectAssociation(response.data.projectAssociation[0])
        })    
    }

    const getProjectResourceAssociation4SubProj = async ()=>{
        setLoading(true);
        await axios.get('/getProjectResourceAssociation4SubProj',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setLoading(false);
            console.log(response.data)
            setProjectAssociation4Sub(response.data.projectAssociation[0])
            setNewprojectAssociation4Sub(response.data.projectAssociation[0])
            
        })
    }

    const getSubscriberTypes = async ()=>{
        await axios.get('/getSubscriberTypes')    
            .then(response=>{
            setLoading(false);
            setSubscriberTypes(response.data.data)
            setLoading(false)
        })
    }

    const getSubProjects = async ()=>{
        setLoading(true)
        await axios.get('/getSubProjects',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setLoading(false);
            setSubProjects(response.data)
        })
    }

    const getSubProjectResourceAssociation = async ()=>{
        setLoading(true)
        await axios.get('/getSubProjectResourceAssociation',{
            params:{
                projectID:props.caseDetails.projectID
            }
        }).then(response=>{
            setSubProjectAssociations(response.data.data)
            setLoading(false)
        })
    }

    useEffect(()=>{
        // vendorAssociated()
        getAssociatedIndividual()
        getProjectResourceAssociation()
        getProjectResourceAssociation4SubProj()
        getSubscriberTypes()
        getSubProjects()
        getSubProjectResourceAssociation()
    },[])

    useEffect(()=>{
        getAssociatedIndividual()
        getProjectResourceAssociation()
        getProjectResourceAssociation4SubProj()
        getSubProjectResourceAssociation()
    },[projSubProjSwitch])

    const resourcesFilterUtil=(resourceID)=>{
        const newArray=[]
        for(let i=0;i<projectAssociation.resources.length;i++){
            const obj={}
            console.log(projectAssociation.resources[i])
            if(projectAssociation.resources[i].resourceID===resourceID){
                obj.resource=projectAssociation.resources[i]
                obj.vendor=projectAssociation.vendorMaster[i]

                newArray.push(obj)
            }
        } 
        return newArray 
    }


    const associationOfParticularSubProj=(subProjNo)=>{

        const newArray=subProjectAssociations?.filter(item=>item.subProjectNo===subProjNo)
        return newArray
        
    }

    const removeAProjectAssociation=async(item)=>{
        setLoading(true)
        console.log(item)
        axios.post('/removeAProjectAssociation',{
            projectID:props.caseDetails.projectID,
            resourceID:item.resourceID,
            vendorID:props.caseDetails.resourceID
        }).then(response=>{
            setLoading(false)
            getAssociatedIndividual()
            getProjectResourceAssociation()
        })
    }

    const removeASubProjectAssociation=async (subProjNo,resourceIDGiven,projectIDGiven)=>{
        const response=await axios.post('/removeSubProjectAssociation',
        {
            projectID:projectIDGiven,
            resourceID:resourceIDGiven,
            subProjectNo:subProjNo
        })
        if(response.status===200){
            vendorAssociated()
        }
   }

   let container=null
   
    if(projSubProjSwitch){
        if(subProjectAssociationAddBtn){
            container=(
                <AddResourceToSubProj
                    caseDetails = {props.caseDetails}
                    getData = {()=>{
                        setSubProjectAssociationAddBtn(false)
                        // vendorAssociated();
                        getAssociatedIndividual()
                        getProjectResourceAssociation()
                        getProjectResourceAssociation4SubProj()
                        getSubProjectResourceAssociation()
                    }}
                />
            )
        }
        else{
            container=(
                <div style={{width:'100%',display:'flex',alignItems:'start',justifyContent:'space-around',alignItems:'start'}}>
                    <div style={{width:'50%', margin:10}}>
                        <h3 style={{textAlign:'center'}}>Associated Resources of project</h3>
                        <div style={{width:'100%'}}>
                            <TableContainer  className="availableResourceTable">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Name</TableCell>
                                            <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Domain</TableCell>
                                            {subscriberTypes.length>0?
                                            <TableCell><FormControl style={{padding:'0px 10px', minWidth:150, marginTop:8, marginBottom:8}}>
                                            <InputLabel id="demo-simple-select-label" style={{top:-5}}>Subscriber Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedSubscriberType}
                                                label="Subscriber Type"
                                                size = "small"
                                                onChange={(e)=>{
                                                    if(!e.target.value){
                                                        setNewProjectAssociation(projectAssociation)
                                                    }
                                                    else{
                                                    setSelectedSubscriberType(e.target.value)
                                                    let resource=[]
                                                    let vendor=[]
                                                    for(let i=0;i<projectAssociation.resources.length;i++){
                                                        if(projectAssociation.resources[i].subscriberTypeID===e.target.value){
                                                            resource.push(projectAssociation.resources[i])
                                                            vendor.push(projectAssociation.vendorMaster[i])
                                                        }
                                                    }
                                                    let newObj=newProjectAssociation
                                                    newObj.resources=resource
                                                    newObj.vendorMaster=vendor
                                                    setNewProjectAssociation(newObj)}
                                                }}
                                            >
                                            
                                            <MenuItem value = {null}>All Type Subscribers</MenuItem>
                                                {subscriberTypes!==undefined?subscriberTypes?.map(item=>{
                                                    return(
                                                        <MenuItem value = {item?.subscriberTypeID}>{item?.subscriberTypeName}</MenuItem>
                                                    )
                                                }):<div></div>}
                                            </Select>
                                            </FormControl></TableCell>:"no Subscriber Type"}
                                            <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        newProjectAssociation4Sub?newProjectAssociation4Sub.vendorMaster.map((item,index)=>(
                                        <TableRow key={index}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.domainID}</TableCell>
                                            <TableCell>{item.subscriberTypeMaster[0].subscriberTypeName}</TableCell>
                                            <TableCell>{item.resourceTypeID === 'I' ? 'Individual':'Company'}</TableCell>
                                            
                                        </TableRow>
                                        )):<div></div>
                                    
                                    }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    
                    <div style={{width:'50%', margin:10}}>
                        <h3 style = {{textAlign:'center'}}>Associated Resources to Sub-Projects</h3>
                        <div>
                        {
                            subProjects?.subProject[0].data.map((item,index)=>(
                                <Accordion>
                                <AccordionSummary  id="panel-header" aria-controls="panel-content">
                                    Sub-Project No. {item.subProjectNo}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Domain</TableCell>
                                                    <TableCell>Subscriber Type</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                associationOfParticularSubProj(item.subProjectNo) && associationOfParticularSubProj(item.subProjectNo).length>0?associationOfParticularSubProj(item.subProjectNo)[0].vendorMaster.map((itemChild,indexChild)=>(
                                                        <TableRow>
                                                            <TableCell>{itemChild.name}</TableCell>
                                                            <TableCell>{itemChild.domainID}</TableCell>
                                                            <TableCell>{itemChild.subscriberTypeMaster[0].subscriberTypeName}</TableCell>
                                                            <TableCell>{itemChild.resourceTypeID=== 'I' ? 'Individual':'Company'}</TableCell>
                                                            <TableCell>
                                                            <p 
                                                                className="addResourceRemoveBtn"
                                                                onClick={()=>{removeASubProjectAssociation(item.subProjectNo,itemChild.resourceID,props.caseDetails.projectID)}}
                                                            >Remove</p>
                                                            </TableCell>
                                                        </TableRow>
                                                    )):<div></div>
                                                }
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                    
                                </AccordionDetails>
                                </Accordion>
                            ))

                        }
                        </div>
                        <div style={{padding:'10px',display:'flex',justifyContent:'center'}}>
                            <Button onClick={()=>{setSubProjectAssociationAddBtn(true)}} variant="outlined">Add Resources</Button>

                        </div>                  
                    </div>
                </div>
            )
        }

    }
    else{
        if(projectAssociationAddBtn){
            container=(
                <AddResource 
                    caseDetails = {props.caseDetails}
                    getData = {()=>{
                        setProjectAssociationAddBtn(false);
                        // vendorAssociated();
                        getAssociatedIndividual();
                        getProjectResourceAssociation();
                    }}
                />
            )
        }
        else{
        container=(
            <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'space-around',alignItems:'start'}}>
                <div style={{width:'50%', margin:5}}>
                    <h3 style={{textAlign:'center'}}>Associated Resources</h3>
                    <div>
                        {newAssociatedIndividual.length > 0 ?(
                            <TableContainer className="availableResourceTable">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="availableResourceTableHead">Name</TableCell>
                                            <TableCell className="availableResourceTableHead">Domain</TableCell>
                                            {subscriberTypes.length>0?
                                                <TableCell>
                                                    <FormControl style={{padding:'0px 10px', minWidth:154, marginTop:8, marginBottom:8}}>
                                                        <InputLabel id="demo-simple-select-label" style={{top:-5}}>Subscriber Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={selectedSubscriberType}
                                                            label="Subscriber Type"
                                                            // style={{padding:'0px 10px'}}
                                                            size='small'
                                                            onChange={(e)=>{
                                                                if(!e.target.value){
                                                                    setNewAssociatedIndividual(associatedIndividual)
                                                                }
                                                                else{
                                                                setSelectedSubscriberType(e.target.value)
                                                                const newArray=associatedIndividual.filter((item)=>item.individualResourceAssociation.subscriberTypeID===e.target.value)
                                                                console.log(newArray)
                                                                setNewAssociatedIndividual(newArray)}
                                                            }}
                                                        >
                                            
                                                            <MenuItem value = {null}>All Type Subscribers</MenuItem>
                                                            {subscriberTypes.map(item=>{
                                                                return(
                                                                    <MenuItem value = {item?.subscriberTypeID}>{item?.subscriberTypeName}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>:"no Subscriber Type"
                                            }
                                            <TableCell className="availableResourceTableHead">Type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            newAssociatedIndividual.map((item,index)=>(
                                                <TableRow key={index}>
                                                    <TableCell>{item.individualResourceAssociation?.name}</TableCell>
                                                    <TableCell>{item.individualResourceAssociation?.domainID}</TableCell>
                                                    <TableCell>{item.individualResourceAssociation?.subscriberTypeMaster.subscriberTypeName}</TableCell>
                                                    <TableCell>{item.individualResourceAssociation?.resourceTypeID === 'I' ? 'Individual':'Company'}</TableCell>
                                                </TableRow>
                                            )) 
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ):(<p>No Resources Associated with the vendor</p>)}
                    </div>
                </div>
                <div style={{width:'50%', margin:5}}>
                    <h3 style={{textAlign:'center'}}>Associate Resources to project</h3>
                    <div>
                        <Dialog open={openRemoveDialog} onClose={()=>setOpenRemoveDialog(false)}>
                            <DialogTitle>Confirmation</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to delete? Deleting the resource, it will automatically delete in sub-project if associated.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={()=>setOpenRemoveDialog(false)}>Cancel</Button>
                            {/* <Button onClick={requestForAssociation}>Submit</Button> */}
                            <Button onClick={()=>{
                                removeAProjectAssociation(tempSelectedIndividual)
                                setOpenRemoveDialog(false)
                                setTempSelectedIndividual(null);
                            }}>Delete</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                        <div>
                            {newProjectAssociation ? (
                                <TableContainer  className="availableResourceTable">
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Name</TableCell>
                                                <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Domain</TableCell>
                                                {subscriberTypes.length>0?
                                                    <TableCell>
                                                        <FormControl style={{padding:'0px 10px', minWidth:150, marginTop:8, marginBottom:8}}>
                                                            <InputLabel id="demo-simple-select-label" style={{top:-5}}>Subscriber Type</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={selectedSubscriberType}
                                                                label="Subscriber Type"
                                                                size='small'
                                                                onChange={(e)=>{
                                                                    if(!e.target.value){
                                                                        setNewProjectAssociation(projectAssociation)
                                                                    }
                                                                    else{
                                                                    setSelectedSubscriberType(e.target.value)
                                                                    let resource=[]
                                                                    let vendor=[]
                                                                    for(let i=0;i<projectAssociation.resources.length;i++){
                                                                        if(projectAssociation.resources[i].subscriberTypeID===e.target.value){
                                                                            resource.push(projectAssociation.resources[i])
                                                                            vendor.push(projectAssociation.vendorMaster[i])
                                                                        }
                                                                    }
                                                                    let newObj=newProjectAssociation
                                                                    newObj.resources=resource
                                                                    newObj.vendorMaster=vendor
                                                                    setNewProjectAssociation(newObj)}
                                                                }}
                                                            >
                                                            
                                                                <MenuItem value = {null}>All Type Subscribers</MenuItem>
                                                                    {subscriberTypes!==undefined?subscriberTypes?.map(item=>{
                                                                        return(
                                                                            <MenuItem value = {item?.subscriberTypeID}>{item?.subscriberTypeName}</MenuItem>
                                                                        )
                                                                    }):<div></div>}
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell>:"no Subscriber Type"}
                                                <TableCell className="availableResourceTableHead" style={{fontWeight:'bolder'}}>Type</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                newProjectAssociation?newProjectAssociation.vendorMaster.map((item,index)=>(
                                                item!==undefined?
                                                <TableRow key={index}>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.domainID}</TableCell>
                                                    <TableCell>{item.subscriberTypeMaster[0].subscriberTypeName}</TableCell>
                                                    <TableCell>{item.resourceTypeID === 'I' ? 'Individual':'Company'}</TableCell>
                                                    <TableCell>
                                                        <p 
                                                            className="addResourceRemoveBtn"
                                                            onClick={()=>{
                                                                setTempSelectedIndividual(item)
                                                                setOpenRemoveDialog(true)
                                                                // removeAProjectAssociation(projectAssociation?.projectID,item.resourceID)
                                                            }}
                                                        >   
                                                        Remove</p>
                                                    </TableCell>
                                                </TableRow>:null
                                                )):null
                                            
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ):(
                                <p>No resource associated</p>
                            )}
                            <div style={{padding:'10px',display:'flex',justifyContent:'center'}}>
                                <Button onClick={()=>{setProjectAssociationAddBtn(true)}} variant="outlined">Add New Resources</Button>
                            </div>
                        </div>
                </div>
            </div>
        )}
    }

    return (
        <div>
            { loading?<CircularProgress/>:<div>
                <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',gap:'10px', background:'teal', color:'white', borderRadius:5}}>
                    <p style={{fontWeight:'bold'}} >Project Association Mode</p>
                    <Switch
                        checked={projSubProjSwitch}
                        onChange={handleProjSubProjSwitch}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color="secondary"
                    />
                    <p style={{fontWeight:'bold'}}>Sub-Project Association Mode</p>
                </div>
                {container}
            </div>}
        </div>
    )
}

export default ProjectAssociation