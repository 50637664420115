import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from "../../../../axios_tteg";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import classes from "../../../Maps/Map/MapCheck.module.css";
import mapStyles from "../../../Maps/Map/mapStyles";
import satellite from "../../../../assets/images/satellite.png";
import roadMap from "../../../../assets/images/roadMap.png";
import CloseIcon from '@mui/icons-material/Close';

const libraries = ["places"];
const mapContainerStyle = {
  height: "80vh",
  width: "100%",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const mapViewStyle = {
  width: 50,
  margin: 10,
  boxShadow: '0 2px 3px #ccc',
  border: '1px solid #eee',
  cursor: "pointer"
};

const ResourceLocation = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC_kRxUGZMpYA361uWGeGohZW5PYqCoj4k",
    libraries,
  });
  const [locations, setLocations] = useState([]);
  const [mapView, setMapView] = useState("roadmap");
  const [center, setCenter] = useState(null);
  const [loginDetailed, setLoginDetails] = useState("");
  const [selected, setSelected] = useState(null);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState(null);
  
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  useEffect(() => {
    let resourceIDs = props.associatedAssets.map(item => item.individualID);
    axios.get('/getResourceLocation', {
      params: { resourceIDs }
    })
    .then((response) => {
      const firstLocation = response.data.getResLoc[0];
      setCenter({
        lat: parseFloat(firstLocation.latitude),
        lng: parseFloat(firstLocation.longitude),
      });
      setLocations(response.data.getResLoc);
      console.log(response.data.getResLoc,"es")
    })
    .catch(e => console.log(e));
  }, [props.associatedAssets]);

  useEffect(() => {
    // Call loginLog when selectedVendorDetails changes
    if (selectedVendorDetails) {
      loginLog(selectedVendorDetails.resourceID);
    }
  }, [selectedVendorDetails]);

  const loginLog = async (resourceID) => {
    try {
      const loginDetails = await axios.post("/getLoginLog", { resourceID });
      console.log(loginDetails,"lk")
      setLoginDetails(loginDetails.data.latestLoginDetails.timeStamp);
    } catch (error) {
      console.error("Error fetching the login log:", error);
    }
  };

  const getResource = (resourceID) => {
    const foundItem = props.associatedAssets.find(item => item.individualID === resourceID);
    if (foundItem) setSelectedVendorDetails(foundItem.VendorDetails[0]);
  };

  const getDomainName = (domainID) => {
    const domain = props.inputPIUtility.domain.find(item => item.domainID === domainID);
    return domain ? domain.domainDescription.domainName : null;
  };

  const getSubscriberType = (subscriberTypeID) => {
    const subscriberType = props.inputPIUtility.subscriberType.find(item => item.subscriberTypeID === subscriberTypeID);
    return subscriberType ? subscriberType.subscriberTypeName : null;
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps...";
  console.log(loginDetailed,"p")

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        mapTypeId={mapView}
        onLoad={onMapLoad}
        zoom={18}
        options={options}
        clickableIcons={false}
        center={center}
      >
        <CloseIcon onClick={() => props.getData('close')} style={{ cursor: 'pointer', position: 'absolute', top: '0rem', right: '0rem', color: 'white' }} />
        {locations.map(item => (
          <Marker
            draggable={false}
            label={item.resourceID}
            key={item.resourceID}
            position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }}
            onClick={() => {
              setSelected({
                resourceID: item.resourceID,
                position: { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) }
              });
              getResource(item.resourceID);
            }}
          />
        ))}
        {selected && (
          <InfoWindow
            position={selected.position}
            onCloseClick={() => {
              setSelected(null);
              setSelectedVendorDetails(null);
            }}
          >
            <div>
              {selectedVendorDetails && (
                <>
                  <div style={{ textAlign: 'left' }}>
                    <p>Name - {selectedVendorDetails.name}</p>
                    <p>Email - {selectedVendorDetails.email}</p>
                    <p>Mobile - {selectedVendorDetails.mobile}</p>
                    <p>Domain - {getDomainName(selectedVendorDetails.domainID)}</p>
                    <p>Subscriber Type - {getSubscriberType(selectedVendorDetails.subscriberTypeID)}</p>
                    <p>Last Attendance - {loginDetailed}</p>
                  </div>
                </>
              )}
            </div>
          </InfoWindow>
        )}
        <div style={{ position: "absolute", bottom: '2rem', right: '4rem', background: 'white', borderRadius: 5 }}>
          <div style={{ display: 'flex' }}>
            <img 
              src={roadMap} 
              alt="RoadMap" 
              style={mapView === 'roadmap' ? mapViewStyle : { width: 50, margin: 10, cursor: "pointer" }} 
              onClick={() => setMapView('roadmap')} 
            />
            <img 
              src={satellite} 
              alt="Satellite" 
              style={mapView === 'satellite' ? mapViewStyle : { width: 50, margin: 10, cursor: "pointer" }} 
              onClick={() => setMapView('satellite')} 
            />
          </div>
        </div>
      </GoogleMap>
    </div>
  );
};

export default ResourceLocation;
