import React from 'react';
import CaseManagement from './CaseManagement';
import UserManagement from './UserManagement';
import MaterialManagement from './MaterialManagement';
import ServiceManagement from './ServiceManagement';
import MachineManagement from './MachineManagement';

const Container = (props)=>{
    let container = null;
    container = (
        <div>
            {props.menuOption === 1 ? (<CaseManagement/>):null}
            {props.menuOption === 2 ? (<UserManagement/>):null}
            {props.menuOption === 3 ? (<MaterialManagement/>):null}
            {props.menuOption === 4 ? (<ServiceManagement/>):null}
            {props.menuOption === 5 ? (<MachineManagement/>):null}
        </div>
    )

    return(
        <div>
            {container}
        </div>
    )
}

export default Container