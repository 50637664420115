import React,{useState,useEffect} from "react";
import AssetsLeft from "./AssetsLeft";
import AssetsRight from "./AssetsRight";

const Assets = ()=>{
    return(
        <div>
            <div style={{ margin:5}}>
                <AssetsLeft/>
            </div>
            {/* <div style={{background:'white', margin:5}}>
                <AssetsRight/>
            </div> */}
        </div>
    )
}

export default Assets