import React, { useState } from 'react'
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab, Paper, Grid, Typography, AppBar} from '@mui/material';
import classes from "./OnboardManager.module.css"
import Machine from '../tabPanel1/Machine';
import AssociatedMachine from '../tabPanel1/AssociatedMachine';
import HR from '../tabPanel1/HR';
import KYC from '../tabPanel1/KYC';
import Assets from '../tabPanel1/Assets';
import AssociatedAssets from '../tabPanel1/AssociatedAssets';
import Assignment from '../tabPanel1/Assignment';
// import Task from "../Task/Task";
import ProjectAssignment from "../tabPanel1/ProjectAssignment"
import { styled } from '@mui/material/styles';
import Cookies from 'universal-cookie';
import HomeIcon from '@mui/icons-material/Home';
import MoneyIcon from '@mui/icons-material/Money';
import ArticleIcon from '@mui/icons-material/Article';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import Project from '../tabPanel1/Project';

const cookies = new Cookies()

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const OnboardManager = (props) =>{

  const [value, setValue] = useState(cookies.get('userData').subscriberCategoryID === 'P-01' ?'1':null)
  const innerWidth = window.innerWidth;

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    let container = null;
    if(innerWidth <= 768){
      container = (
        <div style={{display:'flex', justifyContent:'space-between',margin: 5,background: 'white',borderRadius: 5,padding: 5}}>
          <div onClick = {()=>cookies.get('userData').subscriberCategoryID === 'P-01' ?setValue('1'):null} >
            <HomeIcon className = {(value === '1')? (classes.active):(classes.deActive)} style = {{background:'none'}}>
              <Tab className="tb" label="Freelancer" value="1" disabled={cookies.get('userData').subscriberCategoryID === 'P-01' ? false:true} style={{fontSize:'small'}} />
            </HomeIcon>
          </div>
          <div onClick = {()=>cookies.get('userData').subscriberCategoryID === 'P-01' ?setValue('4'):null} >
            <MoneyIcon className = {(value === '4')? (classes.active):(classes.deActive)} style = {{background:'none'}}>
              <Tab className="tb" label="My Associated Assets" value="4" disabled={cookies.get('userData').subscriberCategoryID === 'P-01' ? false:true} style={{fontSize:'small'}} />
            </MoneyIcon>
          </div>
          <div onClick = {()=>cookies.get('userData').subscriberCategoryID === 'P-01' ?setValue('5'):null} >
            <ArticleIcon className = {(value === '5')? (classes.active):(classes.deActive)} style = {{background:'none'}}>
                <Tab className="tb" label="Enquiry" value="5" disabled={cookies.get('userData').subscriberCategoryID === 'P-01' ? false:true} style={{fontSize:'small'}} />
            </ArticleIcon>
          </div>
          <div onClick = {()=>(cookies.get('userData').subscriberCategoryID === 'P-01'|| cookies.get('userData').subscriberTypeID === 'F-010') ?setValue('2'):null} >
            <MicrowaveIcon className = {(value === '2')? (classes.active):(classes.deActive)} style = {{background:'none'}}>
              <Tab className="tb" label="My Machines" value="2" disabled={(cookies.get('userData').subscriberCategoryID === 'P-01'|| cookies.get('userData').subscriberTypeID === 'F-010') ? false:true} style={{fontSize:'small'}}/>
            </MicrowaveIcon>
          </div>
          {/* <div onClick = {()=>cookies.get('userData').subscriberCategoryID === 'P-01' ? setValue('3'):null} >
            <HailIcon className = {(value === '3')? (classes.active):(classes.deActive)} style = {{background:'none'}}>
              <Tab className="tb" label="My Human Resources" value="3" disabled={cookies.get('userData').subscriberCategoryID === 'P-01' ? false:true} style={{fontSize:'small'}} />
            </HailIcon>
          </div> */}
        </div>
      )
    }
    else{
      container = (
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div onClick = {()=>cookies.get('userData').subscriberCategoryID === 'P-01' ?setValue('1'):null} style= {{width:"30%"}}>
            <Box className = {(value === '1')? (classes.active):(classes.deActive)}>
                <Tab className="tb" label="Freelancer" value="1" disabled={cookies.get('userData').subscriberCategoryID === 'P-01' ? false:true} style={{fontSize:'small'}} />
            </Box>
          </div>
          <div onClick = {()=>cookies.get('userData').subscriberCategoryID === 'P-01' ?setValue('4'):null} style= {{width:"30%"}}>
            <Box className = {(value === '4')? (classes.active):(classes.deActive)}>
                <Tab className="tb" label="My Associated Assets" value="4" disabled={cookies.get('userData').subscriberCategoryID === 'P-01' ? false:true} style={{fontSize:'small'}} />
            </Box>
          </div>
          <div onClick = {()=>cookies.get('userData').subscriberCategoryID === 'P-01' ?setValue('5'):null} style= {{width:"30%"}}>
            <Box className = {(value === '5')? (classes.active):(classes.deActive)}>
                <Tab className="tb" label="Enquiry" value="5" disabled={cookies.get('userData').subscriberCategoryID === 'P-01' ? false:true} style={{fontSize:'small'}} />
            </Box>
          </div>
          {(cookies.get('userData').subscriberTypeID === 'F-010' || cookies.get('userData').subscriberTypeID === 'P-020')?(
            <div onClick = {()=>(cookies.get('userData').subscriberCategoryID === 'P-01'|| cookies.get('userData').subscriberTypeID === 'F-010') ?setValue('2'):null} style= {{width:"30%"}}>
              <Box className = {(value === '2')? (classes.active):(classes.deActive)}>
                <Tab className="tb" label="My Machines" value="2" disabled={(cookies.get('userData').subscriberCategoryID === 'P-01'|| cookies.get('userData').subscriberTypeID === 'F-010') ? false:true} style={{fontSize:'small'}}/>
              </Box>
            </div>
          ):null}
          {((cookies.get('userData').subscriberCategoryID === 'P-01')&&(cookies.get('userData').subscriberTypeID != 'F-010' || cookies.get('userData').subscriberTypeID != 'P-020'))?(
            <div onClick = {()=>((cookies.get('userData').subscriberCategoryID === 'P-01')&&(cookies.get('userData').subscriberTypeID != 'F-010' || cookies.get('userData').subscriberTypeID != 'P-020')) ?setValue('6'):null} style= {{width:"30%"}}>
              <Box className = {(value === '6')? (classes.active):(classes.deActive)}>
                <Tab className="tb" label="My Machines" value="6" disabled={((cookies.get('userData').subscriberCategoryID === 'P-01')&&(cookies.get('userData').subscriberTypeID != 'F-010' || cookies.get('userData').subscriberTypeID != 'P-020')) ? false:true} style={{fontSize:'small'}}/>
              </Box>
            </div>
          ):null}
          {((cookies.get('userData').subscriberCategoryID === 'P-01')&&(cookies.get('userData').subscriberTypeID != 'F-010' || cookies.get('userData').subscriberTypeID != 'P-020'))?(
            <div onClick = {()=>((cookies.get('userData').subscriberCategoryID === 'P-01')&&(cookies.get('userData').subscriberTypeID != 'F-010' || cookies.get('userData').subscriberTypeID != 'P-020')) ?setValue('7'):null} style= {{width:"30%"}}>
              <Box className = {(value === '7')? (classes.active):(classes.deActive)}>
              <Tab className="tb" label="Assignments" value="7" disabled={((cookies.get('userData').subscriberCategoryID === 'P-01')&&(cookies.get('userData').subscriberTypeID != 'F-010' || cookies.get('userData').subscriberTypeID != 'P-020')) ? false:true} style={{fontSize:'small'}}/>
            </Box>
            </div>
          ):null}
          {/* <div onClick = {()=>cookies.get('userData').subscriberCategoryID === 'P-01' ? setValue('3'):null} style= {{width:"30%"}}>
            <Box className = {(value === '3')? (classes.active):(classes.deActive)}>
                <Tab className="tb" label="My Human Resources" value="3" disabled={cookies.get('userData').subscriberCategoryID === 'P-01' ? false:true} style={{fontSize:'small'}} />
            </Box>
          </div> */}
          
          <div>
            
          </div>
          {/* <div onClick = {()=>cookies.get('userData').subscriberCategoryID === 'P-01' ?setValue('8'):null} style= {{width:"30%"}}>
            <Box className = {(value === '8')? (classes.active):(classes.deActive)}>
                <Tab className="tb" label="My Projects" value="8" disabled={cookies.get('userData').subscriberCategoryID === 'P-01' ? false:true} style={{fontSize:'small'}} />
            </Box>
          </div> */}
        </div>
      )
    }

  return (

    <div>
      {/* <div className={classes.TopHeader}>
        <p style={{margin:0}}>Assets Management</p>
      </div> */}
      <div>
        {container}
        <div className={classes.container}>
          <TabContext value={value} >
            <TabPanel value="1" style = {{padding:0}}><Assets resourceID={props.resourceID}/></TabPanel>
            <TabPanel value="2" style = {{padding:0}}><Machine resourceID={props.resourceID}/></TabPanel>
            <TabPanel value="3" style = {{padding:0}}><HR resourceID={props.resourceID}/></TabPanel>
            <TabPanel value="4" style = {{padding:0}}><AssociatedAssets resourceID={props.resourceID}/></TabPanel>
            <TabPanel value="5" style = {{padding:0}}><Assignment resourceID={props.resourceID}/></TabPanel>
            <TabPanel value="6" style = {{padding:0}}><AssociatedMachine resourceID={props.resourceID}/></TabPanel>
            <TabPanel value="7" style = {{padding:0}}><ProjectAssignment resourceID={props.resourceID}/></TabPanel>

            {/* <TabPanel value="8" style = {{padding:0}}><Project resourceID={props.resourceID}/></TabPanel> */}
          </TabContext>
        </div>
{/*           
          <TabContext value={value}>
          
                <TabPanel value="1"><Machine /></TabPanel>
                <TabPanel value="2"><HR /></TabPanel>
                <TabPanel value="3"><KYC /></TabPanel>
            

            </TabContext> */}
        {/* <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} >
            <Grid item xs={3.9} onClick = {()=>setValue('1')} style={{marginLeft: "10px"}} >
              <Box className = {(value === '1')? (classes.active):(classes.deActive)}>
                  <Tab className="tb" label="Machine" value="1"  />
              </Box>
            </Grid>
            <Grid item xs={3.9} onClick = {()=>setValue('2')}>
              <Box className = {(value === '2')? (classes.active):(classes.deActive)}>
                  <Tab className="tb" label="Human Resources" value="1"  />
              </Box>
            </Grid>
            <Grid item xs={3.9} onClick = {()=>setValue('3')} >
              <Box className = {(value === '3')? (classes.active):(classes.deActive)}>
                  <Tab className="tb" label="KYC" value="3"  />
              </Box>
            </Grid>
            {/* <Grid item xs={3}>
              <Item style={{backgroundColor: 'lightgray', marginRight: '7px', marginTop: '48%'}}>Completed</Item>
            </Grid> */}

            {/* <TabContext value={value}>
          
                <TabPanel value="1"><Machine /></TabPanel>
                <TabPanel value="2"><HR /></TabPanel>
                <TabPanel value="3"><KYC /></TabPanel>
            

            </TabContext>
            
          </Grid>
        </Box> */} 
      </div>

    


    

    </div>
  )
}

export default OnboardManager;