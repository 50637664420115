import React,{useState,useEffect} from 'react';
import axios from "../../../axios_tteg"
import { Grid, Paper, Button, Input,Select,MenuItem,CircularProgress,FormControl,InputLabel,TextField } from "@mui/material";
import { Box } from "@mui/system";
import classes from "./UploadDoc.module.css";
import { FileUploader } from "react-drag-drop-files";
import Link from '@mui/material/Link';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UploadDocIndividual = (props)=>{
    const fileTypes = ["PDF","JPG","JPEG"];
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [docDetail, setDocDetail] = useState({
        "DT-00001": {
            docTypeID: "DT-00001",
            docTypeName: "Upload Document",
            value: null,
            isUploaded: false,
            path: null,
        },
        "DT-00016": {
            docTypeID: "DT-00016",
            docTypeName: "Upload CV",
            value: null,
            isUploaded: false,
            path: null,
          }
    });
    
    const [uploadData,setUploadData] = useState({
        documentTypeName:{
            value:null,
            valid:false
        },
    })

    useEffect(()=>{
        axios.get('/getDetailedUserProfile', {
            params:{
                resourceID:props.resourceID
            }
        }).then((response)=>{
            console.log(response);
            let tempDocDetail = {... docDetail};
            let tempUploadData = {... uploadData};
            if(response.data.vendors.length > 0){
                if(response.data.vendors[0].mUserDocType.length > 0){
                    Object.keys(response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus).map(key=>{
                        console.log(key)
                        tempDocDetail[key].path = response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path;
                        if(response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path){
                            tempDocDetail[key].value={
                                name:response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path.substring(response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path.lastIndexOf('/')+1)
                            }
                        }
                        
                        tempDocDetail[key].isUploaded =  response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].isUploaded;
                    })
                    if(response.data.vendors[0].mUserDocType[0].documentTypeName){
                        tempUploadData['documentTypeName'].value = response.data.vendors[0].mUserDocType[0].documentTypeName;
                        tempUploadData['documentTypeName'].valid = true
                    }
                }
                
            }
            setUploadData(tempUploadData)
            setDocDetail(tempDocDetail);
        }).catch(e=>console.log(e))
    },[])
    const handleChange = (file,docTypeID) => {
        let tempDocDetail = { ...docDetail };
        tempDocDetail[docTypeID].value = file;
        tempDocDetail[docTypeID].isUploaded = false;
        setDocDetail(tempDocDetail);
    };
    const handleChangeData = (key,value) => {
        let tempUploadData = { ...uploadData };
        tempUploadData[key].value = value;
        tempUploadData[key].valid = value ? true:false;
        setUploadData(tempUploadData);
    };

    const uploadDocument = (file, key) => {
        var formData = new FormData();
        formData.append("uploadedFile", file);
        formData.append("resourceID", props.resourceID);
        formData.append("docTypeID", key);
        console.log(formData);
        setLoading(true)
        axios.post("/uploadFile", formData, {})
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                let tempDocDetail = { ...docDetail };
                // tempDocDetail[key].value = null;
                tempDocDetail[key].isUploaded = true;
                tempDocDetail[key].path= response.data.path
                setDocDetail(tempDocDetail);
                alert("File uploaded successfully")
                // updateDatabase(tempDocDetail);
            }
        })
        .catch((e) => {
            setLoading(false);
            setError(e.response.error);
        });
    };

    const updateDatabase = () => {
        setLoading(true);
        let isValid = true;
        let docTypes=[]
        for(var key in docDetail) {
            docTypes.push({
                docTypeID: key,
                isUploaded: docDetail[key].isUploaded,
                path: docDetail[key].path
            });
            isValid = isValid && docDetail[key].isUploaded
        }
        
        for (var key in uploadData){
            isValid = isValid && uploadData[key].valid
        }

        if(isValid){
            props.getData({
                status: 1,
                moveToScreen: 6,
            }); 
            axios
            .post("/uploadIndividualDocTypeSubmit", {
                docTypes: docTypes,
                resourceID: props.resourceID,
                documentTypeName:uploadData.documentTypeName.value
            })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    // console.log(response);    
                    // setError('File Uploaded Successfully');
                    // alert('File Uploaded Successfully');
                    props.getData({
                        status: 1,
                        moveToScreen: 6,
                    }); 
                } else {
                    setLoading(false);
                    setError(response.data.error);
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
            });
        }
        else{
            setLoading(false);
            setError('please fill data marked with *')
        }
        
    }
    const deleteUploaded = (path,key1)=>{
        setLoading(true);
        axios.post('/deleteFile',{
            path:path
        }).then((response)=>{
            let tempDocDetail = { ...docDetail };
            tempDocDetail[key1].isUploaded = false;
            tempDocDetail[key1].path= null
            tempDocDetail[key1].value= null
            let docTypes=[]
            for(var key in tempDocDetail) {
                docTypes.push({
                    docTypeID: key,
                    isUploaded: tempDocDetail[key].isUploaded,
                    path: tempDocDetail[key].path
                });
            }
            axios
            .post("/uploadIndividualDocTypeSubmit", {
                docTypes: docTypes,
                resourceID: props.resourceID,
                documentTypeName:uploadData.documentTypeName.value
            })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    setDocDetail(tempDocDetail);
                    alert("File deleted successfully")
                } else {
                    setLoading(false);
                    setError(response.data.error);
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
            });
            
        })
    }

    console.log(docDetail);
    
    let container = null;
    if(loading){
        container = (<CircularProgress/>)
    }
    else{
        container = (
            <Box>
                <Paper
                    elevation={24}
                    className={classes.PaperStyle}
                >
                
                    {/* <p style={{textAlign:'center', fontWeight:'bold',color:'#53459A'}}>KYC</p>     */}
                    <Grid>
                        <p className={classes.TitleStyle} >
                        Upload Documents
                        <hr className={classes.TitleLineStyle} />
                        </p>
                    </Grid>
                    <div className={classes.UploadDocContainer}>
                        <div className={classes.UploadDocIndividualChild}>
                            <Grid>
                                <FormControl
                                    variant="filled"
                                    style={{ padding: "1% 1% 1% 1%", width: "70%" ,maxWidth:355}}
                                >
                                    <InputLabel id="demo-simple-select-filled-label">
                                        Document Type*
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-filled-label"
                                        id="demo-simple-select-filled"
                                        value={uploadData.documentTypeName.value}
                                        onChange={(e)=>handleChangeData('documentTypeName',e.target.value)}
                                    >
                                        <MenuItem value='Aadhar Card'>Aadhar Card</MenuItem>
                                        <MenuItem value='Pan Card'>Pan Card</MenuItem>
                                        <MenuItem value='Driving Licence'>Driving Licence</MenuItem>
                                        <MenuItem value='Voter I-Card'>voter I-Card</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            
                            <Grid className={classes.style} >
                                <Grid style={{padding: '1%',margin: 'auto'}}>
                                    <Grid >
                                        <table style={{width:'100%', padding:10}}>
                                            <tr>
                                                <td><p style={{margin:2}}>Upload {uploadData.documentTypeName.value}*</p></td>
                                                <td>
                                                    <Button
                                                        disabled = {docDetail["DT-00001"].path ? false:true}
                                                        onClick={()=>window.open(docDetail["DT-00001"].path,"_blank")}
                                                    >   
                                                        <p style={{margin:2}}>{docDetail["DT-00001"].path ? 'View':'Not Found'}</p>
                                                    </Button>
                                                </td>
                                                <td>
                                                    <DeleteIcon
                                                        style={{cursor:docDetail["DT-00001"].path?'pointer':null,color:docDetail["DT-00001"].path ?'#53459A':'gray'}}
                                                        onClick = {()=>docDetail["DT-00001"].path ? deleteUploaded(docDetail["DT-00001"].path,"DT-00001"):null}
                                                    />
                                                </td>
                                            </tr>
                                        </table>
                                    </Grid>
                                    <Grid>
                                        <Grid className={classes.UploadStyle}>
                                            <Grid className={classes.UploaderStyle}>
                                                <FileUploader
                                                    multiple={false}
                                                    handleChange={(file)=>handleChange(file,docDetail["DT-00001"].docTypeID)}
                                                    name={"DT-00001"}
                                                    types={fileTypes}
                                                    className={classes.UploadBoxStyle}
                                                />
                                            </Grid>
                                        {/* <Checkbox checked={docDetail.file1.isUploaded} 
                                        className={classes.CheckBox}
                                        /> */}
                                        </Grid>
                                        <div>
                                            <p className={classes.FileUploadText}>
                                                {docDetail["DT-00001"] && docDetail["DT-00001"].value
                                                ? `File name: ${docDetail["DT-00001"].value.name}`
                                                : "No files uploaded"}
                                            </p>
                                            <div>
                                                <Button
                                                    className={classes.UploadBtn}
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={docDetail["DT-00001"] && docDetail["DT-00001"].value ? false : true}
                                                    onClick={() => {
                                                        uploadDocument(docDetail["DT-00001"].value, "DT-00001");
                                                    }}
                                                >
                                                    Upload
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </div>
                        <div className={classes.UploadDocIndividualChild}>
                            <Grid className={classes.style} >
                                <Grid style={{padding: '1%',margin: 'auto'}}>
                                    <Grid >
                                        <table style={{width:'100%', padding:10}}>
                                            <tr>
                                                <td><p style={{margin:2}}>Upload CV*</p></td>
                                                <td>
                                                    <Button
                                                        disabled = {docDetail["DT-00016"].path ? false:true}
                                                        onClick={()=>window.open(docDetail["DT-00016"].path,"_blank")}
                                                    >   
                                                        <p style={{margin:2}}>{docDetail["DT-00016"].path ? 'View':'Not Found'}</p>
                                                    </Button>
                                                </td>
                                                <td>
                                                    <DeleteIcon
                                                        style={{cursor:docDetail["DT-00016"].path?'pointer':null,color:docDetail["DT-00016"].path ?'#53459A':'gray'}}
                                                        onClick = {()=>docDetail["DT-00016"].path ? deleteUploaded(docDetail["DT-00016"].path,"DT-00016"):null}
                                                    />
                                                </td>
                                            </tr>
                                        </table>
                                    </Grid>
                                    <Grid>
                                        <Grid className={classes.UploadStyle}>
                                            <Grid className={classes.UploaderStyle}>
                                                <FileUploader
                                                    multiple={false}
                                                    handleChange={(file)=>handleChange(file,docDetail["DT-00016"].docTypeID)}
                                                    name={"DT-00016"}
                                                    types={fileTypes}
                                                    className={classes.UploadBoxStyle}
                                                />
                                            </Grid>
                                        {/* <Checkbox checked={docDetail.file1.isUploaded} 
                                        className={classes.CheckBox}
                                        /> */}
                                        </Grid>
                                        <div>
                                            <p className={classes.FileUploadText}>
                                                {docDetail["DT-00016"] && docDetail["DT-00016"].value
                                                ? `File name: ${docDetail["DT-00016"].value.name}`
                                                : "No files uploaded"}
                                            </p>
                                            <div>
                                                <Button
                                                    className={classes.UploadBtn}
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={docDetail["DT-00016"] && docDetail["DT-00016"].value ? false : true}
                                                    onClick={() => {
                                                        uploadDocument(docDetail["DT-00016"].value, "DT-00016");
                                                    }}
                                                >
                                                    Upload
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        
                    </div>
                    
                    <Grid className={classes.SubmitBtnPlacement} >
                            <Button
                                className={classes.SubmitBtn}
                                type="submit"
                                color="primary"
                                variant="contained"
                                onClick={()=>{
                                    updateDatabase();
                                    
                                }}
                            >
                                Continue
                            </Button>
                        </Grid>
                        <p style={{color:'red',textAlign:'center'}}>{error}</p>
                </Paper>
            </Box>
            
        )
    }
    
    return(
        <div>
            {container}
        </div>
    )
}
export default UploadDocIndividual