import React,{useState,useEffect} from 'react';
import axios from "../../../../axios_tteg";
import axiosExt from "axios";
import { Grid, Paper, Button, Input,FormControl} from "@mui/material";
import { Box } from "@mui/system";
import classes from "../../../Register/Upload/UploadDoc.module.css";
import { FileUploader } from "react-drag-drop-files";
import Link from '@mui/material/Link';
import EditIcon from '@mui/icons-material/Edit';
import {
        Select,
        TextField,
        CircularProgress,
        MenuItem,
    } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import classes1 from "./Profile.module.css";
import DeleteIcon from '@mui/icons-material/Delete';

const DetailedProfile = (props)=>{
    const [detailedUserProfile,setDetailedUserProfile] = useState(null);
    const fileTypes = ["PDF","JPG","JPEG"];
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [docDetail, setDocDetail] = useState({
        "DT-00001": {
          docTypeID: "DT-00001",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00002": {
          docTypeID: "DT-00002",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00003": {
          docTypeID: "DT-00003",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00004": {
          docTypeID: "DT-00004",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00005": {
          docTypeID: "DT-00005",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00006": {
          docTypeID: "DT-00006",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00007": {
          docTypeID: "DT-00007",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00008": {
          docTypeID: "DT-00008",
          value: null,
          isUploaded: false,
          path: null
        },
        "DT-00009": {
          docTypeID: "DT-00009",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00010": {
          docTypeID: "DT-00010",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00011": {
          docTypeID: "DT-00011",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00012": {
          docTypeID: "DT-00012",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00016": {
            docTypeID: "DT-00016",
            value: null,
            isUploaded: false,
            path: null,
        },
    });
    const [editBankDetails,setEditBankDetails] = useState(false);
    const [editPIData,setEditPIData] = useState(false);
    const [editKYCData,setEditKYCData] = useState(false);
    const [bankName, setBankName] = useState(null);
    const [entity, setEntity] = React.useState(null);
    const [bankDetails,setBankDetails] = useState({
        bankName:{
            value:null,
            valid:false
        },
        ifscCode:{
            value:null,
            valid:false
        },
        accountNumber:{
            value:null,
            valid:false
        },
        accountHolderName:{
            value:null,
            valid:false
        },
        branchAddress:{
            value:null,
            valid:false
        }
    });
    const [piData,setPIData] = useState({
        // entityName:{
        //   value:null,
        //   valid:true
        // },
        entityTypeID:{
          value:0,
          valid:true
        },
        address:{
          value:null,
          valid:false
        },
        pinCode:{
          value:null,
          valid:false
        },
        country:{
          value:null,
          valid:false
        },
        state:{
          value:null,
          valid:false
        },
        district:{
            value:null,
            valid:false
        },
        city:{
          value:null,
          valid:false
        },
        aadharCardNo:{
            value:null,
            valid:props.resourceTypeID === 'I'?false:true
        },
        vendorName:{
            value:null,
            valid:props.resourceTypeID === 'C'?false:true
        },
        educationDetails:{
            value:null,
            valid:props.resourceTypeID === 'I'?false:true
        },
        experience:{
            value:null,
            valid:props.resourceTypeID === 'I'?false:true
        },
        skillArea:{
            value:null,
            valid:props.resourceTypeID === 'I'?false:true
        },
        summary:{
            value:null,
            valid:true
        }
    })
    const [documentTypeName,setDocumentTypeName] = useState(null);

    const [uploadData,setUploadData] = useState({
        documentTypeName:{
            value:'Document Type',
            valid:false
        },
    })

    useEffect(()=>{
        axios.get('/getDetailedUserProfile', {
            params:{
                resourceID:props.resourceID
            }
        }).then((response)=>{
            console.log(response);
            let tempDocDetail = {... docDetail};
            let tempBankDetails = {... bankDetails};
            let tempPIData = {... piData};
            let tempUploadData = {... uploadData};
            if(response.data.vendors.length > 0){
                if(response.data.vendors[0].mUserDocType.length > 0){
                    Object.keys(response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus).map(key=>{
                        tempDocDetail[key].path = response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path;
                        tempDocDetail[key].isUploaded =  response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].isUploaded;
                        if(response.data.vendors[0].mUserDocType[0].documentTypeName){
                            tempUploadData['documentTypeName'].value = response.data.vendors[0].mUserDocType[0].documentTypeName;
                            tempUploadData['documentTypeName'].valid = true
                        }
                        if(response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path){
                            tempDocDetail[key].value={
                                name:response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path.substring(response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path.lastIndexOf('/')+1)
                            }
                        }
                    })
                }
                
                if(response.data.vendors[0].mUserBankDetails.length > 0){
                    Object.keys(response.data.vendors[0].mUserBankDetails[0]).map(key=>{
                        if(tempBankDetails.hasOwnProperty(key)){
                            tempBankDetails[key].value = response.data.vendors[0].mUserBankDetails[0][key];
                            tempBankDetails[key].valid = true;
                        }
                    })
                }
                if(response.data.vendors[0].mUserPersonalInfo.length > 0){
                    Object.keys(response.data.vendors[0].mUserPersonalInfo[0]).map(key=>{
                        if(tempPIData.hasOwnProperty(key)){
                            if(key === 'address'){
                                Object.keys(response.data.vendors[0].mUserPersonalInfo[0][key]).map(innerKey =>{
                                    tempPIData[innerKey].value = response.data.vendors[0].mUserPersonalInfo[0][key][innerKey];
                                    tempPIData[innerKey].valid = true;
                                })
                            }
                            else{
                                tempPIData[key].value = response.data.vendors[0].mUserPersonalInfo[0][key];
                                tempPIData[key].valid = true;
                            }
                            
                        }
                        
                    })
                }
            }
            setDetailedUserProfile(response.data);
            setDocDetail(tempDocDetail);
            setBankDetails(tempBankDetails);
            setPIData(tempPIData);
        }).catch(e=>console.log(e))
    },[])

    useEffect(()=>{
        setLoading(true)
        axios.get('/registerBankNames')
        .then(response=>{
          console.log(response)
          setLoading(false)
          setBankName(response.data);
        })
        .catch(e=>{
          setLoading(false)
        })
    },[])

    // useEffect(()=>{
    //     setLoading(true);
        
    //     axios.get('/registerPIUtility',{
    //       params:{
    //         resourceTypeID:'C'
    //       }
    //     })
    //     .then(response=>{
    //       setLoading(false);
    //       setEntity(response.data.entityType);
    //     })
    //     .catch(e=>console.log(e))
    // },[])


    const getDocName = (docTypeID)=>{
        let docName = null
        detailedUserProfile.docType.map(item=>{
            if(item.docTypeID === docTypeID){
                docName = item.docTypeName
            }
        })
        return (docName)
    }

    const handleChange = (file,docTypeID) => {
        console.log(docTypeID);
        let tempDocDetail = { ...docDetail };
        tempDocDetail[docTypeID].value = file;
        tempDocDetail[docTypeID].isUploaded = false;
        setDocDetail(tempDocDetail);
    };

    const uploadDocument = (file, key) => {
        var formData = new FormData();
        formData.append("uploadedFile", file);
        formData.append("resourceID", props.resourceID);
        formData.append("docTypeID", key);
        console.log(formData);
        setLoading(true)
        axios.post("/uploadFile", formData, {})
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                let tempDocDetail = { ...docDetail };
                tempDocDetail[key].value = null;
                tempDocDetail[key].isUploaded = true;
                tempDocDetail[key].path= response.data.path
                
                if(props.resourceTypeID === 'I'){
                    updateDatabaseIndividual(tempDocDetail);
                    setDocDetail(tempDocDetail);
                }
                else{
                    updateDatabase(tempDocDetail);
                    setDocDetail(tempDocDetail);
                }
                
            }
        })
        .catch((e) => {
            setLoading(false);
            setError(e.response.error);
        });
    };



    const updateDatabase = (docDet) => {
        setLoading(true)
        let docTypes=[]
        for(var key in docDetail) {
          if(docDet.hasOwnProperty(key)) {
            var item= docDet[key]
            // console.log(item)
            docTypes.push({
              docTypeID: key,
              isUploaded: item.isUploaded,
              path: item.path
            });
          }
        }
        axios
          .post("/uploadDocTypeSubmit", {
            docTypes: docTypes,
            resourceID: props.resourceID,
        })
        .then((response) => {
            console.log(response);
            setLoading(false);
            if (response.status === 200) {
              console.log(response);    
              setError('File Uploaded Successfully');
              alert('File Uploaded Successfully');
            } else {
              setError(response.data.error);
            }
        })
        .catch((e) => {
            setLoading(false);
            setError(e.response.data.error);
        });
    }

    const updateDatabaseIndividual = () => {
        setLoading(true);
        let isValid = true;
        let docTypes=[]
        for(var key in docDetail) {
            if(key === 'DT-00001'){
                docTypes.push({
                    docTypeID: key,
                    isUploaded: docDetail[key].isUploaded,
                    path: docDetail[key].path
                });
                isValid = isValid && docDetail[key].isUploaded
                for (var key in uploadData){
                    isValid = isValid && uploadData[key].valid
                }
            }
            else if(key === 'DT-00016'){
                docTypes.push({
                    docTypeID: key,
                    isUploaded: docDetail[key].isUploaded,
                    path: docDetail[key].path
                });
                isValid = isValid && docDetail[key].isUploaded
            }
            
        }
        console.log(isValid)
        // if(isValid){
            
            axios
            .post("/uploadIndividualDocTypeSubmit", {
                docTypes: docTypes,
                resourceID: props.resourceID,
                documentTypeName:uploadData.documentTypeName.value
            })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    // console.log(response);    
                    setError('File Uploaded Successfully');
                    alert('File Uploaded Successfully');
                } else {
                    setLoading(false);
                    setError(response.data.error);
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
            });
        // }
        // else{
            // setLoading(false);
            // setError('please fill data marked with *')
        // }
        
    }

    const handleBankChange = (key,value)=>{
        console.log(key,value.length)
        const tempBankDetails = { ... bankDetails};
        if(value){
          tempBankDetails[key].value = value;
          tempBankDetails[key].valid = true;
        }
        else{
          tempBankDetails[key].value = value;
          tempBankDetails[key].valid = false;
        }
        if(key === 'ifscCode'){
            if(value.length === 11){
                getDetailsFromIFSC(value)
            }
            else{
                tempBankDetails['bankName'].value = '';
                tempBankDetails['bankName'].valid = false;
                tempBankDetails['branchAddress'].value = '';
                tempBankDetails['branchAddress'].valid = false;
            }
            
        }
        setBankDetails(tempBankDetails);
    };

    const handlePIChange = (key,value)=>{
        const tempPIData = { ... piData};
        if(value){

          tempPIData[key].value = value;
          tempPIData[key].valid = true;
        }
        else{
          tempPIData[key].value = value;
          tempPIData[key].valid = false;
        }
        if(key === 'pinCode'){
            if(value.length === 6)
                getDetailsFromPinCode(value)
            else{
                tempPIData['state'].value = '';
                tempPIData['state'].valid = false;
                tempPIData['district'].value = '';
                tempPIData['district'].valid = false;
                tempPIData['city'].value = '';
                tempPIData['city'].valid = false;
                tempPIData['country'].value = '';
                tempPIData['country'].valid = false;
            }
        }
        setPIData(tempPIData);
    };

    const updateBankDetails = ()=>{
        let isValid = true;
        console.log(bankDetails)
        Object.keys(bankDetails).map(item=>{
          isValid = isValid && bankDetails[item].valid
        });
        if(isValid){
          setLoading(true)
          axios.post('/registerBank',{
            bankName:bankDetails.bankName.value,
            ifscCode:bankDetails.ifscCode.value,
            accountNumber:bankDetails.accountNumber.value,
            bankName:bankDetails.bankName.value,
            accountHolderName:bankDetails.accountHolderName.value,
            resourceID:props.resourceID,
            branchAddress:bankDetails.branchAddress.value,
          })
          .then(response=>{
            console.log(response)
            setLoading(false);
            
          })
          .catch(e=>{
            console.log(e.response.data)
            setLoading(false);
            setError(e.response.data.error)
          })
        }
        else{
            alert('*Please fill appropraite data')
          setError('*Please fill appropraite data')
        }
    }

    const updatePIDataDetails = ()=>{
        let isValid = true;
        console.log(piData);
        Object.keys(piData).map(item=>{
            isValid = isValid && piData[item].valid
        });
        console.log(isValid)
        if(isValid){
            setLoading(true)
            axios.post('/registerPISubmit',{
                entityTypeID:piData.entityTypeID.value,
                address:{
                    address:piData.address.value,
                    pinCode:piData.pinCode.value,
                    city:piData.city.value,
                    district:piData.district.value,
                    country:piData.country.value,
                    state:piData.state.value
                },
                aadharCardNo:piData.aadharCardNo.value,
                vendorName:piData.vendorName.value,
                resourceID:props.resourceID,
                educationDetails:piData.educationDetails.value,
                experience:piData.experience.value,
                skillArea:piData.skillArea.value,
                summary:piData.summary.value
            })
            .then(response=>{
                setLoading(false);
                console.log(response)
            })
            .catch(e=>{
                setLoading(false);
                console.log(e.response.data.error)
                setError(e.response.data.error)
            })
        }
        else{
            alert('*Please fill appropraite data')
            setError('*Please fill appropraite data')
        }
    }

    const getDetailsFromPinCode = (pinCode) =>{
        setLoading(true);
        axiosExt.get(`https://api.postalpincode.in/pincode/${parseInt(pinCode)}`)
        .then(response=>{
            setLoading(false);
            if(response.data[0].PostOffice){
                const tempPIData = { ... piData};
                tempPIData['state'].value = response.data[0].PostOffice[0].State;
                tempPIData['state'].valid = true;
                tempPIData['district'].value = response.data[0].PostOffice[0].District;
                tempPIData['district'].valid = true;
                tempPIData['city'].value = response.data[0].PostOffice[0].Division;
                tempPIData['city'].valid = true;
                tempPIData['country'].value = response.data[0].PostOffice[0].Country;
                tempPIData['country'].valid = true;
                setPIData(tempPIData)
            }
            else{
                const tempPIData = { ... piData};
                tempPIData['state'].value = '';
                tempPIData['state'].valid = false;
                tempPIData['district'].value = '';
                tempPIData['district'].valid = false;
                tempPIData['city'].value = '';
                tempPIData['city'].valid = false;
                tempPIData['country'].value = '';
                tempPIData['country'].valid = false;
                setPIData(tempPIData)
            }
    
        }).catch(e=>{console.log(e)})
    }

    const getDetailsFromIFSC = (ifscCode)=>{
        console.log(ifscCode);
        setLoading(true);
        axiosExt.get(`https://ifsc.razorpay.com/${ifscCode}`)
        .then(response=>{
            setLoading(false);
            const tempBankDetails = { ... bankDetails};
            tempBankDetails['bankName'].value = response.data.BANK;
            tempBankDetails['bankName'].valid = true;
            tempBankDetails['branchAddress'].value = response.data.BRANCH;
            tempBankDetails['branchAddress'].valid = true;
            setBankDetails(tempBankDetails);
        })
        .catch(e=>{
            setLoading(false);
            console.log(e);
            const tempBankDetails = { ... bankDetails};
            tempBankDetails['bankName'].value = '';
            tempBankDetails['bankName'].valid = false;
            tempBankDetails['branchAddress'].value = '';
            tempBankDetails['branchAddress'].valid = false;
            setBankDetails(tempBankDetails);
        });
    }

    const handleChangeData = (key,value) => {
        let tempUploadData = { ...uploadData };
        tempUploadData[key].value = value;
        tempUploadData[key].valid = value ? true:false;
        setUploadData(tempUploadData);
    };

    const deleteUploaded = (path,key1)=>{
        setLoading(true);
        axios.post('/deleteFile',{
            path:path
        }).then((response)=>{
            let tempDocDetail = { ...docDetail };
            tempDocDetail[key1].isUploaded = false;
            tempDocDetail[key1].path= null
            tempDocDetail[key1].value= null
            let docTypes=[]
            for(var key in tempDocDetail) {
                docTypes.push({
                    docTypeID: key,
                    isUploaded: tempDocDetail[key].isUploaded,
                    path: tempDocDetail[key].path
                });
            }
            axios
            .post("/uploadDocTypeSubmit", {
                docTypes: docTypes,
                resourceID: props.resourceID
            })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    setDocDetail(tempDocDetail);
                    alert("File deleted successfully")
                } else {
                    setLoading(false);
                    setError(response.data.error);
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
            });
            
        })
    }

    const deleteUploadedIndividual = (path,key1)=>{
        setLoading(true);
        axios.post('/deleteFile',{
            path:path
        }).then((response)=>{
            let tempDocDetail = { ...docDetail };
            tempDocDetail[key1].isUploaded = false;
            tempDocDetail[key1].path= null
            tempDocDetail[key1].value= null
            let docTypes=[]
            for(var key in tempDocDetail) {
                docTypes.push({
                    docTypeID: key,
                    isUploaded: tempDocDetail[key].isUploaded,
                    path: tempDocDetail[key].path
                });
            }
            axios
            .post("/uploadIndividualDocTypeSubmit", {
                docTypes: docTypes,
                resourceID: props.resourceID,
                documentTypeName:uploadData.documentTypeName.value
            })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    setDocDetail(tempDocDetail);
                    alert("File deleted successfully")
                } else {
                    setLoading(false);
                    setError(response.data.error);
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
            });
            
        })
    }

    console.log(piData);

    let container = null;
    if(loading){
        container = (
            <div style = {{position:'absolute', top:'50vh', left:'50%'}}>
                <CircularProgress />
            </div>
            )
    }
    else if(detailedUserProfile){
        container = (
            <div>
                <ArrowBackIcon style = {{cursor:'pointer', paddingTop:5, paddingLeft:15, color:'#53459A'}}
                    onClick = {()=>props.getData('back')}
                />
                <div className={classes1.Container}>
                    <div
                        className={classes1.DetailedProfile}
                    >
                        <p style={{textAlign:'center', fontWeight:'bold'}}>Personal Info</p>
                        <EditIcon 
                            onClick = {()=>setEditPIData(!editPIData)}
                            style = {{padding: 5,
                                color: editPIData ? '#53459A':'lightgray',
                                cursor:'pointer',
                                float:'right'
                            }}
                            
                        />
                        <div>
                            {/* {detailedUserProfile.vendors.length > 0 ?( */}
                                <table style={{padding:10, width:'100%'}}>
                                    {/* <tr>
                                        <td><p style = {{margin:1}}>Entity Name</p></td>
                                        <td>
                                            <TextField
                                                value={piData.entityName.value}
                                                disabled={editPIData ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                onChange={(e)=>handlePIChange('entityName',e.target.value)}
                                            />
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <td><p style = {{margin:1}}>Entity Type ID</p></td>
                                        <td>
                                            <TextField
                                                value={piData.entityTypeID.value}
                                                disabled={editPIData ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                onChange={(e)=>handlePIChange('entityTypeID',e.target.value)}
                                            />
                                        </td>
                                    </tr> */}
                                    {props.resourceTypeID === 'I'?(
                                        <tr>
                                            <td><p style = {{margin:1}}>Aadhar Card No.*</p></td>
                                            <td>
                                                <TextField
                                                    value={piData.aadharCardNo.value}
                                                    disabled={editPIData ? false:true}
                                                    size = 'small'
                                                    sx={{ input: { color: '#53459A' } }}
                                                    autoComplete="off"
                                                    onChange={(e)=>handlePIChange('aadharCardNo',e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    ):(
                                        <tr>
                                            <td><p style = {{margin:1}}>Single Point of Contact Name (as in Govt ID)*</p></td>
                                            <td>
                                                <TextField
                                                    disabled={editPIData ? false:true}
                                                    value={piData.vendorName.value}
                                                    size = 'small'
                                                    sx={{ input: { color: '#53459A' } }}
                                                    autoComplete="off"
                                                    onChange={(e)=>handlePIChange('vendorName',e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    
                                    {/* <tr>
                                        <td><p style = {{margin:1}}>Resource ID</p></td>
                                        <td>
                                            <TextField
                                                value={piData.resourceID.value}
                                                disabled={editPIData ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                onChange={(e)=>handlePIChange('aadharCardNo',e.target.value)}
                                            />
                                            <p style = {{margin:1}}>{detailedUserProfile.vendors[0].resourceID}</p>
                                        </td>
                                    </tr> */}
                                    
                                    <tr>
                                        <td><p style = {{margin:1}}>Pin Code*</p></td>
                                        <td>
                                            <TextField
                                                value={piData.pinCode.value}
                                                disabled={editPIData ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                autoComplete="off"
                                                onChange={(e)=>handlePIChange('pinCode',e.target.value)}
                                            />
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].address.pinCode}</p> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style = {{margin:1}}>City*</p></td>
                                        <td>
                                            <TextField
                                                value={piData.city.value}
                                                disabled={editPIData ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                autoComplete="off"
                                                onChange={(e)=>handlePIChange('city',e.target.value)}
                                            />
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].address.city}</p> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style = {{margin:1}}>District*</p></td>
                                        <td>
                                            <TextField
                                                value={piData.district.value}
                                                disabled={editPIData ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                autoComplete="off"
                                                onChange={(e)=>handlePIChange('district',e.target.value)}
                                            />
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].address.city}</p> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style = {{margin:1}}>State*</p></td>
                                        <td>
                                            <TextField
                                                value={piData.state.value}
                                                disabled={editPIData ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                autoComplete="off"
                                                onChange={(e)=>handlePIChange('state',e.target.value)}
                                            />
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].address.state}</p> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style = {{margin:1}}>Country*</p></td>
                                        <td>
                                            <TextField
                                                value={piData.country.value}
                                                disabled={editPIData ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                autoComplete="off"
                                                onChange={(e)=>handlePIChange('country',e.target.value)}
                                            />
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].address.country}</p></td> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style = {{margin:1}}>Address*</p></td>
                                        <td>
                                            <TextField
                                                value={piData.address.value}
                                                disabled={editPIData ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                autoComplete="off"
                                                onChange={(e)=>handlePIChange('address',e.target.value)}
                                            />
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].address.address}</p> */}
                                        </td>
                                    </tr>
                                    {props.resourceTypeID === 'I'}{
                                        <tr>
                                            <td><p style = {{margin:1}}>Educational Details*</p></td>
                                            <td>
                                                <Select
                                                    labelId="demo-simple-select-filled-label"
                                                    id="demo-simple-select-filled"
                                                    value={piData.educationDetails.value}
                                                    onChange={(e)=>handlePIChange('educationDetails',e.target.value)}
                                                    size="small"
                                                    disabled={editPIData ? false:true}
                                                    sx={{ input: { color: '#53459A' } }}
                                                    autoComplete="off"
                                                    style = {{width:'88%'}}
                                                >
                                                    <MenuItem value='10th'>10th</MenuItem>
                                                    <MenuItem value='12th'>12th</MenuItem>
                                                    <MenuItem value='Graduate & above'>Graduate & above</MenuItem>
                                                </Select>
                                            </td>
                                        </tr>
                                    }
                                    {props.resourceTypeID === 'I'}{
                                        <tr>
                                            <td><p style = {{margin:1}}>Skill Areas*</p></td>
                                            <td>
                                                <TextField
                                                    disabled={editPIData ? false:true}
                                                    size = 'small'
                                                    sx={{ input: { color: '#53459A' } }}
                                                    autoComplete="off"
                                                    value={piData.skillArea.value}
                                                    onChange={(e)=>handlePIChange('skillArea',e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    {props.resourceTypeID === 'I'}{
                                        <tr>
                                            <td><p style = {{margin:1}}>Experience in months*</p></td>
                                            <td>
                                                <TextField
                                                    value={piData.experience.value}
                                                    disabled={editPIData ? false:true}
                                                    size = 'small'
                                                    sx={{ input: { color: '#53459A' } }}
                                                    autoComplete="off"
                                                    onChange={(e)=>handlePIChange('experience',e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    {props.resourceTypeID === 'I'}{
                                        <tr>
                                            <td><p style = {{margin:1}}>Summary</p></td>
                                            <td>
                                                <TextField
                                                    value={piData.summary.value}
                                                    disabled={editPIData ? false:true}
                                                    size = 'small'
                                                    sx={{ input: { color: '#53459A' } }}
                                                    autoComplete="off"
                                                    onChange={(e)=>handlePIChange('summary',e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    }
                                </table>
                                <div style={{textAlign:'center'}}> 
                                    <Button
                                        style = {{
                                            background: editPIData ? '#53459A':'lightgray',
                                            padding:5,
                                            margin:5,
                                            color:'white',
                                            width:120
                                        }}
                                        disabled = {editPIData ? false:true}
                                        onClick = {()=>updatePIDataDetails()}
                                    >Update</Button> 
                                </div>
                            {/* ):
                            (<p> NO Data Found</p>)
                            } */}
                            
                        </div>                 
                    </div>
                    <div
                        className={classes1.DetailedProfile}
                    >
                        <p style={{textAlign:'center', fontWeight:'bold'}}>Bank Details</p>
                        <EditIcon 
                            onClick = {()=>setEditBankDetails(!editBankDetails)}
                            style = {{padding: 5,
                                color: editBankDetails ? '#53459A':'lightgray',
                                cursor:'pointer',
                                float:'right'
                            }}
                            
                        />
                        <div>
                            {/* {(detailedUserProfile.vendors.length > 0 )?( */}
                            <div>
                                <table style={{padding:10, width:'100%'}}>
                                    <tr>
                                        <td><p style = {{margin:1}}>IFSC Code*</p></td>
                                        <td>
                                            <TextField
                                                value={bankDetails.ifscCode.value}
                                                disabled={editBankDetails ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                autoComplete="off"
                                                onChange={(e)=>handleBankChange('ifscCode',e.target.value)}
                                            />
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].mUserBankDetails.length > 0 ? detailedUserProfile.vendors[0].ifscCode:null}</p></td> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style = {{margin:1}}>Bank Name*</p></td>
                                        <td>
                                        {/* <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value={bankDetails.bankName.value}
                                            disabled={true}
                                            size = 'small'
                                            style = {{width:'88%',color:'#53459A'}}
                                            onChange={(e)=>handleBankChange('bankName',e.target.value)}
                                        >
                                            {bankName && bankName.map(item=>(
                                                <MenuItem value={item.bankName} style = {{color : '#53459A'}}>{item.bankName}</MenuItem>
                                            )
                                            )}
                                        </Select> */}
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].mUserBankDetails.length > 0 ? detailedUserProfile.vendors[0].bankName:null}</p> */}
                                            <TextField
                                                value={bankDetails.bankName.value}
                                                disabled={true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style = {{margin:1}}>Branch Address*</p></td>
                                        <td>
                                            <TextField
                                                value={bankDetails.branchAddress.value}
                                                disabled={true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                // onChange={(e)=>handleBankChange('branchAddress',e.target.value)}
                                            />
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].mUserBankDetails.length > 0 ? detailedUserProfile.vendors[0].branchAddress:'-'}</p> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style = {{margin:1}}>Account Number*</p></td>
                                        <td>
                                            <TextField
                                                value={bankDetails.accountNumber.value}
                                                disabled={editBankDetails ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                autoComplete="off"
                                                onChange={(e)=>handleBankChange('accountNumber',e.target.value)}
                                            />
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].mUserBankDetails.length > 0 ? detailedUserProfile.vendors[0].accountNumber:null}</p> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><p style = {{margin:1}}>Account Holder Name*</p></td>
                                        <td>
                                            <TextField
                                                value={bankDetails.accountHolderName.value}
                                                disabled={editBankDetails ? false:true}
                                                size = 'small'
                                                sx={{ input: { color: '#53459A' } }}
                                                autoComplete="off"
                                                onChange={(e)=>handleBankChange('accountHolderName',e.target.value)}
                                            />
                                            {/* <p style = {{margin:1}}>{detailedUserProfile.vendors[0].mUserBankDetails.length > 0 ? detailedUserProfile.vendors[0].accountHolderName:null}</p> */}
                                        </td>
                                    </tr>
                                    
                                </table>
                                <div style={{textAlign:'center'}}> 
                                    <Button
                                        style = {{
                                            background: editBankDetails ? '#53459A':'lightgray',
                                            padding:5,
                                            margin:5,
                                            color:'white',
                                            width:120
                                        }}
                                        disabled = {editBankDetails ? false:true}
                                        onClick = {()=>updateBankDetails()}
                                    >Update</Button> 
                                </div>
                                
                            </div>
                            {/* ):
                            (<p style={{padding:10}}> NO Data Found</p>)
                            } */}
                            
                        </div>             
                    </div>
                    <div className={classes1.DetailedProfile}>
                        <EditIcon 
                            onClick = {()=>setEditKYCData(!editKYCData)}
                            style = {{padding: 5,
                                color: editKYCData ? '#53459A':'lightgray',
                                cursor:'pointer',
                                float:'right'
                            }}
                            
                        />
                        <p style={{textAlign:'center', fontWeight:'bold'}}>KYC</p>    
                        <table style={{width:'100%', padding:10}}>
                            {Object.keys(docDetail).map(key=>{
                                console.log(key);
                                if(props.resourceTypeID === 'C'){
                                    return(
                                        <tr>
                                            <td><p style={{margin:2}}>{getDocName(key)}</p></td>
                                            <td>
                                                <Button
                                                    disabled = {docDetail[key].path ? false:true}
                                                    onClick={()=>window.open(docDetail[key].path,"_blank")}
                                                >
                                                    
                                                        <p style={{margin:2}}>{docDetail[key].path ? 'View':'Not Found'}</p>
                                                    
                                                </Button>
                                            </td>
                                            <td>
                                                <DeleteIcon
                                                    style={{cursor:docDetail[key].path?'pointer':null,color:docDetail[key].path ?'#53459A':'gray'}}
                                                    onClick = {()=>docDetail[key].path ? deleteUploaded(docDetail[key].path,key):null}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }
                                else{
                                    if(key === 'DT-00001'){
                                        return(
                                            <>
                                                <tr>
                                                    <td><p style={{margin:2}}>Document Type</p></td>
                                                    <td>
                                                        <Select
                                                            labelId="demo-simple-select-filled-label"
                                                            id="demo-simple-select-filled"
                                                            value={uploadData.documentTypeName.value}
                                                            onChange={(e)=>handleChangeData('documentTypeName',e.target.value)}
                                                            size="small"
                                                            disabled = {!editKYCData}
                                                        >
                                                            <MenuItem value='Aadhar Card'>Aadhar Card</MenuItem>
                                                            <MenuItem value='Pan Card'>Pan Card</MenuItem>
                                                            <MenuItem value='Driving Licence'>Driving Licence</MenuItem>
                                                            <MenuItem value='Voter I-Card'>voter I-Card</MenuItem>
                                                        </Select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><p style={{margin:2}}>{uploadData.documentTypeName.value}</p></td>
                                                    <td>
                                                        <Button
                                                            disabled = {docDetail[key].path ? false:true}
                                                            onClick={()=>window.open(docDetail[key].path,"_blank")}
                                                        >   
                                                            <p style={{margin:2}}>{docDetail[key].path ? 'View':'Not Found'}</p>
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <DeleteIcon
                                                            style={{cursor:docDetail[key].path?'pointer':null,color:docDetail[key].path ?'#53459A':'gray'}}
                                                            onClick = {()=>docDetail[key].path ? deleteUploadedIndividual(docDetail[key].path,key):null}
                                                        />
                                                    </td>
                                                </tr>
                                            </>
                                            
                                        )
                                    }
                                    else if(key === 'DT-00016') {
                                        return(
                                                <tr>
                                                    <td><p style={{margin:2}}>{getDocName(key)}</p></td>
                                                    <td>
                                                        <Button
                                                            disabled = {docDetail[key].path ? false:true}
                                                            onClick={()=>window.open(docDetail[key].path,"_blank")}
                                                        >
                                                            
                                                                <p style={{margin:2}}>{docDetail[key].path ? 'View':'Not Found'}</p>
                                                            
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <DeleteIcon
                                                            style={{cursor:docDetail[key].path?'pointer':null,color:docDetail[key].path ?'#53459A':'gray'}}
                                                            onClick = {()=>docDetail[key].path ? deleteUploadedIndividual(docDetail[key].path,key):null}
                                                        />
                                                    </td>
                                                </tr>
                                            
                                        )
                                    }
                                }    
                            })}
                            
                        </table>
                    </div>
                    
                </div>
                <div
                    style = {{
                        margin: '1%',
                        boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
                        borderRadius:5,
                        // height:'40vh',
                        padding:10,
                        color: '#53459A'
                    }}
                >
                    <p style={{textAlign:'center', fontWeight:'bold'}}>Update KYC</p>
                    <div style={{whiteSpace:'nowrap', overflow:'auto'}}>
                        {(detailedUserProfile.docType).map(item=>{
                            if(props.resourceTypeID === 'C'){
                                if(docDetail.hasOwnProperty(item.docTypeID)){
                                    return (
                                        <div style={{display:'inline-block', margin:20}}>
                                            <Grid className={classes.style} >
                                                <Grid className={classes.alignItems}>
                                                <Grid className={classes.HeadingStyle}>
                                                    <p>{getDocName(item.docTypeID)}</p>
                                                </Grid>
                                                <Grid>
                                                    <Grid className={classes.UploadStyle}>
                                                    <Grid className={classes.UploaderStyle}>
                                                        <FileUploader
                                                            multiple={false}
                                                            handleChange={(file)=>handleChange(file,item.docTypeID)}
                                                            name={item.docTypeID}
                                                            types={fileTypes}
                                                            className={classes.UploadBoxStyle}
                                                        />
                                                    </Grid>
                                                    {/* <Checkbox checked={docDetail.file1.isUploaded} 
                                                    className={classes.CheckBox}
                                                    /> */}
                                                    </Grid>
                                                    <div >
                                                    <p className={classes.FileUploadText}>
                                                        {docDetail[item.docTypeID] && docDetail[item.docTypeID].value
                                                        ? `File name: ${docDetail[item.docTypeID].value.name}`
                                                        : "No files uploaded"}
                                                    </p>
                                                    <div >
                                                        <Button
                                                        className={classes.UploadBtn}
                                                        type="submit"
                                                        color="primary"
                                                        variant="contained"
                                                        disabled={docDetail[item.docTypeID] && docDetail[item.docTypeID].value ? false : true}
                                                        onClick={() => {
                                                            uploadDocument(docDetail[item.docTypeID].value, item.docTypeID);
                                                        }}
                                                        >
                                                        Upload
                                                        </Button>
                                                    </div>
                                                    </div>
                                                </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                }
                            }
                            else{
                                if(item.docTypeID === 'DT-00001' || item.docTypeID === 'DT-00016'){
                                    if(docDetail.hasOwnProperty(item.docTypeID)){
                                        return (
                                            <div style={{display:'inline-block', margin:20}}>
                                                <Grid className={classes.style} >
                                                    
                                                    <Grid className={classes.alignItems}>
                                                        <Grid className={classes.HeadingStyle}>
                                                            <p>{item.docTypeID === 'DT-00001' ? uploadData.documentTypeName.value:getDocName(item.docTypeID)}</p>
                                                        </Grid>
                                                    <Grid>
                                                        <Grid className={classes.UploadStyle}>
                                                            <Grid className={classes.UploaderStyle}>
                                                                <FileUploader
                                                                    multiple={false}
                                                                    handleChange={(file)=>handleChange(file,item.docTypeID)}
                                                                    name={item.docTypeID}
                                                                    types={fileTypes}
                                                                    className={classes.UploadBoxStyle}
                                                                />
                                                            </Grid>
                                                            {/* <Checkbox checked={docDetail.file1.isUploaded} 
                                                            className={classes.CheckBox}
                                                            /> */}
                                                            </Grid>
                                                            <div >
                                                            <p className={classes.FileUploadText}>
                                                                {docDetail[item.docTypeID] && docDetail[item.docTypeID].value
                                                                ? `File name: ${docDetail[item.docTypeID].value.name}`
                                                                : "No files uploaded"}
                                                            </p>
                                                            <div >
                                                                <Button
                                                                className={classes.UploadBtn}
                                                                type="submit"
                                                                color="primary"
                                                                variant="contained"
                                                                disabled={docDetail[item.docTypeID] && docDetail[item.docTypeID].value ? false : true}
                                                                onClick={() => {
                                                                    uploadDocument(docDetail[item.docTypeID].value, item.docTypeID);
                                                                }}
                                                                >
                                                                Upload
                                                                </Button>
                                                            </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )
                                    }
                                }
                            }
                            
                            
                        })}
                    </div>
                </div>
            </div>
            
        )
    }
    return(
        <div>
            {container}
        </div>
    )
}

export default DetailedProfile