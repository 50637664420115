import React,{useState,useEffect} from 'react';
import axios from '../../../../axios_tteg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import classes from "../../../Register/Upload/UploadDoc.module.css";
import { FileUploader } from "react-drag-drop-files";
import DownloadIcon from '@mui/icons-material/Download';

const ServiceManagement = ()=>{
    const [serviceItem,setServiceItem] = useState([]);
    const [serviceSubCategory,setServiceSubCategory] = useState([]);
    const [open,setOpen] = useState(false);
    const [openEdit,setOpenEdit] = useState(false);
    const [selectedsubComponentSubCategoryID,setSelectedsubComponentSubCategoryID] = useState(null);
    const [assetSubComponentID,setAssetSubComponentID] = useState(null)
    const [newItem,setNewItem] = useState({
        name:null,
        variety:null,
        unit:null
    });
    const [error,setError] = useState(null);
    const [catalogue,setCatalogue] = useState({
        value:null,
        isUploaded:false,
        path: null,
    });
    const [loading,setLoading] = useState(false);
    const fileTypes = ["PDF","JPG","JPEG"];

    useEffect(()=>{
        getAssetSubComponentSubCategory()
        getServiceItem();
    },[])

    const getAssetSubComponentSubCategory = ()=>{
        axios.get('/getAssetSubComponentSubCategory',{
            params:{
                subComponentCategoryID:'Services01'
            }
        })
        .then((response)=>{
            
            setServiceSubCategory(response.data.subComponentCategory);
        })
        .catch(e=>console.log(e))
    }

    const getServiceItem = ()=>{
        axios.get('/getAssetSubComponentMaster',{
            params:{
                subComponentCategoryID:'Services01'
            }
        })
        .then((response)=>{
            setServiceItem(response.data.assetSubCompCat);
        })
        .catch(e=>console.log(e))
    }

    const handleOpen = (subComponentSubCategoryID)=>{
        setSelectedsubComponentSubCategoryID(subComponentSubCategoryID);
        setOpen(true);
    }

    const addNewItemHandler = ()=>{
        if(selectedsubComponentSubCategoryID && newItem.name && newItem.variety && newItem.unit && catalogue.path){
            axios.post('/addAssetSubComponentMaster',{
                subComponentCategoryID:'Services01',
                subComponentSubCategoryID:selectedsubComponentSubCategoryID,
                assetSubComponentName:newItem.name,
                assetSubComponentVarieties:newItem.variety,
                unit:newItem.unit,
                catalogue:catalogue.path
            })
            .then(response=>{
                setNewItem({
                    name:null,
                    variety:null,
                    unit:null
                });
                setSelectedsubComponentSubCategoryID(null);
                alert("Added Successfully");
                setOpen(false);
                getServiceItem();
                setCatalogue({
                    value:null,
                    isUploaded:false,
                    path: null,
                })
            })
            .catch(e=>console.log(e))
        }
        else{
            alert("Please fill all data marked with *");
        }
    }

    const updateMaterial = (subComponentSubCategoryID,assetSubComponentID,assetSubComponentName,assetSubComponentVarieties,unit,catalogue)=>{
        setOpenEdit(true);
        setSelectedsubComponentSubCategoryID(subComponentSubCategoryID);
        setAssetSubComponentID(assetSubComponentID);
        setNewItem({
            name:assetSubComponentName,
            variety:assetSubComponentVarieties,
            unit:unit
        });
        setCatalogue({
            value:null,
            isUploaded:true,
            path: catalogue,
        })
    }

    const updateItemHandler = ()=>{
        if(selectedsubComponentSubCategoryID && newItem.name && newItem.variety && newItem.unit && catalogue.path){
            axios.post('/updateAssetSubComponentMaster',{
                subComponentCategoryID:'Services01',
                subComponentSubCategoryID:selectedsubComponentSubCategoryID,
                assetSubComponentID:assetSubComponentID,
                assetSubComponentName:newItem.name,
                assetSubComponentVarieties:newItem.variety,
                unit:newItem.unit,
                catalogue:catalogue.path
            })
            .then(response=>{
                setNewItem({
                    name:null,
                    variety:null,
                    unit:null
                });
                setSelectedsubComponentSubCategoryID(null);
                alert("Updated Successfully");
                setOpenEdit(false);
                getServiceItem();
            })
            .catch(e=>console.log(e))
        }
        else{
            alert("Please fill all data marked with *")
        }
    }

    const deleteItemHandler = (assetSubComponentID)=>{
        axios.post('/deleteAssetSubComponentMaster',{
            assetSubComponentID:assetSubComponentID
        })
        .then(response=>{
            alert("Deleted Successfully");
            getServiceItem();
        })
        .catch(e=>console.log(e))
    }

    const handleChange = (file) => {
        let tempCatalogue = { ...catalogue };
        tempCatalogue.value = file;
        tempCatalogue.isUploaded = false;
        setCatalogue(tempCatalogue);
    };

    const uploadDocument = (file) => {
        var formData = new FormData();
        if(selectedsubComponentSubCategoryID && newItem.name && newItem.variety){
            formData.append("uploadedFile", file);
            formData.append("subComponentCategoryID", 'Material01');
            formData.append("subComponentSubCategoryID", selectedsubComponentSubCategoryID);
            formData.append("assetSubComponentName", newItem.name);
            formData.append("assetSubComponentVarieties", newItem.variety);
            console.log(formData);
            setLoading(true)
            axios.post("/uploadCatalogueFile", formData, {})
                .then((response) => {
                    setLoading(false);
                    if (response.status === 200) {
                        let tempCatalogue = { ...catalogue };
                        tempCatalogue.value = null;
                        tempCatalogue.isUploaded = true;
                        tempCatalogue.path= response.data.path
                        setCatalogue(tempCatalogue);
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.error);
            });
        }
        else{
            alert("Please fill above data")
        }
        
    };

    let container = null;
    if(open){
        container = (
            <div>
                <Dialog
                    open={open}
                    onClose={()=>setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Add New Item
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div >
                                <table >
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>Item Name</td>
                                        <td style={{padding:'5px 10px'}}>
                                            <TextField
                                                id="outlined-required"
                                                label="Item Name*"
                                                value = {newItem.name}
                                                onChange={(e)=>{
                                                    let tempNewItem = {... newItem};
                                                    tempNewItem.name = e.target.value;
                                                    setNewItem(tempNewItem)
                                                }}
                                                size = 'small'
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>Variety</td>
                                        <td style={{padding:'5px 10px'}}>
                                            <TextField
                                                id="outlined-required"
                                                label="Variety*"
                                                value = {newItem.variety}
                                                onChange={(e)=>{
                                                    let tempNewItem = {... newItem};
                                                    tempNewItem.variety = e.target.value;
                                                    setNewItem(tempNewItem)
                                                }}
                                                size = 'small'
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>Unit</td>
                                        <td style={{padding:'5px 10px'}}>
                                            <TextField
                                                id="outlined-required"
                                                label="Unit*"
                                                value = {newItem.unit}
                                                onChange={(e)=>{
                                                    let tempNewItem = {... newItem};
                                                    tempNewItem.unit = e.target.value;
                                                    setNewItem(tempNewItem)
                                                }}
                                                size = 'small'
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>Catalogue*</td>
                                        <td style={{padding:'5px 10px'}}>
                                            <div style={{display:'inline-block', margin:20}}>
                                                {catalogue.path ? (
                                                    <Button onClick={()=>{window.open(catalogue.path,"_blank")}}><p>View Catalogue</p></Button>
                                                    ):null
                                                }
                                                <Grid className={classes.style} >
                                                    
                                                    <Grid className={classes.alignItems}>
                                                        <Grid className={classes.UploadStyle}>
                                                            <Grid className={classes.UploaderStyle}>
                                                                <FileUploader
                                                                    multiple={false}
                                                                    handleChange={(file)=>handleChange(file)}
                                                                    name='Catalogue'
                                                                    types={fileTypes}
                                                                    className={classes.UploadBoxStyle}
                                                                />
                                                            </Grid>
                                                            {/* <Checkbox checked={docDetail.file1.isUploaded} 
                                                            className={classes.CheckBox}
                                                            /> */}
                                                            </Grid>
                                                            <div >
                                                            <p className={classes.FileUploadText} style={{width:'100%'}}>
                                                                {catalogue && catalogue.value
                                                                ? `File name: ${catalogue.value.name}`
                                                                : "No files uploaded"}
                                                            </p>
                                                            <div >
                                                                <Button
                                                                className={classes.UploadBtn}
                                                                type="submit"
                                                                color="primary"
                                                                variant="contained"
                                                                disabled={catalogue && catalogue.value ? false : true}
                                                                onClick={() => {
                                                                    uploadDocument(catalogue.value);
                                                                }}
                                                                >
                                                                Upload
                                                                </Button>
                                                            </div>
                                                            </div>
                                                        </Grid>
                                                </Grid>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                <p style={{color:'red', fontSize:'small'}}>{error}</p>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpen(false)}>Cancel</Button>
                        <Button onClick={()=>addNewItemHandler()} autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                    
                </Dialog>
            </div>
        )
    }
    else if(openEdit){
        container = (
            <div>
                <Dialog
                    open={openEdit}
                    onClose={()=>setOpenEdit(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Update Item
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div >
                                <table >
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>Item Name</td>
                                        <td style={{padding:'5px 10px'}}>
                                        <TextField
                                            id="outlined-required"
                                            label="Item Name*"
                                            value = {newItem.name}
                                            onChange={(e)=>{
                                                let tempNewItem = {... newItem};
                                                tempNewItem.name = e.target.value;
                                                setNewItem(tempNewItem)
                                            }}
                                            size = 'small'
                                        />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>Variety</td>
                                        <td style={{padding:'5px 10px'}}>
                                        <TextField
                                            id="outlined-required"
                                            label="Variety*"
                                            value = {newItem.variety}
                                            onChange={(e)=>{
                                                let tempNewItem = {... newItem};
                                                tempNewItem.variety = e.target.value;
                                                setNewItem(tempNewItem)
                                            }}
                                            size = 'small'
                                        />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>Unit</td>
                                        <td style={{padding:'5px 10px'}}>
                                        <TextField
                                            id="outlined-required"
                                            label="Unit*"
                                            value = {newItem.unit}
                                            onChange={(e)=>{
                                                let tempNewItem = {... newItem};
                                                tempNewItem.unit = e.target.value;
                                                setNewItem(tempNewItem)
                                            }}
                                            size = 'small'
                                        />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{padding:'5px 10px'}}>Catalogue*</td>
                                        <td style={{padding:'5px 10px'}}>
                                            <div style={{display:'inline-block', margin:20}}>
                                                {catalogue.path ? (
                                                    <Button onClick={()=>{window.open(catalogue.path,"_blank")}}><p>View Catalogue</p></Button>
                                                    ):null
                                                }
                                                <Grid className={classes.style} >
                                                    <Grid className={classes.alignItems}>
                                                        <Grid className={classes.UploadStyle}>
                                                            <Grid className={classes.UploaderStyle}>
                                                                <FileUploader
                                                                    multiple={false}
                                                                    handleChange={(file)=>handleChange(file)}
                                                                    name='Catalogue'
                                                                    types={fileTypes}
                                                                    className={classes.UploadBoxStyle}
                                                                />
                                                            </Grid>
                                                            {/* <Checkbox checked={docDetail.file1.isUploaded} 
                                                            className={classes.CheckBox}
                                                            /> */}
                                                            </Grid>
                                                            <div >
                                                                <p className={classes.FileUploadText} style={{width:'100%'}}>
                                                                    {catalogue && catalogue.value
                                                                    ? `File name: ${catalogue.value.name}`
                                                                    : "No files uploaded"}
                                                                </p>
                                                                <div >
                                                                    <Button
                                                                    className={classes.UploadBtn}
                                                                    type="submit"
                                                                    color="primary"
                                                                    variant="contained"
                                                                    disabled={catalogue && catalogue.value ? false : true}
                                                                    onClick={() => {
                                                                        uploadDocument(catalogue.value);
                                                                    }}
                                                                    >
                                                                    Upload
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                </Grid>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                                <p style={{color:'red', fontSize:'small'}}>{error}</p>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenEdit(false)}>Cancel</Button>
                        <Button onClick={()=>updateItemHandler()} autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                    
                </Dialog>
            </div>
        )
    }
    else{
        container = (
            <div>
                <div>
                    {serviceSubCategory.map((item,index)=>{
                        return(
                            <div>
                                <div style={{margin:10,background:'teal',color:'white'}}>
                                    <p style={{padding:10, fontSize:16, fontWeight:'bold'}}>{item.subComponentSubCategoryName}</p>
                                </div>
                                <div style={{margin:5, padding:5}}>
                                    <TableContainer component={Paper} style = {{maxHeight:256, overflow:'auto'}}>
                                        <Table sx={{ minWidth:500 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell style={{fontWeight:'bold'}}>Name</TableCell>
                                                    <TableCell style={{fontWeight:'bold'}}>Variety</TableCell>
                                                    <TableCell style={{fontWeight:'bold'}}>Unit</TableCell>
                                                    <TableCell style={{fontWeight:'bold'}}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {serviceItem.map((innerItem,index) => {
                                                    if(innerItem.subComponentSubCategoryID == item.subComponentSubCategoryID){
                                                        return (
                                                            innerItem.assetSubComponentMaster.map((ite,ind)=>{
                                                                return(
                                                                    <TableRow
                                                                        key={index+ind}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell >{ite.assetSubComponentName}</TableCell>
                                                                        <TableCell >{ite.assetSubComponentVarieties}</TableCell>
                                                                        <TableCell >{ite.unit}</TableCell>
                                                                        <TableCell style={{float:'right'}} >
                                                                            <DownloadIcon
                                                                                style={{padding:'0px 5px', cursor:'pointer'}}
                                                                                onClick = {()=>{
                                                                                    if(ite.catalogue)  
                                                                                        window.open(ite.catalogue,"_blank")
                                                                                }}
                                                                            />
                                                                            <EditIcon 
                                                                                style={{padding:'0px 5px', cursor:'pointer'}}
                                                                                onClick = {()=>
                                                                                    updateMaterial(
                                                                                        item.subComponentSubCategoryID,
                                                                                        ite.assetSubComponentID,
                                                                                        ite.assetSubComponentName,
                                                                                        ite.assetSubComponentVarieties,
                                                                                        ite.unit,
                                                                                        ite.catalogue
                                                                                    )
                                                                                }
                                                                            />
                                                                            <DeleteIcon 
                                                                                style={{padding:'0px 5px', cursor:'pointer'}}
                                                                                onClick = {()=>deleteItemHandler(ite.assetSubComponentID)}
                                                                            />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        )
                                                    }
                                                        
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div
                                    style={{
                                        textAlign: 'right',
                                        padding: '0px 10px'
                                    }}
                                >
                                    <Button
                                        onClick = {()=>{
                                            setNewItem({
                                                name:null,
                                                variety:null,
                                                unit:null
                                            });
                                            setCatalogue({
                                                value:null,
                                                isUploaded:false,
                                                path:null
                                            })
                                            handleOpen(item.subComponentSubCategoryID)
                                        }}
                                    >
                                        Add New
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div></div>
            </div>
        )
    }
    

    return(
        <div>
            {container}
        </div>
        
    )

    
}

export default ServiceManagement 