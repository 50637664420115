import React,{useState,useEffect} from 'react';
import axios from "../../../../axios_tteg";
import MapContainer from '../../../Maps/MapContainer';
import Cookies from 'universal-cookie';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate } from "react-router-dom";

const cookies = new Cookies()

const Task = (props)=>{
    let navigate = useNavigate();
    const [tasks,setTasks] = useState(null);
    const [ongoingTask,setOngoingTask] = useState([]);
    const [completedTask,setCompletedTask] = useState([]);
    const [goToMap,setGoToMap] = useState(false);
    const [goToCaseID,setGoToCaseID] = useState(null)
    console.log(props);
    useEffect(()=>{
        axios.get('/getTask',{
            params:{
                resourceID:props.resourceID
            }
        })
        .then(response=>{
            console.log(response);
            setTasks(response.data)
        })
        .catch(e=>{
            console.log(e)
        })
    },[])

    const goToCase = (caseDetails)=>{
        // console.log(caseDetails);
        // setGoToCaseID(caseDetails);
        props.getData(caseDetails);
    }

    const goToEMS = ()=>{
        navigate("/EMS")
    }

    const getContactDetail = (companyDetails)=>{
        alert(`Please contact ${companyDetails[0].name} - Contact No ${companyDetails[0].mobile} for further proccess.`)
    }

    

    let container =null;
    let expenseContainer = null;
    if(goToMap && goToCaseID){
        container = (
            <MapContainer caseDetails = {goToCaseID}/>
        )
    }

    else{
        let tempOngoingTask = [... ongoingTask];
        let tempCompletedTask = [... completedTask];
        if(tasks && tasks.cases.length > 0){
            tasks.cases.map((item,index)=>{
                if(item.description.role === 'F-003'){
                    if(item.caseStatusLog[item.caseStatusLog.length-1].status.planning === 1){
                        tempOngoingTask.push(item);
                    }
                    else{
                        tempCompletedTask.push(item);   
                    }
                }
                else if(item.description.role === 'F-005'){
                    if(item.caseStatusLog[item.caseStatusLog.length-1].status.surveyour === 1){
                        tempOngoingTask.push(item);
                    }
                    else{
                        tempCompletedTask.push(item);   
                    }
                }
            })
            if(props.Completed){
                if(cookies.get('userData').subscriberTypeID === 'F-003'){
                    container = tempCompletedTask.map(item=>{
                        return(
                            <div 
                                style = {{
                                    padding:10,
                                    background:'aliceblue',
                                    margin:5,
                                    textAlign:'left',
                                    cursor:'pointer'
                                }}
                                onClick = {()=>goToCase(item)}>
                                <p>{item.caseMaster[0].title}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>{item.caseMaster[0].caseDescription}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>latitude - {item.caseMaster[0].location.latitude}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>longitude - {item.caseMaster[0].location.longitude}</p>
                            </div>
                            
                        )
                    })
                }
                else{
                    container = tempCompletedTask.map(item=>{
                        return(
                            <div 
                                style = {{
                                    padding:10,
                                    background:(new Date() > new Date(item.endDate).setUTCHours(23,59,59,999) || item.individualAssociation[0].companyID != item.companyID) ? 'lightgray':'aliceblue',
                                    margin:5,
                                    textAlign:'left',
                                    cursor:'pointer'
                                }}
                                onClick = {()=>(new Date() > new Date(item.endDate).setUTCHours(23,59,59,999) || item.individualAssociation[0].companyID != item.companyID) ? getContactDetail(item.companyDetails):goToCase(item)}>
                                <p>{item.caseMaster[0].title}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>{item.caseMaster[0].caseDescription}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>latitude - {item.caseMaster[0].location.latitude}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>longitude - {item.caseMaster[0].location.longitude}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>End Date - {new Date(item.endDate).toLocaleString()}</p>
                            </div>
                            
                        )
                    })
                }
                    
            }
            else{
                if(cookies.get('userData').subscriberTypeID === 'F-003'){
                    container = tempOngoingTask.map(item=>{
                        return(
                            <div 
                                style = {{
                                    padding:10,
                                    background: 'aliceblue',
                                    margin:5,
                                    textAlign:'left',
                                    cursor:'pointer'
                                }}
                                onClick = {()=>goToCase(item)}>
                                <p>{item.caseMaster[0].title}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>{item.caseMaster[0].caseDescription}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>latitude - {item.caseMaster[0].location.latitude}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>longitude - {item.caseMaster[0].location.longitude}</p>
                            </div>
                        )
                    })
                }
                else{
                    
                    container = tempOngoingTask.map(item=>{
                        return(
                            <div 
                                style = {{
                                    padding:10,
                                    background:(new Date() > new Date(item.endDate).setUTCHours(23,59,59,999) || item.individualAssociation[0].companyID != item.companyID) ? 'lightgray':'aliceblue',
                                    margin:5,
                                    textAlign:'left',
                                    cursor:'pointer'
                                }}
                                onClick = {()=>(new Date() > new Date(item.endDate).setUTCHours(23,59,59,999) || item.individualAssociation[0].companyID != item.companyID)  ? getContactDetail(item.companyDetails):goToCase(item)}>
                                <p>{item.caseMaster[0].title}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>{item.caseMaster[0].caseDescription}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>latitude - {item.caseMaster[0].location.latitude}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>longitude - {item.caseMaster[0].location.longitude}</p>
                                <p style={{fontSize:'small', color:'darkgray', margin:'2px auto'}}>End Date - {new Date(item.endDate).toLocaleString()}</p>
                            </div>
                        )
                    })
                }
                    
            }
        }
        if(tasks && tasks.projectExpenses.length > 0){
            tasks.projectExpenses.map(item=>{
                if(item.acknowledged === 'true'){
                    tempCompletedTask.push(item);
                }
                else
                    tempOngoingTask.push(item);
            });
            if(props.Completed){
                expenseContainer = tempCompletedTask.map(item=>{
                    return(
                        <div 
                            style = {{
                                padding:10,
                                background: 'aliceblue',
                                margin:5,
                                textAlign:'left',
                                cursor:'pointer'
                            }}
                            onClick = {()=>goToEMS()}>
                            <p style={{margin:'5px 0px'}}>Acknowledged TRansaction </p>
                            <p style={{ fontSize:'small', margin:'2px auto', color:'darkgray'}}>Amount - <CurrencyRupeeIcon style={{fontSize:'small'}}/> {item.amount}</p>
                            <p style={{ fontSize:'small', margin:'2px auto', color:'darkgray'}}>Payer - {item.caseMaster[0].name}</p>
                        </div>
                    )
                })
            }
            else{
                expenseContainer = tempOngoingTask.map(item=>{
                    return(
                        <div 
                            style = {{
                                padding:10,
                                background: 'aliceblue',
                                margin:5,
                                textAlign:'left',
                                cursor:'pointer'
                            }}
                            onClick = {()=>goToEMS()}>
                            <p style={{margin:'5px 0px'}}>Acknowledgement Pending </p>
                            <p style={{ fontSize:'small', margin:'2px auto', color:'gray'}}>Amount - <CurrencyRupeeIcon style={{fontSize:'small'}}/> {item.amount}</p>
                            <p style={{ fontSize:'small', margin:'2px auto', color:'gray'}}>Payer - {item.caseMaster[0].name}</p>
                        </div>
                    )
                })
            }
            
        }
    } 
        
    
    return(
        <div>
            {container}
            {expenseContainer}
        </div>
    )
}

export default Task