import React,{useCallback, useEffect, useState} from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MultipleSelectChip from "../../../UI/MultipleSelect/MultipleSelect";
import axios from "../../../../axios_tteg";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from "universal-cookie";
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import classes from "./tabPanel1.module.css";

const cookies = new Cookies();

const AssetsLeft = ()=>{
    const [inputPIUtility,setInputPIUtility] = useState(null);
    const [inputOptionDomain,setInputOptionDomain] = useState([]);
    const [inputOptionMachine,setInputOptionMachine] = useState([]);
    const [inputOptionSubType,setInputOptionSubType] = useState([]);
    const [filterData,setFilterData] = useState([]);
    const [dateAssociated,setDateAssociated]=useState([])
    const [filterOption,setFilterOption] = useState();
    const [pageNo,setPageNo] = useState(0);
    const [totalPage,setTotalPage] = useState(0);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [connectDetails,setConnectDetails] = React.useState(null);
    const [requestText,setRequestText] = React.useState(null);
    const [loading,setLoading] = React.useState(false);
    const [startDate,setStartDate] = useState(new Date());
    const [endDate,setEndDate] = useState(new Date());
    const innerWidth = window.innerWidth;

    useEffect(()=>{
        axios.get('/registerPIUtility1')
        .then(response=>{
            setInputPIUtility(response.data);
            inputDomainFormat(response.data.domainBase);
            inputSubTypeFormat(response.data.subscriberType);
        })
        .catch(e=>console.log(e))

        axios.get('/getMachineType')
        .then(response=>{
            inputFormatMachine(response.data.machineType);
        })
        .catch(e=>console.log(e))
    },[])

    useEffect(()=>{
        if(inputPIUtility && inputOptionMachine.length > 0){
            let tempVal = {};
            let domainIDs = {};
            let subscriberTypeIDs = {};
            let machineIDs = {};
            inputPIUtility.domain.map(item=>{
                domainIDs[item.domainID] = true;
            });
            inputPIUtility.subscriberType.map(item=>{
                subscriberTypeIDs[item.subscriberTypeID] = false;
            })
            inputOptionMachine.map(item=>{
                machineIDs[item.value] = false;
            })
            tempVal = {domainIDs,subscriberTypeIDs,machineIDs};
            setFilterOption(tempVal);
            // inpu
        }
        getFilteredData();
    },[inputPIUtility,inputOptionMachine])

    useEffect(()=>{
        if(filterOption){
            getFilteredData();
        }
    },[filterOption,pageNo])

    const getFilteredData = ()=>{
        
        axios.post('/filter2',{
            ... filterOption,
            subscriberCategoryID:cookies.get('userData').subscriberCategoryID,
            associationType:'freelance',
            headers:{
                page: pageNo,
                limit: 10
            },
        })
        .then((response)=>{
            console.log(response,"dz");
            if(response.data.vendors[0].data.length > 0 && response.data.vendors[0].pagination.length > 0){
                setFilterData(response.data.vendors[0].data);
                setTotalPage(response.data.vendors[0].pagination[0].total);
            }
            else{
                setFilterData(response.data.vendors[0].data);
                setTotalPage(0);
            }
            
        })
        .catch(e=>console.log(e));    
    }
    


    const inputDomainFormat = (domainBase)=>{
        let format = []
        domainBase.map(item=>{
            format.push({
                label:item.domainBaseName,
                value:item.domainBaseID
            })
        })
        setInputOptionDomain(format)
    } 

    const inputSubTypeFormat = (subType)=>{
        let format = []
        subType.map(item=>{
            format.push({
                label:item.subscriberTypeName,
                value:item.subscriberTypeID
            })
        })
        setInputOptionSubType(format)
    } 

    const inputFormatMachine = (machineType)=>{
        let format = []
        machineType.map(item=>{
            format.push({
                label:item.machineTypeName,
                value:item.machineTypeID
            })
        })
        setInputOptionMachine(format)
    } 
    useEffect(async()=>{
    const getDate = () => {
        axios.get(`/lastAssociationDate`)
            .then(response => {
                // Handle the response here
                console.log(response.data,"data");
                setDateAssociated(response.data.allAssociationData)
            })
            .catch(error => {
                // Handle any errors here
                console.error("There was an error fetching the date:", error);
            });
    }
   await getDate()
},[])
const getLatestAssociation = (resourceID) => {
    // Find the latest association for the specific resourceID
    const filteredData = dateAssociated.filter(item => item.individualID === resourceID);

    if (filteredData.length > 0) {
        // Sort the filtered data by endDate in descending order
        filteredData.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

        // Return the latest association (first item after sorting)
        return filteredData[0].endDate;
    } else {
        return null;  // Return null if no data is found for the given resourceID
    }
}


    const getDomainName = (domainID)=>{
        let tempDomainName = null;
        inputPIUtility.domain.map(item=>{

            if(item.domainID === domainID){
                tempDomainName = item.domainDescription.domainName
                // return (item.domainDescription.domainName);
            }
        });
        return tempDomainName
    }

    const getSubscriberType = (subscriberTypeID)=>{
        let tempSubscriberName = null;
        inputPIUtility.subscriberType.map(item=>{
            if(item.subscriberTypeID === subscriberTypeID){
                tempSubscriberName = item.subscriberTypeName
                // return (item.domainDescription.domainName);
            }
        });
        return tempSubscriberName
    }

    const getMachineType = (machineTypeID)=>{
        let tempMachineType = null;
        inputOptionMachine.map(item=>{
            if(item.value === machineTypeID)
                tempMachineType = item.label
        })
        return tempMachineType
    }

    const handleChangeDuration = (key,value)=>{
        if(key === 'startDate'){
            setStartDate(value);
        }
        else if(key === 'endDate'){
            setEndDate(value)
        }
    }

    let container = null;

    if(filterData.length > 0){
        container = (
            <div style={{justifyItems:'center'}}>
                {filterData.map(item=>{
                    // console.log(item,"kbk");
                    return(
                        <div style = {{margin:'10px 0px', width:'100%'}}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className={classes.CardContent}>
                                        <div>
                                            Vandor Name - {item.name}
                                        </div>
                                        <div>
                                            Domain - {getDomainName(item.domainID)}
                                        </div>
                                        <div>
                                            Subscriber Type - {getSubscriberType(item.subscriberTypeID)}
                                        </div>
                                        <div>
                                            {item.resourceTypeID === 'I' ? 'Individual':'Company'}
                                        </div>
                                        <div>
                                            Last Association- {getLatestAssociation(item.resourceID)||"Not associated"}
                                        </div>
                                    </Typography>
                                    {(item.machineDescription.length > 0) ? (
                                        <div>
                                            <p style={{textAlign:'left'}}>Machine Details :</p>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                                                {innerWidth <= 768 ? (
                                                    <table style={{width:'100%', textAlign:'left', background: 'aliceblue',padding: 10,borderRadius: 5}}>
                                                        {item.machineDescription.map(innerItem=>{
                                                            <>
                                                                <tr>
                                                                    <td>Type</td>
                                                                    <td>{getMachineType(innerItem.machineTypeID)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Model No.</td>
                                                                    <td>{innerItem.modelNumber}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Machine Capacity</td>
                                                                    <td>{innerItem.machineCapacityInTonnes}</td>    
                                                                </tr>
                                                                <tr>
                                                                    <td>Sector</td>
                                                                    <td>{innerItem.machineAvailabilitySectors}</td>     
                                                                </tr>
                                                                <tr>
                                                                    <td>OEM</td>
                                                                    <td>{innerItem.OEM}</td>    
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>
                                                                        <Button size="small" onClick={()=>{
                                                                            setOpenDialog(true)
                                                                            let tempConnectDetails = {};
                                                                            tempConnectDetails={
                                                                                vendorResourceID:item.resourceID,
                                                                                machineID: innerItem.machineID,
                                                                                humanResourceID:null
                                                                            }
                                                                            setConnectDetails(tempConnectDetails);
                                                                        }}>Associate</Button>
                                                                    </td>   
                                                                </tr>
                                                            </>
                                                        })}
                                                        
                                                    </table>
                                                ):(
                                                    <table style={{width:'100%', textAlign:'left', background: 'aliceblue',padding: 10,borderRadius: 5}}>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Model No.</th>
                                                            <th>Machine Capacity</th>
                                                            <th>Sector</th>
                                                            <th>OEM</th>
                                                            <th></th>
                                                        </tr>
                                                        {item.machineDescription.map(innerItem=>{
                                                            return( 
                                                                <tr>
                                                                    <td>{getMachineType(innerItem.machineTypeID)}</td>
                                                                    <td>{innerItem.modelNumber}</td>
                                                                    <td>{innerItem.machineCapacityInTonnes}</td>    
                                                                    <td>{innerItem.machineAvailabilitySectors}</td>   
                                                                    <td>{innerItem.OEM}</td>    
                                                                    <td><Button size="small" onClick={()=>{
                                                                        setOpenDialog(true)
                                                                        let tempConnectDetails = {};
                                                                        tempConnectDetails={
                                                                            vendorResourceID:item.resourceID,
                                                                            machineID: innerItem.machineID,
                                                                            humanResourceID:null
                                                                        }
                                                                        setConnectDetails(tempConnectDetails);
                                                                    }}>Associate</Button></td>                                                         
                                                                </tr>
                                                            )
                                                        })}
                                                    </table>
                                                )}
                                                
                                            </Typography>
                                        </div>
                                    )
                                    :null}
                                    {(item.humanResourceVendor && item.humanResourceVendor.length > 0) ? (
                                        <div>
                                            <p style={{textAlign:'left'}}>Human Resource Details :</p>
                                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom >
                                                {innerWidth <= 768 ? (
                                                    <table style={{
                                                        width: '100%',
                                                        textAlign: 'initial',
                                                        background: 'aliceblue',
                                                        padding: 10,
                                                        borderRadius: 5
                                                    }}>
                                                        {item.humanResourceVendor.map(innerItem=>(
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        Name
                                                                    </td>
                                                                    <td>
                                                                        {innerItem.humanResourceInfo.name}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Domain
                                                                    </td>
                                                                    <td>
                                                                        {getDomainName(innerItem.humanResourceInfo.domainTypeID)}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Skills
                                                                    </td>
                                                                    <td>
                                                                        {innerItem.humanResourceInfo.skills}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Experience
                                                                    </td>
                                                                    <td>
                                                                        {innerItem.humanResourceInfo.experience}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Tenure
                                                                    </td>
                                                                    <td>
                                                                        {innerItem.humanResourceInfo.tenure}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                        <Button size="small" onClick={()=>{
                                                                            setOpenDialog(true);
                                                                            let tempConnectDetails = {};
                                                                            tempConnectDetails={
                                                                                vendorResourceID:item.resourceID,
                                                                                machineID: null,
                                                                                humanResourceID:innerItem.humanResourceID
                                                                            }
                                                                            setConnectDetails(tempConnectDetails);
                                                                        }}>Associate</Button>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </table>
                                                ):(
                                                    <table style={{width:'100%', textAlign:'left', background: 'aliceblue',padding: 10,borderRadius: 5}}>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Domain</th>
                                                            <th>Skills</th>
                                                            <th>Experience</th>
                                                            <th>Tenure</th>
                                                            <th></th>
                                                        </tr>
                                                        {item.humanResourceVendor.map(innerItem=>{
                                                            return( 
                                                                <tr>
                                                                    <td>{innerItem.humanResourceInfo.name}</td>
                                                                    <td>{getDomainName(innerItem.humanResourceInfo.domainTypeID)}</td>
                                                                    <td>{innerItem.humanResourceInfo.skills}</td>    
                                                                    <td>{innerItem.humanResourceInfo.experience}</td>   
                                                                    <td>{innerItem.humanResourceInfo.tenure}</td>    
                                                                    <td><Button size="small" onClick={()=>{
                                                                        setOpenDialog(true);
                                                                        let tempConnectDetails = {};
                                                                        tempConnectDetails={
                                                                            vendorResourceID:item.resourceID,
                                                                            machineID: null,
                                                                            humanResourceID:innerItem.humanResourceID
                                                                        }
                                                                        setConnectDetails(tempConnectDetails);
                                                                    }}>Associate</Button></td>                                                         </tr>
                                                            )
                                                        })}
                                                    </table>
                                                )}
                                                
                                            </Typography>
                                        </div>
                                        
                                        
                                    )
                                        
                                        
                                     :null}
                                    {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Domain - {getDomainName(item.domainID)}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Subscriber Type - {getSubscriberType(item.subscriberTypeID)}
                                    </Typography> */}
                                    
                                </CardContent>
                                {(item.subscriberTypeID != 'F-010' && item.subscriberTypeID != 'P-020')?(
                                    <CardActions style={{justifyContent:'space-around'}}>
                                        <Button size="small" onClick={()=>{
                                            setOpenDialog(true);
                                            let tempConnectDetails = {};
                                            tempConnectDetails={
                                                vendorResourceID:item.resourceID,
                                                machineID: null,
                                                humanResourceID:null
                                            }
                                            setConnectDetails(tempConnectDetails);
                                        }}>Associate</Button>
                                    </CardActions>
                                ):null}
                                
                            </Card>
                        </div>
                    )
                })}
            </div>
            
        )
        
    }
    else{
        container = (
            <div>
                <p>No Freelancers found !!</p>
            </div>
        )
    }
    const getDataFromFilter = (type,data) =>{
        if(type === 'domain'){
            let tempFilterOption = {...filterOption};
            if(data.length > 0){
                let selectedOption = data.join('');
                let domainID = null
                inputPIUtility.domain.map(item=>{
                    if(item.domainCode == selectedOption){
                        domainID = item.domainID
                    }
                })
                Object.keys(tempFilterOption.domainIDs).map(item=>{
                    tempFilterOption.domainIDs[item] = false;
                })
                tempFilterOption.domainIDs[domainID] = true;
                data.map(item=>{
                    let dID = null
                    inputPIUtility.domain.map(innerItem=>{
                        if(innerItem.domainCode == item){
                            tempFilterOption.domainIDs[innerItem.domainID] = true;
                        }
                    })
                    // tempFilterOption.domainIDs[item] = true;
                })
            }
            else{
                Object.keys(tempFilterOption.domainIDs).map(item=>{
                    tempFilterOption.domainIDs[item] = true;
                })
            }
            
            setFilterOption(tempFilterOption);

            
            // filterOption.domainIDs

        }
        else if(type === 'subscriberType'){
            let tempFilterOption = {...filterOption};
            Object.keys(tempFilterOption.subscriberTypeIDs).map(item=>{
                tempFilterOption.subscriberTypeIDs[item] = false;
            })
            data.map(item=>{
                tempFilterOption.subscriberTypeIDs[item] = true;
            })
            setFilterOption(tempFilterOption);
        }
        else if(type === 'machineType'){
            let tempFilterOption = {...filterOption};
            Object.keys(tempFilterOption.machineIDs).map(item=>{
                tempFilterOption.machineIDs[item] = false;
            })
            data.map(item=>{
                tempFilterOption.machineIDs[item] = true;
            })
            setFilterOption(tempFilterOption);
        }
    }

    const requestForConnect = ()=>{
        setLoading(true);
        axios.post('/storeResourceID',{
            enquiryResourceID:cookies.get('userData').resourceID,
            vendorResourceID:connectDetails.vendorResourceID,
            machineID:connectDetails.machineID,
            humanResourceID:connectDetails.humanResourceID,
            text:requestText,
        })
        .then((response)=>{
            console.log(response);
            setLoading(false);
            setRequestText(null);
            setConnectDetails(null);
            setOpenDialog(false);
            getFilteredData();
            alert('Thanks for showing intreast, our sales team will contact you shortly')
        })
        .catch(e=>{
            console.log(e);
            setLoading(false);
        })

    }

    const requestForAssociation = ()=>{
        // setLoading(true);
        axios.post('/updateAssociation',{
            individualID:connectDetails.vendorResourceID,
            associationType :'found', // if needed we can created schema for associaton types
            companyID:cookies.get('userData').resourceID,
            timeStamp: new Date(),   
            startDate:startDate,
            endDate:endDate,
            machineID:connectDetails.machineID,
            humanResourceID:connectDetails.humanResourceID,
        })
        .then((response)=>{
            setLoading(false);
            setRequestText(null);
            setConnectDetails(null);
            setOpenDialog(false);
            getFilteredData();
            alert('Thanks for showing intreast, our sales team will contact you shortly')
        })
        .catch(e=>{
            console.log(e);
            setLoading(false);
        })

    }

    return(
        <div>
            {loading ? (
             <CircularProgress/>   
            ):(
                <>
                <div style ={{display:'flex', justifyContent:'flex-end', margin:5}}>
                {/* <Box sx={{ width: 256 }}>
                    <FormControl size="small">
                        <InputLabel id="demo-select-small">Associated Assets</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={showAssociatedAssets}
                            label="Associated Assets"
                            onChange={associatedAssetsHandler}
                        >
                        <MenuItem value={false}>Available Freelancers</MenuItem>
                        <MenuItem value={true}>Associated Freelancers</MenuItem>
                        </Select>
                    </FormControl>
                </Box> */}
                </div>
            <div className={classes.Options}>
                <MultipleSelectChip 
                    Options = {inputOptionDomain}
                    mainLabel  = 'Domain'
                    getData = {(data)=>getDataFromFilter('domain',data)}
                />
                <MultipleSelectChip 
                    Options = {inputOptionSubType}
                    mainLabel  = 'SubscriberType'
                    getData = {(data)=>getDataFromFilter('subscriberType',data)}
                />
                <MultipleSelectChip 
                    Options = {inputOptionMachine}
                    mainLabel  = 'Machine Type'
                    getData = {(data)=>getDataFromFilter('machineType',data)}
                />
                <Pagination count={Math.ceil(totalPage/10)} page={pageNo+1} onChange={(event,value)=>setPageNo(value-1)} />
                
            </div>
            <div>
                <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
                    <DialogTitle>Associate</DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        Thank you for showing intreast. Our sales team will call you for future references.
                    </DialogContentText>
                    <div className={classes.AssociateResourceStart}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                            <DesktopDatePicker
                                label="Start Date"
                                inputFormat="DD/MM/YYYY"
                                value={startDate}
                                onChange={(date)=>handleChangeDuration('startDate',date)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                label="End Date"
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                onChange={(date)=>handleChangeDuration('endDate',date)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    
                    
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={requestForAssociation}>Submit</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                {container}
            </div>
            </>
            )}
        </div>
        
    )
}

export default AssetsLeft