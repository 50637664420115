import React,{useCallback, useEffect, useState} from "react";
import axios from "../../../../axios_tteg";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from "universal-cookie";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MapContainer from "../../../Maps/MapContainer";
import ExpenseManagementSystem from "../../../ExpenseManagement/ExpenseManagementSystem";
import ProjectManagementSystem from "../../../ProjectManagement/ProjectManagementSystem";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AddIcon from '@mui/icons-material/Add';
import MapIcon from '@mui/icons-material/Map';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import classes from "./tabPanel1.module.css";
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();

const Project = (props)=>{
    let navigate = useNavigate();
    const [projects,setProjects] = useState(null);
    const [ProjectDetails,setProjectDetails] = useState(null);
    const [goToMap,setGoToMap] = useState(false);
    const [goToCaseID,setGoToCaseID] = useState(null);
    const [goToAddResource,setGoToAddResource] = useState(false);
    const [associatedIndividual,setAssociatedIndividual] = useState([]);
    const [selelctedIndividualResources,setSelectedIndividualResources] = useState([
        {
            subscriberTypeID:'F-002',
            subscriberTypeName:'Project Manager',
            resourceID:null
        },
        {
            subscriberTypeID:'F-004',
            subscriberTypeName:'Site-Supervisor',
            resourceID:null
        }
    ]);
    const [selectedProject,setSelectedProject] = useState(null);
    const [projectResourceAssociation,setProjectResourceAssociation] = useState([]);
    const [viewResources,setViewResources] = useState(false);
    const [openEMS,setOpenEMS] = useState(false);
    const [openPMS,setOpenPMS] = useState(false);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        getProject();
    },[])

    const getProject = ()=>{
        setLoading(true);
        axios.get('/getProjects',
            {
                params:{
                    resourceID:props.resourceID
                }
            }
        )
        .then(response=>{
            setLoading(false)
            setProjects(response.data.projectCase)
        })
        .catch(e=>{
            setLoading(false);
            console.log(e);
        })
    }

    const getProjectDetails = ()=>{
        setLoading(true);
        axios.get('/getProjectDetails',
            {
                params:{
                    resourceID:props.resourceID
                }
            }
        )
        .then(response=>{
            setLoading(false)
            setProjectDetails(response.data.projectCase)
        })
        .catch(e=>{
            setLoading(false);
            console.log(e);
        })
    } 

    const getAssociatedIndividual = (projectID)=>{
        setLoading(true);
        axios.get('/getAssociatedIndividual',{
            params:{
                companyID:props.resourceID
            }
        }).then(response=>{
            setLoading(false);
            setAssociatedIndividual(response.data.association)
            setGoToAddResource(true)
            setSelectedProject(projectID);
        })
    }

    const getProjectResourceAssociation = (projectID)=>{
        setLoading(true);
        axios.get('/getProjectResourceAssociation',{
            params:{
                projectID:projectID
            }
        }).then(response=>{
            setLoading(false);
            setProjectResourceAssociation(response.data.projectAssociation);
            // let tempSelectedIndividualResources = [... selelctedIndividualResources];
            // tempSelectedIndividualResources = response.data.projectAssociation[0].resources;
            // setSelectedIndividualResources(tempSelectedIndividualResources);
        })
    }

    const goToCase = (caseDetails)=>{
        // if(innerWidth <= 768){
        //     alert("Please use large screen device for exploring the assignment.")
        // }
        // else{
            setGoToCaseID(caseDetails);
            setGoToMap(true);
        // }
            
    }

    const subscriberTypeHandler = (value,index,type)=>{
        let tempSelectedIndividualResources = [... selelctedIndividualResources]
        tempSelectedIndividualResources[index][type] = value;
        setSelectedIndividualResources(tempSelectedIndividualResources)
    }

    const addIndividualAssociation = ()=>{
        let tempSelectedIndividualResources = [... selelctedIndividualResources]
        tempSelectedIndividualResources.push ({
            subscriberTypeID:null,
            resourceID:null
        });
        setSelectedIndividualResources(tempSelectedIndividualResources)
    }

    const individualAssociationHandler = ()=>{
        let tempCleanedData = []
        let tempSelectedIndividualResources = [... selelctedIndividualResources]
        tempSelectedIndividualResources.map((item)=>{
            if(item.subscriberTypeID && item.resourceID)
                tempCleanedData.push(item)
        });
        tempSelectedIndividualResources = tempCleanedData;
        setSelectedIndividualResources(tempSelectedIndividualResources);
        if(tempSelectedIndividualResources.length === 2){
            setLoading(true);
            axios.post('/projectResourceAssociation',{
                projectID:selectedProject,
                vendorID:cookies.get('userData').resourceID,
                resources:tempSelectedIndividualResources
            })
            .then(response=>{
                setLoading(false)
                setSelectedIndividualResources([
                    {
                        subscriberTypeID:'F-002',
                        subscriberTypeName:'Project Manager',
                        resourceID:null
                    },
                    {
                        subscriberTypeID:'F-004',
                        subscriberTypeName:'Site-Supervisor',
                        resourceID:null
                    }
                ]);
                setSelectedProject(null);
                setGoToAddResource(false);
            })
        }
        else{
            alert('Please provide the appropriate data')
        }
    }

    function removeDuplicates(jsonArray) {
        // Step 1: Convert JSON array to a string
        const jsonString = JSON.stringify(jsonArray);
    
        // Step 2: Convert the string back to an array of objects
        const jsonObjectArray = JSON.parse(jsonString);
    
        // Step 3: Use a Set to track unique objects
        const uniqueSet = new Set();
    
        // Step 4: Iterate through the array and add each object to the Set
        jsonObjectArray.forEach((obj) => {
            uniqueSet.add(JSON.stringify(obj));
        });
    
        // Step 5: Convert the Set back to an array
        const uniqueArray = Array.from(uniqueSet).map((str) => JSON.parse(str));
    
        // Step 6: Convert the array back to a JSON string
        const uniqueJsonString = JSON.stringify(uniqueArray);
    
        return uniqueJsonString;
    }


    let container = null;
    if(loading){
        container = (<CircularProgress/>)
    }
    else if(goToMap && goToCaseID){
        container = (<MapContainer caseDetails = {goToCaseID} getData = {()=>{setGoToMap(false);setGoToCaseID(null)}}/>)
    }
    else if(openEMS){
        container = (<ExpenseManagementSystem fromProject = {true} getData = {()=>setOpenEMS(false)}/>)
    }
    else if(openPMS){
        container = (
            <ProjectManagementSystem 
                fromProject = {true} 
                getData = {()=>setOpenPMS(false)}
                caseDetails = {goToCaseID}
            />)
    }
    else if(projects){
        container = (
            projects.map(item=>{
                return(
                    <div style = {{margin:10}}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className={classes.AssignmentHeader}>
                                    <div>
                                        Title - {item.caseMaster[0].title}
                                    </div>
                                    <div>
                                        Case ID - {item.caseID}
                                    </div>
                                    <div>
                                        Company - {item.caseMaster[0].companyDetails.name}
                                    </div>
                                    <div>
                                        Domain - {item.caseMaster[0].domainDetails.domainDescription.domainName}
                                    </div>
                                </Typography>
                                <Typography className={classes.AssignmentDisplay}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style = {{textAlign:'left',padding: 10}}>
                                        <div>
                                            Latitude (degrees) - {parseFloat(item.caseMaster[0].location.latitude).toFixed(2)}
                                        </div>
                                        <div>
                                            Longitude (degrees) - {parseFloat(item.caseMaster[0].location.longitude).toFixed(2)}
                                        </div>
                                        <div>
                                            Distance (Km) - {parseFloat(item.caseMaster[0].location.distance).toFixed(2)}
                                        </div>
                                    </Typography>
                                </Typography>
                            </CardContent>
                            <CardActions style={{justifyContent:'flex-end'}}>
                                <Tooltip title = 'Explore'>
                                    <IconButton>
                                        <MapIcon style = {{cursor:'pointer', color:'teal'}} onClick={()=>goToCase(item)}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title = 'Add Resources'>
                                    <IconButton>
                                        <AddIcon style = {{cursor:'pointer', color:'teal'}} onClick = {()=> {
                                            // getProjectResourceAssociation(item.projectID);
                                            getAssociatedIndividual(item.projectID)
                                        }}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title = 'View Resources'>
                                    <IconButton>
                                        <VisibilityIcon 
                                            style = {{cursor:'pointer', color:'teal'}}
                                            onClick = {()=>{
                                                setViewResources(true);
                                                getProjectResourceAssociation(item.projectID);
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title = 'Expense Management'>
                                    <IconButton>
                                        <CurrencyRupeeIcon 
                                            style = {{cursor:'pointer', color:'teal'}}
                                            onClick = {()=>navigate("/EMS")}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title = 'Project Management'>
                                    <IconButton>
                                        <AssignmentLateIcon 
                                            style = {{cursor:'pointer', color:'teal'}}
                                            onClick = {()=>{
                                                // setOpenPMS(true)
                                                // setGoToCaseID(item)
                                                navigate("/PMS")
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                {/* <Button size="small" onClick={()=>goToCase(item)}>Explore</Button>
                                <Button size="small" onClick = {()=> {
                                        getProjectResourceAssociation(item.projectID);
                                        getAssociatedIndividual(item.projectID)
                                    }}>Add Resources</Button> */}
                            </CardActions>
                        </Card>
                    </div>        
                )
            })
        )
    }
    return(
        <div>
            {container}
            <Dialog onClose={()=>{setGoToAddResource(false)}} open={goToAddResource}>
                <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <DialogTitle>Add Resources</DialogTitle>
                    <div>
                        <CloseIcon onClick = {()=>{setGoToAddResource(false)}} style = {{padding:10, cursor:'pointer'}}/>
                    </div>
                </Typography>
                <Typography>
                    <div>
                        {selelctedIndividualResources.map((individualItem,individualIndex)=>{
                            return(
                                <div style = {{display:'flex', margin:10, padding:10, alignItems:'center'}}>
                                    {/* <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                        <InputLabel id="demo-simple-select-label">Select Subscriber Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={individualItem.subscriberTypeID}
                                            label={individualItem.subscriberTypeName}
                                            style={{padding:0}}
                                            disabled = {true}
                                            // onChange={(e)=>subscriberTypeHandler(e.target.value,individualIndex,'subscriberTypeID')}
                                        >
                                        </Select>
                                    </FormControl> */}
                                    <p style={{textWrap:'nowrap'}}>{individualItem.subscriberTypeName}</p>
                                    <FormControl style={{margin:10,minWidth:200, width:'100%'}} >
                                        <InputLabel id="demo-simple-select-label">Select Individual</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={individualItem.resourceID}
                                            label="Individual"
                                            style={{padding:0}}
                                            onChange={(e)=>subscriberTypeHandler(e.target.value,individualIndex,'resourceID')}
                                        >
                                            {associatedIndividual.map((item,index)=>{
                                                if(item.individualResourceAssociation.subscriberTypeID === individualItem.subscriberTypeID )
                                                return(
                                                    <MenuItem value = {item.individualResourceAssociation.resourceID}>{item.individualResourceAssociation.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                    {/* <DeleteIcon 
                                        style={{color:'red',margin:10, cursor:'pointer'}}
                                        onClick={()=>{
                                            let tempSelectedIndividualResources = [... selelctedIndividualResources]
                                            tempSelectedIndividualResources.splice(individualIndex, 1);
                                            setSelectedIndividualResources(tempSelectedIndividualResources);
                                        }}
                                    /> */}
                                </div>
                                
                            )
                        })}
                        <CardActions style={{justifyContent:'space-around'}}>
                            {/* <Button size="small" onClick={()=>addIndividualAssociation()}>Add</Button> */}
                            <Button size="small" onClick = {()=> individualAssociationHandler()}>Submit</Button>
                        </CardActions>
                        
                    </div>
                </Typography>
            </Dialog>
            <Dialog onClose={()=>{setViewResources(false)}} open={viewResources} style={{width:'100%'}}>
                <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                    <DialogTitle>Associated Resources</DialogTitle>
                    <div>
                        <CloseIcon onClick = {()=>{setViewResources(false)}} style = {{padding:10, cursor:'pointer'}}/>
                    </div>
                </Typography>
                <Typography>
                    <div>
                        <TableContainer component={Paper}style={{margin:'10px 0px'}}>
                            <Table aria-label="simple table" style={{width:500}}>
                                <TableHead>
                                    <TableRow style = {{textAlign:'center', fontWeight:'bold'}}>
                                    
                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                            Subscriber Type
                                        </TableCell>
                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                            Resource ID
                                        </TableCell>
                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                            Name
                                        </TableCell>
                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                            Email
                                        </TableCell>
                                        <TableCell  style = {{textAlign:'center', fontWeight:'bold'}}>
                                            Mobile No.
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {projectResourceAssociation.length > 0 && projectResourceAssociation[0].vendorMaster.map((item,index)=>{
                                        return(
                                            <TableRow>
                                                <TableCell>
                                                    {item.subscriberTypeMaster[0].subscriberTypeName}
                                                </TableCell>
                                                <TableCell>
                                                    {item.resourceID}
                                                </TableCell>
                                                <TableCell>
                                                    {item.name}
                                                </TableCell>
                                                <TableCell>
                                                    {item.email}
                                                </TableCell>
                                                <TableCell>
                                                    {item.mobile}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Typography>
            </Dialog>
        </div>
    )
}

export default Project