import React,{useState,useEffect} from 'react';
import axios from "../../../axios_tteg";
import { Grid, Paper, Button, Input } from "@mui/material";
import { Box } from "@mui/system";
import classes from "./UploadDoc.module.css";
import { FileUploader } from "react-drag-drop-files";
import Link from '@mui/material/Link';
import EditIcon from '@mui/icons-material/Edit';
import {
    Select,
    TextField,
    CircularProgress,
    MenuItem,
  } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';

const innerWidth = window.innerWidth;
  

const UploadDoc = (props)=>{
    const fileTypes = ["PDF","JPG","JPEG"];
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [docDetail, setDocDetail] = useState({
        "DT-00001": {
          docTypeID: "DT-00001",
          docTypeName: "Address Proof-Govt. Doc.",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00002": {
          docTypeID: "DT-00002",
          docTypeName:"Identity Proof of Establishment",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00003": {
          docTypeID: "DT-00003",
          docTypeName:"Shop Act Gumasta",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00004": {
          docTypeID: "DT-00004",
          docTypeName:"GST Certificate",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00005": {
          docTypeID: "DT-00005",
          docTypeName:"PAN Card",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00006": {
          docTypeID: "DT-00006",
          docTypeName:"Aadhar Card",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00007": {
          docTypeID: "DT-00007",
          docTypeName:"ESIC",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00008": {
          docTypeID: "DT-00008",
          docTypeName:"PF",
          value: null,
          isUploaded: false,
          path: null
        },
        "DT-00009": {
          docTypeID: "DT-00009",
          docTypeName:"Labour License",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00010": {
          docTypeID: "DT-00010",
          docTypeName:"MSME Udyog Aadhar",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00011": {
          docTypeID: "DT-00011",
          docTypeName:"A Class Certificate for Electrical Work",
          value: null,
          isUploaded: false,
          path: null,
        },
        "DT-00012": {
          docTypeID: "DT-00012",
          docTypeName:"Work Experience Certificate",
          value: null,
          isUploaded: false,
          path: null,
        }
    });

    useEffect(()=>{
        axios.get('/getDetailedUserProfile', {
            params:{
                resourceID:props.resourceID
            }
        }).then((response)=>{
            console.log(response);
            let tempDocDetail = {... docDetail};
            if(response.data.vendors.length > 0){
                if(response.data.vendors[0].mUserDocType.length > 0){
                    Object.keys(response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus).map(key=>{
                        if(response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path){
                            tempDocDetail[key].path = response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path;
                            tempDocDetail[key].value={
                                name:response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path.substring(response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].path.lastIndexOf('/')+1)
                            }
                        }
                        
                        tempDocDetail[key].isUploaded =  response.data.vendors[0].mUserDocType[0].docTypeSubmitStatus[key].isUploaded;
                    })
                }
                
            }
            setDocDetail(tempDocDetail);
        }).catch(e=>console.log(e))
    },[])
    


    const handleChange = (file,docTypeID) => {
        console.log(docTypeID);
        let tempDocDetail = { ...docDetail };
        tempDocDetail[docTypeID].value = file;
        tempDocDetail[docTypeID].isUploaded = false;
        setDocDetail(tempDocDetail);
    };

    const uploadDocument = (file, key) => {
        var formData = new FormData();
    
        formData.append("uploadedFile", file);
        formData.append("resourceID", props.resourceID);
        formData.append("docTypeID", key);
    
        console.log(formData);
    
        setLoading(true)
        axios.post("/uploadFile", formData, {})
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                let tempDocDetail = { ...docDetail };
                tempDocDetail[key].value = null;
                tempDocDetail[key].isUploaded = true;
                tempDocDetail[key].path= response.data.path
                setDocDetail(tempDocDetail);
                updateDatabase(tempDocDetail);
            }
        })
        .catch((e) => {
            setLoading(false);
            setError(e.response.error);
        });
    };

    const updateDatabase = (docDet) => {
        setLoading(true)
        let docTypes=[]
        for(var key in docDetail) {
          if(docDet.hasOwnProperty(key)) {
            var item= docDet[key]
            docTypes.push({
              docTypeID: key,
              isUploaded: item.isUploaded,
              path: item.path
            });
          }
        }
        axios
          .post("/uploadDocTypeSubmit", {
            docTypes: docTypes,
            resourceID: props.resourceID,
        })
        .then((response) => {
            console.log(response);
            setLoading(false);
            if (response.status === 200) {
              console.log(response);    
              setError('File Uploaded Successfully');
              alert('File Uploaded Successfully');
            } else {
              setError(response.data.error);
            }
        })
        .catch((e) => {
            setLoading(false);
            setError(e.response.data.error);
        });
    }

    const deleteUploaded = (path,key1)=>{
        setLoading(true);
        axios.post('/deleteFile',{
            path:path
        }).then((response)=>{
            let tempDocDetail = { ...docDetail };
            tempDocDetail[key1].isUploaded = false;
            tempDocDetail[key1].path= null
            tempDocDetail[key1].value= null
            let docTypes=[]
            for(var key in tempDocDetail) {
                docTypes.push({
                    docTypeID: key,
                    isUploaded: tempDocDetail[key].isUploaded,
                    path: tempDocDetail[key].path
                });
            }
            axios
            .post("/uploadDocTypeSubmit", {
                docTypes: docTypes,
                resourceID: props.resourceID
            })
            .then((response) => {
                setLoading(false);
                if (response.status === 200) {
                    setDocDetail(tempDocDetail);
                    alert("File deleted successfully")
                } else {
                    setLoading(false);
                    setError(response.data.error);
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(e.response.data.error);
            });
            
        })
    }

    
    let container = null;
    if(loading){
        container = (<CircularProgress/>)
    }
    else{
        container = (
            <Box>
                <Paper
                elevation={24}
                //   className={classes.PaperStyle}
                >
                
                    {/* <p style={{textAlign:'center', fontWeight:'bold',color:'#53459A'}}>KYC</p>     */}
                    <Grid>
                        <p className={classes.TitleStyle} >
                        Upload Documents
                        <hr className={classes.TitleLineStyle} />
                        </p>
                    </Grid>
                    {innerWidth <= 768 ? (
                        <div className={classes.uploadDocContainer}>
                            <div style = {{
                                    margin: '0.5%',
                                    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
                                    borderRadius:5,
                                    color:'#53459A'
                                }}>
                                <table style={{width:'100%', padding:10}}>
                                    {Object.keys(docDetail).map((key,index)=>{
                                        return(
                                            <tr>
                                                <td><p style={{margin:2}}>{docDetail[key].docTypeName}</p></td>
                                                <td>
                                                    <Button
                                                        disabled = {docDetail[key].path ? false:true}
                                                        onClick={()=>window.open(docDetail[key].path,"_blank")}
                                                    >
                                                        <p style={{margin:2}}>{docDetail[key].path ? 'View':'Not Found'}</p>
                                                    </Button>
                                                </td>
                                                <td>
                                                    <DeleteIcon
                                                        style={{cursor:docDetail[key].path?'pointer':null,color:docDetail[key].path ?'#53459A':'gray'}}
                                                        onClick = {()=>docDetail[key].path ? deleteUploaded(docDetail[key].path,key):null}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </table>
                            </div>                    
                        </div>
                    ):(
                        <div className={classes.uploadDocContainer} style = {{display:'flex'}}>
                            <div style = {{
                                    margin: '0.5%',
                                    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
                                    borderRadius:5,
                                    width: '49%',
                                    height:'40vh',
                                    overflow:'auto',
                                    color:'#53459A'
                                }}>
                                <table style={{width:'100%', padding:10}}>
                                    {Object.keys(docDetail).map((key,index)=>{
                                        if(index % 2 ===0){
                                            return(
                                                <tr>
                                                    <td><p style={{margin:2}}>{docDetail[key].docTypeName}</p></td>
                                                    <td>
                                                        <Button
                                                            disabled = {docDetail[key].path ? false:true}
                                                            onClick={()=>window.open(docDetail[key].path,"_blank")}
                                                        >
                                                            
                                                                <p style={{margin:2}}>{docDetail[key].path ? 'View':'Not Found'}</p>
                                                            
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <DeleteIcon
                                                            style={{cursor:docDetail[key].path?'pointer':null,color:docDetail[key].path ?'#53459A':'gray'}}
                                                            onClick = {()=>docDetail[key].path ? deleteUploaded(docDetail[key].path,key):null}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        
                                        
                                    })}
                                    
                                </table>
                            </div>
                            <div style = {{
                                    margin: '0.5%',
                                    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
                                    borderRadius:5,
                                    width: '49%',
                                    height:'40vh',
                                    overflow:'auto',
                                    color:'#53459A'
                                }}>
                                <table style={{width:'100%', padding:10}}>
                                    {Object.keys(docDetail).map((key,index)=>{
                                        if(index % 2 !== 0){
                                            return(
                                                <tr>
                                                    <td><p style={{margin:2}}>{docDetail[key].docTypeName}</p></td>
                                                    <td>
                                                        <Button
                                                            disabled = {docDetail[key].path ? false:true}
                                                            onClick={()=>window.open(docDetail[key].path,"_blank")}
                                                        >
                                                            
                                                                <p style={{margin:2}}>{docDetail[key].path ? 'View':'Not Found'}</p>
                                                            
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <DeleteIcon
                                                            style={{cursor:docDetail[key].path?'pointer':null,color:docDetail[key].path ?'#53459A':'gray'}}
                                                            onClick = {()=>docDetail[key].path ? deleteUploaded(docDetail[key].path):null}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        
                                        
                                    })}
                                    
                                </table>
                            </div>
                            
                        </div>
                    )}
                    
                    <div
                        style = {{
                            margin: '0.5%',
                            // boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
                            // borderRadius:5,
                            // height:'40vh',
                            padding:10,
                            color: '#53459A'
                        }}
                    >
                        {/* <p style={{textAlign:'center', fontWeight:'bold'}}>Upload Documents</p> */}
                        <div style={{whiteSpace:'nowrap', overflow:'auto'}}>
                            {/* {(detailedUserProfile.vendors.length > 0  && detailedUserProfile.vendors[0].mUserDocType.length > 0)?( */}
                                {Object.keys(docDetail).map(key=>{
                                    // if(docDetail.hasOwnProperty(item.docTypeID)){
                                        return (
                                            <div style={{display:'inline-block', margin:20}}>
                                                <Grid className={classes.style} >
                                                    <Grid className={classes.alignItems}>
                                                    <Grid className={classes.HeadingStyle}>
                                                        <p>{docDetail[key].docTypeName}</p>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid className={classes.UploadStyle}>
                                                        <Grid className={classes.UploaderStyle}>
                                                            <FileUploader
                                                            multiple={false}
                                                            handleChange={(file)=>handleChange(file,docDetail[key].docTypeID)}
                                                            name={key}
                                                            types={fileTypes}
                                                            className={classes.UploadBoxStyle}
                                                            />
                                                        </Grid>
                                                        {/* <Checkbox checked={docDetail.file1.isUploaded} 
                                                        className={classes.CheckBox}
                                                        /> */}
                                                        </Grid>
                                                        <div>
                                                            <p className={classes.FileUploadText}>
                                                                {docDetail[key] && docDetail[key].value
                                                                ? `File name: ${docDetail[key].value.name}`
                                                                : "No files uploaded"}
                                                            </p>
                                                            <div>
                                                                <Button
                                                                    className={classes.UploadBtn}
                                                                    type="submit"
                                                                    color="primary"
                                                                    variant="contained"
                                                                    disabled={docDetail[key] && docDetail[key].value ? false : true}
                                                                    onClick={() => {
                                                                        uploadDocument(docDetail[key].value, key);
                                                                    }}
                                                                >
                                                                    Upload
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )
                                    // }
                                    
                                })}
                            {/* ):null} */}
                        </div>
                        
                    </div>
                    <Grid className={classes.SubmitBtnPlacement} >
                            <Button
                                className={classes.SubmitBtn}
                                type="submit"
                                color="primary"
                                variant="contained"
                                onClick={()=>{
                                    props.getData({
                                        status: 1,
                                        moveToScreen: 6,
                                    }); 
                                }}
                            >
                                Continue
                            </Button>
                        </Grid>
                        {/* <p style={{color:'red',textAlign:'center'}}>Note : This can be skipped by toggling the left panel</p> */}
                </Paper>
            </Box>
            
        )
    }
    
    return(
        <div>
            {container}
        </div>
    )
}

export default UploadDoc