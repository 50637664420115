import React,{useState} from "react";
import LeftMenu from './LeftMenu';
import Container from "./MainComponent/Container";
// import Header from "./Header";
import Header from "../Components/Header/Header"
const AdminDashboardContainer = ()=>{
    let [menuOption,setMenuOption] = useState(1)
    let container = null;
    container = (
        <div>
            <div>
                <Header/>
            </div>
            <div style={{display:'flex'}}>
                <div style={{width:'82%', padding:10}}>
                    <Container menuOption = {menuOption}/>
                </div>
                <div style={{width:'18%', height:'89vh', padding:10,background:'teal'}}>
                    <LeftMenu getData = {(option)=>{setMenuOption(option)}} />
                </div>
            </div>
        </div>
        
    )
    return(
        <div>
            {container}
        </div>
    )
}

export default AdminDashboardContainer