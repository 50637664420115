import React,{useState, useEffect} from 'react';
import axios from "../../../../axios_tteg";
import { Button, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import classes from "../../../Register/Upload/UploadDoc.module.css";
import { FileUploader } from "react-drag-drop-files";
import DownloadIcon from '@mui/icons-material/Download';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';


const MachineManagement = ()=>{
    const [machineTypes,setMachineTypes] = useState([]);
    const [openAdd,setOpenAdd] = useState(false);
    const [openEdit,setOpenEdit] = useState(false);
    const [newMachine,setNewMachine] = useState({
        machineTypeName:null,
        machineDescription:[{
            modelNo:null,
            OEM:null,
            capacity:null
        }]
    });
    const [error,setError] = useState(null);
    const [catalogue,setCatalogue] = useState({
        value:null,
        isUploaded:false,
        path: null,
    });
    const [loading,setLoading] = useState(false);
    const fileTypes = ["PDF","JPG","JPEG"];
    const [expanded, setExpanded] = useState(false);

    const handleChangeAccordian = (index) => {
        if(index === expanded)
            setExpanded(null)
        else
            setExpanded(index);
    };

    const deleteHandler = (index,innerIndex)=>{
        
    }
    
    useEffect(()=>{
        setLoading(true)
        axios.get('/getMachineType')
        .then(response=>{
            setLoading(false);
            setMachineTypes(response.data.machineType)
        })
        .catch(e=>console.log(e))
    },[])

    const AddMachine = ()=>{
        let isValid = true;
        isValid = isValid && newMachine.machineTypeName;
        newMachine.machineDescription.map(item=>{
            if(item.modelNo && item.OEM && item.capacity){
                isValid = isValid && true;
            }
            else{
                isValid = isValid && false;
            }
        })
        
        if(isValid){
            setLoading(true)
            axios.post('/updateMachineTypeMaster',{
                machineTypeID:null,
                machineTypeName:newMachine.machineTypeName,
                machineDescription:newMachine.machineDescription,
                path:null,
                approved:1
            })
            .then(response=>{
                setLoading(false);
                alert('Added Successfully');
                setNewMachine({
                    machineTypeName:null,
                    machineDescription:[{
                        modelNo:null,
                        OEM:null,
                        capacity:null
                    }]
                });
                setOpenAdd(false);
            })
            .catch(e=>{
                setLoading(false);
                console.log(e)
            });
        }
        else{
            alert("Please fill all data marked with *");
        }
        
    }

    const updateModel = (machineTypeID,index)=>{
        setLoading(true)
        axios.post('/updateMachineTypeMaster',{
            machineTypeID:machineTypeID,
            machineTypeName:machineTypes[index].machineTypeName,
            machineDescription:machineTypes[index].machineDescription,
            approved:machineTypes[index].approved,
            path:machineTypes[index].path
        })
        .then(response=>{
            setLoading(false);
            alert('Updated Successfully');
        })
        .catch(e=>{
            setLoading(false);
            console.log(e)
        });
    }

    const deleteModel = (machineTypeID)=>{ 
        setLoading(true)
            axios.post('/deleteMachineTypeMaster',{
                machineTypeID:machineTypeID
            })
            .then(response=>{
                setLoading(false);
                alert('Deleted Successfully');
            })
            .catch(e=>{
                setLoading(false);
                console.log(e)
            });
    }

    const addNewItemHandler = ()=>{
        console.log("hi")
    }

    let container = null;
    console.log(newMachine)
    if(openAdd){
        container = (
            <div>
                <Dialog
                    open={openAdd}
                    onClose={()=>setOpenAdd(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Add New Machine
                    </DialogTitle>
                    <DialogContent style = {{padding:20}}>
                        <DialogContentText id="alert-dialog-description">
                            <div >
                                <TextField
                                    id="outlined-required"
                                    label="Machine Type*"
                                    value = {newMachine.machineTypeName}
                                    onChange={(e)=>{
                                        let tempNewMachine = {... newMachine};
                                        tempNewMachine.machineTypeName = e.target.value;
                                        setNewMachine(tempNewMachine)
                                    }}
                                    size = 'small'
                                    style={{margin:10}}
                                />
                                <table style={{padding:10,background:'aliceblue'}}>
                                    {newMachine.machineDescription.map((item,index)=>(
                                        <tr style={{margin:10}}>
                                            <td >
                                                <TextField
                                                    id="outlined-required"
                                                    label="Model No*"
                                                    value = {item.modelNo ? item.modelNo : ''}
                                                    onChange={(e)=>{
                                                        let tempNewMachine = {... newMachine};
                                                        tempNewMachine.machineDescription[index].modelNo = e.target.value;
                                                        setNewMachine(tempNewMachine)
                                                    }}
                                                    size = 'small'
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    id="outlined-required"
                                                    label="OEM*"
                                                    value = {item.OEM ? item.OEM:''}
                                                    onChange={(e)=>{
                                                        let tempNewMachine = {... newMachine};
                                                        tempNewMachine.machineDescription[index].OEM = e.target.value;
                                                        setNewMachine(tempNewMachine)
                                                    }}
                                                    size = 'small'
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    id="outlined-required"
                                                    label="Capacity*"
                                                    value = {item.capacity ? item.capacity : ''}
                                                    type='number'
                                                    InputProps={{
                                                        inputProps: { min: 0 }
                                                    }}
                                                    onChange={(e)=>{
                                                        let tempNewMachine = {... newMachine};
                                                        tempNewMachine.machineDescription[index].capacity = e.target.value < 0 ? e.target.value * -1 : e.target.value;
                                                        setNewMachine(tempNewMachine)
                                                    }}
                                                    size = 'small'
                                                />
                                            </td>
                                            {index === newMachine.machineDescription.length-1 ? (
                                                <td>
                                                    <div style={{display:'flex'}}>
                                                        <DeleteIcon 
                                                            style = {{color:'teal',cursor:'pointer'}}
                                                            onClick = {()=>{
                                                                
                                                                if(newMachine.machineDescription.length > 1){
                                                                    let tempNewMachine = {... newMachine}
                                                                    tempNewMachine.machineDescription.splice(index,1)
                                                                    setNewMachine(tempNewMachine)
                                                                }
                                                                else{
                                                                    alert("Cannot delete")
                                                                }
                                                                
                                                            }}
                                                        />
                                                        <AddCircleIcon 
                                                            style = {{color:'teal',cursor:'pointer'}}
                                                            onClick = {()=>{
                                                                
                                                                if(newMachine.machineDescription[index].modelNo && newMachine.machineDescription[index].OEM && newMachine.machineDescription[index].capacity){
                                                                    let tempNewMachine = {... newMachine}
                                                                    tempNewMachine.machineDescription.push({
                                                                        modelNo:null,
                                                                        OEM:null,
                                                                        capacity:null
                                                                    })
                                                                    setNewMachine(tempNewMachine)
                                                                }
                                                                
                                                            }}
                                                        />
                                                    </div>
                                                    
                                                    
                                                </td>
                                            ):(
                                                <td>
                                                    <DeleteIcon 
                                                        style = {{color:'teal',cursor:'pointer'}}
                                                        onClick = {()=>{
                                                            
                                                            if(newMachine.machineDescription.length >1){
                                                                let tempNewMachine = {... newMachine}
                                                                tempNewMachine.machineDescription.splice(index,1)
                                                                setNewMachine(tempNewMachine)
                                                            }
                                                            else{
                                                                alert("Cannot delete")
                                                            }
                                                            
                                                        }}
                                                    />
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </table>
                                <p style={{color:'red', fontSize:'small'}}>{error}</p>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenAdd(false)}>Cancel</Button>
                        <Button onClick={()=>AddMachine()} autoFocus>
                            Submit
                        </Button>
                    </DialogActions>
                    
                </Dialog>
            </div>
        )
    }
    else if(loading){
        container=(
            <div>
                <CircularProgress/>
            </div>
        )
    }
    else{
        container = (
            <div>
                <div style={{margin:5, padding:5}}>
                    <div style={{background: 'teal', color: 'white'}}>
                        <p style={{padding: 10, fontSize: 16, fontWeight: 'bold'}}>Material Management</p>
                    </div>
                    {machineTypes.map((item,index)=>{
                        return(
                            <Accordion expanded={expanded === index} onChange={()=>handleChangeAccordian(index)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {item.machineTypeName}
                                    </Typography>
                                    <Typography >
                                        <DeleteIcon 
                                            style={{cursor:'pointer', color:'teal'}}
                                            onClick = {(e)=>{
                                                e.stopPropagation();
                                                if (window.confirm("Are you sure you want to delete") == true) {
                                                    let tempMachineTypes = [... machineTypes];
                                                    tempMachineTypes.splice(index,1);
                                                    setMachineTypes(tempMachineTypes);
                                                    deleteModel(item.machineTypeID);
                                                }
                                                else{
                                                    console.log('cancelled')
                                                }
                                            }}
                                        />
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{background:'aliceBlue'}}>
                                    <Typography>
                                        {item.machineDescription.map((innerItem,innerIndex)=>{
                                            return(
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td style={{padding:'5px 10px'}}>
                                                                <TextField
                                                                    id="outlined-required"
                                                                    label="Model Number*"
                                                                    value = {innerItem.modelNo}
                                                                    onChange={(e)=>{
                                                                        let tempMachineTypes = [... machineTypes];
                                                                        tempMachineTypes[index].machineDescription[innerIndex].modelNo = e.target.value;
                                                                        setMachineTypes(tempMachineTypes)
                                                                    }}
                                                                    size = 'small'
                                                                />
                                                            </td>
                                                            <td style={{padding:'5px 10px'}}>
                                                                <TextField
                                                                    id="outlined-required"
                                                                    label="OEM*"
                                                                    value = {innerItem.OEM}
                                                                    onChange={(e)=>{
                                                                        let tempMachineTypes = [... machineTypes];
                                                                        tempMachineTypes[index].machineDescription[innerIndex].OEM = e.target.value;
                                                                        setMachineTypes(tempMachineTypes)
                                                                    }}
                                                                    size = 'small'
                                                                />
                                                            </td>
                                                            <td style={{padding:'5px 10px'}}>
                                                                <TextField
                                                                    id="outlined-required"
                                                                    label="Capacity*"
                                                                    value = {innerItem.capacity}
                                                                    onChange={(e)=>{
                                                                        let tempMachineTypes = [... machineTypes];
                                                                        tempMachineTypes[index].machineDescription[innerIndex].capacity = e.target.value;
                                                                        setMachineTypes(tempMachineTypes)
                                                                    }}
                                                                    size = 'small'
                                                                />
                                                            </td>
                                                            {innerIndex === item.machineDescription.length -1 ?(
                                                                <td>
                                                                    <DeleteIcon 
                                                                        style={{cursor:'pointer', color:'teal'}}
                                                                        onClick = {()=>{
                                                                            if(machineTypes[index].machineDescription.length > 1){
                                                                                if (window.confirm("Are you sure you want to delete") == true) {
                                                                                    let tempMachineTypes = [... machineTypes];
                                                                                    tempMachineTypes[index].machineDescription.splice(innerIndex,1);
                                                                                    setMachineTypes(tempMachineTypes);
                                                                                    updateModel(item.machineTypeID,index)
                                                                                } else {
                                                                                    console.log('deleted')
                                                                                }
                                                                            }
                                                                            else{
                                                                                alert('Cannot delete as there should be atleast one model associated with machine type')
                                                                            }
                                                                        }}
                                                                    />
                                                                    <AddCircleIcon 
                                                                        style = {{color:'teal',cursor:'pointer'}}
                                                                        onClick = {()=>{
                                                                            console.log(item.machineDescription[innerIndex]);
                                                                            if(item.machineDescription[innerIndex].modelNo && item.machineDescription[innerIndex].OEM && item.machineDescription[innerIndex].capacity){
                                                                                let tempMachineTypes = [... machineTypes];
                                                                                tempMachineTypes[index].machineDescription.push({
                                                                                    modelNo:null,
                                                                                    OEM:null,
                                                                                    capacity:null
                                                                                })
                                                                                setMachineTypes(tempMachineTypes)
                                                                            }
                                                                            else{
                                                                                alert("Please fill all data marked with *");
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                            ):(
                                                                <td>
                                                                    <DeleteIcon 
                                                                        style={{cursor:'pointer', color:'teal'}}
                                                                        onClick = {()=>{
                                                                            if(machineTypes[index].machineDescription.length > 1){
                                                                                if (window.confirm("Are you sure you want to delete") == true) {
                                                                                    let tempMachineTypes = [... machineTypes];
                                                                                    tempMachineTypes[index].machineDescription.splice(innerIndex,1);
                                                                                    setMachineTypes(tempMachineTypes);
                                                                                    updateModel(item.machineTypeID,index)
                                                                                } else {
                                                                                    console.log('deleted')
                                                                                }
                                                                            }
                                                                            else{
                                                                                alert('Cannot delete as there should be atleast one model associated with machine type')
                                                                            }
                                                                        }}
                                                                    />
                                                                </td>
                                                            )}
                                                            
                                                        </tr>
                                                    </table>
                                                </div>
                                            )
                                        })}
                                        
                                    </Typography>
                                    <Typography>
                                        <div
                                            style={{
                                                textAlign: 'right',
                                                padding: '0px 10px',
                                                display:'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <Button
                                                onClick = {()=>{updateModel(item.machineTypeID,index)}}
                                            >
                                                Update
                                            </Button>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                    
                </div>
                <div
                    style={{
                        textAlign: 'right',
                        padding: '0px 10px'
                    }}
                >
                    <Button
                        onClick = {()=>{
                            setOpenAdd(true);
                        }}
                    >
                        Add New
                    </Button>
                </div>
            </div>
        )
    }

    return(
        <div>
            {container}
        </div>
    )
}

export default MachineManagement