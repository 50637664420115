import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Typography from '@mui/material/Typography';
import mapStyles from "../../../Maps/Map/mapStyles";
import classes from "./Map.module.css";
import compass from "../../../../assets/images/compass.png";
import satellite from "../../../../assets/images/satellite.png";
import roadMap from "../../../../assets/images/roadMap.png";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';

const mapContainerStyle = {
    height: "80vh",
    width: "100%",
};
  
const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

const center = {
    lat: 28.70406,
    lng: 77.102493,
};

const mapViewStyle = {
    width:50,
    margin:10,
    boxShadow: '0 2px 3px #ccc',
    border: '1px solid #eee',
    cursor:"pointer"
}

const libraries = ["places"];
const Map = (props)=>{
    const [mapView,setMapView] = React.useState("roadmap");
    const [latLng,setLatLng] = React.useState({});
    const [totalDistance,setTotalDistance] = React.useState(null);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyC_kRxUGZMpYA361uWGeGohZW5PYqCoj4k",
        libraries,
    });
    const [openSelect,setOpenSelect] = useState(false);
    const [error,setError] = useState(null);

    useEffect(()=>{
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLatLng({
                    lat:position.coords.latitude,
                    lng:position.coords.longitude,
                    time:new Date()
                })
                center.lat = position.coords.latitude;
                center.lng = position.coords.longitude;
            },
            () => null
          );
    },[])
    const panTo = React.useCallback(({ lat, lng }) => {
        try{
          mapRef.current.panTo({ lat, lng });
        }
        catch(err){
          alert(err);
        }
        mapRef.current.setZoom(16);
    }, []);

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);

    function Locate({ panTo }) {
        return (
          <button
            className={classes.locate}
            onClick={() => {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatLng({
                        lat:position.coords.latitude,
                        lng:position.coords.longitude,
                        time:new Date()
                    })
                  panTo({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                },
                () => null
              );
            }}
          >
            <img src={compass} alt="compass" />
          </button>
        );
    }
    const onMarkerDragEnd = (coord) => {
        const { latLng } = coord;
        setLatLng({
            lat:latLng.lat(),
            lng:latLng.lng()
        });
      };

    const addNew = ()=>{
        setError(null);
        if(totalDistance){
            props.getData({
                latLng:latLng,
                totalDistance:totalDistance
            })
        }
        setError('PLease fill all data marked with *')
    }

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";

    return(
        <Typography style = {{width:'100%'}}>
            <Locate panTo={panTo} />
            <ContextMenuTrigger id="contextmenu">
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    mapTypeId = {mapView}
                    zoom={18}
                    onClick={(e)=>{
                        const { latLng } = e;
                        setLatLng({
                            lat:latLng.lat(),
                            lng:latLng.lng()
                        });
                        setOpenSelect(true);
                        
                    }}
                    options={options}
                    onLoad={onMapLoad} // when map loads it should be in the browserLocation
                    clickableIcons = {false}
                    center = {center}
                >
                    <Marker
                        draggable = {true}
                        label = {{text:`(${latLng.lat},${latLng.lng})`,color:'#fff'}}
                        position={{ lat:latLng.lat, lng: latLng.lng }}
                        onClick={(e)=>{
                            const { latLng } = e;
                            setLatLng({
                                lat:latLng.lat(),
                                lng:latLng.lng()
                            });
                            setOpenSelect(true);
                            
                        }}
                        onDragEnd = {(e)=>{
                            onMarkerDragEnd(e);
                        }}
                        />
                </GoogleMap>
                <div 
                    style={
                        {position:"absolute",
                        bottom:35,right:56,
                        background:'white',
                        borderRadius:5
                    }}>
                    <div style={{display:'flex'}}>
                        <img 
                            src = {roadMap} 
                            alt = "RoadMap"
                            style = {
                            mapView === 'roadmap' ? 
                            mapViewStyle: {width:50,margin:10,cursor:"pointer"}
                            } 
                            onClick = {()=>setMapView('roadmap')}/>
                        <img 
                            src = {satellite} 
                            alt = "Satellite"
                            style = {
                            mapView === 'satellite' ?
                            mapViewStyle: {width:50,margin:10,cursor:"pointer"}
                            } 
                            onClick = {()=>setMapView('satellite')}/>
                    </div>
                </div>
            
            </ContextMenuTrigger>
            {openSelect ? (
                <Dialog onClose={()=>setOpenSelect(false)} open={openSelect}>
                    <Typography style = {{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                        <DialogTitle>Are you sure you want to select</DialogTitle>
                        <CloseIcon onClick = {()=>setOpenSelect(false)} style = {{padding:10, cursor:'pointer'}}/>
                    </Typography>
                    
                    <Typography>
                        <FormControl style = {{width:520}}>
                            <div style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
                                <TextField 
                                    id="outlined-basic" 
                                    label="Latitude in degrees *" 
                                    variant="outlined" 
                                    value = {latLng.lat}
                                    style = {{margin:10}}
                                    size = 'small'
                                    disabled = {true}

                                />
                                <TextField 
                                    id="outlined-basic" 
                                    label="Longitude in degrees *" 
                                    variant="outlined" 
                                    value = {latLng.lng}
                                    style = {{margin:10}}
                                    size = 'small'
                                    disabled = {true}
                                />
                            </div>
                            
                            
                            <TextField
                                id="outlined-multiline-static"
                                label="Total Distance (in KM) *"
                                value = {totalDistance}
                                onChange={(e)=>setTotalDistance(e.target.value)}
                                style = {{margin:10}}
                            />
                            <div style={{textAlign:'center', padding:10}}>
                                <Button variant="outlined" onClick={()=>addNew()}> Submit</Button>
                            </div>
                            
                        </FormControl>
                        <p style={{textAlign:'center',color:'red', fontSize:'small'}}>{error}</p>
                    </Typography>
                </Dialog>
            ):null}
            
        </Typography>
        
        
    )
}

export default Map