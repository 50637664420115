import React,{useState, useEffect} from "react";
import logo from "../../Resources/final-lo1.png";
import {FormControl, InputLabel, Select, MenuItem, AppBar} from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import classes from "./Header.module.css";
import Cookies from "universal-cookie";
import LogoutIcon from '@mui/icons-material/Logout';
import axios from "../../../../axios_tteg";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from "react-router-dom";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';

const cookies = new Cookies();

const Header = (props)=>{
    let navigate = useNavigate();
    const [screen, setScreen] = useState(0);
    const [role, setRole] = useState("");
    const [openMenu,setOpenMenu] = useState(false);
    // const name = cookies.get('userData').name;
    
    const handleChange = (event, newValue) => {
      console.log(newValue);
      props.getData(event.target.value);
        setScreen(event.target.value);
    };

    const getLocation = async (resourceID)=>{
        props.getData('logout');
        if (navigator.geolocation) {
            await navigator.geolocation.watchPosition(function(position) {
                axios.post('/updateLoginLog',{
                    latitude:position.coords.latitude,
                    longitude:position.coords.longitude,
                    resourceID:resourceID,
                    status:'logout'
                })
                .then(response=>{
                    console.log(response);
                    
                    cookies.remove('userData', {path:"/"})
                    window.location.reload(false);
                    props.getData('completed');
                })
                .catch(e=>console.log(e))
            });
        }
        
        // window.location.reload(false);
    }

    const moveToProfile = () => {
        navigate("/profile");
    };

    const moveToLocation = ()=>{
        navigate("/myLocation")
    }
    const moveToExpense = ()=>{
        navigate("/EMS")
    }

    const moveToPM = ()=>{
        navigate("/PMS")
    }

    let container = null;
    container = (
        <div className='header'>    
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" style={{backgroundColor: 'white', justifyContent:'space-between'}}>
                    <Toolbar id='tool' style = {{display:'flex', justifyContent:'space-between'}}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="primary"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <img src={logo} alt="Logo" className={classes.Logo} onClick = {()=>navigate('/')}/>
                        </IconButton>
                        {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{color: 'black'}}>
                            <FormControl id='specific'> 
                                <Select onChange={handleChange} value={screen} size="small">
                                    <MenuItem value={0} onClick={() => setScreen(0)} >Task Manager</MenuItem>
                                    <MenuItem value={1} onClick={() => setScreen(1)}>Planning</MenuItem>
                                </Select>
                            </FormControl>                            
                        </Typography> */}

                        {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{color: 'black',fontSize:'14px'}} className='menit'>
                            {role === "Planner" ? <text>Role - Planner</text> : <text>Role - </text>}
                        </Typography> */}

                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{color: 'black',fontSize:'14px', padding:10, textAlign:'right'}} className='menit'>
                            Hi {cookies.get('userData')? cookies.get('userData').name :"User"} !
                        </Typography>
                        <IconButton
                            size="large"
                            edge="start"
                            style={{color: 'black'}}
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <SettingsIcon className='menit'id='specific' 
                                onMouseEnter={() => setOpenMenu(true)}
                                /> 
                            <MenuIcon id='menubtn'
                                onClick = {() => setOpenMenu(!openMenu)}
                            />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
            {openMenu?(
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    style = {{position:'absolute', border:'0.5px solid lightgray', width:200, right:0, zIndex:2}}
                    onMouseLeave={() => setOpenMenu(false)}
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick = {()=>{
                                navigate('/');
                                cookies.remove('userData', {path:"/"})
                                window.location.reload(false);
                                }}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItemButton>
                        </ListItem>
                        {cookies.get('userData').resourceTypeID === 'T'? null:(
                            <ListItem disablePadding>
                                <ListItemButton onClick = {()=>{
                                    moveToLocation()
                                }}>
                                    <ListItemIcon>
                                        <LocationOnIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="My Locations" />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {cookies.get('userData').resourceTypeID === 'T'? null:(
                            <ListItem disablePadding>
                                <ListItemButton onClick={()=>{
                                    moveToProfile()
                                }}>
                                    <ListItemIcon>
                                        <AccountCircleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Profile" />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {cookies.get('userData').resourceTypeID === 'T'? null:(
                            <ListItem disablePadding>
                                <ListItemButton onClick={()=>{
                                    moveToExpense()
                                }}>
                                    <ListItemIcon>
                                        <CurrencyRupeeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="EMS" />
                                </ListItemButton>
                            </ListItem>
                        )}
                        {cookies.get('userData').resourceTypeID === 'T'? null:(
                            <ListItem disablePadding>
                                <ListItemButton onClick={()=>{
                                    moveToPM()
                                }}>
                                    <ListItemIcon>
                                        <AssignmentLateIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="PMS" />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Box>
            ):null}
        </div>
    )
    return(
        <div>
            {container}
        </div>
    )
}

export default Header