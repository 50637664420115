import React,{useState} from 'react';
import classes from './LeftMenu.module.css'

const LeftMenu = (props)=>{
    const [menuOption,setMenuOption] = useState(1)
    let container = null;
    container = (
        <div>
            <div 
                className={menuOption === 1 ? classes.ActiveMenu:classes.DeactiveMenu}
                onClick = {()=>{
                    setMenuOption(1);
                    props.getData(1);
                }}
            >
                <p>Case Management</p>
            </div>
            <div 
                className={menuOption === 2 ? classes.ActiveMenu:classes.DeactiveMenu}
                onClick = {()=>{
                    setMenuOption(2);
                    props.getData(2);
                }}>
                <p>User Management</p>
            </div>
            <div 
                className={menuOption === 3 ? classes.ActiveMenu:classes.DeactiveMenu}
                onClick = {()=>{
                    setMenuOption(3);
                    props.getData(3);
                }}>
                <p>Material Management</p>
            </div>
            <div 
                className={menuOption === 4 ? classes.ActiveMenu:classes.DeactiveMenu}
                onClick = {()=>{
                    setMenuOption(4);
                    props.getData(4);
                }}>
                <p>Service Management</p>
            </div>
            <div 
                className={menuOption === 5 ? classes.ActiveMenu:classes.DeactiveMenu}
                onClick = {()=>{
                    setMenuOption(5);
                    props.getData(5);
                }}>
                <p>Machine Management</p>
            </div>
        </div>
    )
    return(
        <div>
            {container}
        </div>
    )
}

export default LeftMenu