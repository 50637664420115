import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import './index.css';
import App from './App';
import PreRegistrationContainer from './Components/PreRegistration/PreRegistrationContainer';
import Profile from './Components/Dashboard/Components/Profile/Profile';
import MyLocation from './Components/Dashboard/Components/MyLocation/MyLocation';
import ExpenseManagementSystem from './Components/ExpenseManagement/ExpenseManagementSystem';
import ProjectManagementSystem from './Components/ProjectManagement/Project';
import reportWebVitals from './reportWebVitals';
import Cookies from "universal-cookie";
const cookies = new Cookies();

ReactDOM.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route path="/register" element={<PreRegistrationContainer />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/myLocation" element={<MyLocation />}></Route>
        <Route path="/EMS" element={<ExpenseManagementSystem />}></Route>
        <Route path="/PMS" element={<ProjectManagementSystem />}></Route>
      </Routes>
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
